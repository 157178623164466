import React from 'react'

import moment from 'moment'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import CloseIcon from '../../assets/icons/ic_close_white.svg'
import {updateModal} from '../../redux/data/modal/ModalActions'
import {DarkGrey} from '../../styles/_theme'
import {MainText, ClickableBox} from '../../styles/BasicStyles'
import JourneyBlockComponent from '../journey_block/JourneyBlockComponent'
import {
  CardContainer,
  CardTimeHeader,
  CardTask,
  CardTaskText
} from './CardsStyles'

const JourneyVersionCard = ({
  item,
  translate,
  isFeed,
  marginH,
  onClose,
  dispatch
}) => {
  if (!item.journey) return null
  const header =
    item.journey && item.journey.goal ? item.journey.goal : false

  return (
    <CardContainer
      marginH={marginH}
      isFeed={isFeed}
      style={{flex: isFeed ? 1 : 0}}
      onClick={() =>
        dispatch(
          updateModal({
            type: 'version',
            object: item
          })
        )
      }
    >
      {header && (
        <CardTask>
          <CardTaskText>
            {header && `${translate('FROM')} ${header}`}
          </CardTaskText>
          {onClose && (
            <ClickableBox
              onClick={(e) => {
                e.stopPropagation()
                onClose(item)
              }}
            >
              <img src={CloseIcon} alt='close' />
            </ClickableBox>
          )}
        </CardTask>
      )}
      <CardTimeHeader>{moment(item.createdAt).fromNow()}</CardTimeHeader>

      <MainText
        color={DarkGrey}
        margin='12px 11px'
        style={{
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden'
        }}
      >
        {translate('JOURNEY_VERSION_DESCRIPTION')}
      </MainText>
      <div style={{flex: 1, justifyContent: 'flex-end'}}>
        <JourneyBlockComponent
          onPressItem={() =>
            dispatch(
              updateModal({
                type: 'version',
                object: item
              })
            )
          }
          item={item.journey}
          isFull
          height={isFeed ? '190px' : '140px'}
        />
      </div>
    </CardContainer>
  )
}

JourneyVersionCard.propTypes = {
  item: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  isFeed: PropTypes.bool,
  onClose: PropTypes.func,
  marginH: PropTypes.number,
  dispatch: PropTypes.func.isRequired
}

JourneyVersionCard.defaultProps = {
  marginH: 0,
  onClose: undefined,
  isFeed: false
}

const mapStateToProps = (state) => ({
  activeJourney: state.user.activeJourney
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(JourneyVersionCard)
)
