import styled from 'styled-components'

export const NotificationsSection = styled.div`
  display: inline-block;
  width: calc(100% + 48px);
  margin: 0 -24px;
  height: calc(100vh - 80px);
`

export const NotificationsList = styled.div`
  display: inline-block;
  width: 100%;
  height: calc(100vh - 80px);
  padding: 20px 24px;
  overflow-y: scroll;
`
