import styled from 'styled-components'

import {
  PrimaryColor,
  white,
  ThirdColor,
  LightGey,
  DarkGey,
  DarkGrey
} from '../../styles/_theme'
import ImageComponent from '../images/ImageComponent'

export const TaskHeaderButtons = styled.div`
  display: flex;
  width: 100%;
  background-color: ${PrimaryColor};
  color: ${white};
  height: 62px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`

export const TaskArrowBtn = styled.div`
  padding: 19px 14px;
  cursor: pointer;
`

export const TaskCenter = styled.div`
  color: ${white};
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TaskCompletionBtn = styled.button`
  padding: 19px 14px;
  cursor: pointer;
  background-color: ${({color}) => color || ThirdColor};
  color: ${white};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  height: 62px;
  white-space: nowrap;
  outline: none;
  transition: all 0.3s ease;
  width: 100%;
  border: none;

  &:hover,
  &:visited,
  &:active {
    outline: none;
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
  }
`

export const TaskCompletionClickable = styled.div`
  padding: 19px 14px;
  cursor: pointer;
  background-color: ${({color}) => color || ThirdColor};
  color: ${white};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  height: 62px;
  white-space: nowrap;

  &:hover,
  &:visited,
  &:active {
    outline: none;
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
  }
`

export const TaskImage = styled.div`
  width: 100%;
  height: 320px;
  ratio: 0;
  background-color: ${LightGey};
  overflow: hidden;
`

export const TaskBgImage = styled(ImageComponent)``

export const TaskBg = styled.div`
  height: ${({height}) => height || '320px'};
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;
`

export const TaskInfo = styled.div`
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  margin-bottom: 30px;
  color: ${DarkGrey};
`

export const StepTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  margin-bottom: 16px;
  color: ${DarkGey};
`

export const TaskTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 24px;
  color: ${DarkGrey};
  margin-bottom: 20px;
`

export const NextStepBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 16px;
  right: 0px;
  z-index: 3;

  &.animated {
    animation: animation 0.3s 1;
  }

  @keyframes animation {
    0% {
      right: -30px;
    }
    25% {
      right: -20px;
    }
    50% {
      right: -15px;
    }
    75% {
      right: -10px;
    }
    100% {
      right: 0px;
    }
  }
`
