import React, {useState} from 'react'

import {Slider, DatePicker, TimePicker} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
// import AddToCalendar from 'react-add-to-calendar'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import AddToCalendarComponent from '../../components/add_calendar/AddToCalendar'
import BaseButton from '../../components/buttons/BaseButton'
import {ActiveButtonGroup} from '../../components/buttons/ButtonsStyles'
import {EditJourney} from '../../infra/requests/JourneyRequests'
import {updateActiveJourney} from '../../redux/data/user/UserActions'
import {
  CustomStepPanel,
  CustomStepLabel,
  SliderVal,
  SetReminder,
  DateTimeBlock
} from './ActiveJourneyStyles'

const CustomStep = ({translate, step, journey, dispatch}) => {
  const [sliderVal, setSliderVal] = useState(
    step && step.completion ? step.completion : 0
  )
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState(moment())
  // const [dateTime, setDateTime] = useState(moment())
  const [event, setEvent] = useState({
    title: `${journey.title} - ${step.name}`,
    description: '',
    location: '',
    startTime: moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    endTime: moment().add(30, 'm').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    url: `/activate-journey/${journey._id}`
  })
  const [time, setTime] = useState(
    moment()
      .set('hour', moment().get('hour') + 1)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0)
  )

  const onChangeDate = (d) => {
    setDate(d)
    const dt = moment()
      .set('year', moment(d).get('year'))
      .set('month', moment(d).get('month'))
      .set('day', moment(d).get('day'))
      .set('hour', moment(time).get('hour'))
      .set('minute', moment(time).get('minute'))
      .set('second', 0)
      .set('millisecond', 0)

    // setDateTime(dt)

    const eventConfig = {
      title: `${journey.title} - ${step.name}`,
      description: '',
      location: '',
      startTime: moment(dt).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      endTime: moment(dt)
        .add(30, 'm')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      url: `/activate-journey/${journey._id}`
    }

    setEvent(eventConfig)
  }

  const onChangeTime = (t) => {
    setTime(t)
    const dt = moment()
      .set('year', moment(date).get('year'))
      .set('month', moment(date).get('month'))
      .set('day', moment(date).get('day'))
      .set('hour', moment(t).get('hour'))
      .set('minute', moment(t).get('minute'))
      .set('second', 0)
      .set('millisecond', 0)

    // setDateTime(dt)

    const eventConfig = {
      title: `${journey.title} - ${step.name}`,
      description: '',
      location: '',
      startTime: moment(dt).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      endTime: moment(dt)
        .add(30, 'm')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      url: `/activate-journey/${journey._id}`
    }

    setEvent(eventConfig)

    // SetTheLocalNotificationStep(activeJourney, step, date, translate);
  }

  const onSave = async () => {
    setLoading(true)
    const steps = []
    journey.steps.map((s) => {
      if (s._id === step._id) {
        const newStep = {...s, completion: sliderVal}
        steps.push(newStep)
      } else {
        steps.push(s)
      }
      return s
    })

    const editedJourney = {...journey, steps}
    try {
      const {data} = await EditJourney(journey._id, editedJourney)

      dispatch(
        updateActiveJourney(
          {active_journey: data}
          // translate,
          // true
        )
      )
    } catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <CustomStepPanel>
      <BaseButton
        style={{float: 'right'}}
        className='small'
        BaseBtn='button'
        onClick={onSave}
        label={translate('SAVE')}
        category='secondary'
        disabled={loading}
      />
      <CustomStepLabel>
        {translate('CHANGE_YOUR_PROGRESS_HERE')}
      </CustomStepLabel>
      <Slider
        style={{
          width: '100%',
          height: 10,
          margin: '0px 0px 10px'
        }}
        min={0}
        max={100}
        step={1}
        value={sliderVal || 0}
        onChange={(val) => setSliderVal(val)}
      />
      <SliderVal>{sliderVal}%</SliderVal>
      <CustomStepLabel>
        {translate('WOULD_YOU_LIKE_TO_SET_REMINDER')}
      </CustomStepLabel>
      <SetReminder>
        {translate('SET_REMINDER')}
        <DateTimeBlock>
          <DatePicker
            onChange={onChangeDate}
            value={date}
            bordered={false}
            placeholder={translate('SET_REMINDER')}
            suffixIcon={<></>}
            format='DD MMM YYYY'
          />
          <TimePicker
            onChange={onChangeTime}
            value={time}
            bordered={false}
            suffixIcon={<></>}
            format='HH:mm'
          />
        </DateTimeBlock>
      </SetReminder>
      <ActiveButtonGroup>
        <AddToCalendarComponent
          event={event}
          buttonLabel={translate('ADD_TO_CALENDAR')}
        />
      </ActiveButtonGroup>
    </CustomStepPanel>
  )
}

CustomStep.propTypes = {
  translate: PropTypes.func.isRequired,
  step: PropTypes.object.isRequired,
  journey: PropTypes.object,
  dispatch: PropTypes.func.isRequired
}

CustomStep.defaultProps = {
  journey: undefined
}

const mapStateToProps = (state) => ({
  journey: state.user.active_journey
})

const mapActionToProps = (dispatch) => ({dispatch})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(CustomStep)
)
