export const GeneralName = id => `general_${id}`;
export const GeneralMessage = id => `general_${id}_message`;
export const ChannelName = id => `channel_${id}`;
export const ChannelMessage = id => `channel_${id}_message`;
export const PrivateName = (myId, otherId) => {
  const n1 = parseInt(myId.match(/\d/g).join(''));
  const n2 = parseInt(otherId.match(/\d/g).join(''));
  if (n1 >= n2) return `private_${n1}_${n2}`;
  return `private_${n2}_${n1}`;
};
export const PrivateMessage = (myId, otherId) => {
  const n1 = parseInt(myId.match(/\d/g).join(''));
  const n2 = parseInt(otherId.match(/\d/g).join(''));
  if (n1 >= n2) return `private_${n1}_${n2}_message`;
  return `private_${n2}_${n1}_message`;
};
