import React, {useRef, useState} from 'react'

import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import ReCAPTCHA from 'react-google-recaptcha'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import AlertService from '../../../components/alert/AlertService'
import BaseButton from '../../../components/buttons/BaseButton'
import TextInput from '../../../components/input/TextInput'
import {ForgotPassword} from '../../../infra/requests/BaseRequests'
import FormValidator from '../../../infra/services/validations/FormValidator'
import {InputErrorColor} from '../../../styles/_theme'
import {
  PageForm,
  AccountTextContainer,
  AccountText,
  SmallText
} from '../../../styles/BasicStyles'
import {Text} from '../../lessons/LessonPageStyles'
import {LoginActions} from '../LoginPageStyles'

const ValidateForgotPassword = FormValidator.make({
  email: 'required|email'
})

const ForgotPage = ({translate, router, goLogin}) => {
  const [confirm, setConfirm] = useState(false)
  const [error, setError] = useState(false)
  const recaptchaRef = useRef()

  const onSubmit = async (fields) => {
    try {
      const recaptchaValue = recaptchaRef.current.getValue()

      if (!recaptchaValue) return setError(translate('MUST_CHECK_RECAPTCHA'))

      const result = await ForgotPassword({...fields, recaptchaValue})
      if (result.success) {
        AlertService.success('SUCCESS')
        // router.history.push('/')
        setConfirm(true)
      } else if (result.message?.includes('NO_RECAPTCHA_KEY')) {
        setError(translate('NO_RECAPTCHA_KEY'))
      } else if (result.message?.includes('ERROR_VALIDATING_RECAPTCHA')) {
        setError(translate('ERROR_VALIDATING_RECAPTCHA'))
      } else {
        setError(translate('INVALID_EMAIL'))
      }
    } catch (e) {
      console.warn(e)
    }
  }

  return (
    <>
      <Form onSubmit={onSubmit} validate={ValidateForgotPassword}>
        {({handleSubmit, pristine, valid, submitting}) => (
          <PageForm onSubmit={handleSubmit}>
            {confirm ? (
              <Text>{translate('FORGOT_CONFIRM_DESCRIPTION')}</Text>
            ) : (
              <>
                <Text>{translate('FORGOT_PASSWORD_DESCRIPTION')}</Text>
                <Field
                  auth
                  component={TextInput}
                  hasTopSpace={30}
                  name='email'
                  label={translate('EMAIL')}
                  placeholder='email@email.com'
                />
                {error && (
                  <SmallText
                    style={{marginTop: 10}}
                    color={InputErrorColor}
                  >
                    {error}
                  </SmallText>
                )}
                <LoginActions>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  />
                  <BaseButton
                    icon
                    category='primary'
                    label={translate('RETRIEVE')}
                    type='submit'
                    disabled={submitting || pristine || !valid}
                  />
                </LoginActions>
              </>
            )}
          </PageForm>
        )}
      </Form>
      <AccountTextContainer>
        <AccountText onClick={goLogin}>
          {translate('GO_TO_LOGIN')}
        </AccountText>
      </AccountTextContainer>
    </>
  )
}

ForgotPage.propTypes = {
  translate: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  goLogin: PropTypes.func.isRequired
}

const mapStateToProps = () => ({})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(ForgotPage)
)
