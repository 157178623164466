import React from 'react'

import {PropTypes} from 'prop-types'

import {
  InputBox,
  InputLabel,
  TextInputStyled,
  PasswordIconContainer,
  PasswordIcon,
  InputSubContainer
} from './InputStyles'

const TextInput = ({
  input,
  label,
  meta,
  typeText,
  placeholder,
  afterChange,
  min,
  max,
  hasTopSpace,
  disabled,
  auth,
  icon,
  onTouchIcon
}) => {
  const handleChange = (event) => {
    input.onChange(event.target.value)
    if (typeof afterChange === 'function') {
      afterChange(event.target.value)
    }
  }

  const hasError = meta.invalid && meta.submitFailed

  return (
    <InputBox hasTopSpace={hasTopSpace}>
      {label && <InputLabel auth={auth}>{label}</InputLabel>}
      <InputSubContainer>
        <TextInputStyled
          auth={auth}
          hasError={hasError}
          type={typeText}
          placeholder={placeholder}
          value={input.value}
          onChange={handleChange}
          disabled={disabled}
          min={min}
          max={max}
        />
        {icon && onTouchIcon && (
          <PasswordIconContainer onClick={onTouchIcon}>
            <PasswordIcon src={icon} />
          </PasswordIconContainer>
        )}
      </InputSubContainer>
    </InputBox>
  )
}

TextInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  typeText: PropTypes.string,
  afterChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  hasTopSpace: PropTypes.number,
  disabled: PropTypes.bool,
  auth: PropTypes.bool,
  icon: PropTypes.string,
  onTouchIcon: PropTypes.func
}

TextInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  label: undefined,
  placeholder: undefined,
  meta: {},
  typeText: 'text',
  afterChange: undefined,
  min: undefined,
  max: undefined,
  hasTopSpace: undefined,
  disabled: false,
  auth: false,
  icon: undefined,
  onTouchIcon: undefined
}

export default TextInput
