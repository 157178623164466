import React, {useState, useEffect} from 'react'

import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import MessagePage from '../../pages/message/MessagePage'
import {PageContainer, LoadingSpin} from '../../styles/BasicStyles'

const UnauthenticatedRoute = ({route, ready, routerProps}) => {
  const [showMessage, setShowMessage] = useState(false)
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) setShowMessage(true)
      else setShowMessage(false)
    })
  }, [])

  if (!ready) {
    return <LoadingSpin min='100vh' size='large' />
  }

  if (window.innerWidth < 768 || showMessage) {
    return <MessagePage />
  }

  const PageComponent = route.component
  return (
    <PageContainer>
      <PageComponent router={routerProps} />
    </PageContainer>
  )
}

const mapStateToProps = (state) => ({
  ready: state.info.ready
})

UnauthenticatedRoute.propTypes = {
  ready: PropTypes.bool,
  route: PropTypes.object,
  routerProps: PropTypes.object
}

UnauthenticatedRoute.defaultProps = {
  ready: false,
  route: {
    path: '/',
    component: [],
    shouldRenderHeader: false,
    shouldRenderFooter: false
  },
  routerProps: {}
}

export default connect(mapStateToProps)(UnauthenticatedRoute)
