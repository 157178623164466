import styled from 'styled-components'

import {
  NavbarSize,
  PrimaryColor,
  ErrorColor,
  lightBlue2,
  ThirdColor,
  white,
  SuccessColor
} from '../../styles/_theme'
import device from '../../styles/Responsive'

export const HeaderNavbar = styled.nav`
  height: ${NavbarSize};
  background-color: ${PrimaryColor};
  display: inline-block;
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  overflow: hidden;
  padding: 0 30px;
`

export const HeaderLogo = styled.img`
  display: inline-block;
  width: 40px;
  margin-right: 60px;

  @media ${device.laptop} {
    margin-right: 50px;
  }
`

export const HeaderMenu = styled.div`
  display: inline-block;
`

export const NavbarItem = styled.a`
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: calc(${NavbarSize} - 2px);
  color: white;
  text-align: center;
  width: 125px;
  cursor: pointer;
  position: relative;
  border-bottom: 2px solid
    ${({active}) => (active ? 'white' : 'transparent')};

  &:hover,
  &:active,
  &:visited {
    color: white;
  }
`

export const Bubble = styled.div`
  border-radius: 50%;
  background: ${white};
  padding: 10px;
  position: absolute;
  right: ${({position}) => position || 100}px;
  top: 18px;
  cursor: pointer;
  display: flex;
  height: 46px;
  width: 46px;
  align-items: center;
  justify-content: center;

  ::after {
    display: ${({notification}) => (notification ? 'block' : 'none')};
    position: absolute;
    top: 0px;
    right: 0px;
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${ErrorColor};
  }

  &.active {
    background: ${ThirdColor};

    svg path {
      fill: ${white};
    }
  }

  &:hover,
  &:visited,
  &:active {
    background-color: ${lightBlue2};
    svg path {
      fill: ${ThirdColor};
    }
  }
`

export const BubbleIcon = styled.img`
  width: 24px;
  height: 24px;
`

export const HeaderActiveJourneyBlock = styled.div`
  display: inline-flex;
  width: 350px;
  @media ${device.laptop} {
    display: none;
  }

  @media ${device.laptopL} {
    width: 250px;
  }
`

export const HeaderActiveJourneyTitle = styled.div`
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: ${white};
  width: calc(100% - 24px);
`

export const GreenBox = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 0 0 12px 0 ${({color}) => color || SuccessColor};
  background-color: ${({color}) => color || SuccessColor};
  margin-right: 8px;
`
