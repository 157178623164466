import {createAction} from '@reduxjs/toolkit'

export const updateInactiveJourneys = createAction(
  'inactive_journeys/save',
  (data) => ({
    payload: data
  })
)

export const removeInactiveJourney = createAction(
  'inactive_journeys/remove',
  (data) => ({
    payload: data
  })
)
