import React, {useState} from 'react'

import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'

import AlertService from '../../components/alert/AlertService'
import BaseButton from '../../components/buttons/BaseButton'
import TextInput from '../../components/input/TextInput'
import {JoinGroups} from '../../infra/requests/ProfileRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {PageForm} from '../../styles/BasicStyles'
import {
  MainDescription,
  EnterButton,
  ButtonGroupLeft,
  ButtonGroupRight,
  MenuTitle,
  BlockContainer,
  LabelError
} from './SettingsStyles'

const ValidateJoinGroup = FormValidator.make({
  code: 'required'
})

const JoinGroup = ({translate, onSave}) => {
  const [error, setError] = useState(false)

  const onSubmit = async (values) => {
    try {
      const result = await JoinGroups({...values})
      if (result.success) {
        setError(false)
        AlertService.success(translate('SAVED_SUCCESS_JOIN_GROUP'))
        if (onSave) onSave()
      } else {
        setError(result.message === 'ORGANIZATION_HAS_MAX_NUMBER_USERS' ? translate(result.message) : translate('JOIN_GROUP_ERROR_MESSAGE'))
      }
    } catch (e) {
      console.warn(e)
    }
  }

  return (
    <>
      <MenuTitle spaceT={30} spaceB={36}>
        {translate('JOIN_GROUP')}
      </MenuTitle>
      <BlockContainer>
        <MainDescription>
          {translate('ENTER_GUEST_CODE_DESCRIPTION')}
        </MainDescription>
        <Form onSubmit={onSubmit} validate={ValidateJoinGroup}>
          {({handleSubmit, form, valid, pristine, submitting}) => (
            <PageForm
              onSubmit={(event) => {
                const promise = handleSubmit(event).then(() => {
                  form.reset()
                })
                return promise
              }}
            >
              <Field
                component={TextInput}
                name='code'
                refField='code'
                hasTopSpace={30}
                label={translate('CODE')}
              />
              {error && <LabelError>{error}</LabelError>}
              <EnterButton>
                <ButtonGroupLeft />
                <ButtonGroupRight>
                  <BaseButton
                    icon
                    disabled={submitting || pristine || !valid}
                    category='enter'
                    type='submit'
                    label={translate('ENTER')}
                  />
                </ButtonGroupRight>
              </EnterButton>
            </PageForm>
          )}
        </Form>
      </BlockContainer>
    </>
  )
}

JoinGroup.propTypes = {
  translate: PropTypes.func.isRequired,
  onSave: PropTypes.func
}

JoinGroup.defaultProps = {
  onSave: undefined
}

export default withLocalize(JoinGroup)
