import React, {useState, useEffect} from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {SRLWrapper} from 'simple-react-lightbox'

import DefaultImg from '../../assets/default_custom.png'
import {ReactComponent as CloseIcon} from '../../assets/icons/ic_close_blue.svg'
import {ReactComponent as Settings} from '../../assets/icons/ic_settings.svg'
import NoJourneyImg from '../../assets/no_journey.png'
import BookmarksList from '../../components/bookmarks/BookmarksList'
import BaseButton from '../../components/buttons/BaseButton'
import FeedbacksList from '../../components/feedbacks/FeedbacksList'
import RequestFeedbackModal from '../../components/feedbacks/RequestFeedbackModal'
import {JourneyBottom} from '../../components/journey_block/JourneyBlockComponentStyles'
import ProgressBarComponent from '../../components/progress_bar/ProgressBarComponent'
import CreateReflection from '../../components/reflections/CreateReflection'
import ReflectionsList from '../../components/reflections/ReflectionsList'
import TasksComponent from '../../components/task/TasksComponent'
import {TaskImage} from '../../components/task/TaskStyles'
import VideoPlayer from '../../components/video/VideoPlayer'
import {
  CompleteJourney,
  ToggleJourneyArchived
} from '../../infra/requests/JourneyRequests'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {
  CalcJourneySteps,
  GetH5PRatio
} from '../../infra/utils/CommonFunctions'
import {updateModal} from '../../redux/data/modal/ModalActions'
import {updateActiveJourney} from '../../redux/data/user/UserActions'
import {GetAndUpdateArchivedJourneys} from '../../redux/thunks/UpdateThunk'
import {ThirdColor, LightGey} from '../../styles/_theme'
import {
  JourneyHeaderStepsGroup,
  Clickable,
  JourneyHeaderStepsIcon,
  ClickableDiv,
  InformationPageScroll,
  NoJourneyBlock,
  NoJourneyImage,
  NoJourneyInfoBlock,
  HeaderTitle,
  H5P
} from '../journeys/JourneyStyles'
import {
  JourneyCard,
  InformationPage,
  InformationContainer,
  BookmarksBox,
  CountNumber,
  CountLabel,
  SettingsButton,
  JourneyDescription,
  JourneyLabel,
  JourneyTitle,
  JourneyIntention,
  CreateButton,
  JourneyTop,
  CompleteBtn,
  CloseBtn
} from './ActiveJourneyStyles'
import CustomSteps from './CustomSteps'
import JourneySettings from './JourneySettings'
import StepsList from './StepsList'

const GetImage = (media, code) => {
  switch (media?.type) {
    case 'images':
      return (
        <TaskImage style={{height: '100%', maxHeight: 380}}>
          <img src={media.file.url} style={{width: '100%'}} alt='media' />
        </TaskImage>
      )
    case 'videos': {
      const video = GetLanguageValue(media.file.url, code)
      return <VideoPlayer video={video} height='380px' width='100%' />
    }
    case 'h5p':
    case 'embedded': {
      const file = GetLanguageValue(media.file.iframe, code)
      const r = GetH5PRatio(file)
      return (
        <H5P
          style={{
            paddingTop: r.isPx || `${r}%`,
            overflow: 'hidden',
            marginBottom: 35
          }}
          dangerouslySetInnerHTML={{
            __html: file
          }}
        />
      )
    }
    default:
      return <img src={DefaultImg} style={{width: '100%'}} alt='media' />
  }
}

const ActiveJourneyPage = ({
  dispatch,
  journey,
  router,
  activeLanguage,
  translate
}) => {
  const location = useLocation()
  const [section, setSection] = useState(
    location?.hash ? location.hash.replace('#', '') : 'steps'
  )
  const [step, setStep] = useState(undefined)
  const [index, setIndex] = useState(undefined)
  const [requestFeedbackModal, setRequestFeedbackModal] = useState(
    undefined
  )

  useEffect(() => {
    setSection(location?.hash ? location.hash.replace('#', '') : 'steps')
  }, [location])

  const onPressCompleteJourney = async () => {
    try {
      await CompleteJourney(journey._id)
      router.history.push('/journeys')
      dispatch(updateActiveJourney({active_journey: null}))
      dispatch(GetAndUpdateArchivedJourneys())
    } catch (e) {
      console.warn(e)
    }
  }

  const onPressArchiveJourney = async () => {
    try {
      await ToggleJourneyArchived(journey._id)
      router.history.push('/journeys')
      dispatch(updateActiveJourney({active_journey: null}))
      dispatch(GetAndUpdateArchivedJourneys())
    } catch (e) {
      console.warn(e)
    }
  }

  const renderContent = () => {
    switch (section) {
      case 'settings':
        return (
          <JourneySettings
            journey={journey}
            onClickBack={() => setSection('steps')}
          />
        )
      case 'new_reflections':
        return <CreateReflection onClickBack={() => setSection('steps')} />
      case 'tasks':
        let nextStep
        let nextStepIndex
        if (journey.guided_steps[index + 1]) {
          nextStep = journey.guided_steps[index + 1]
          nextStepIndex = index + 1
        }

        return (
          <TasksComponent
            step={step}
            nextStep={nextStep}
            nextStepIndex={nextStepIndex}
            index={index + 1}
            onClickBack={() => setSection('steps')}
            onClickNextStep={(s, i) => {
              // setSection('tasks')
              setStep(s)
              setIndex(i)
            }}
          />
        )
      case 'reflections':
        return <ReflectionsList onClickBack={() => setSection('steps')} />
      case 'feedbacks':
        return <FeedbacksList onClickBack={() => setSection('steps')} />
      case 'bookmarks':
        return <BookmarksList onClickBack={() => setSection('steps')} />
      default:
        return (
          <div>
            {journey.type === 'guided' ? (
              <StepsList
                onClickStep={(s, i) => {
                  setSection('tasks')
                  setStep(s)
                  setIndex(i)
                }}
              />
            ) : (
              <CustomSteps />
            )}
          </div>
        )
    }
  }

  return (
    <>
      {journey ? (
        <>
          <JourneyCard>
            <SRLWrapper hideControlsAfter='100'>
              {GetImage(journey.media, activeLanguage.code)}
            </SRLWrapper>
            {section !== 'settings' && section !== 'new_reflections' && (
              <SettingsButton onClick={() => setSection('settings')}>
                <Settings />
              </SettingsButton>
            )}
            <div style={{position: 'relative'}}>
              <BookmarksBox
                onClick={() => setSection('feedbacks')}
                isSelected={section === 'feedbacks'}
              >
                <CountNumber isSelected={section === 'feedbacks'}>
                  {journey.feedbacks.length}
                </CountNumber>
                <CountLabel isSelected={section === 'feedbacks'}>
                  {translate('FEEDBACKS')}
                </CountLabel>
              </BookmarksBox>
              <BookmarksBox
                left={1}
                onClick={() => setSection('reflections')}
                isSelected={section === 'reflections'}
              >
                <CountNumber isSelected={section === 'reflections'}>
                  {journey.reflections.length}
                </CountNumber>
                <CountLabel isSelected={section === 'reflections'}>
                  {translate('REFLECTIONS')}
                </CountLabel>
              </BookmarksBox>
              <BookmarksBox
                left={2}
                onClick={() => setSection('bookmarks')}
                isSelected={section === 'bookmarks'}
              >
                <CountNumber isSelected={section === 'bookmarks'}>
                  {journey.bookmarks.length}
                </CountNumber>
                <CountLabel isSelected={section === 'bookmarks'}>
                  {translate('BOOKMARKS')}
                </CountLabel>
              </BookmarksBox>
            </div>
            <JourneyDescription style={{paddingTop: 90}}>
              <JourneyTop>
                <ClickableDiv onClick={() => setSection('steps')}>
                  <JourneyLabel>
                    {translate('ACTIVE_JOURNEY')}
                  </JourneyLabel>
                  <JourneyTitle>{journey.title}</JourneyTitle>
                  <JourneyHeaderStepsGroup spaceB={16}>
                    {!journey.validation &&
                    moment(journey.review_date) > moment() ? (
                      <div style={{whiteSpace: 'nowrap'}}>
                        {translate('REVIEW_IN')}{' '}
                        {moment(journey.review_date).fromNow(true)}
                      </div>
                    ) : journey?.validation?.completed ? (
                      <Clickable
                        onClick={(e) => {
                          e.stopPropagation()
                          dispatch(
                            updateModal({
                              type: 'validation',
                              object: journey.validation,
                              isShare: true
                            })
                          )
                        }}
                      >
                        {translate('VIEW_REVIEW')}
                      </Clickable>
                    ) : (
                      <Clickable
                        onClick={(e) => {
                          e.stopPropagation()
                          dispatch(
                            updateModal({
                              type: 'validation',
                              object: journey.validation,
                              isShare: true
                            })
                          )
                        }}
                      >
                        {translate('REVIEW_NOW')}
                      </Clickable>
                    )}
                    <JourneyHeaderStepsIcon />
                    <div style={{whiteSpace: 'nowrap'}}>
                      {CalcJourneySteps(journey)} {translate('STEPS')}
                    </div>
                  </JourneyHeaderStepsGroup>
                  <JourneyIntention>{journey.intention}</JourneyIntention>
                </ClickableDiv>
                <ProgressBarComponent
                  progress={`${journey.completion}%`}
                  borderRadius={19}
                  height={22}
                  marginB={32}
                  color={ThirdColor}
                  unfilledColor={LightGey}
                />
              </JourneyTop>
              {section !== 'new_reflections' && (
                <JourneyBottom>
                  {section === 'settings' ? (
                    <CreateButton
                      category='secondary'
                      label={translate('ARCHIVE_JOURNEY')}
                      onClick={onPressArchiveJourney}
                    />
                  ) : (
                    <>
                      <CreateButton
                        category='secondary'
                        label={translate('NEW_REFLECTION')}
                        onClick={() => setSection('new_reflections')}
                      />
                      <CreateButton
                        category='secondary'
                        label={translate('REQUEST_FEEDBACK')}
                        onClick={() => setRequestFeedbackModal(true)}
                      />
                    </>
                  )}
                </JourneyBottom>
              )}
            </JourneyDescription>
            {journey.completion === '100' && (
              <CompleteBtn onClick={onPressCompleteJourney}>
                {translate('TAP_TO_COMPLETE')}
              </CompleteBtn>
            )}
          </JourneyCard>
          <InformationPage>
            <InformationContainer>{renderContent()}</InformationContainer>
            {requestFeedbackModal && (
              <RequestFeedbackModal
                open={requestFeedbackModal}
                onClose={() => setRequestFeedbackModal(false)}
                onSave={() => setRequestFeedbackModal(false)}
              />
            )}
          </InformationPage>
        </>
      ) : (
        <InformationPageScroll>
          <InformationContainer>
            <Row>
              <Col xs={24}>
                <CloseBtn onClick={() => router.history.goBack()} showBg>
                  <CloseIcon />
                </CloseBtn>
              </Col>
              <NoJourneyBlock style={{marginTop: 63}}>
                <NoJourneyImage ratio={0} image={{url: NoJourneyImg}} />
                <NoJourneyInfoBlock>
                  <HeaderTitle>
                    {translate('DONT_HAVE_ACTIVE_JOURNEY')}
                  </HeaderTitle>
                  <BaseButton
                    type='button'
                    onClick={() => router.history.push('/create-journey')}
                    label={translate('CREATE_CUSTOM_JOURNEY')}
                    category='tertiary'
                    style={{marginTop: 24}}
                  />
                </NoJourneyInfoBlock>
              </NoJourneyBlock>
            </Row>
          </InformationContainer>
        </InformationPageScroll>
      )}
    </>
  )
}

ActiveJourneyPage.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  journey: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired
}

ActiveJourneyPage.defaultProps = {
  journey: undefined
}

const mapStateToProps = (state) => ({
  journey: state.user.active_journey
})

const mapActionToProps = (dispatch) => ({dispatch})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(ActiveJourneyPage)
)
