import React from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import DefaultImg from '../../assets/default_custom.png'
import IconPlay from '../../assets/icons/ic_play.svg'
import NoJourneyImg from '../../assets/no_journey.png'
import BaseButton from '../../components/buttons/BaseButton'
import ChartComponent from '../../components/chart/ChartComponent'
import ProgressBarComponent from '../../components/progress_bar/ProgressBarComponent'
import {ContainerTitle} from '../../components/sliders/SliderStyles'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {
  CalcJourneySteps,
  GetH5PRatio
} from '../../infra/utils/CommonFunctions'
import {ThirdColor, LightGey} from '../../styles/_theme'
import {VideoBox} from '../../styles/BasicStyles'
import {JourneyImage} from '../active_journey/ActiveJourneyStyles'
import {
  JourneyHeaderStepsGroup,
  Clickable,
  JourneyHeaderStepsIcon,
  NoJourneyBlock,
  NoJourneyImage,
  NoJourneyInfoBlock,
  HeaderTitle
} from '../journeys/JourneyStyles'
import {
  CardContainer,
  JourneyTitle,
  JourneyInfo,
  H5P
} from './DashboardStyles'

const GetImage = (media, code) => {
  switch (media?.type) {
    case 'images':
      return <JourneyImage image={media.file} ratio={0.6} marginB={16} />
    case 'videos': {
      const file = {
        url: GetLanguageValue(media.file.thumbnail, code),
        color: '#fff',
        type: media.type
      }
      return (
        <VideoBox isAuto>
          <img
            src={IconPlay}
            alt='video'
            style={{position: 'absolute', zIndex: 1}}
          />
          <JourneyImage image={file} ratio={0.6} marginB={16} />
        </VideoBox>
      )
    }
    case 'h5p':
    case 'embedded': {
      const file = GetLanguageValue(media.file.iframe, code)
      const r = GetH5PRatio(file)
      return (
        <H5P
          style={{
            paddingTop: r > 60 ? '60%' : r.isPx || `${r}%`
          }}
          dangerouslySetInnerHTML={{
            __html: file
          }}
        />
      )
    }
    default:
      return (
        <JourneyImage image={{url: DefaultImg}} ratio={0.6} marginB={16} />
      )
  }
}

const ActiveJourney = ({journey, translate, activeLanguage, router}) => {
  if (!journey) {
    return (
      <NoJourneyBlock style={{marginTop: 0}}>
        <NoJourneyImage ratio={0} image={{url: NoJourneyImg}} />
        <NoJourneyInfoBlock>
          <HeaderTitle>
            {translate('DONT_HAVE_ACTIVE_JOURNEY')}
          </HeaderTitle>
          <BaseButton
            type='button'
            onClick={() =>
              router.history.push('/journeys#suggested_journeys')
            }
            label={translate('SELECT_A_JOURNEY')}
            category='tertiary'
            style={{marginTop: 60, width: '80%'}}
          />
          <BaseButton
            type='button'
            onClick={() => router.history.push('/create-journey')}
            label={translate('CREATE_CUSTOM_JOURNEY')}
            category='tertiary'
            style={{marginTop: 32, width: '80%'}}
          />
        </NoJourneyInfoBlock>
      </NoJourneyBlock>
    )
  }

  const nFeedbacks =
    journey !== null
      ? journey.feedbacks
          .map((x) => {
            const question = x.questions?.find((y) => y.type === 3)
            if (question) {
              return Number.parseInt(question.response, 10)
            }
            return 0
          })
          .reverse()
          .filter((arg) => arg !== 0)
      : []
  const nReflections =
    journey !== null
      ? journey.reflections
          .map((x) => {
            let question = false
            if (x.questions) {
              question = x.questions?.find((y) => y.type === 3)
            }
            if (x.response?.questions) {
              question = x.response.questions?.find((y) => y.type === 3)
              if (question) {
                const questions3 = x.response.questions.filter(
                  (x) => x.type === 3
                )
                let count = 0
                questions3.map((q) => {
                  count += parseInt(q.response)
                  return q
                })
                question = {
                  ...question,
                  response: parseInt(count / questions3.length)
                }
              }
            }
            if (question) {
              return Number.parseInt(question.response, 10)
            }
            return 0
          })
          .reverse()
          .filter((arg) => arg !== 0)
      : []

  const total = Math.max(nFeedbacks.length, nReflections.length)
  let list = []
  for (let i = 0; i < total; i++) {
    list.push({
      name: i + 1,
      feedbacks: nFeedbacks[i] || undefined,
      reflections: nReflections[i] || undefined
    })
  }

  if (!list.length) {
    list = [
      {
        name: '1',
        feedbacks: undefined,
        reflections: undefined
      }
    ]
  }

  return (
    <div
      style={{cursor: 'pointer', height: '100%'}}
      onClick={() => router.history.push('/active-journey')}
    >
      <CardContainer style={{padding: 0, marginBottom: 0, height: '100%'}}>
        {GetImage(journey.media, activeLanguage.code)}
        <JourneyInfo>
          <ContainerTitle>{translate('ACTIVE_JOURNEY')}</ContainerTitle>
          <JourneyTitle>{journey.title}</JourneyTitle>
          <JourneyHeaderStepsGroup spaceB={16}>
            {!journey.validation &&
            moment(journey.review_date) > moment() ? (
              <div style={{whiteSpace: 'nowrap'}}>
                {translate('REVIEW_IN')}{' '}
                {moment(journey.review_date).fromNow(true)}
              </div>
            ) : journey?.validation?.completed ? (
              <Clickable>{translate('VIEW_REVIEW')}</Clickable>
            ) : (
              <Clickable>{translate('REVIEW_NOW')}</Clickable>
            )}
            <JourneyHeaderStepsIcon />
            <div style={{whiteSpace: 'nowrap'}}>
              {CalcJourneySteps(journey)} {translate('STEPS')}
            </div>
          </JourneyHeaderStepsGroup>
          <ProgressBarComponent
            progress={`${journey.completion}%`}
            borderRadius={19}
            height={22}
            marginB={32}
            color={ThirdColor}
            unfilledColor={LightGey}
          />
          <ChartComponent list={list} translate={translate} />
        </JourneyInfo>
      </CardContainer>
    </div>
  )
}

ActiveJourney.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  journey: PropTypes.object,
  router: PropTypes.object.isRequired
}

ActiveJourney.defaultProps = {
  journey: false
}

export default withLocalize(ActiveJourney)
