import React from 'react'

import {PropTypes} from 'prop-types'

import SIcon from '../../assets/icons/ic_search.svg'
import {SearchSection, SearchInputStyled, SearchIcon} from './InputStyles'

const SearchInput = ({input, type, placeholder, style}) => (
  <SearchSection style={style}>
    <SearchInputStyled
      type={type}
      placeholder={placeholder}
      value={input.value}
      onChange={input.onChange}
    />
    <SearchIcon src={SIcon} />
  </SearchSection>
)

SearchInput.propTypes = {
  input: PropTypes.object,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object
}

SearchInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  placeholder: undefined,
  type: 'text',
  style: {}
}

export default SearchInput
