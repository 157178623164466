import ActivateJourneyPage from '../../pages/activate_journey/ActivateJourneyPage'
import ActiveJourneyPage from '../../pages/active_journey/ActiveJourneyPage'
import CreateJourneyPage from '../../pages/create_journey/CreateJourneyPage'
import DashboardPage from '../../pages/dashboard/DashboardPage'
import CompletedJourneyPage from '../../pages/journeys/CompletedJourneyPage'
import JourneysPage from '../../pages/journeys/JourneysPage'
import LessonPage from '../../pages/lessons/LessonPage'
import ManageLessonPage from '../../pages/lessons/ManageLessonPage'
import LibraryPage from '../../pages/library/LibraryPage'
import SettingsPage from '../../pages/settings/SettingsPage'

export default [
  {
    path: '/',
    component: DashboardPage,
    isAuthenticated: true
  },
  {
    path: '/journeys',
    component: JourneysPage,
    isAuthenticated: true
  },
  {
    path: '/library',
    component: LibraryPage,
    isAuthenticated: true
  },
  {
    path: '/settings',
    component: SettingsPage,
    isAuthenticated: true
  },
  {
    path: '/active-journey',
    component: ActiveJourneyPage,
    isAuthenticated: true
  },
  {
    path: '/create-journey',
    component: CreateJourneyPage,
    isAuthenticated: true
  },
  {
    path: '/create-journey/:id',
    component: CreateJourneyPage,
    isAuthenticated: true
  },
  {
    path: '/create-lesson',
    component: ManageLessonPage,
    isAuthenticated: true
  },
  {
    path: '/create-lesson/:id',
    component: ManageLessonPage,
    isAuthenticated: true
  },
  {
    path: '/lesson/:id',
    component: LessonPage,
    isAuthenticated: true
  },
  {
    path: '/completed-journey/:id',
    component: CompletedJourneyPage,
    isAuthenticated: true
  },
  {
    path: '/activate-journey/:id',
    component: ActivateJourneyPage,
    isAuthenticated: true
  }
]
