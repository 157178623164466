import styled from 'styled-components'

import {
  BorderColor,
  DarkGey,
  ThirdColor,
  white,
  LightGey,
  DarkGrey,
  SuccessColor,
  DefaultGey,
  PrimaryColor
} from '../../styles/_theme'

export const TabHeader = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  border-bottom: 2px solid ${BorderColor};
`

export const TabBtn = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  color: ${({isSelected}) => (isSelected ? ThirdColor : DarkGey)};
  border-bottom: 2px solid;
  border-color: ${({isSelected}) =>
    isSelected ? ThirdColor : BorderColor};
  height: 44px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LineContainer = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  padding: 12px 20px;
  height: 46px;
  margin-bottom: 4px;
  background-color: ${ThirdColor};
  color: ${white};
  justify-content: space-between;
  display: flex;
  border-radius: 23px;

  &:hover,
  &:visited,
  &:active {
    svg path {
      fill: ${PrimaryColor};
    }
  }
`

export const JourneyFormContent = styled.div`
  .ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 20px 0px 40px;
  }
`

export const LineSection = styled.div`
  height: 46px;
  background-color: ${LightGey};
  padding: 0px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({isSelected}) => (isSelected ? DarkGrey : DarkGey)};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 6px;
`

export const SelectContainer = styled.div`
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`

export const SelectCircle = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid;
  border-color: ${({isSelected}) =>
    isSelected ? SuccessColor : DefaultGey};
  border-radius: 16px;
  background-color: ${({isSelected}) =>
    isSelected ? SuccessColor : 'transparent'};
  float: right;
  align-items: center;
  justify-content: center;
  display: flex;
`

export const PrivacyOptionComponent = styled.div`
  margin-top: ${({index}) => (index ? '40px' : '0px')};
`

export const MediumText = styled.div`
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  color: ${DarkGrey};
  margin-bottom: 20px;
`

export const StyledPoliciesView = styled.div`
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`

export const SmallText = styled.div`
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  color: ${DarkGey};
`

export const StepsText = styled.div`
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  display: flex;
`
