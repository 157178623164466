import {createReducer} from '@reduxjs/toolkit'

import {updateKudos, clearKudos} from './KudosActions'

const initialState = false

const KudosReducer = createReducer(initialState, {
  [updateKudos]: (state, action) => action.payload,
  [clearKudos]: () => initialState
})

export default KudosReducer
