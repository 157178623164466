import React, {useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import EmptyLayoutComponent from '../../components/empty_layout/EmptyLayoutComponent'
import JourneyBlockComponent from '../../components/journey_block/JourneyBlockComponent'
import JourneyModal from '../../components/modal/JourneyModal'
import {
  SetJourneyActive,
  ToggleJourneyArchived
} from '../../infra/requests/JourneyRequests'
import {updateActiveJourney} from '../../redux/data/user/UserActions'
import {
  GetAndUpdateInactiveJourneys,
  GetAndUpdateArchivedJourneys
} from '../../redux/thunks/UpdateThunk'
import {
  InformationTitle,
  InformationContainer,
  JourneyIntention
} from '../active_journey/ActiveJourneyStyles'
import {InformationPageScroll} from './JourneyStyles'

const InactiveJourneys = ({
  translate,
  inactiveJourneys,
  dispatch,
  router
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(undefined)
  const [isDeleted, setIsDeleted] = useState(undefined)
  const [journeyModal, setJourneyModal] = useState(false)

  const onPressBtn = async (item) => {
    setIsLoading(true)
    setIsDeleted(undefined)
    try {
      setIsDeleting(item._id)
      const result = await SetJourneyActive(item._id)
      dispatch(
        updateActiveJourney({active_journey: result.data, translate})
      )
      dispatch(GetAndUpdateInactiveJourneys())
      setIsDeleting(undefined)
      setIsDeleted(item._id)
      router.history.push('/active-journey')
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onPressArchiveJourney = async (item) => {
    setIsLoading(true)
    setIsDeleted(undefined)
    try {
      setIsDeleting(item._id)
      await ToggleJourneyArchived(item._id)
      inactiveJourneys.filter((j) => j._id !== item._id)
      dispatch(GetAndUpdateInactiveJourneys())
      dispatch(GetAndUpdateArchivedJourneys())
      setIsDeleting(undefined)
      setIsDeleted(item._id)
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <InformationPageScroll>
      <InformationContainer>
        <Row>
          <Col xs={24}>
            <InformationTitle>
              {translate('INACTIVE_JOURNEYS')}
            </InformationTitle>
          </Col>
          <Col xs={24}>
            <JourneyIntention>
              {translate('INACTIVE_JOURNEYS_DESCRIPTION')}
            </JourneyIntention>
          </Col>
          {inactiveJourneys && inactiveJourneys.length > 0 ? (
            <Col xs={24}>
              {inactiveJourneys.map((item, index) => (
                <JourneyBlockComponent
                  key={index}
                  onPressItem={(i) => setJourneyModal(i)}
                  item={item}
                  height='180px'
                  onPressArchive={onPressArchiveJourney}
                  onPressBtn={onPressBtn}
                  isInactive
                  loading={isLoading}
                  isDeleting={
                    isDeleting === item._id ? item._id : undefined
                  }
                  isDeleted={isDeleted === item._id ? item._id : undefined}
                />
              ))}
            </Col>
          ) : (
            <EmptyLayoutComponent tag='EMPTY_INACTIVE_JOURNEYS' />
          )}
          {journeyModal && (
            <JourneyModal
              open={!!journeyModal}
              journey={journeyModal}
              onClose={() => setJourneyModal(false)}
              router={router}
              isInactive
              onPressArchive={() => {
                setJourneyModal(false)
                onPressArchiveJourney(journeyModal)
              }}
              onPressReactivate={() => {
                setJourneyModal(false)
                onPressBtn(journeyModal)
              }}
            />
          )}
        </Row>
      </InformationContainer>
    </InformationPageScroll>
  )
}

InactiveJourneys.propTypes = {
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  inactiveJourneys: PropTypes.array,
  router: PropTypes.object.isRequired
}

InactiveJourneys.defaultProps = {
  inactiveJourneys: undefined
}

const mapStateToProps = (state) => ({
  journey: state.user.active_journey,
  inactiveJourneys: state.inactive_journeys
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(InactiveJourneys)
)
