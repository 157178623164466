import React from 'react'

import PropTypes from 'prop-types'

import {ReactComponent as RateSelectedIcon} from '../../assets/icons/ic_rate_selected.svg'
import {ReactComponent as RateUnselectedIcon} from '../../assets/icons/ic_rate_unselected.svg'
import {
  StarRatingContainer,
  StarBtn,
  StarRatingContainerNoEditable
} from './ReviewStyles'

const StarRatingComponent = ({
  input,
  editable,
  noEditable,
  isRight,
  small,
  spacet
}) => {
  const renderStartProgress = () => {
    const StartComponent = []
    let {value} = input
    if (editable && (!value || !input.value === '')) {
      input.onChange('1')
      value = 1
    }

    for (let index = 0; index < 5; index++) {
      if (parseInt(value) >= index + 1) {
        StartComponent.push(
          <StarBtn
            onClick={() => input.onChange((index + 1).toString())}
            key={index}
            disabled={!editable}
          >
            <RateSelectedIcon className='selected' />
          </StarBtn>
        )
      } else {
        StartComponent.push(
          <StarBtn
            onClick={() => input.onChange((index + 1).toString())}
            key={index}
            disabled={!editable}
          >
            <RateUnselectedIcon />
          </StarBtn>
        )
      }
    }
    return StartComponent
  }

  const showStarProgress = () => {
    const StartComponent = []
    let {value} = input
    if (editable && (!value || !input.value === '')) {
      input.onChange('1')
      value = 1
    }
    for (let index = 0; index < 5; index++) {
      if (parseInt(value) >= index + 1) {
        StartComponent.push(
          <div style={{marginRight: 10}} key={index}>
            <RateSelectedIcon
              style={{width: small ? 12 : 26, height: small ? 12 : 26}}
            />
          </div>
        )
      } else {
        StartComponent.push(
          <div style={{marginRight: 10}} key={index}>
            <RateUnselectedIcon
              style={{width: small ? 12 : 26, height: small ? 12 : 26}}
            />
          </div>
        )
      }
    }
    return StartComponent
  }

  return noEditable ? (
    <StarRatingContainerNoEditable
      spaceb={0}
      isRight={isRight}
      spacet={spacet}
    >
      {showStarProgress()}
    </StarRatingContainerNoEditable>
  ) : (
    <StarRatingContainer style={{margin: '25px 0px', height: 0}}>
      {renderStartProgress()}
    </StarRatingContainer>
  )
}

StarRatingComponent.propTypes = {
  input: PropTypes.object.isRequired,
  noEditable: PropTypes.bool,
  isRight: PropTypes.bool,
  editable: PropTypes.bool,
  spacet: PropTypes.number,
  small: PropTypes.bool
}

StarRatingComponent.defaultProps = {
  noEditable: false,
  isRight: false,
  editable: false,
  spacet: undefined,
  small: false
}

export default StarRatingComponent
