import React from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {ReactComponent as CloseIcon} from '../../assets/icons/ic_close_blue.svg'
import IconPlay from '../../assets/icons/ic_play.svg'
import {RemoveUpdatedInfoTask} from '../../infra/requests/JourneyRequests'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {
  CalculateProgress,
  GetValuePreview,
  GetH5PRatio
} from '../../infra/utils/CommonFunctions'
import {
  CloseBtn,
  GuidedStepContainer,
  GuidedStepsInfo,
  StepTasks,
  StepDescription,
  StepTitle,
  GuidedStepPhotoHeight,
  GuidedStepPhotoHeight2
} from '../../pages/active_journey/ActiveJourneyStyles'
import {getStepStatus} from '../../pages/active_journey/StepsList'
import {updateActiveJourney} from '../../redux/data/user/UserActions'
import {white, DarkGrey} from '../../styles/_theme'
import {VideoBox} from '../../styles/BasicStyles'
import BaseButton from '../buttons/BaseButton'
import {
  StepsContainer,
  StepsText
} from '../journey_block/JourneyBlockComponentStyles'
import {H5PDisabled} from '../modal/BaseModalStyles'
import ProgressBarComponent from '../progress_bar/ProgressBarComponent'
import AssignmentTask from './AssignmentTask'
import FeedbackRatingTask from './FeedbackRatingTask'
import FeedbackTask from './FeedbackTask'
import LessonTask from './LessonTask'
import ReflectionTask from './ReflectionTask'
import {NextStepBottom} from './TaskStyles'

class TasksComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTaskIndex: 1,
      totalTasks: 1,
      closeNext: false
    }
  }

  componentDidMount = () => {
    const {step, activeJourney, completedJourney} = this.props
    const journey = completedJourney || activeJourney
    const currentStep = journey.guided_steps.find(
      (s) => s._id === step._id
    )

    if (!currentStep) return
    let task = currentStep.tasks.find((t) => !t.response?.completed)
    if (!task) task = currentStep.tasks[0]

    const taskIndex = step.tasks.findIndex((t) => t._id === task._id)
    this.setState({
      currentTaskIndex: taskIndex,
      totalTasks: currentStep.tasks.length
    })
  }

  componentDidUpdate = (prevProps) => {
    const {step, completedJourney, activeJourney} = this.props

    if (prevProps?.step !== step) {
      const journey = completedJourney || activeJourney
      const currentStep = journey.guided_steps.find(
        (s) => s._id === step._id
      )

      if (!currentStep) return
      let task = currentStep.tasks.find((t) => !t.response?.completed)
      if (!task) task = currentStep.tasks[0]

      const taskIndex = step.tasks.findIndex((t) => t._id === task._id)
      this.setState({
        currentTaskIndex: taskIndex,
        totalTasks: currentStep.tasks.length
      })
    }
  }

  componentWillUnmount = () => {
    const {currentTaskIndex} = this.state
    const currentTask = this.getTask(currentTaskIndex)
    this.updateTaskView(currentTask)
  }

  updateTaskView = async (currentTask) => {
    const {step, activeJourney, dispatch} = this.props
    if (!step.updated && !currentTask.updated) return

    try {
      const result = await RemoveUpdatedInfoTask(activeJourney._id, {
        task: currentTask._id
      })
      dispatch(updateActiveJourney({active_journey: result.data}))
    } catch (error) {
      console.warn(error)
    }
  }

  previousTask = () => {
    const {currentTaskIndex} = this.state
    const {activeJourney, completedJourney} = this.props
    const journey = completedJourney || activeJourney

    const currentTask = this.getTask(currentTaskIndex)
    this.updateTaskView(currentTask)

    const {step} = this.props
    const currentStep = journey.guided_steps.find(
      (s) => s._id === step._id
    )
    if (!currentStep) return

    const task = this.getTask(currentTaskIndex - 1)
    if (!task) {
      this.setState({
        currentTaskIndex: currentStep.tasks.length - 1
      })
    } else {
      this.setState({
        currentTaskIndex: currentTaskIndex - 1
      })
    }
  }

  nextTask = () => {
    const {currentTaskIndex} = this.state
    const {activeJourney, completedJourney, onClickBack} = this.props
    const journey = completedJourney || activeJourney

    const currentTask = this.getTask(currentTaskIndex)
    this.updateTaskView(currentTask)

    const {step} = this.props
    const currentStep = journey.guided_steps.find(
      (s) => s._id === step._id
    )
    if (!currentStep) return

    const progress = CalculateProgress(currentStep)

    if (
      currentStep.tasks.length === currentTaskIndex + 1 &&
      progress == 100
    ) {
      onClickBack()
    }

    const task = this.getTask(currentTaskIndex + 1)
    if (!task) {
      this.setState({
        currentTaskIndex: 0
      })
    } else {
      this.setState({
        currentTaskIndex: currentTaskIndex + 1
      })
    }
  }

  getTask = (currentTaskIndex) => {
    const {step, activeJourney, completedJourney} = this.props
    const journey = completedJourney || activeJourney
    const currentStep = journey.guided_steps.find(
      (s) => s._id === step._id
    )
    if (!currentStep) return

    const task = currentStep.tasks[currentTaskIndex]

    return task
  }

  renderTask = () => {
    const {currentTaskIndex, totalTasks, closeNext} = this.state
    const {
      index,
      step,
      isCompleted,
      activeJourney,
      completedJourney
    } = this.props
    const journey = completedJourney || activeJourney

    const customStep = journey.guided_steps.find((s) => s._id === step._id)

    const currentTask = this.getTask(currentTaskIndex)
    // const totalTasks = customStep.tasks.length
    switch (currentTask?.type) {
      case 'lesson':
        return (
          <LessonTask
            task={currentTask}
            taskIndex={currentTaskIndex}
            stepIndex={index}
            totalTasks={totalTasks}
            nextTask={this.nextTask}
            previousTask={this.previousTask}
            idJourney={journey._id}
            isCompleted={isCompleted}
            renderNextStep={() => this.renderNextStep(closeNext)}
          />
        )
      case 'assignment':
        return (
          <AssignmentTask
            task={currentTask}
            taskIndex={currentTaskIndex}
            stepIndex={index}
            totalTasks={totalTasks}
            nextTask={this.nextTask}
            previousTask={this.previousTask}
            idJourney={journey._id}
            isCompleted={isCompleted}
            renderNextStep={() => this.renderNextStep(closeNext)}
          />
        )
      case 'reflection':
        return (
          <ReflectionTask
            task={currentTask}
            taskIndex={currentTaskIndex}
            stepIndex={index}
            totalTasks={totalTasks}
            nextTask={this.nextTask}
            previousTask={this.previousTask}
            idJourney={journey._id}
            total={customStep.tasks.length}
            isCompleted={isCompleted}
            renderNextStep={() => this.renderNextStep(closeNext)}
          />
        )
      case 'feedback':
        return (
          <FeedbackTask
            task={currentTask}
            taskIndex={currentTaskIndex}
            stepIndex={index}
            totalTasks={totalTasks}
            nextTask={this.nextTask}
            previousTask={this.previousTask}
            isCompleted={isCompleted}
            renderNextStep={() => this.renderNextStep(closeNext)}
          />
        )
      case 'feedbackrating':
        return (
          <FeedbackRatingTask
            task={currentTask}
            taskIndex={currentTaskIndex}
            stepIndex={index}
            totalTasks={totalTasks}
            nextTask={this.nextTask}
            previousTask={this.previousTask}
            isCompleted={isCompleted}
            renderNextStep={() => this.renderNextStep(closeNext)}
          />
        )
      default:
        return false
    }
  }

  renderNextStep = (closeNext) => {
    const {
      step,
      nextStep,
      nextStepIndex,
      activeJourney,
      completedJourney,
      translate,
      activeLanguage,
      onClickNextStep
    } = this.props

    const journey = completedJourney || activeJourney
    const currentStep = journey.guided_steps.find(
      (s) => s._id === step._id
    )
    const progress = CalculateProgress(currentStep)
    let showNextStep = false
    let nextStepTask
    let nextStepProgress
    let stepStatus
    if (progress == 100 && nextStep) {
      showNextStep = true
      nextStepTask = nextStep.tasks.find((t) => t.media)
      nextStepProgress = CalculateProgress(nextStep)
      stepStatus = getStepStatus(
        nextStepIndex + 1,
        nextStepProgress,
        nextStep,
        journey
      )
    }

    const showNStep = showNextStep && stepStatus !== 'locked'
    console.log('closeNext', closeNext, stepStatus, nextStep)
    if (!closeNext && showNStep) {
      return (
        <NextStepBottom className='animated'>
          <GuidedStepContainer
            noOpacity
            isCompleted={nextStepProgress == 100}
            style={{flexDirection: 'row', width: 400}}
          >
            {nextStep.updated && (
              <StepsContainer
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  boxShadow: '1px 1px 7px rgba(0,0,0,0.5)'
                }}
              >
                <StepsText>{translate('UPDATED')}</StepsText>
              </StepsContainer>
            )}
            {this.renderImage(nextStepTask, activeLanguage, {
              flex: 1,
              height: 'auto'
            })}
            <GuidedStepsInfo
              style={{
                flex: 3,
                padding:
                  nextStep.updated && !nextStepTask?.media
                    ? '40px 16px 10px'
                    : '14px 16px 10px'
              }}
            >
              <div
                style={{
                  height: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <div>
                  <CloseBtn
                    onClick={() => {
                      console.log('closeNext')
                      this.setState({closeNext: true})
                    }}
                    showBg
                    style={{
                      right: 6,
                      top: 6,
                      width: 30,
                      height: 30,
                      padding: 5
                    }}
                  >
                    <CloseIcon
                      style={{
                        width: 20,
                        height: 20
                      }}
                    />
                  </CloseBtn>
                  <StepTitle>
                    {translate('STEP')} {nextStepIndex + 1}
                  </StepTitle>
                  {/* <StepName>
                  {GetValuePreview(
                    nextStep.title,
                    activeLanguage && activeLanguage.code
                  )}
                </StepName> */}
                  {nextStep.description && (
                    <StepDescription>
                      {GetValuePreview(
                        nextStep.description,
                        activeLanguage && activeLanguage.code
                      )}
                    </StepDescription>
                  )}
                  <StepTasks>
                    {nextStep.tasks.length}{' '}
                    {nextStep.tasks.length === 1
                      ? translate('TASK')
                      : translate('TASKS')}
                  </StepTasks>
                </div>
                <div>
                  <ProgressBarComponent
                    progress={`${nextStepProgress}%`}
                    borderRadius={13}
                    width='100%'
                    height={22}
                    marginB={0}
                    color={white}
                    unfilledColor='rgba(227, 227, 227, 0.4)'
                    unfilledTextColor={white}
                    filledTextColor={DarkGrey}
                  />
                </div>
              </div>
            </GuidedStepsInfo>
          </GuidedStepContainer>
          <BaseButton
            loading={false}
            type='button'
            onClick={() => onClickNextStep(nextStep, nextStepIndex)}
            label={translate('NEXT_STEP')}
            category='secondary'
            icon
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 14,
              width: 'auto'
            }}
          />
        </NextStepBottom>
      )
    }
  }

  renderImage = (task, activeLanguage, style) => {
    switch (task?.media?.type) {
      case 'images':
        return <GuidedStepPhotoHeight2 ratio={0} image={task.media.file} />
      case 'videos': {
        const image = {
          url: GetLanguageValue(
            task.media.file.thumbnail,
            activeLanguage.code
          ),
          color: '#fff'
        }
        return (
          <VideoBox style={style}>
            <img
              src={IconPlay}
              alt='video'
              style={{position: 'absolute', zIndex: 1}}
            />
            <GuidedStepPhotoHeight ratio={0} image={image} />
          </VideoBox>
        )
      }
      case 'h5p':
      case 'embedded': {
        const file = GetLanguageValue(
          task.media.file.iframe,
          activeLanguage.code
        )
        const r = GetH5PRatio(file)
        return (
          <H5PDisabled
            style={{
              paddingTop: r.isPx || `${r}%`,
              ...style
            }}
            dangerouslySetInnerHTML={{
              __html: `${file}<div class="H5PTop" />`
            }}
          />
        )
      }
      default:
        return false
    }
  }

  render() {
    const {onClickBack} = this.props

    return (
      <Row>
        <Col xs={24}>
          <CloseBtn
            onClick={onClickBack}
            showBg
            style={{
              width: 44,
              height: 44,
              float: 'right',
              marginBottom: 30,
              position: 'relative'
            }}
          >
            <CloseIcon />
          </CloseBtn>
        </Col>
        {this.renderTask()}
      </Row>
    )
  }
}

TasksComponent.propTypes = {
  onClickBack: PropTypes.func.isRequired,
  step: PropTypes.object.isRequired,
  activeJourney: PropTypes.object,
  index: PropTypes.number,
  completedJourney: PropTypes.object,
  isCompleted: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  onClickNextStep: PropTypes.func,
  nextStep: PropTypes.object,
  nextStepIndex: PropTypes.number
}

TasksComponent.defaultProps = {
  activeJourney: undefined,
  completedJourney: undefined,
  index: 1,
  isCompleted: false,
  onClickNextStep: () => {},
  nextStep: undefined,
  nextStepIndex: undefined
}

const mapStateToProps = (state) => ({
  activeJourney: state.user.active_journey
})

const mapActionToProps = (dispatch) => ({dispatch})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(TasksComponent)
)
