import React, {useState} from 'react'

import {Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import {GetValuePreview} from '../../infra/utils/CommonFunctions'
import {white} from '../../styles/_theme'
import AttachmentsList from '../attachments/AttachmentsList'
import {Label} from '../attachments/AttachmentsStyles'
import BaseButton from '../buttons/BaseButton'
import {ActiveButtonGroup} from '../buttons/ButtonsStyles'
import FeedbackRatingCard from '../cards/FeedbackRatingCard'
import RequestFeedbackRatingModal from '../feedbacks/RequestFeedbackRatingModal'
import TaskHeader from './TaskHeader'
import {TaskInfo, TaskTitle, StepTitle} from './TaskStyles'

const FeedbackRatingTask = ({
  task,
  taskIndex,
  stepIndex,
  totalTasks,
  activeLanguage,
  nextTask,
  previousTask,
  translate,
  isCompleted,
  renderNextStep
}) => {
  const [requestFeedbackModal, setRequestFeedbackModal] = useState(
    undefined
  )

  const responses =
    task.response?.feedbacks.find((f) => f.finished) &&
    task.response.feedbacks.filter((f) => f.finished).length > 0
      ? task.response.feedbacks
      : false
  return (
    <Col xs={24}>
      <TaskHeader
        task={task}
        taskIndex={taskIndex}
        totalTasks={totalTasks}
        nextTask={nextTask}
        previousTask={previousTask}
        isFeedback
        renderNextStep={renderNextStep}
      />
      <StepTitle style={{marginTop: 16}}>
        {translate('STEP')} {stepIndex}
      </StepTitle>
      <TaskTitle>
        {GetValuePreview(
          task.title,
          activeLanguage && activeLanguage.code
        )}
      </TaskTitle>

      {task.description && (
        <TaskInfo
          dangerouslySetInnerHTML={{
            __html: GetValuePreview(
              task.description,
              activeLanguage && activeLanguage.code
            )
          }}
        />
      )}
      {task.attachments && task.attachments.length > 0 && (
        <AttachmentsList items={task.attachments} edit />
      )}
      {!isCompleted && (
        <ActiveButtonGroup>
          <BaseButton
            type='button'
            onClick={() => setRequestFeedbackModal(true)}
            label={translate('REQUEST_FEEDBACK')}
            category='secondary'
          />
        </ActiveButtonGroup>
      )}
      {responses && (
        <>
          <Label style={{marginBottom: 20}}>
            {translate('PREVIOUS_FEEDBACKS')}
          </Label>
          <FeedbackRatingCard
            isFeed
            items={responses}
            title={translate('FEEDBACKS')}
            bgColor={white}
          />
        </>
      )}
      {requestFeedbackModal && (
        <RequestFeedbackRatingModal
          open={requestFeedbackModal}
          currentTask={task}
          onClose={() => setRequestFeedbackModal(false)}
          onSave={() => setRequestFeedbackModal(false)}
          type='feedbackrating'
        />
      )}
    </Col>
  )
}

FeedbackRatingTask.propTypes = {
  task: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  taskIndex: PropTypes.number,
  totalTasks: PropTypes.number,
  stepIndex: PropTypes.number,
  previousTask: PropTypes.func.isRequired,
  nextTask: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  isCompleted: PropTypes.bool,
  renderNextStep: PropTypes.func
}

FeedbackRatingTask.defaultProps = {
  taskIndex: 1,
  totalTasks: 1,
  stepIndex: 1,
  isCompleted: false,
  renderNextStep: undefined
}

export default withLocalize(FeedbackRatingTask)
