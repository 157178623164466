import React, {useState} from 'react'

import {Row, Col} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {SRLWrapper} from 'simple-react-lightbox'

import DefaultImg from '../../assets/default_custom.png'
import {ReactComponent as CloseIcon} from '../../assets/icons/ic_close_blue.svg'
import {
  CreateJourney,
  SetJourneyActive,
  verifyIfExistJourney
} from '../../infra/requests/JourneyRequests'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {
  GetValuePreview,
  CalcJourneySteps,
  GetH5PRatio
} from '../../infra/utils/CommonFunctions'
import {JourneyDescription} from '../../pages/active_journey/ActiveJourneyStyles'
import InitialJourneyData from '../../pages/create_journey/InitialJourneyData'
import {
  JourneyHeaderStepsGroup,
  JourneyHeaderStepsIcon
} from '../../pages/journeys/JourneyStyles'
import {updateActiveJourney} from '../../redux/data/user/UserActions'
import {GetAndUpdateInactiveJourneys} from '../../redux/thunks/UpdateThunk'
import {ThirdColor, ErrorColor} from '../../styles/_theme'
import BaseButton from '../buttons/BaseButton'
import LanguageComponent from '../language/LanguageComponent'
import {TaskImage} from '../task/TaskStyles'
import VideoPlayer from '../video/VideoPlayer'
import {Modal, ModalContainer, H5P} from './BaseModalStyles'

const JourneyModal = ({
  open,
  journey,
  onClose,
  width,
  translate,
  activeLanguage,
  activity,
  dispatch,
  router,
  isInactive,
  onPressArchive,
  onPressReactivate,
  onPressDelete,
  onPressUnarchive,
  onPressCompleted,
  activeJourney,
  inactiveJourneys
}) => {
  const [lang, setLang] = useState(
    journey?.company?.languages
      ? journey?.company?.languages.find(
        (l) => l === activeLanguage.code
      ) ? activeLanguage.code
      : !journey?.company?.languages.find(
          (l) => l === activeLanguage.code
        ) && journey.company?.languages.find((l) => l === 'en')
        ? 'en'
        : journey.company?.languages[0] || activeLanguage.code
      : journey.company?.languages[0] || activeLanguage.code
  )
  const [errorMessage, setErrorMessage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const description =
    activity && journey.type === 'guided'
      ? journey.from.description
      : journey.description || journey.intention

  const renderImage = () => {
    const {media} = journey

    switch (media?.type) {
      case 'images':
        return (
          <TaskImage>
            <img
              src={media.file.url}
              style={{width: '100%'}}
              alt='media'
            />
          </TaskImage>
        )
      case 'videos': {
        const video = GetLanguageValue(media.file.url, activeLanguage.code)
        return <VideoPlayer video={video} height='320px' width='100%' />
      }
      case 'h5p':
      case 'embedded': {
        const file = GetLanguageValue(
          media.file.iframe,
          activeLanguage.code
        )
        const r = GetH5PRatio(file)
        return (
          <H5P
            style={{
              paddingTop: r.isPx || `${r}%`,
              overflow: 'hidden'
            }}
            dangerouslySetInnerHTML={{
              __html: file
            }}
          />
        )
      }

      default:
        return <img src={DefaultImg} alt='img' style={{width: '100%'}} />
    }
  }

  const quickStart = async () => {
    try {
      const result = await CreateJourney({
        title: GetValuePreview(journey.goal, activeLanguage.code),
        intention: GetValuePreview(journey.intention, activeLanguage.code),
        goal: GetValuePreview(journey.goal, activeLanguage.code),
        review_weeks: journey.review_weeks,
        type: 'guided',
        reminder: journey.reminder || InitialJourneyData.reminder,
        privacy: journey.privacy || InitialJourneyData.privacy,
        from: journey._id
      })

      if (result.success) {
        dispatch(
          updateActiveJourney({active_journey: result.data, translate})
        )
        dispatch(GetAndUpdateInactiveJourneys())
        router.history.push('/active-journey')
        onClose()
      } else if (result.message) {
        setErrorMessage(result.message)
      }
    } catch (error) {
      console.warn(error)
    }
  }

  const personalizeJourney = async () => {
    try {
      const result = await verifyIfExistJourney(journey._id)
      if (result.success) {
        onClose()
        router.history.push(`/create-journey/${journey._id}`, {
          journey,
          language: lang
        })
      } else if (result.message) {
        setErrorMessage(result.message)
      }
    } catch (error) {
      console.warn(error)
    }
  }

  const onPressActivateJourney = async () => {
    setIsLoading(true)
    try {
      let existJourney = activeJourney.from === journey._id || undefined
      if (!existJourney) {
        existJourney = inactiveJourneys.find(
          (ij) => ij.from === journey._id
        )
        if (existJourney?._id) {
          const result = await SetJourneyActive(existJourney._id)
          dispatch(
            updateActiveJourney({active_journey: result.data, translate})
          )
          dispatch(GetAndUpdateInactiveJourneys())
        }
      }
      setErrorMessage(false)
      router.history.push('/active-journey')
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      padding='0px'
      visible={open}
      onCancel={onClose}
      width={width}
      footer={null}
      closeIcon={<CloseIcon />}
    >
      <Row>
        <Col
          xs={24}
          style={{
            overflow: journey?.media?.type === 'h5p' ? 'hidden' : 'inherit'
          }}
        >
          <SRLWrapper hideControlsAfter='100'>{renderImage()}</SRLWrapper>
        </Col>
      </Row>
      <ModalContainer>
        {!activity && (
          <LanguageComponent
            lang={lang}
            onPress={(l) => setLang(l)}
            languages={
              description && typeof description !== 'string'
                ? journey.company?.languages
                  ? journey.company.languages
                  : Object.keys(description)
                : []
            }
          />
        )}
        <JourneyHeaderStepsGroup spaceB={10} color={ThirdColor}>
          <div style={{whiteSpace: 'nowrap'}}>
            {CalcJourneySteps(journey)} {translate('STEPS')}
          </div>
          {journey.category && journey.category.name && (
            <>
              <JourneyHeaderStepsIcon color={ThirdColor} />
              {GetValuePreview(journey.category.name, lang)}
            </>
          )}
        </JourneyHeaderStepsGroup>
        {description && (
          <JourneyDescription
            style={{padding: '0px 0px 50px'}}
            dangerouslySetInnerHTML={{
              __html: GetValuePreview(description, lang)
            }}
          />
        )}
        {errorMessage ? (
          <>
            <JourneyHeaderStepsGroup spaceB={10} color={ErrorColor}>
              {translate(errorMessage)}
            </JourneyHeaderStepsGroup>
            <BaseButton
              loading={isLoading}
              type='button'
              onClick={onPressActivateJourney}
              label={translate('OPEN_MY_JOURNEY')}
              category='secondary'
              icon
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 14,
                width: '100%'
              }}
            />
          </>
        ) : (
          <>
            {onPressArchive && (
              <BaseButton
                type='button'
                onClick={onPressArchive}
                label={translate('ARCHIVE')}
                category='primary'
                icon
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  marginBottom: 14
                }}
              />
            )}
            {onPressReactivate && (
              <BaseButton
                type='button'
                onClick={onPressReactivate}
                label={translate('OPEN')}
                category='secondary'
                icon
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 14,
                  width: '100%'
                }}
              />
            )}
            {onPressUnarchive && (
              <BaseButton
                type='button'
                onClick={onPressUnarchive}
                label={translate('UNARCHIVE')}
                category='primary'
                icon
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 14,
                  width: '100%'
                }}
              />
            )}
            {onPressDelete && (
              <BaseButton
                type='button'
                onClick={onPressDelete}
                label={translate('DELETE')}
                category='error'
                icon
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 14,
                  width: '100%'
                }}
              />
            )}
            {onPressCompleted && (
              <BaseButton
                type='button'
                onClick={onPressCompleted}
                label={translate('VIEW')}
                category='secondary'
                icon
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 14,
                  width: '100%'
                }}
              />
            )}
            {!isInactive && (
              <>
                <BaseButton
                  type='button'
                  onClick={personalizeJourney}
                  // () =>
                  //   router.history.push(`/create-journey/${journey._id}`, {
                  //     journey
                  //   })
                  // }
                  label={translate('PERSONALIZE')}
                  category='tertiary'
                  icon
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 14,
                    width: '100%'
                  }}
                />
                <BaseButton
                  type='button'
                  onClick={quickStart}
                  label={translate('QUICK_START')}
                  category='success'
                  icon
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 14,
                    width: '100%'
                  }}
                />
              </>
            )}
          </>
        )}
      </ModalContainer>
    </Modal>
  )
}

JourneyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  journey: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  width: PropTypes.number,
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  activity: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object,
  isInactive: PropTypes.bool,
  onPressArchive: PropTypes.func,
  onPressReactivate: PropTypes.func,
  onPressUnarchive: PropTypes.func,
  onPressDelete: PropTypes.func,
  onPressCompleted: PropTypes.func,
  activeJourney: PropTypes.object.isRequired,
  inactiveJourneys: PropTypes.array.isRequired
}

JourneyModal.defaultProps = {
  router: undefined,
  width: 500,
  activity: false,
  isInactive: false,
  onPressArchive: undefined,
  onPressReactivate: undefined,
  onPressUnarchive: undefined,
  onPressDelete: undefined,
  onPressCompleted: undefined
}

const mapActionToProps = (dispatch) => ({dispatch})

const mapStateToProps = (state) => ({
  activeJourney: state.user.active_journey,
  inactiveJourneys: state.inactive_journeys
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(JourneyModal)
)
