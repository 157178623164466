import React from 'react'

import PropTypes from 'prop-types'

import {TabsContainer} from '../LoginPageStyles'
import WithCode from './WithCode'

const RegisterPage = ({router}) => (
  <TabsContainer>
    <WithCode router={router} />
  </TabsContainer>
)

RegisterPage.propTypes = {
  router: PropTypes.object.isRequired
}

export default RegisterPage
