import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import JourneyForm from '../create_journey/JourneyForm'

const JourneySettings = ({journey, onClickBack}) => (
  <JourneyForm journey={journey} edit onClickBack={onClickBack} />
)

JourneySettings.propTypes = {
  journey: PropTypes.object.isRequired,
  onClickBack: PropTypes.func
}

JourneySettings.defaultProps = {
  onClickBack: undefined
}

export default withLocalize(JourneySettings)
