import {createAction} from '@reduxjs/toolkit'

export const updateLatestKudos = createAction(
  'latest_kudos/save',
  (data) => ({
    payload: data
  })
)

export const clearLatestKudos = createAction('latest_kudos/clear')
