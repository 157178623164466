import React from 'react'

import {Row, Col} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useDispatch} from 'react-redux'

import IconPlay from '../../assets/icons/ic_play.svg'
import BuildFileUrl from '../../infra/services/files/FileService'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {updateModal} from '../../redux/data/modal/ModalActions'
import {VideoBox2} from '../../styles/BasicStyles'
import {CardImage} from '../cards/CardsStyles'
import {KudosMessage} from '../sliders/SliderStyles'
import {SeeAllLabel, ListLabel, ShadowKudos} from './KudosStyles'

const KudosShortList = ({
  items,
  total,
  title,
  topSpace,
  translate,
  activeLanguage
}) => {
  const dispatch = useDispatch()

  const renderImage = (item) => {
    if (item.attachment) {
      switch (item.attachment.contentType) {
        case 'image/jpeg':
        case 'image/png': {
          const image = {
            url: BuildFileUrl(item.attachment._id),
            color: '#fff'
          }
          return <CardImage ratio={0} image={image} />
        }
        case 'video/mp4': {
          const image = BuildFileUrl(item.attachment._id)
          return (
            <VideoBox2>
              <img
                src={IconPlay}
                alt='video'
                style={{position: 'absolute'}}
              />
              <video
                style={{
                  width: '100%'
                }}
              >
                <source src={image} type='video/mp4' />
              </video>
            </VideoBox2>
          )
        }
        default:
          return null
      }
    } else {
      const image = {
        url: BuildFileUrl(
          item.kudos && item.kudos.image && item.kudos.image.file
        ),
        color: '#fff'
      }
      return <CardImage ratio={0} image={image} />
    }
  }

  const renderTitle = (selected = {}) => {
    let message
    if (selected.kudos) {
      message = GetLanguageValue(selected.kudos.title, activeLanguage.code)
    }
    if (selected.message) message = selected.message
    return <KudosMessage>{message}</KudosMessage>
  }

  return (
    <Row style={{marginTop: topSpace || 0, padding: '0px 20px 20px'}}>
      <Col xs={16}>{title && <ListLabel>{title}</ListLabel>}</Col>
      {total > items.length && (
        <Col xs={8}>
          <SeeAllLabel>{translate('SEE_ALL')}</SeeAllLabel>
        </Col>
      )}
      <Col xs={24}>
        <Row>
          {items.map((item, index) => (
            <Col xs={8} xl={6} key={index}>
              <div
                style={{cursor: 'pointer'}}
                onClick={() =>
                  dispatch(
                    updateModal({
                      type: 'kudos',
                      object: item,
                      isShare: true,
                      isFeed: true
                    })
                  )
                }
              >
                {renderImage(item)}
                <ShadowKudos />
                {renderTitle(item)}
              </div>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

KudosShortList.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  title: PropTypes.string,
  total: PropTypes.number,
  topSpace: PropTypes.number
}

KudosShortList.defaultProps = {
  title: undefined,
  total: 0,
  topSpace: 0
}
export default withLocalize(KudosShortList)
