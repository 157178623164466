import styled from 'styled-components'

import {
  LightGey,
  white,
  DarkGrey,
  DarkGey,
  SuccessColor,
  orangeYellow,
  grey,
  ThirdColor,
  BorderColor
} from '../../styles/_theme'
import device from '../../styles/Responsive'
import ImageComponent from '../images/ImageComponent'

export const JourneyBlockContainer = styled.div`
  margin-horizontal: ${({isFull}) => (isFull ? 0 : 14)}px;
  margin-bottom: ${({isFull}) => (isFull ? 0 : 14)}px;
  background: ${({isCustom}) => (isCustom ? BorderColor : white)};
  height: ${({height}) => height || '190px'};
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  opacity: ${({isDeleting}) => (isDeleting ? 0.5 : 1)};
  cursor: pointer;

  @media ${device.laptopL} {
    margin-bottom: ${({isActiveJourney, isFull}) =>
      isActiveJourney ? 0 : isFull ? 0 : 14}px;
    height: ${({isActiveJourney, height}) =>
      isActiveJourney ? 'auto' : height || '190px'};
  }
`

// export const JourneyBlockC = styled.View`
//   margin-horizontal: ${({isFull}) => (isFull ? 0 : generalSpace)}px;
//   margin-bottom: ${({isFull}) => (isFull ? 0 : generalSpace)}px;
//   background: ${({isCustom}) => (isCustom ? lightGrey : white)};
//   height: ${({height}) => (height || 190)}px;
//   border-radius: 4px;
//   overflow: hidden;
//   position: relative;
// `

export const StepsContainer = styled.div`
  height: 30px;
  background: ${({isCompleted, isInactive, isGrey, isFull}) =>
    isCompleted
      ? SuccessColor
      : isInactive
      ? orangeYellow
      : isGrey
      ? DarkGey
      : isFull
      ? grey
      : ThirdColor};
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  display: inline-flex;
  margin-left: 10px;
`

export const StepsText = styled.div`
  font-size: 12px;
  color: ${white};
  font-weight: 400;
  line-height: 30px;
  display: inline-flex;
  padding: 0px 20px;
`

export const JourneyPhoto = styled(ImageComponent)`
  width: 100%;
  height: 100%;
  ratio: 0;
  background-color: ${LightGey};

  @media ${device.laptopL} {
    height: ${({isActiveJourney}) => (isActiveJourney ? 'auto' : '100%')};
    max-height: 320px;
  }
`

export const JourneyInfo = styled.div`
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding-horizontal: 10px;
  z-index: 10;
  box-shadow: inset 0px -130px 60px -60px #000000;
  display: flex;
`

export const JourneyDescription = styled.div`
  width: 100%;
  height: auto;
  font-size: 14px;
  color: ${({isCustom}) => (isCustom ? DarkGrey : white)};
  font-weight: 400;
  line-height: 18px;
  padding: 0px 10px;
`

export const JourneyBottom = styled.div`
  margin-bottom: 16px;
  margin-top: 10px;
  width: 100%;
  display: block;
`

export const JourneyBottomIn = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
`

export const JourneyTitle = styled.div`
  flex: 5;
  font-size: 16px;
  color: ${({isCustom}) => (isCustom ? DarkGrey : white)};
  font-weight: 500;
  line-height: 20px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const JourneyBtnBlock = styled.div`
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
  align-self: flex-end;
  display: flex;
`

export const JourneyBtnTop = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`
