import React, {useState, useEffect} from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'

import UserDefault from '../../assets/default_user.png'
import CloseIcon from '../../assets/icons/ic_close.svg'
import KudosIcon from '../../assets/icons/ic_kudos.svg'
import ChatIcon from '../../assets/icons/ic_message_circle.svg'
import {GetPeer} from '../../infra/requests/CommunityRequests'
import {CloseBtn} from '../../pages/active_journey/ActiveJourneyStyles'
import {updateKudos} from '../../redux/data/kudos/KudosActions'
import {ThirdColor, white} from '../../styles/_theme'
import {LoadingSpin} from '../../styles/BasicStyles'
import KudosShortList from '../kudos/KudosShortList'
import {
  UserImageContainer,
  UserBackground,
  UserImage,
  UserBtns,
  RoundBtn
} from '../modal/BaseModalStyles'
import {
  HeaderBlock,
  ChatInfo,
  PeerName,
  ActiveJourney,
  ActiveJourneyTitle,
  PeerBackground
} from './ChatStyles'

const PeerProfileComponent = ({closeDrawer, translate}) => {
  const dispatch = useDispatch()

  const {chat} = useSelector((state) => state)
  const [peer, setPeer] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getPeer = async () => {
      const {data, success} = await GetPeer(chat._id)
      if (success) {
        setPeer(data)
      }
      setLoading(false)
    }

    if (!peer) getPeer()
  }, [peer, chat])

  const image = chat.image
    ? chat.image
    : chat.photo
    ? chat.photo
    : {url: UserDefault}

  if (loading) return <LoadingSpin min='200px' />
  console.log(peer.kudos)
  return (
    <ChatInfo style={{height: '100%'}}>
      <HeaderBlock>
        <UserBackground color={white} style={{height: 98}}>
          <CloseBtn onClick={closeDrawer}>
            <img src={CloseIcon} alt='back' />
          </CloseBtn>
        </UserBackground>
        <UserImageContainer style={{top: 30, zIndex: 1}}>
          <UserImage ratio={0} image={image} />
        </UserImageContainer>
        <PeerBackground color={ThirdColor}>
          <PeerName>{peer.name}</PeerName>
          {peer.active_journey && (
            <>
              <ActiveJourney> {translate('ACTIVE_JOURNEY')}</ActiveJourney>
              <ActiveJourneyTitle>
                {peer.active_journey?.title}
              </ActiveJourneyTitle>
            </>
          )}
          <UserBtns style={{position: 'absolute', bottom: -25}}>
            <RoundBtn margin='0px 12px 0px 0px' onClick={closeDrawer}>
              <img src={ChatIcon} alt='Chat' />
            </RoundBtn>
            <RoundBtn
              margin='0px 0px 0px 12px'
              onClick={() => dispatch(updateKudos(peer))}
            >
              <img src={KudosIcon} alt='Kudos' />
            </RoundBtn>
          </UserBtns>
        </PeerBackground>
        {peer.profile_kudos && peer.kudos && peer.kudos.length > 0 && (
          <KudosShortList
            title={translate('LATEST_KUDOS_RECEIVED')}
            items={peer.kudos.slice(0, 8)}
            topSpace={40}
            total={peer.kudos.length}
          />
        )}
      </HeaderBlock>
    </ChatInfo>
  )
}

PeerProfileComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired
}

export default withLocalize(PeerProfileComponent)
