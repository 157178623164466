import {createReducer} from '@reduxjs/toolkit'

import {updateFriends} from './FriendsActions'

const initialState = {}

const FriendsReducer = createReducer(initialState, {
  [updateFriends]: (state, action) => action.payload
})

export default FriendsReducer
