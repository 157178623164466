import client from '../config/AxiosConfig'

export const GetActivity = async (page, limit) =>
  client.get(`/activity/${page}/${limit}`)

export const GetNetworkActivity = async () =>
  client.get('/activity/network')

export const GetFeed = async () => client.get('/feed')

export const RespondFeedback = async (id, fields) =>
  client.put(`/feedbacks/${id}`, fields)

export const IgnoreFeedback = async (id) =>
  client.delete(`/feedbacks/${id}`)

export const JourneyValidation = async (id, fields) =>
  client.put(`/validation/${id}`, fields)

export const GetJourneyValidation = async (fields) =>
  client.post('/validation/request', fields)

export const MarkFeedbackSeen = async (id) =>
  client.put(`/feedbacks/${id}/seen`)

export const MarkSeen = async (id) => client.put(`/kudos/${id}/seen`)

export const IgnoreVersion = async (id) => client.delete(`/version/${id}`)
