import React from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import BaseButton from '../../components/buttons/BaseButton'
import LessonCard from '../../components/cards/LessonCard'
import EmptyLayoutComponent from '../../components/empty_layout/EmptyLayoutComponent'
import SelectInput from '../../components/input/SelectInput'
import {GetLibrary} from '../../infra/requests/LibraryRequests'
import {GetValuePreview} from '../../infra/utils/CommonFunctions'
import {Margin} from '../../styles/BasicStyles'
import {InformationPageScroll} from '../journeys/JourneyStyles'
import {
  LibraryContent,
  Title,
  ComboContainer,
  ButtonContainer
} from './LibraryStyles'

const limit = 12

class LibraryPage extends React.Component {
  constructor(props) {
    super(props)
    this.scrollComponent = null
    this.state = {
      isLoading: false,
      library: [],
      page: 1,
      total: undefined,
      category: false
    }
  }

  componentDidMount = () => {
    this.getLibrary()
  }

  getLibrary = async () => {
    const {page, isLoading, total, library, category} = this.state
    if (isLoading || total <= library.length) return
    this.setState({isLoading: true})
    try {
      const result = await GetLibrary(page, limit, category || '')
      if (result.success) {
        const newLibrary = [...library, ...result.data.items]
        this.setState({
          isLoading: false,
          library: newLibrary,
          page: page + 1,
          total: result.data.total
        })
      }
    } catch (error) {
      console.warn(error)
    }
  }

  onChangeSortBy = async (value) => {
    const {category} = this.state
    if (value === category) return
    this.setState(
      {
        category: value,
        library: [],
        page: 1,
        total: undefined
      },
      this.getLibrary
    )
  }

  handleLoadMore = () => {
    const {library, isLoading, total} = this.state

    if (isLoading || total <= library.length) return
    if (
      this.scrollComponent &&
      this.scrollComponent.scrollHeight <=
        this.scrollComponent.scrollTop +
          this.scrollComponent.clientHeight +
          100
    ) {
      this.getLibrary()
    }
  }

  render() {
    const {translate, activeLanguage, info, router} = this.props
    const {category, library} = this.state
    const categories = info.data.categories.map((c) => ({
      label:
        GetValuePreview(c.name, activeLanguage && activeLanguage.code) ||
        '',
      value: c._id
    }))

    const sortCategories = [...categories]?.sort((a, b) => GetValuePreview(a.label, activeLanguage.code).localeCompare(GetValuePreview(b.label, activeLanguage.code)))

    return (
      <InformationPageScroll
        onScroll={this.handleLoadMore}
        ref={(ref) => {
          this.scrollComponent = ref
        }}
      >
        <LibraryContent>
          <Row gutter={24}>
            <Col xs={18}>
              <Title>{translate('LIBRARY')}</Title>
            </Col>
          </Row>
          <Margin size={30} />
          <Row gutter={24}>
            <Col xs={12}>
              <ComboContainer>
                <SelectInput
                  maxwidth='350px'
                  allowClear
                  placeholder={translate('SORT_BY')}
                  input={{
                    value: category,
                    onChange: (c) => this.onChangeSortBy(c)
                  }}
                  items={sortCategories || []}
                  onChange={null}
                  meta={{valid: true}}
                />
              </ComboContainer>
            </Col>
            <Col xs={12}>
              <ButtonContainer>
                <BaseButton
                  BaseBtn='button'
                  onClick={() => router.history.push('/create-lesson')}
                  label={translate('NEW_LESSON')}
                  category='tertiary'
                  style={{float: 'right', marginBottom: 16}}
                />
              </ButtonContainer>
            </Col>
          </Row>
          {library && library.length > 0 ? (
            <Col xs={24}>
              <Row gutter={[14, 20]}>
                {library.map((item, index) => (
                  <Col xs={12} md={8} lg={6} key={index}>
                    <div style={{height: 320}}>
                      <LessonCard
                        onClick={() =>
                          router.history.push(`/lesson/${item._id}`, {
                            lesson: item
                          })
                        }
                        item={item}
                        bgColor='transparent'
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          ) : (
            <EmptyLayoutComponent tag='EMPTY_LESSONS' />
          )}
        </LibraryContent>
      </InformationPageScroll>
    )
  }
}

LibraryPage.propTypes = {
  router: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  info: state.info
})
export default withLocalize(connect(mapStateToProps)(LibraryPage))
