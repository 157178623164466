import React, {useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {ReactComponent as SettingsIcon} from '../../assets/icons/ic_arrow_settings.svg'
import {ReactComponent as BellIcon} from '../../assets/icons/ic_bell.svg'
import {ReactComponent as MessageIcon} from '../../assets/icons/ic_message.svg'
import Logo from '../../assets/logo_white.svg'
import {hasNotificationGroup} from '../../infra/utils/CommonFunctions'
import {updateChat} from '../../redux/data/chat/ChatActions'
import {DefaultGey} from '../../styles/_theme'
import CommunityDrawer from '../community/CommunityDrawer'
import NotificationsDrawer from '../notifications/NotificationsDrawer'
import SettingsModal from './components/SettingsModal'
import {
  HeaderNavbar,
  HeaderLogo,
  HeaderMenu,
  NavbarItem,
  Bubble,
  HeaderActiveJourneyBlock,
  GreenBox,
  HeaderActiveJourneyTitle
} from './HeaderStyles'
import NavbarMenus from './NavbarMenus'

const NavigationHeader = ({
  router,
  notifications,
  user,
  translate,
  dispatch
}) => {
  const [seeNotifications, setNotifications] = useState(false)
  const [seeCommunity, setCommunity] = useState(false)
  const [seeSettings, setSettings] = useState(false)
  const hasNotificationCommunity =
    hasNotificationGroup(notifications, 'NEW_FRIEND_INVITE') ||
    hasNotificationGroup(notifications, 'FRIEND_ACCEPTED') ||
    hasNotificationGroup(notifications, 'FRIEND_REJECTED') ||
    hasNotificationGroup(notifications, 'PRIVATE') ||
    hasNotificationGroup(notifications, 'CHANNEL') ||
    hasNotificationGroup(notifications, 'GENERAL')

  const hasNotificationFeed =
    hasNotificationGroup(notifications, 'FEEDBACK') ||
    hasNotificationGroup(notifications, 'KUDOS') ||
    hasNotificationGroup(notifications, 'VALIDATION') ||
    hasNotificationGroup(notifications, 'UPDATE_JOURNEY')

  return (
    <>
      <HeaderNavbar>
        <Row>
          <HeaderLogo src={Logo} />
          <Col sm={16} lg={18}>
            <HeaderMenu>
              {user && user.active_journey ? (
                <HeaderActiveJourneyBlock>
                  <GreenBox />
                  <HeaderActiveJourneyTitle
                    onClick={() => {
                      router.history.push('/active-journey#steps')
                      // window.location.reload(false);
                    }}
                  >
                    {user.active_journey.title}
                  </HeaderActiveJourneyTitle>
                </HeaderActiveJourneyBlock>
              ) : (
                <HeaderActiveJourneyBlock>
                  <GreenBox color={DefaultGey} />
                  <HeaderActiveJourneyTitle
                    onClick={() => router.history.push('/journeys')}
                  >
                    {translate('NO_ACTIVE_JOURNEY')}
                  </HeaderActiveJourneyTitle>
                </HeaderActiveJourneyBlock>
              )}
              {NavbarMenus.map((item, index) => (
                <NavbarItem
                  onClick={() => router.history.push(item.link)}
                  active={router.location.pathname === item.link}
                  key={index}
                >
                  {translate(item.name)}
                </NavbarItem>
              ))}
            </HeaderMenu>
          </Col>
          <>
            <Bubble
              id='openNotificationsDrawer'
              notification={hasNotificationFeed}
              position={165}
              onClick={() => {
                setNotifications(!seeNotifications)
                setCommunity(false)
                setSettings(false)
                dispatch(updateChat())
              }}
              active={seeNotifications}
              className={seeNotifications ? 'active' : ''}
            >
              <BellIcon />
            </Bubble>
            <Bubble
              id='openCommunityDrawer'
              notification={hasNotificationCommunity}
              position={100}
              onClick={() => {
                setCommunity(!seeCommunity)
                setNotifications(false)
                setSettings(false)
                dispatch(updateChat(false))
              }}
              active={seeCommunity}
              className={seeCommunity ? 'active' : ''}
            >
              <MessageIcon />
            </Bubble>
            <Bubble
              position={40}
              onClick={() => {
                setSettings(!seeSettings)
                setNotifications(false)
                setCommunity(false)
                dispatch(updateChat(false))
              }}
              active={seeSettings}
              className={seeSettings ? 'active' : ''}
            >
              <SettingsIcon />
            </Bubble>
          </>
        </Row>
      </HeaderNavbar>
      <CommunityDrawer
        open={seeCommunity}
        onClose={() => {
          setCommunity(false)
          dispatch(updateChat(false))
        }}
      />
      {seeNotifications && (
        <NotificationsDrawer
          open={seeNotifications}
          onClose={() => setNotifications(false)}
        />
      )}
      {seeSettings && (
        <SettingsModal
          router={router}
          user={user}
          onClose={() => setSettings(false)}
        />
      )}
    </>
  )
}

NavigationHeader.propTypes = {
  router: PropTypes.object.isRequired,
  notifications: PropTypes.array,
  user: PropTypes.object,
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
}

NavigationHeader.defaultProps = {
  notifications: undefined,
  user: undefined
}

const mapStateToProps = (state) => ({
  notifications: state.user.notifications,
  user: state.user
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(NavigationHeader)
)
