import styled from 'styled-components'

import {
  NavbarSize,
  white,
  LightGey,
  PrimaryColor,
  DarkGey,
  ThirdColor,
  DarkGrey,
  lightBlue,
  mediumBlue
} from '../../styles/_theme'
import ImageComponent from '../images/ImageComponent'

export const ChatSection = styled.div`
  display: flex;
  width: calc(100% + 48px);
  margin: 0 -24px;
  background-color: ${LightGey};
  height: calc(100% - 80px);
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
`

export const ChatListSection = styled.div`
  height: calc(100vh - ${NavbarSize} - 100px);
  overflow: auto;
`

export const ChatHeader = styled.div`
  display: flex;
  width: 100%;
  height: 90px;
  box-shadow: -10px 0 20px -4px rgba(0, 0, 0, 0.1);
  background-color: ${white};
  padding: 20px;
  padding-bottom: 26px;
  align-items: center;
`

export const ChatHeaderClickable = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`

export const ChatPhoto = styled(ImageComponent)`
  float: left;
  width: 44px;
  height: 44px;
  background-color: grey;
  border-radius: 50%;
  margin-right: 10px;
`

export const ChatContent = styled.div`
  width: calc(100% - 170px);
  float: left;
  margin-left: 10px;
`

export const ChatName = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
`

export const CloseButton = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  right: 20px;
  position: absolute;
`
export const ChatSendButton = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  right: 20px;
  position: absolute;
`

export const ChatFooter = styled.div`
  width: 100%;
  min-height: 52px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${white};
  padding: 8px 14px;
`

export const ChatSendSection = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 10px 0px 0px;
  position: relative;
  border: solid 1px #e3e3e3;
  border-radius: 18px;
`

export const SendButton = styled.button`
  display: inline-block;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.3s ease;
  border: none;

  &:hover,
  &:visited,
  &:active {
    outline: none;
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
  }
`

export const ChatMessages = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: calc(100% - 142px);
  position: relative;
  overflow-y: auto;
`

export const ChatMessageContent = styled.div`
  width: 100%;
  flex-direction: row;
`

export const MessageContainer = styled.div`
  width: 256px;
  margin: 10px 18px;
  background-color: ${({isUser}) => (isUser ? PrimaryColor : white)};
  float: ${({isUser}) => (isUser ? 'right' : 'left')};
  border-radius: 4px;
  opacity: ${({pending}) => (pending ? 0.5 : 1)};
  padding: 4px;
`

export const MessageUserName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  color: ${DarkGrey};
  padding: 10px;
  padding-bottom: 0px;
`

export const MessageText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
  color: ${({isUser}) => (isUser ? white : DarkGey)};
  padding: 10px;
  padding-bottom: 2px;
`

export const LinkText = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
  color: ${({isUser}) => (isUser ? white : ThirdColor)};
  text-decoration-line: underline;
  cursor: pointer;
`

export const MessageTime = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
  color: ${DarkGey};
  text-align: right;
  padding: 10px;
  padding-top: 0px;
`

export const BackButton = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-right: 10px;
`

export const ChatHeaderPhoto = styled(ImageComponent)`
  width: 100%;
  height: 250px;
  background-color: ${lightBlue};
`

export const DefaultHeaderPhoto = styled.div`
  width: 100%;
  height: 250px;
  background-color: ${lightBlue};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const HeaderBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`

export const HeaderUsers = styled.div`
  width: 100%;
  height: 70px;
  background-color: ${mediumBlue};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  padding: 10px;
  color: ${white};
  line-height: 22px;
`

export const PeerName = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
  color: ${white};
  line-height: 22px;
`

export const EditBtn = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
`

export const ChatInfo = styled.div`
  width: 100%;
  height: auto;
  background-color: ${LightGey};
  height: calc(100% - 80px);
  overflow: auto;
`

export const LineContainer = styled.div`
  display: flex;
  height: 66px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding: 20px;
  background-color: ${({bgColor}) => bgColor || '#FFFFFF'};

  &:hover,
  &:focus,
  &:visited,
  &:active {
    background-color: ${lightBlue};
  }
`

export const UsersContainerCenter = styled.div`
  top: -26px;
  position: absolute;
`

export const UserImage = styled(ImageComponent)``

export const UserImageBox = styled.div`
  width: 52px;
  height: 52px;
  background-color: ${lightBlue};
  position: absolute;
  border-radius: 50%;
  border: 2px solid ${white};
  overflow: hidden;
`

export const ActiveJourney = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${white};
  padding-bottom: 12px;
  padding-top: 30px;
`

export const ActiveJourneyTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: ${white};
`

export const PeerBackground = styled.div`
  width: 100%;
  min-height: 140px;
  background-color: ${ThirdColor};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 75px 10px;
  position: relative;
`
