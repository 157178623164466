import React from 'react'

import PropTypes from 'prop-types'

import {StyledTabs, StyledPane} from './TabStyles'

const Tabs = ({panels, tabSize, activeKey, onChange}) => (
  <StyledTabs $tabSize={tabSize} activeKey={activeKey} onChange={onChange}>
    {panels.map((panel) => (
      <StyledPane
        tab={
          panel.hasNotification ? <span>{panel.name}</span> : panel.name
        }
        key={panel.key}
      >
        {panel.component}
      </StyledPane>
    ))}
  </StyledTabs>
)

Tabs.propTypes = {
  panels: PropTypes.array.isRequired,
  tabSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  activeKey: PropTypes.string,
  onChange: PropTypes.func
}

Tabs.defaultProps = {
  tabSize: 0,
  activeKey: undefined,
  onChange: undefined
}

export default Tabs
