import React from 'react'

import {Route, Switch, BrowserRouter as Router} from 'react-router-dom'

import NotFound from '../pages/not_found/NotFound'
import CombinedRoutes from './CombinedRoutes'
import AuthenticatedRoute from './route_types/AuthenticatedRoute'
import UnauthenticatedRoute from './route_types/UnauthenticatedRoute'

const ApplicationRoutes = () => {
  // add authentication validations
  const renderApplicationRoute = (route, key) => {
    if (route.isAuthenticated) {
      return (
        <Route
          key={key}
          exact
          path={route.path}
          render={(routerProps) => (
            <AuthenticatedRoute route={route} routerProps={routerProps} />
          )}
        />
      )
    }

    return (
      <Route
        key={key}
        exact
        path={route.path}
        component={(routerProps) => (
          <UnauthenticatedRoute route={route} routerProps={routerProps} />
        )}
      />
    )
  }

  return (
    <Router>
      <Switch>
        {CombinedRoutes.map(renderApplicationRoute)}
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default ApplicationRoutes
