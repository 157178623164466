import {createReducer} from '@reduxjs/toolkit'

import {
  updateInactiveJourneys,
  removeInactiveJourney
} from './InactiveJourneysActions'

const initialState = []

const InactiveJourneysReducer = createReducer(initialState, {
  [updateInactiveJourneys]: (state, action) => action.payload,
  [removeInactiveJourney]: (state, action) =>
    state.filter((j) => j._id !== action.payload)
})

export default InactiveJourneysReducer
