import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import ArrowIconBlack from '../../../assets/icons/ic_chevron_right_black.svg'
import LogoutIcon from '../../../assets/icons/logout.svg'
import SettingsIcon from '../../../assets/icons/settings.svg'
import {AuthTokenKey} from '../../../infra/config/LocalStorageKeys'
import {Logout} from '../../../infra/requests/BaseRequests'
import SocketClient from '../../../infra/services/socket/SocketConnection'
import {updateChats} from '../../../redux/data/chats/ChatsActions'
import {CleanAndUpdateAllData} from '../../../redux/thunks/UpdateThunk'
import {
  ModalContainer,
  Button,
  ButtonColumn,
  IconContainer,
  Title,
  Margin
} from './SettingsModalStyles'

const SettingsDrawer = ({translate, onClose, router, user, dispatch}) => {
  const DoLogout = async () => {
    try {
      const deviceToken = localStorage.getItem(AuthTokenKey)
      await Logout({device: deviceToken, email: user.email})
      localStorage.removeItem(AuthTokenKey)
    } catch (error) {
      console.warn(error)
    }
    const socketClient = SocketClient.create()
    socketClient.disconnect()
    dispatch(updateChats())
    dispatch(CleanAndUpdateAllData())
    router.history.push('/login')
  }
  return (
    <ModalContainer
      visible
      onCancel={onClose}
      width={320}
      footer={null}
      closable={false}
      mask={false}
    >
      <Button onClick={() => router.history.push('/settings')}>
        <ButtonColumn>
          <IconContainer>
            <img src={SettingsIcon} alt='Settings' />
          </IconContainer>
          <Title spaceL={10}>{translate('SETTINGS')}</Title>
        </ButtonColumn>
        <img src={ArrowIconBlack} alt='ArrowIcon' />
      </Button>
      <Margin />
      <Button onClick={() => DoLogout()}>
        <ButtonColumn>
          <IconContainer>
            <img src={LogoutIcon} alt='Logout' />
          </IconContainer>
          <Title spaceL={10}>{translate('LOGOUT')}</Title>
        </ButtonColumn>
        <img src={ArrowIconBlack} alt='ArrowIcon' />
      </Button>
    </ModalContainer>
  )
}

SettingsDrawer.propTypes = {
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = () => ({})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(SettingsDrawer)
)
