import FormValidator from '../../../infra/services/validations/FormValidator'

export const CustomJourneysValidations = (values) => {
  const errors = FormValidator.make({
    goal: 'required',
    intention: 'required',
    review_weeks: 'required',
    steps: 'array'
  })(values)
  if (values.reminder) {
    errors.reminder = FormValidator.make({
      time: 'required',
      days: 'array'
    })(values.reminder)
  }

  if (values.steps && values.steps.length > 0) {
    errors.steps = []
    values.steps.map((step, index) => {
      errors.steps[index] = FormValidator.make({
        name: 'required'
      })(step)
      return step
    })
  }
  return errors
}

export const JourneysValidations = (values) => {
  const errors = FormValidator.make({
    goal: 'required',
    intention: 'required',
    review_weeks: 'required'
  })(values)
  if (values.reminder) {
    errors.reminder = FormValidator.make({
      time: 'required',
      days: 'array'
    })(values.reminder)
  }

  if (values.steps && values.steps.length > 0) {
    errors.steps = []
    values.steps.map((step, index) => {
      errors.steps[index] = FormValidator.make({
        name: 'required'
      })(step)
      return step
    })
  }
  return errors
}
