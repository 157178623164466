import {createReducer} from '@reduxjs/toolkit'

import {updateArchivedJourneys} from './ArchivedJourneysActions'

const initialState = []

const ArchivedJourneysReducer = createReducer(initialState, {
  [updateArchivedJourneys]: (state, action) => action.payload
})

export default ArchivedJourneysReducer
