const size = {
  tabletS: '550px',
  tabletM: '650px',
  tablet: '768px',
  tabletL: '850px',
  laptopS: '900px',
  laptop: '992px',
  laptopL: '1200px',
  desktop: '1440px',
  desktopL: '1600px',
  desktopXL: '1900px'
}

const device = {
  tabletS: `(max-width: ${size.tabletS})`,
  tabletM: `(max-width: ${size.tabletM})`,
  tablet: `(max-width: ${size.tablet})`,
  tabletL: `(max-width: ${size.tabletL})`,
  laptopS: `(max-width: ${size.laptopS})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktopL})`,
  desktopXL: `(max-width: ${size.desktopXL})`
}
export default device
