import React, {useState} from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'

import UserDefault from '../../assets/default_user.png'
import GroupIcon from '../../assets/icons/group_icon.png'
import BackIcon from '../../assets/icons/ic_chevron_left.svg'
import CloseIcon from '../../assets/icons/ic_close.svg'
import Drawer from '../drawer/Drawer'
import ChatComponent from './ChatComponent'
import {
  ChatHeader,
  ChatPhoto,
  ChatName,
  ChatSection,
  CloseButton,
  ChatHeaderClickable,
  BackButton
} from './ChatStyles'
import PeerProfileComponent from './PeerProfileComponent'
import ProfileComponent from './ProfileComponent'

const ChatDrawer = ({open, onClose, translate}) => {
  const [section, setSection] = useState('chat')
  const {chat} = useSelector((state) => state)

  const renderContent = () => {
    switch (section) {
      case 'profile':
        return <ProfileComponent closeDrawer={onClose} />
      case 'peer_profile':
        return (
          <PeerProfileComponent closeDrawer={() => setSection('chat')} />
        )
      default:
        return <ChatComponent />
    }
  }

  return (
    <Drawer destroyOnClose open={open} onClose={onClose} title=''>
      <ChatSection>
        {section !== 'peer_profile' && (
          <ChatHeader>
            {section === 'profile' && (
              <BackButton
                src={BackIcon}
                onClick={() => setSection('chat')}
              />
            )}
            <ChatHeaderClickable
              onClick={() =>
                chat.type !== 'private'
                  ? setSection('profile')
                  : setSection('peer_profile')
              }
            >
              {section !== 'profile' && (
                <>
                  {chat.type === 'private' ? (
                    <ChatPhoto
                      image={
                        chat.image
                          ? chat.image
                          : chat.photo
                          ? chat.photo
                          : {url: UserDefault}
                      }
                    />
                  ) : (
                    <ChatPhoto image={chat.image || {url: GroupIcon}} />
                  )}
                </>
              )}
              <ChatName isDark numberOfLines={1}>
                {chat.type !== 'private' && '#'}{' '}
                {chat.name || translate('CHAT')}
              </ChatName>
            </ChatHeaderClickable>
            {section === 'chat' && (
              <CloseButton src={CloseIcon} onClick={onClose} />
            )}
          </ChatHeader>
        )}
        {renderContent()}
      </ChatSection>
    </Drawer>
  )
}

ChatDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(ChatDrawer)
