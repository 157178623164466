import styled from 'styled-components'

import {white, ThirdColor, DarkGey, BorderColor} from '../../styles/_theme'

export const LoginLogo = styled.img`
  max-width: 100%;
  margin-bottom: 50px;
`

export const LoginButton = styled.div`
  float: right;
  margin-top: 20px;
`

export const LoginActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  & > div, & > button {
    margin-top: 20px;
  }

  & > button {
    display: block;
  }
`

export const TabsHeader = styled.div`
  display: block;
  flex-direction: column;
  height: 46px;
  justify-content: center;
  margin-top: 10px;
`

export const TabsContainer = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: center;
`

export const TabButton = styled.button`
  width: 50%;
  height: 100%;
  outline: none;
  cursor: pointer;
  display: inline-block;
  border: none;
  background-color: ${white};
  color: ${({active}) => (active ? ThirdColor : DarkGey)};
  border-bottom: 2px solid ${BorderColor};
  border-color: ${({active}) => (active ? ThirdColor : BorderColor)};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
`

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`

export const FormSubContainer = styled.div`
  background-color: ${white};
  width: 100%;
  max-width: 591px;
`
