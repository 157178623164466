import React, {useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'

import IconPlay from '../../assets/icons/ic_play.svg'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {VideoBox} from '../../styles/BasicStyles'
import EmptyLayoutComponent from '../empty_layout/EmptyLayoutComponent'
import {H5PDisabled} from '../modal/BaseModalStyles'
import JourneyModal from '../modal/JourneyModal'
import {
  Container,
  ContainerTitle,
  SeeAll,
  StyledCarousel,
  CarouselCard,
  CardImage,
  CardTitle,
  H5PJourneyDetail
} from './SliderStyles'

const SuggestedJourneys = ({translate, activeLanguage, router}) => {
  const suggested = useSelector((state) => state.suggested_journeys)
  const [journeyModal, setJourneyModal] = useState(false)

  const renderImage = (journey = {}) => {
    const {media} = journey
    switch (media?.type) {
      case 'images':
        return <CardImage image={media.file} />
      case 'videos': {
        const image = {
          url: GetLanguageValue(media.file.thumbnail, activeLanguage.code),
          color: '#fff'
        }
        return (
          <VideoBox>
            <img
              src={IconPlay}
              alt='video'
              style={{position: 'absolute', zIndex: 1}}
            />
            <CardImage image={image} />
          </VideoBox>
        )
      }
      case 'h5p':
      case 'embedded': {
        return (
          <H5PDisabled
            style={{height: 220}}
            dangerouslySetInnerHTML={{
              __html: `${GetLanguageValue(
                media.file.iframe,
                activeLanguage.code
              )}<div class="H5PTop" />`
            }}
          />
        )
      }
      default:
        return <CardImage />
    }
  }

  return (
    <Container>
      <Row>
        <Col xs={24} style={{display: 'flex'}}>
          <ContainerTitle>
            {translate('SUGGESTED_JOURNEYS')}
          </ContainerTitle>
          {suggested && suggested.length > 0 && (
            <SeeAll
              onClick={() =>
                router.history.push('/journeys#suggested_journeys')
              }
            >
              {translate('SEE_ALL')}
            </SeeAll>
          )}
        </Col>
        <Col xs={24}>
          {suggested && suggested.length > 0 ? (
            <StyledCarousel dots autoplay effect='fade'>
              {suggested.map((journey) => (
                <CarouselCard
                  style={{backgroundColor: 'red'}}
                  key={journey._id}
                  onClick={() => setJourneyModal(journey)}
                >
                  {renderImage(journey)}
                  <CardTitle>
                    {GetLanguageValue(journey.title, activeLanguage.code)}
                  </CardTitle>
                </CarouselCard>
              ))}
            </StyledCarousel>
          ) : (
            <EmptyLayoutComponent tag='EMPTY_LESSONS' />
          )}
          {journeyModal && (
            <JourneyModal
              open={!!journeyModal}
              journey={journeyModal}
              onClose={() => setJourneyModal(false)}
              router={router}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

SuggestedJourneys.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
}

export default withLocalize(SuggestedJourneys)
