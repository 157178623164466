import {Row, Col} from 'antd'
import {rgbUnit} from 'style-value-types'
import styled from 'styled-components'

import ImageComponent from '../../components/images/ImageComponent'
import {
  NavbarSize,
  ThirdColor,
  PrimaryColor,
  DarkGrey,
  white,
  DarkGey,
  LightGey,
  mediumBlue
} from '../../styles/_theme'
import device from '../../styles/Responsive'

export const JourneyLeftContent = styled.div`
  position: fixed;
  width: 323px;
  height: calc(100vh - ${NavbarSize});
  top: ${NavbarSize};
  left: 0;
  background: white;
  overflow-x: auto;
  padding-top: 40px;

  @media ${device.laptop} {
    width: 250px;
  }
`

export const SectionTitle = styled.div`
  font-size: 22px;
  line-height: 1.09;
  font-weight: bold;
  color: ${DarkGrey};
  margin: 0px 20px 24px;
`

export const OptionsList = styled.div`
  width: 100%;
`

export const OptionBtn = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({selected}) =>
    selected ? PrimaryColor : mediumBlue};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  color: #ffffff;
  cursor: pointer;
  padding: 0px 8px 0px 20px;
  margin-bottom: 8px;
  height: 66px;
  align-items: center;
  justify-content: space-between;

  &:hover,
  &:visited,
  &:active {
    background-color: ${PrimaryColor};
  }
`

export const InformationPage = styled.div`
  width: 100%;
  display: flex;
  margin-left: 323px;
  justify-content: center;
  background-color: ${LightGey};
  overflow: hidden;
  height: calc(100vh - 80px);

  @media ${device.laptop} {
    margin-left: 250px;
  }
`

export const InformationPageScroll = styled.div`
  width: 100%;
  display: flex;
  background-color: ${LightGey};
  overflow-y: auto;
  height: calc(100vh - 80px);
  -webkit-box-pack: center;
  justify-content: center;
`

export const InformationContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  padding: 60px 30px;
`

export const JourneyDetail = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: ${white};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  color: ${DarkGrey};

  @media ${device.laptopL} {
    height: auto;
  }
`

export const JourneyDetailTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.22;
  color: ${DarkGrey};
  margin-bottom: 24px;
`

export const JourneyTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 1.09;
  color: ${DarkGrey};
  margin-bottom: 16px;
`

export const JourneyHeaderStepsGroup = styled.div`
  display: flex;
  margin-top: ${({spaceT}) => spaceT || 0}px;
  margin-bottom: ${({spaceB}) => spaceB || 24}px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  color: ${({color}) => color || DarkGey};
`

export const Clickable = styled.div`
  cursor: pointer;
  white-space: nowrap;
`

export const ClickableDiv = styled.div`
  cursor: pointer;
`

export const JourneyHeaderStepsIcon = styled.div`
  background-color: ${({color}) => color || DarkGey};
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 8px;
  display: table;
`

export const JourneyIntroductionText = styled.div`
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  color: ${DarkGrey};
  margin-bottom: ${({spaceB}) => spaceB || 0}px;
`

export const ActiveJourneyBlock = styled(Row)`
  width: 100%;
  cursor: pointer;
  margin-bottom: 26px;

  @media ${device.laptopL} {
    display: flex;
    flex-direction: column-reverse;
    flex-flow: column-reverse;
  }
`

export const ActiveJourneyCol = styled(Col)`
  @media ${device.laptopL} {
    flex: 1;
    display: flex;
    flex-flow: column-reverse;
  }
`

export const NoJourneyImage = styled(ImageComponent)`
  width: 100%;
  height: 100%;
  background-color: ${LightGey};
`

export const NoJourneyBlock = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
  position: relative;
`

export const NoJourneyInfoBlock = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;
`

export const HeaderTitle = styled.div`
  font-size: 26px;
  font-weight: 800;
  line-height: 1.23;
  color: ${white};
`
export const H5P = styled.div`
  display: flex;

  &&& iframe {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`
