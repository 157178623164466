import React, {useEffect} from 'react'

import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  IgnoreFeedback,
  MarkFeedbackSeen,
  MarkSeen,
  IgnoreVersion
} from '../../infra/requests/ActivityRequests'
import {removeNotification} from '../../redux/data/user/UserActions'
import {GetAndUpdateFeed} from '../../redux/thunks/UpdateThunk'
import FeedbackCard from '../cards/FeedbackCard'
import JourneyVersionCard from '../cards/JourneyVersionCard'
import KudosCard from '../cards/KudosCard'
import RequestFeedbackCard from '../cards/RequestFeedbackCard'
import ValidationsCard from '../cards/ValidationsCard'
import Drawer from '../drawer/Drawer'
import EmptyLayoutComponent from '../empty_layout/EmptyLayoutComponent'
import {
  NotificationsSection,
  NotificationsList
} from './NotificationsStyles'

const NotificationsDrawer = ({
  open,
  onClose,
  feed,
  dispatch,
  notifications
}) => {
  const removeNotifications = async () => {
    if (notifications.length > 0) {
      const hasKudos = notifications.some(
        (x) => x.tag.indexOf('KUDOS') > -1
      )
      if (hasKudos) {
        dispatch(removeNotification('kudos'))
      }
      const hasFeedback = notifications.some(
        (x) => x.tag.indexOf('FEEDBACK') > -1
      )
      if (hasFeedback) {
        dispatch(removeNotification('feedback'))
      }
      const hasValidation = notifications.some(
        (x) => x.tag.indexOf('VALIDATION') > -1
      )
      if (hasValidation) {
        dispatch(removeNotification('validation'))
      }
      const hasVersions = notifications.some(
        (x) => x.tag.indexOf('UPDATE_JOURNEY') > -1
      )
      if (hasVersions) {
        dispatch(removeNotification('UPDATE_JOURNEY'))
      }
    }
  }

  useEffect(() => {
    removeNotifications()
  })

  const onRemove = async (item) => {
    if (!item.seen) {
      switch (item.feedType) {
        case 'feedback_requests':
          await IgnoreFeedback(item._id)
          dispatch(GetAndUpdateFeed())
          break
        case 'feedbacks_received':
          await MarkFeedbackSeen(item._id)
          dispatch(GetAndUpdateFeed())
          break
        case 'kudos':
          await MarkSeen(item._id)
          dispatch(GetAndUpdateFeed())
          break
        case 'journeys_versions':
          await IgnoreVersion(item._id)
          dispatch(GetAndUpdateFeed())
          break
        default:
          break
      }
    }
  }

  const renderList = () =>
    feed.map((item, index) => {
      switch (item.feedType) {
        case 'feedback_requests':
          return (
            <RequestFeedbackCard
              key={index}
              item={item}
              onClose={onRemove}
              isFeed
            />
          )

        case 'feedbacks_received':
          return (
            <FeedbackCard
              key={index}
              item={item}
              onClose={onRemove}
              isFeed
              bgColor='transparent'
            />
          )

        case 'kudos':
          return (
            <KudosCard key={index} item={item} onClose={onRemove} isFeed />
          )

        case 'validations':
          return <ValidationsCard key={index} item={item} isFeed />

        case 'journeys_versions':
          return (
            <JourneyVersionCard
              key={index}
              item={item}
              isFeed
              onClose={onRemove}
            />
          )

        default:
          return []
      }
    })

  return (
    <Drawer destroyOnClose open={open} onClose={onClose} title=''>
      <NotificationsSection>
        {feed && feed.length > 0 ? (
          <NotificationsList>{renderList()}</NotificationsList>
        ) : (
          <EmptyLayoutComponent tag='EMPTY_FEED' />
        )}
      </NotificationsSection>
    </Drawer>
  )
}

NotificationsDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  feed: PropTypes.array,
  notifications: PropTypes.array
}

NotificationsDrawer.defaultProps = {
  feed: undefined,
  notifications: undefined
}

const mapStateToProps = (state) => ({
  feed: state.feed,
  notifications: state.user.notifications
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default connect(
  mapStateToProps,
  mapActionToProps
)(NotificationsDrawer)
