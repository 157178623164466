import {createReducer} from '@reduxjs/toolkit'

import {updateSuggestedJourneys} from './SuggestedJourneysActions'

const initialState = []

const SuggestedJourneysReducer = createReducer(initialState, {
  [updateSuggestedJourneys]: (state, action) => action.payload
})

export default SuggestedJourneysReducer
