export default [
  {
    name: 'DASHBOARD',
    link: '/'
  },
  {
    name: 'JOURNEYS',
    link: '/journeys'
  },
  {
    name: 'LIBRARY',
    link: '/library'
  }
]
