import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import arrayMutators from 'final-form-arrays'
import {PropTypes} from 'prop-types'
import {Field, FormSpy} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {useDispatch} from 'react-redux'

import DefaultLink from '../../assets/default_link.png'
import CloseIcon from '../../assets/icons/ic_close_white.svg'
import IconPlay from '../../assets/icons/ic_play.svg'
import {GetKudos, SendKudos} from '../../infra/requests/CommunityRequests'
import BuildFileUrl from '../../infra/services/files/FileService'
import {GetValuePreview} from '../../infra/utils/CommonFunctions'
import {clearKudos} from '../../redux/data/kudos/KudosActions'
import {InputBox, InputLabel} from '../input/InputStyles'
import TextAreaInput from '../input/TextAreaInput'
import MediaPickerComponent from '../media_picker/MediaPickerComponent'
import {CloseBtn, AttachImage} from '../media_picker/PhotoComponentStyles'
import BaseModalForm from '../modal/BaseModalForm'
import {
  KudoContainer,
  KudoText,
  KudosImage,
  ShadowKudos
} from './KudosStyles'

const ValidateKudos = (values) => {
  const errors = {}
  if (!values.item) {
    errors.item = 'Required'
  }
  return errors
}

const SendKudosModal = ({peer, translate, activeLanguage}) => {
  const dispatch = useDispatch()
  const [kudos, setKudos] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  useEffect(() => {
    async function getKudos() {
      const result = await GetKudos()
      setKudos(result.data)
    }
    if (!kudos) {
      getKudos()
    }
  }, [kudos])

  const onSaveForm = async (fields) => {
    try {
      const contentToSend = {to: peer._id}

      if (fields.message) {
        contentToSend.message = fields.message
      }

      if (fields.item && fields.item._id) {
        contentToSend.id = fields.item._id
      }

      if (fields.item && fields.item.file) {
        contentToSend.attachment = fields.item
      }
      await SendKudos(contentToSend)
      dispatch(clearKudos())
    } catch (error) {
      console.warn(error)
    }
  }

  const onUpdateInput = async (value, form) => {
    form.change('item', value)
    setCurrentStep(1)
  }

  const renderImage = (value) => {
    switch (value?.type) {
      case 'image': {
        const image = {
          url: value?.object?.preview ? value.object.preview : DefaultLink,
          color: '#fff'
        }
        return <AttachImage image={image} />
      }
      case 'video': {
        const image = value?.object?.preview
          ? value.object.preview
          : DefaultLink
        return (
          <div
            style={{
              width: '100%',
              height: 172,
              backgroundColor: '#f4f4f4',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden'
            }}
          >
            <img
              src={IconPlay}
              alt='video'
              style={{position: 'absolute'}}
            />
            <video
              key={Math.random()}
              style={{
                width: '100%'
              }}
            >
              <source src={image} type='video/mp4' />
            </video>
          </div>
        )
      }
      default:
        return null
    }
  }

  return (
    <BaseModalForm
      open={!!peer}
      onClose={() => dispatch(clearKudos())}
      title={translate('GIVE_KUDOS')}
      onSubmit={onSaveForm}
      validate={ValidateKudos}
      okText={translate('SEND')}
      initialValues={{}}
      mutators={{
        ...arrayMutators
      }}
      noPristine
    >
      <FormSpy subscription={{values: true}}>
        {({values, form}) => (
          <>
            <InputBox>
              <InputLabel>{translate('GIVE_KUDOS_TITLE')}</InputLabel>
              {!(currentStep === 1 && values.item) ? (
                <Row gutter={[6, 6]}>
                  <Col xs={12} md={8}>
                    <Field
                      name='item'
                      component={() => <input style={{display: 'none'}} />}
                    />
                    <Field
                      editable
                      name='new_kudos'
                      refField='new_kudos'
                      label={translate('TAKE_PICTURE_OR_VIDEO')}
                      component={MediaPickerComponent}
                      onInputChange={(value) => onUpdateInput(value, form)}
                    />
                  </Col>
                  {kudos &&
                    kudos.map((kudo, index) => (
                      <Col xs={12} md={8} key={index}>
                        <KudoContainer
                          onClick={() => onUpdateInput(kudo, form)}
                        >
                          <KudosImage
                            image={{
                              url: BuildFileUrl(kudo.image.file)
                            }}
                          />
                          <ShadowKudos />
                          <KudoText>
                            {GetValuePreview(
                              kudo.title,
                              activeLanguage && activeLanguage.code
                            )}
                          </KudoText>
                        </KudoContainer>
                      </Col>
                    ))}
                </Row>
              ) : (
                <>
                  {values.item && values.item.object ? (
                    <div style={{position: 'relative'}}>
                      {renderImage(values.item)}
                      <CloseBtn
                        onClick={(e) => {
                          e.preventDefault()
                          form.change('item', false)
                          form.change('new_kudos', false)
                        }}
                        showBg
                      >
                        <img src={CloseIcon} alt='remove' />
                      </CloseBtn>
                    </div>
                  ) : (
                    <div style={{position: 'relative'}}>
                      <KudosImage
                        image={{
                          url: BuildFileUrl(values.item.image.file)
                        }}
                      />
                      <ShadowKudos />
                      <KudoText>
                        {GetValuePreview(
                          values.item.title,
                          activeLanguage && activeLanguage.code
                        )}
                      </KudoText>
                      <CloseBtn
                        onClick={(e) => {
                          e.preventDefault()
                          form.change('item', false)
                        }}
                        showBg
                      >
                        <img src={CloseIcon} alt='remove' />
                      </CloseBtn>
                    </div>
                  )}
                  <Field
                    name='message'
                    refField='message'
                    label={translate('WRITE_YOUR_MESSAGE_HERE')}
                    placeholder={translate('EXAMPLE_OF_MESSAGE_HERE')}
                    component={TextAreaInput}
                    numberOfLines={5}
                    minHeight={182}
                    editable
                    hasTopSpace={40}
                  />
                </>
              )}
            </InputBox>
          </>
        )}
      </FormSpy>
    </BaseModalForm>
  )
}

SendKudosModal.propTypes = {
  translate: PropTypes.func.isRequired,
  peer: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired
}

export default withLocalize(SendKudosModal)
