export const NavbarSize = '80px'

export const PageBackground = '#F8F8F8'

export const PrimaryColor = '#0b4081'
export const SecondaryColor = '#17ac9d'
export const ThirdColor = '#3378c8'

export const DefaultGey = '#c0c0c0'
export const DarkGrey = '#0c0c0c'
export const LightGey = '#f4f4f4'
export const DarkGey = '#828282'
export const white = '#ffffff'
export const red = '#cc0000'
export const DarkRed = '#ab0000'
export const brownGrey = '#8b8b8b'
export const blackTwo = '#313131'
export const greyishBrown = '#3d3d3d'
export const mediumBlue = '#7498d6'
export const lightBlue = '#d3dbf2'
export const pinkishGrey = '#c8c8c8'
export const orangeYellow = '#ffa000'
export const grey = '#818181'
export const lightBlue2 = '#a5b9e4'
export const veryLightPink = '#e9e9e9'

export const SuccessColor = '#069f22'
export const ErrorColor = '#cc0000'

export const TextColor = '#0c0c0c'
export const BorderColor = '#e3e3e3'
export const WarningColor = '#cc0000'

export const InputErrorColor = '#ff0100'
