import React from 'react'

import {Select, Spin} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import {StyledSelectInput, InputBox, InputLabel} from './InputStyles'

const {Option} = Select

const SelectInput = ({
  input,
  meta,
  label,
  items,
  placeholder,
  mode,
  notFoundMessage,
  fetching,
  disabled,
  fetchData,
  loading,
  allowClear,
  hasTopSpace,
  maxwidth,
  radius,
  bgcolor
}) => {
  const {invalid, submitFailed} = meta
  const showError = invalid && submitFailed
  const formatValue = (i) => {
    if (!i || !i.value) return undefined
    if (Array.isArray(i.value)) return i.value
    return i.value.toString()
  }
  return (
    <InputBox hasTopSpace={hasTopSpace}>
      {label && <InputLabel>{label}</InputLabel>}
      <StyledSelectInput
        bordered={false}
        disabled={disabled}
        mode={mode}
        placeholder={placeholder}
        notFoundContent={
          fetching ? <Spin size='small' /> : notFoundMessage
        }
        filterOption
        onSearch={fetchData}
        onChange={input.onChange}
        value={formatValue(input)}
        showArrow
        loading={loading}
        error={showError ? 1 : 0}
        allowClear={allowClear}
        maxwidth={maxwidth}
        radius={radius}
        bgcolor={bgcolor}
      >
        {items.map((current) => (
          <Option key={current.value}>{current.label}</Option>
        ))}
      </StyledSelectInput>
    </InputBox>
  )
}

SelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  items: PropTypes.array,
  placeholder: PropTypes.string,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  mode: PropTypes.string,
  notFoundMessage: PropTypes.string,
  fetching: PropTypes.bool,
  disabled: PropTypes.bool,
  hasTopSpace: PropTypes.number,
  allowClear: PropTypes.bool,
  maxwidth: PropTypes.string,
  radius: PropTypes.number,
  bgcolor: PropTypes.string
}

SelectInput.defaultProps = {
  items: [],
  placeholder: undefined,
  fetchData: undefined,
  loading: false,
  mode: undefined,
  notFoundMessage: undefined,
  fetching: false,
  disabled: false,
  hasTopSpace: undefined,
  label: undefined,
  allowClear: false,
  maxwidth: undefined,
  radius: undefined,
  bgcolor: undefined
}

export default withLocalize(SelectInput)
