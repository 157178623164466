import React, {useState} from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import DefaultImg from '../../assets/no_journey.png'
import BaseButton from '../../components/buttons/BaseButton'
import DefaultList from '../../components/default_list/DefaultList'
import JourneyBlockComponent from '../../components/journey_block/JourneyBlockComponent'
import JourneyModal from '../../components/modal/JourneyModal'
import ProgressBarComponent from '../../components/progress_bar/ProgressBarComponent'
import {
  SetJourneyActive,
  ToggleJourneyArchived
} from '../../infra/requests/JourneyRequests'
import {CalcJourneySteps} from '../../infra/utils/CommonFunctions'
import {updateActiveJourney} from '../../redux/data/user/UserActions'
import {
  GetAndUpdateInactiveJourneys,
  GetAndUpdateArchivedJourneys
} from '../../redux/thunks/UpdateThunk'
import {ThirdColor, LightGey} from '../../styles/_theme'
import {InformationContainer} from '../active_journey/ActiveJourneyStyles'
import {
  JourneyDetail,
  JourneyDetailTitle,
  JourneyHeaderStepsGroup,
  Clickable,
  JourneyHeaderStepsIcon,
  JourneyTitle,
  InformationPageScroll,
  ActiveJourneyBlock,
  ActiveJourneyCol,
  NoJourneyImage,
  HeaderTitle,
  NoJourneyBlock,
  NoJourneyInfoBlock
} from './JourneyStyles'

const MainJourneys = ({
  translate,
  journey,
  router,
  inactiveJourneys,
  suggestedJourneys,
  dispatch
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [journeyModal, setJourneyModal] = useState(false)
  const [suggestedJourneyModal, setSuggestedJourneyModal] = useState(false)

  const onPressBtn = async (item) => {
    setIsLoading(true)
    try {
      const result = await SetJourneyActive(item._id)
      dispatch(
        updateActiveJourney({active_journey: result.data, translate})
      )
      dispatch(GetAndUpdateInactiveJourneys())
      router.history.push('/active-journey')
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onPressArchiveJourney = async (item) => {
    setIsLoading(true)
    try {
      await ToggleJourneyArchived(item._id)
      dispatch(GetAndUpdateInactiveJourneys())
      dispatch(GetAndUpdateArchivedJourneys())
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <InformationPageScroll>
      <InformationContainer>
        <Row>
          <Col xs={24}>
            {journey && (
              <BaseButton
                type='button'
                onClick={() => router.history.push('/create-journey')}
                label={translate('CREATE_CUSTOM_JOURNEY')}
                category='tertiary'
                style={{float: 'right', marginBottom: 16}}
              />
            )}
          </Col>
          {journey ? (
            <ActiveJourneyBlock
              onClick={() => router.history.push('/active-journey')}
            >
              <ActiveJourneyCol xs={24} xl={8}>
                <JourneyDetail>
                  <JourneyDetailTitle>
                    {translate('ACTIVE_JOURNEY')}
                  </JourneyDetailTitle>
                  <JourneyTitle>{journey.title}</JourneyTitle>
                  <JourneyHeaderStepsGroup>
                    {!journey.validation &&
                    moment(journey.review_date) > moment() ? (
                      <div style={{whiteSpace: 'nowrap'}}>
                        {translate('REVIEW_IN')}{' '}
                        {moment(journey.review_date).fromNow(true)}
                      </div>
                    ) : journey?.validation?.completed ? (
                      <Clickable>{translate('VIEW_REVIEW')}</Clickable>
                    ) : (
                      <Clickable>{translate('REVIEW_NOW')}</Clickable>
                    )}
                    <JourneyHeaderStepsIcon />
                    <div style={{whiteSpace: 'nowrap'}}>
                      {CalcJourneySteps(journey)} {translate('STEPS')}
                    </div>
                  </JourneyHeaderStepsGroup>
                  <ProgressBarComponent
                    progress={`${journey.completion}%`}
                    borderRadius={19}
                    height={22}
                    marginB={32}
                    color={ThirdColor}
                    unfilledColor={LightGey}
                  />
                  {journey.intention}
                </JourneyDetail>
              </ActiveJourneyCol>
              <ActiveJourneyCol xs={24} xl={16}>
                <JourneyBlockComponent
                  item={journey}
                  height='100%'
                  isActiveJourney
                />
              </ActiveJourneyCol>
            </ActiveJourneyBlock>
          ) : (
            <NoJourneyBlock style={{height: 300}}>
              <NoJourneyImage
                ratio={0}
                image={{url: DefaultImg}}
                style={{height: 300}}
              />
              <NoJourneyInfoBlock>
                <HeaderTitle>
                  {translate('DONT_HAVE_ACTIVE_JOURNEY')}
                </HeaderTitle>
                <BaseButton
                  type='button'
                  onClick={() => router.history.push('/create-journey')}
                  label={translate('CREATE_CUSTOM_JOURNEY')}
                  category='tertiary'
                  style={{marginTop: 24}}
                />
              </NoJourneyInfoBlock>
            </NoJourneyBlock>
          )}
          {!journey && inactiveJourneys && inactiveJourneys.length > 0 && (
            <DefaultList
              title={translate('INACTIVE_JOURNEYS')}
              data={inactiveJourneys.slice(0, 3)}
              renderItem={(item) => (
                <JourneyBlockComponent
                  onPressItem={(i) => setJourneyModal(i)}
                  item={item}
                  height='180px'
                  onPressArchive={onPressArchiveJourney}
                  onPressBtn={onPressBtn}
                  isInactive
                  loading={isLoading}
                />
              )}
            />
          )}
          {!journey &&
            (!inactiveJourneys || !inactiveJourneys.length) &&
            suggestedJourneys &&
            suggestedJourneys.length > 0 && (
              <DefaultList
                title={translate('SUGGESTED_JOURNEYS')}
                data={suggestedJourneys.slice(0, 3)}
                renderItem={(item) => (
                  <JourneyBlockComponent
                    item={item}
                    height='180px'
                    loading={isLoading}
                    onPressItem={(i) => setSuggestedJourneyModal(i)}
                  />
                )}
              />
            )}
          {journeyModal && (
            <JourneyModal
              open={!!journeyModal}
              journey={journeyModal}
              onClose={() => setJourneyModal(false)}
              isInactive
              onPressArchive={() => {
                setJourneyModal(false)
                onPressArchiveJourney(journeyModal)
              }}
              onPressReactivate={() => {
                setJourneyModal(false)
                onPressBtn(journeyModal)
              }}
            />
          )}
          {suggestedJourneyModal && (
            <JourneyModal
              open={!!suggestedJourneyModal}
              journey={suggestedJourneyModal}
              onClose={() => setSuggestedJourneyModal(false)}
              router={router}
            />
          )}
        </Row>
      </InformationContainer>
    </InformationPageScroll>
  )
}

MainJourneys.propTypes = {
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  journey: PropTypes.object,
  router: PropTypes.object.isRequired,
  inactiveJourneys: PropTypes.array,
  suggestedJourneys: PropTypes.array
}

MainJourneys.defaultProps = {
  journey: undefined,
  inactiveJourneys: undefined,
  suggestedJourneys: undefined
}

const mapStateToProps = (state) => ({
  journey: state.user.active_journey,
  inactiveJourneys: state.inactive_journeys,
  suggestedJourneys: state.suggested_journeys
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(MainJourneys)
)
