import React from 'react'

import moment from 'moment'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {updateModal} from '../../redux/data/modal/ModalActions'
import {DarkGrey} from '../../styles/_theme'
import {MainText} from '../../styles/BasicStyles'
import JourneyBlockComponent from '../journey_block/JourneyBlockComponent'
import {
  CardContainer,
  CardTimeHeader,
  CardTask,
  CardTaskText
} from './CardsStyles'

const ValidationsCard = ({item, translate, isFeed, marginH, dispatch}) => {
  if (!item.journey) return null
  const header =
    item.journey && item.journey.goal ? item.journey.goal : false

  return (
    <CardContainer
      marginH={marginH}
      isFeed={isFeed}
      style={{flex: isFeed ? 1 : 0}}
      onClick={() =>
        dispatch(
          updateModal({
            type: 'validation',
            object: item
          })
        )
      }
    >
      {header && (
        <CardTask>
          <CardTaskText>
            {header && `${translate('FROM')} ${header}`}
          </CardTaskText>
        </CardTask>
      )}
      <CardTimeHeader>{moment(item.createdAt).fromNow()}</CardTimeHeader>

      <MainText color={DarkGrey} margin='12px 11px'>
        {translate('VALIDATE_DESCRIPTION')}
      </MainText>
      <div style={{flex: 1, justifyContent: 'flex-end'}}>
        <JourneyBlockComponent
          onPressItem={() =>
            dispatch(
              updateModal({
                type: 'validation',
                object: item
              })
            )
          }
          item={item.journey}
          isFull
          height={isFeed ? '190px' : '140px'}
        />
      </div>
    </CardContainer>
  )
}

ValidationsCard.propTypes = {
  item: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  isFeed: PropTypes.bool,
  marginH: PropTypes.number
}

ValidationsCard.defaultProps = {
  marginH: 0,
  isFeed: false
}

const mapStateToProps = (state) => ({
  activeJourney: state.user.activeJourney
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(ValidationsCard)
)
