import React from 'react'

import {Slider} from 'antd'
import PropTypes from 'prop-types'

import {StarRatingContainer, TextSlider} from './ReviewStyles'

const PointRatingComponent = ({
  input,
  noEditable,
  isRight,
  editable,
  spacet,
  spaceb,
  small
}) => {
  const edit = !noEditable && editable
  const renderStartProgress = () => {
    if (edit && (!input.value || !input.value === '')) {
      input.onChange(1)
    }

    return (
      <div
        style={{
          padding: '0px 10px',
          width: small ? 140 : '100%',
          height: small ? 30 : 60
        }}
      >
        <Slider
          disabled={!edit}
          style={{
            width: '100%',
            height: small ? 15 : 30,
            margin: '18px 0px 0px 0px'
          }}
          min={1}
          max={10}
          step={1}
          value={parseInt(input.value) || 1}
          onChange={(val) => input.onChange(parseInt(val))}
        />
        <div
          style={{
            width: '100%',
            position: 'relative',
            margin: '0px -10px'
          }}
        >
          <TextSlider
            style={{
              left: `${((input.value - 1) * 100) / 9}%`
            }}
          >
            {input.value}
          </TextSlider>
        </div>
      </div>
    )
  }

  return (
    <StarRatingContainer isRight={isRight} spacet={spacet} spaceb={spaceb}>
      {renderStartProgress()}
    </StarRatingContainer>
  )
}

PointRatingComponent.propTypes = {
  input: PropTypes.object.isRequired,
  noEditable: PropTypes.bool,
  isRight: PropTypes.bool,
  editable: PropTypes.bool,
  spacet: PropTypes.number,
  spaceb: PropTypes.number,
  small: PropTypes.bool
}

PointRatingComponent.defaultProps = {
  noEditable: false,
  isRight: false,
  editable: false,
  spacet: undefined,
  spaceb: undefined,
  small: false
}

export default PointRatingComponent
