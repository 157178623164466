import React, {useState} from 'react'

import {Popconfirm} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'

import UserDefault from '../../assets/default_user.png'
import IconArrow from '../../assets/icons/ic_chevron_right_black.svg'
import Edit from '../../assets/icons/ic_edit.svg'
import Image from '../../assets/icons/ic_image.svg'
import {LeaveChannel} from '../../infra/requests/CommunityRequests'
import {updateChat} from '../../redux/data/chat/ChatActions'
import {GetAndUpdateChats} from '../../redux/thunks/UpdateThunk'
import {Label} from '../review_components/ReviewStyles'
import AddMembersModal from './AddMembersModal'
import {
  DefaultHeaderPhoto,
  ChatHeaderPhoto,
  HeaderBlock,
  EditBtn,
  HeaderUsers,
  ChatInfo,
  LineContainer,
  UserImage,
  UsersContainerCenter,
  UserImageBox
} from './ChatStyles'
import EditGroupModal from './EditGroupModal'
import MembersModal from './MembersModal'
import RemoveMembersModal from './RemoveMembersModal'

const ProfileComponent = ({closeDrawer, translate}) => {
  const dispatch = useDispatch()

  const {chat, user} = useSelector((state) => state)
  const [membersModal, setMembersModal] = useState(false)
  const [addMembersModal, setAddMembersModal] = useState(false)
  const [removeMembersModal, setRemoveMembersModal] = useState(false)
  const [editGroupModal, setGroupModal] = useState(false)

  const image = chat.image ? chat.image : false

  const users = chat.users ? [...chat.users] : []
  if (chat.creator && !users.find((u) => u._id === chat.creator._id)) {
    users.push(chat.creator)
  }

  const onPressLeaveGroup = async () => {
    try {
      const result = await LeaveChannel(chat.channel)
      if (result.success) {
        dispatch(updateChat(false))
        dispatch(GetAndUpdateChats())
        closeDrawer()
      }
    } catch (error) {
      console.warn(error)
    }
  }

  return (
    <ChatInfo>
      <HeaderBlock>
        {chat.type === 'channel' && chat.creator?._id === user._id && (
          <EditBtn onClick={() => setGroupModal(true)}>
            <img src={Edit} alt='edit' />
          </EditBtn>
        )}
        {image ? (
          <ChatHeaderPhoto ratio={0} image={image} />
        ) : (
          <DefaultHeaderPhoto>
            <img src={Image} alt='default' />
          </DefaultHeaderPhoto>
        )}
        <HeaderUsers>
          <UsersContainerCenter>
            {users
              .sort(() => 0.5 - Math.random())
              .slice(0, 3)
              .map((u, index) => {
                const img = u.image
                  ? u.image
                  : u.photo
                  ? u.photo
                  : {url: UserDefault}
                return (
                  <UserImageBox
                    key={index}
                    style={{
                      left: index === 0 ? -55 : index === 1 ? -23 : 9,
                      zIndex:
                        index === 0
                          ? 8
                          : index === 1
                          ? 9
                          : index === 2
                          ? 10
                          : 0
                    }}
                  >
                    <UserImage image={img} />
                  </UserImageBox>
                )
              })}
          </UsersContainerCenter>
          {chat.name}
        </HeaderUsers>
      </HeaderBlock>
      <Label margin='50px 20px 0px'>{translate('GROUP_INFO')}</Label>
      <LineContainer onClick={() => setMembersModal(true)}>
        {translate('SEE_GROUP_MEMBERS')}
        <img src={IconArrow} alt='IconArrow' />
      </LineContainer>
      {chat.creator?._id === user._id && (
        <LineContainer onClick={() => setAddMembersModal(true)}>
          {translate('ADD_NEW_MEMBERS')}
          <img src={IconArrow} alt='IconArrow' />
        </LineContainer>
      )}
      {chat.type === 'channel' && (
        <>
          <Label margin='32px 20px 0px'>
            {translate('GROUP_EXIT_TITLE')}
          </Label>
          {chat.creator?._id === user._id &&
            chat.users &&
            chat.users.length > 0 && (
              <LineContainer onClick={() => setRemoveMembersModal(true)}>
                {translate('REMOVE_MEMBER')}
                <img src={IconArrow} alt='IconArrow' />
              </LineContainer>
            )}
          <Popconfirm
            placement='top'
            title={translate('CONFIRM_LEAVE_GROUP')}
            onConfirm={onPressLeaveGroup}
          >
            <LineContainer onClick={(e) => e.stopPropagation()}>
              {translate('LEAVE_GROUP')}
              <img src={IconArrow} alt='IconArrow' />
            </LineContainer>
          </Popconfirm>
        </>
      )}
      {membersModal && (
        <MembersModal
          open={membersModal}
          users={users}
          onClose={() => setMembersModal(false)}
        />
      )}
      {addMembersModal && (
        <AddMembersModal
          open={addMembersModal}
          onClose={() => setAddMembersModal(false)}
        />
      )}
      {removeMembersModal && (
        <RemoveMembersModal
          open={removeMembersModal}
          users={chat.users}
          onClose={() => setRemoveMembersModal(false)}
        />
      )}
      {editGroupModal && (
        <EditGroupModal
          open={editGroupModal}
          chat={chat}
          onClose={() => setGroupModal(false)}
          onSave={() => setGroupModal(false)}
        />
      )}
    </ChatInfo>
  )
}

ProfileComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired
}

export default withLocalize(ProfileComponent)
