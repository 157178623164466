import React, {useState} from 'react'

import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import {withLocalize} from 'react-localize-redux'

import DefaultLink from '../../assets/default_link.png'
import Photo from '../../assets/icons/ic_camera.svg'
import CloseIcon from '../../assets/icons/ic_close_white.svg'
import IconPlay from '../../assets/icons/ic_play.svg'
import {red, DarkGrey} from '../../styles/_theme'
import {SmallText} from '../input/InputStyles'
import {KudoText} from '../kudos/KudosStyles'
import {ImagePicker, CloseBtn, AttachImage} from './PhotoComponentStyles'

const MediaPickerComponent = ({
  input,
  label,
  editable,
  translate,
  acceptedTypes,
  onInputChange,
  isSelected
}) => {
  const [errorMessage, setErrorMessage] = useState()

  const handleImageDrop = (accepted, rejected) => {
    if (accepted.length <= 0 || (rejected && rejected.length > 0)) {
      if (rejected[0]?.size > 5000000) {
        return setErrorMessage(translate('ERROR_FILE_SIZE'))
      }
      return setErrorMessage(translate('ERROR_FILE'))
    }
    const type =
      accepted[0].type.indexOf('video') > -1
        ? 'video'
        : accepted[0].type.indexOf('image') > -1
        ? 'image'
        : 'file'

    const uploaded = {
      type,
      object: {
        type,
        path: accepted[0].path,
        name: accepted[0].name,
        preview: URL.createObjectURL(accepted[0])
      },
      file: accepted[0]
    }
    input.onChange(uploaded)
    if (onInputChange) onInputChange(uploaded)
  }

  const renderImage = (value) => {
    switch (value?.type) {
      case 'image': {
        const image = {
          url: value?.object?.preview ? value.object.preview : DefaultLink,
          color: '#fff'
        }
        return <AttachImage isSelected={isSelected} image={image} />
      }
      case 'video': {
        const image = value?.object?.preview
          ? value.object.preview
          : DefaultLink
        return (
          <div
            style={{
              width: '100%',
              height: 172,
              backgroundColor: '#f4f4f4',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden'
            }}
          >
            <img
              src={IconPlay}
              alt='video'
              style={{position: 'absolute'}}
            />
            <video
              key={Math.random()}
              style={{
                width: '100%'
              }}
            >
              <source src={image} type='video/mp4' />
            </video>
          </div>
        )
      }
      default:
        return null
    }
  }
  return (
    <>
      {editable && (
        <Dropzone
          onDrop={handleImageDrop}
          style={{
            display: 'inline-block',
            float: 'right',
            marginBottom: '30px'
          }}
          accept={acceptedTypes}
          maxSize={5000000}
        >
          {({getRootProps, getInputProps}) => (
            <ImagePicker {...getRootProps()} style={{outline: 'none'}}>
              <input {...getInputProps()} />
              {input.value ? (
                <>
                  {renderImage(input.value)}
                  <CloseBtn
                    onClick={(e) => {
                      e.preventDefault()
                      input.onChange(false)
                      if (onInputChange) onInputChange(false)
                    }}
                    showBg
                  >
                    <img src={CloseIcon} alt='remove' />
                  </CloseBtn>
                </>
              ) : (
                <>
                  <img src={Photo} alt='upload' />
                  <KudoText style={{color: DarkGrey}}>{label}</KudoText>
                  {errorMessage && (
                    <SmallText color={red}>{errorMessage}</SmallText>
                  )}
                </>
              )}
            </ImagePicker>
          )}
        </Dropzone>
      )}
    </>
  )
}

MediaPickerComponent.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  translate: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  error: PropTypes.bool,
  acceptedTypes: PropTypes.string,
  onInputChange: PropTypes.func,
  showValues: PropTypes.bool,
  isSelected: PropTypes.bool
}

MediaPickerComponent.defaultProps = {
  error: false,
  editable: false,
  acceptedTypes: 'image/jpeg, video/mp4',
  label: undefined,
  onInputChange: undefined,
  showValues: false,
  isSelected: false
}

export default withLocalize(MediaPickerComponent)
