import React from 'react'

import {PropTypes} from 'prop-types'

import {InputBox, InputLabel, TextAreaInputField} from './InputStyles'

const TextAreaInput = ({
  input,
  label,
  meta,
  placeholder,
  afterChange,
  minHeight,
  hasTopSpace,
  editable
}) => {
  const handleChange = (event) => {
    input.onChange(event.target.value)
    if (typeof afterChange === 'function') {
      afterChange(event.target.value)
    }
  }

  const hasError = meta.invalid && meta.submitFailed

  return (
    <InputBox hasTopSpace={hasTopSpace}>
      {label && <InputLabel>{label}</InputLabel>}
      <TextAreaInputField
        error={hasError || undefined}
        $hasError={hasError || undefined}
        placeholder={placeholder}
        value={input.value}
        onChange={handleChange}
        min={minHeight}
        autoSize={{minRows: 2, maxRows: 6}}
        disabled={!editable}
      />
    </InputBox>
  )
}

TextAreaInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  afterChange: PropTypes.func,
  minHeight: PropTypes.number,
  hasTopSpace: PropTypes.number,
  editable: PropTypes.bool
}

TextAreaInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  label: undefined,
  placeholder: undefined,
  meta: {},
  afterChange: undefined,
  minHeight: undefined,
  hasTopSpace: undefined,
  editable: false
}

export default TextAreaInput
