import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useDispatch} from 'react-redux'

import IconPlay from '../../assets/icons/ic_play.svg'
import {MarkSeen} from '../../infra/requests/ActivityRequests'
import BuildFileUrl from '../../infra/services/files/FileService'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {InformationTitle} from '../../pages/active_journey/ActiveJourneyStyles'
import {GetAndUpdateFeed} from '../../redux/thunks/UpdateThunk'
import {DarkGrey} from '../../styles/_theme'
import {WhiteLayout, MainText, VideoBox} from '../../styles/BasicStyles'
import {AttachBtn} from '../attachments/AttachmentsStyles'
import {InputBox} from '../input/InputStyles'
import {KudosImageCard} from './KudosStyles'

const KudosDetail = ({kudo, translate, isShare, activeLanguage}) => {
  const dispatch = useDispatch()
  const [hasFetchSeen, setHasFetchSeen] = useState(false)

  useEffect(() => {
    async function setKudosSeen() {
      await MarkSeen(kudo._id)
      dispatch(GetAndUpdateFeed())
      setHasFetchSeen(true)
    }
    if (!hasFetchSeen && !kudo.seen && !isShare) {
      setKudosSeen()
    }
  }, [hasFetchSeen, kudo._id, kudo.seen, dispatch, isShare])

  const renderImage = () => {
    if (kudo.attachment) {
      switch (kudo.attachment.contentType) {
        case 'image/jpeg':
        case 'image/png': {
          const image = {
            url: BuildFileUrl(kudo.attachment._id),
            color: '#fff'
          }
          return (
            <AttachBtn href={image.url} rel='noreferrer' target='_blank'>
              <KudosImageCard image={image} />
            </AttachBtn>
          )
        }
        case 'video/mp4': {
          const image = BuildFileUrl(kudo.attachment._id)
          return (
            <AttachBtn href={image} rel='noreferrer' target='_blank'>
              <VideoBox>
                <img
                  src={IconPlay}
                  alt='video'
                  style={{position: 'absolute'}}
                />
                <video>
                  <source src={image} type='video/mp4' />
                </video>
              </VideoBox>
            </AttachBtn>
          )
        }
        default:
          return null
      }
    } else {
      const image = {
        url: BuildFileUrl(
          kudo.kudos && kudo.kudos.image && kudo.kudos.image.file
        ),
        color: '#fff'
      }
      return (
        <AttachBtn href={image.url} rel='noreferrer' target='_blank'>
          <KudosImageCard image={image} />
        </AttachBtn>
      )
    }
  }

  const renderTitle = () => {
    let title
    if (kudo.kudos) {
      title = GetLanguageValue(kudo.kudos.title, activeLanguage.code)
    }

    return (
      <>
        {title && (
          <MainText margin='16px 0px 0px' color={DarkGrey}>
            {title}
          </MainText>
        )}
        {kudo.message && (
          <MainText margin='16px 0px 0px' color={DarkGrey}>
            {kudo.message}
          </MainText>
        )}
      </>
    )
  }

  return (
    <Row>
      <Col xs={24}>
        <InformationTitle>{translate('KUDOS')}</InformationTitle>
      </Col>
      <Col xs={24}>
        <Row>
          <WhiteLayout space=' 0px 30px 30px'>
            <InputBox hasTopSpace={30}>{renderImage()}</InputBox>
            {renderTitle()}
          </WhiteLayout>
        </Row>
      </Col>
    </Row>
  )
}

KudosDetail.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  kudo: PropTypes.object.isRequired,
  isShare: PropTypes.bool
}

KudosDetail.defaultProps = {
  isShare: false
}
export default withLocalize(KudosDetail)
