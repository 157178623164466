import React from 'react'

import {Switch} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {UpdatePermissions} from '../../infra/requests/ProfileRequests'
import {userSave} from '../../redux/data/user/UserActions'
import {Label, LineContainer, MenuTitle} from './SettingsStyles'

const Notifications = ({translate, user, dispatch}) => {
  const onChangeEmail = async () => {
    const result = await UpdatePermissions({
      notifications_email: !user.notifications_email
    })
    if (result.success) {
      dispatch(
        userSave({
          ...user,
          notifications_email: result.data.notifications_email
        })
      )
    }
  }

  const onChangeApp = async () => {
    const result = await UpdatePermissions({
      notifications_in_app: !user.notifications_in_app
    })
    if (result.success) {
      dispatch(
        userSave({
          ...user,
          notifications_in_app: result.data.notifications_in_app
        })
      )
    }
  }

  return (
    <>
      <MenuTitle spaceT={30} spaceB={36}>
        {translate('NOTIFICATIONS')}
      </MenuTitle>
      <LineContainer>
        <Label>{translate('FROM_EMAIL')}</Label>
        <Switch
          onChange={onChangeEmail}
          checked={user.notifications_email}
        />
      </LineContainer>
      <LineContainer spaceT={4}>
        <Label>{translate('IN_APP')}</Label>
        <Switch
          onChange={onChangeApp}
          checked={user.notifications_in_app}
        />
      </LineContainer>
    </>
  )
}

Notifications.propTypes = {
  translate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(Notifications)
)
