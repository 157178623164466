import React from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import EmptyLayoutComponent from '../../components/empty_layout/EmptyLayoutComponent'
import JourneyBlockComponent from '../../components/journey_block/JourneyBlockComponent'
import JourneyModal from '../../components/modal/JourneyModal'
import {GetGuidedJourneys} from '../../infra/requests/JourneyRequests'
import {LoadingSpin} from '../../styles/BasicStyles'
import {
  InformationTitle,
  InformationContainer,
  JourneyIntention
} from '../active_journey/ActiveJourneyStyles'
import {InformationPageScroll} from './JourneyStyles'

const limit = 10

class SuggestedJourneys extends React.Component {
  constructor(props) {
    super(props)
    this.scrollComponent = null
    this.state = {
      isLoading: false,
      journeys: [],
      page: 1,
      total: undefined,
      journeyModal: false
    }
  }

  componentDidMount = () => {
    this.getSuggestedJourneys()
  }

  getSuggestedJourneys = async () => {
    const {page, journeys, isLoading, total} = this.state
    if (isLoading || total <= journeys.length) return
    this.setState({isLoading: true})
    try {
      const result = await GetGuidedJourneys(page, limit)
      if (result.success) {
        const newJourneys = [...journeys, ...result.data.items]
        this.setState({
          isLoading: false,
          journeys: newJourneys,
          page: page + 1,
          total: result.data.total
        })
        // dispatch(updateSuggestedJourneys(newJourneys))
      }
    } catch (error) {
      console.warn(error)
    }
  }

  handleLoadMore = () => {
    if (
      this.scrollComponent &&
      this.scrollComponent.scrollHeight <=
        this.scrollComponent.scrollTop +
          this.scrollComponent.clientHeight +
          100
    ) {
      this.getSuggestedJourneys()
    }
  }

  render() {
    const {translate, router} = this.props
    const {journeys, isLoading, journeyModal} = this.state

    return (
      <InformationPageScroll
        onScroll={this.handleLoadMore}
        ref={(ref) => {
          this.scrollComponent = ref
        }}
      >
        <InformationContainer>
          <Row>
            <Col xs={24}>
              <InformationTitle>
                {translate('SUGGESTED_JOURNEYS')}
              </InformationTitle>
            </Col>
            <Col xs={24}>
              <JourneyIntention>
                {translate('SUGGESTED_JOURNEYS_DESCRIPTION')}
              </JourneyIntention>
            </Col>
            {isLoading ? (
              <LoadingSpin min='200px' />
            ) : journeys && journeys.length > 0 ? (
              <Col xs={24}>
                <Row gutter={[6, 0]}>
                  {journeys.map((item, index) => (
                    <Col xs={24} lg={12} key={index}>
                      <JourneyBlockComponent
                        key={index}
                        item={item}
                        height='180px'
                        loading={isLoading}
                        onPressItem={(item) =>
                          this.setState({journeyModal: item})
                        }
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            ) : (
              <EmptyLayoutComponent tag='EMPTY_SUGGESTED_JOURNEYS' />
            )}
          </Row>
          {journeyModal && (
            <JourneyModal
              open={!!journeyModal}
              journey={journeyModal}
              onClose={() => this.setState({journeyModal: false})}
              router={router}
            />
          )}
        </InformationContainer>
      </InformationPageScroll>
    )
  }
}

SuggestedJourneys.propTypes = {
  translate: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  journey: state.user.active_journey
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(SuggestedJourneys)
)
