import styled from 'styled-components'

import {
  NavbarSize,
  ThirdColor,
  LightGey,
  DarkGey,
  DarkGrey
} from '../../styles/_theme'
import ImageComponent from '../images/ImageComponent'

export const CommunitySection = styled.div`
  display: inline-block;
  width: calc(100% + 48px);
  margin: 0 -24px;
`

export const ChatListSection = styled.div`
  height: calc(100vh - ${NavbarSize} - 100px);
  overflow: auto;
`

export const ChatBox = styled.div`
  display: inline-block;
  width: 100%;
  border-left: 3px solid
    ${({border}) => (border ? ThirdColor : 'transparent')};
  padding: 10px 8px;
  cursor: pointer;
  margin-bottom: -6px;

  &:hover {
    background: ${LightGey};
  }
`

export const ChatPhoto = styled(ImageComponent)`
  float: left;
  width: 60px;
  height: 60px;
  background-color: grey;
  border-radius: 50%;
`

export const ChatContent = styled.div`
  width: calc(100% - 170px);
  float: left;
  margin-left: 10px;
`

export const ChatName = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
`
export const ChatMessage = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.38;
  height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const ChatOptions = styled.div`
  width: 90px;
  float: left;
  text-align: right;
`

export const ChatDate = styled.div`
  font-size: 12px;
  color: ${DarkGey};
`

export const ActionsBar = styled.div`
  position: absolute;
  right: 24px;
  top: 16px;
`
export const AddGroupButton = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

export const TitleSection = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: ${DarkGrey};
  margin: ${({margin}) => margin || '0px'};
`

export const ChatRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
