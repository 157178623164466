import React from 'react'

import {Col, Row} from 'antd'
import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import {Form} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'

import BaseButton from '../../components/buttons/BaseButton'
import {WhiteLayout} from '../../styles/BasicStyles'
import {InformationTitle} from '../active_journey/ActiveJourneyStyles'
import {TabHeader, TabBtn} from './CreateJourneyStyles'
import {
  JourneysValidations,
  CustomJourneysValidations
} from './validations/JourneysValidations'

class Wizard extends React.Component {
  static Page = ({children}) => children

  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      values: props.initialValues || {}
    }
  }

  next = (values) => {
    const {page} = this.state
    const {children} = this.props
    this.setState({
      page: Math.min(page + 1, children.length - 1),
      values
    })
  }

  previous = () => {
    const {page} = this.state

    this.setState({
      page: Math.max(page - 1, 0)
    })
  }

  validate = (values) => {
    const {page} = this.state
    const {children} = this.props

    const activePage = React.Children.toArray(children)[page]
    return activePage.props.validate
      ? activePage.props.validate(values)
      : {}
  }

  handleSubmit = (values) => {
    const {children, onSubmit, edit, journeyType} = this.props
    const {page} = this.state
    const total =
      React.Children.count(children) -
      (edit && journeyType === 'guided' ? 2 : 1)
    const isLastPage = page === total
    if (isLastPage) {
      return onSubmit(values)
    }
    this.next(values)
  }

  onClickTab = (p) => {
    const {values} = this.state
    const {children} = this.props

    this.setState({
      page: Math.min(p, children.length - 1),
      values
    })
  }

  render() {
    const {
      children,
      translate,
      router,
      edit,
      onClickBack,
      journeyType
    } = this.props
    const {page, values} = this.state
    const activePage = React.Children.toArray(children)[page]
    return (
      <Form
        initialValues={values}
        validate={
          journeyType === 'guided'
            ? JourneysValidations
            : CustomJourneysValidations
        }
        onSubmit={this.handleSubmit}
        mutators={{
          ...arrayMutators
        }}
      >
        {({handleSubmit, submitting, valid}) => (
          <form onSubmit={handleSubmit}>
            <Row gutter={[12, 24]}>
              <Col xs={12}>
                <InformationTitle>
                  {translate('JOURNEY_SETTINGS')}
                </InformationTitle>
              </Col>
              <Col xs={12}>
                <BaseButton
                  type='submit'
                  label={translate('SAVE')}
                  category='enter'
                  style={{float: 'right'}}
                  disabled={submitting || !valid}
                />
                <BaseButton
                  onClick={() =>
                    edit ? onClickBack() : router.history.goBack()
                  }
                  label={translate('CANCEL')}
                  category='default'
                  style={{float: 'right', marginRight: 10}}
                />
              </Col>
            </Row>
            <WhiteLayout space='0px'>
              <TabHeader>
                <TabBtn
                  isSelected={page === 0}
                  onClick={() => this.onClickTab(0)}
                >
                  {translate('GOAL')}
                </TabBtn>
                <TabBtn
                  isSelected={page === 1}
                  onClick={() => this.onClickTab(1)}
                >
                  {translate('SETTINGS')}
                </TabBtn>
                {!(journeyType === 'guided' && edit) && (
                  <TabBtn
                    isSelected={page === 2}
                    onClick={() => this.onClickTab(2)}
                  >
                    {translate('STEPS')}
                  </TabBtn>
                )}
              </TabHeader>
              {activePage}
            </WhiteLayout>
            {/* <pre style={{maxWidth: 500}}>
              {JSON.stringify(values, 0, 2)}
            </pre> */}
          </form>
        )}
      </Form>
    )
  }
}

Wizard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired,
  router: PropTypes.object,
  edit: PropTypes.bool,
  onClickBack: PropTypes.func,
  journeyType: PropTypes.string
}

Wizard.defaultProps = {
  edit: false,
  router: undefined,
  onClickBack: undefined,
  journeyType: 'guided'
}

export default withLocalize(Wizard)
