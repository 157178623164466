import styled from 'styled-components'

import {white, LightGey, DarkGrey} from '../../styles/_theme'
import ImageComponent from '../images/ImageComponent'

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${({color}) => color || DarkGrey};
  line-height: 20px;
  margin-bottom: 20px;
`

export const AttachImage = styled(ImageComponent)`
  width: 100%;
  height: 150px;
  ratio: 0;
  background-color: ${LightGey};
  cursor: pointer;
`

export const AttachBtn = styled.a`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${LightGey};
  cursor: pointer;
  display: flex;
`

export const CloseBtn = styled.div`
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  margin: 4px;
  padding: 5px;
  position: absolute;
  border-radius: 17px;
  top: 0px;
  right: 0px;
  background-color: ${({showBg}) =>
    showBg ? 'rgba(12,12,12,0.4)' : 'transparent'};
  width: 44px;
  height: 44px;
`

export const AttachmentType = styled.div`
  position: absolute;
  bottom: 6px;
  left: 6px;
  background-color: rgba(12, 12, 12, 0.4);
  flex-direction: row;
  align-items: center;
  padding: 6px;
  padding-right: 18px;
  height: 30px;
  border-radius: 10px;
  max-width: calc(100% - 12px);
  margin-right: 30px;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  color: ${white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const Label = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: ${DarkGrey};
`
