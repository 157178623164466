import React, {useState} from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {hasNotificationGroup} from '../../infra/utils/CommonFunctions'
import {updateChat} from '../../redux/data/chat/ChatActions'
import {removeNotification} from '../../redux/data/user/UserActions'
import ChatDrawer from '../chat/ChatDrawer'
import Drawer from '../drawer/Drawer'
import Tabs from '../tabs/Tabs'
import ChatsComponent from './ChatsComponent'
import {CommunitySection} from './CommunityStyles'
import FriendsComponent from './FriendsComponent'
import NewGroupModal from './NewGroupModal'
import PeersComponent from './PeersComponent'

const CommunityDrawer = ({
  open,
  onClose,
  translate,
  dispatch,
  chat,
  user
}) => {
  const [section, setSection] = useState('chats')
  const [groupModal, setGroupModal] = useState(false)

  const {notifications} = user

  const permissions =
    !user.roles || !user.roles.filter((r) => r.role === 7).length
      ? false
      : 1

  const hasNotification =
    hasNotificationGroup(notifications, 'NEW_FRIEND_INVITE') ||
    hasNotificationGroup(notifications, 'FRIEND_ACCEPTED') ||
    hasNotificationGroup(notifications, 'FRIEND_REJECTED')

  const hasNotificationChats =
    hasNotificationGroup(notifications, 'PRIVATE') ||
    hasNotificationGroup(notifications, 'CHANNEL') ||
    hasNotificationGroup(notifications, 'GENERAL')

  const onClickChat = (item) => {
    dispatch(updateChat(item))
    // setChat(!seeChat)
  }

  const tabs = !permissions
    ? [
        {
          key: 'chats',
          name: translate('CHATS'),
          component: (
            <ChatsComponent
              onClickChat={onClickChat}
              onClickNewGroup={() => setGroupModal(true)}
            />
          ),
          hasNotification: hasNotificationChats
        },
        {
          key: 'peers',
          name: translate('FRIENDS'),
          component: <FriendsComponent onClickChat={onClickChat} />,
          hasNotification
        }
      ]
    : [
        {
          key: 'chats',
          name: translate('CHATS'),
          component: (
            <ChatsComponent
              onClickChat={onClickChat}
              onClickNewGroup={() => setGroupModal(true)}
            />
          ),
          hasNotification: hasNotificationChats
        },
        {
          key: 'colleagues',
          name: translate('PEERS'),
          component: <PeersComponent onClickChat={onClickChat} />
        },
        {
          key: 'peers',
          name: translate('FRIENDS'),
          component: <FriendsComponent onClickChat={onClickChat} />,
          hasNotification
        }
      ]

  return (
    <Drawer
      destroyOnClose
      open={open}
      onClose={onClose}
      title={translate('COMMUNITY')}
    >
      <CommunitySection>
        <Tabs
          activeKey={section}
          onChange={(s) => {
            if (s === 'peers') {
              dispatch(removeNotification('NEW_FRIEND_INVITE'))
              dispatch(removeNotification('FRIEND_ACCEPTED'))
              dispatch(removeNotification('FRIEND_REJECTED'))
            }
            setSection(s)
          }}
          tabSize={`${100 / tabs.length}%`}
          panels={tabs}
        />
        <NewGroupModal
          open={groupModal}
          onClose={() => setGroupModal(false)}
          onSave={() => setGroupModal(false)}
        />
        {chat && (
          <ChatDrawer
            open={!!chat}
            onClose={() => dispatch(updateChat(false))}
            openGroupModal={() => setGroupModal(true)}
          />
        )}
      </CommunitySection>
    </Drawer>
  )
}

CommunityDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  chat: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  user: PropTypes.object.isRequired
}
CommunityDrawer.defaultProps = {
  chat: undefined
}

const mapStateToProps = (state) => ({
  chat: state.chat,
  user: state.user
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(CommunityDrawer)
)
