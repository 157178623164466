import React, {useState} from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import {ReactComponent as ShareIcon} from '../../assets/icons/ic_share.svg'
import BaseButton from '../buttons/BaseButton'
import SquareButton from '../buttons/SquareButton'
import ShareModal from './ShareModal'

const ShareComponent = ({
  item,
  type,
  translate,
  isRight,
  isCircle,
  style,
  title
}) => {
  const [shareModal, setShareModal] = useState(false)

  return (
    <>
      {isCircle ? (
        <BaseButton
          type='button'
          label={title || translate('SHARE')}
          category='secondary'
          onClick={() => setShareModal(true)}
          style={style}
        />
      ) : (
        <SquareButton
          onClick={() => setShareModal(true)}
          isRight={isRight}
        >
          <>
            {translate('SHARE')}
            <ShareIcon style={{marginLeft: 8}} />
          </>
        </SquareButton>
      )}
      <ShareModal
        open={!!shareModal}
        item={item}
        type={type}
        onClose={() => setShareModal(false)}
        onSave={() => setShareModal(false)}
      />
    </>
  )
}

ShareComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  isRight: PropTypes.bool,
  isCircle: PropTypes.bool,
  style: PropTypes.object,
  item: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string
}

ShareComponent.defaultProps = {
  isRight: false,
  isCircle: false,
  style: {},
  title: undefined
}

export default withLocalize(ShareComponent)
