import React from 'react'

import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'

const VideoPlayer = ({video, width, height}) => (
  <ReactPlayer url={video} height={height} width={width} controls />
)

VideoPlayer.propTypes = {
  video: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

VideoPlayer.defaultProps = {
  video: undefined,
  height: '400px',
  width: '600px'
}

export default VideoPlayer
