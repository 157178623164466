import React from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {ReactComponent as CloseIcon} from '../../assets/icons/ic_close_blue.svg'
import {
  InformationTitle,
  CloseBtn
} from '../../pages/active_journey/ActiveJourneyStyles'
import {white} from '../../styles/_theme'
import FeedbackCard from '../cards/FeedbackCard'
import ReflectionCard from '../cards/ReflectionCard'
import EmptyLayoutComponent from '../empty_layout/EmptyLayoutComponent'

const BookmarksList = ({
  translate,
  activeJourney,
  completedJourney,
  onClickBack
}) => {
  const journey = completedJourney || activeJourney
  if (!journey) return null

  const renderItem = (item, index) => {
    const {type} = item

    switch (type) {
      case 'reflection':
        return (
          <ReflectionCard
            key={index}
            item={item.object}
            bgColor='transparent'
            isCompleted={!!completedJourney}
          />
        )
      default:
        return (
          <FeedbackCard
            key={index}
            item={item.object}
            bgColor={white}
            isCompleted={!!completedJourney}
          />
        )
    }
  }

  return (
    <Row gutter={[12, 24]}>
      <Col xs={20}>
        <InformationTitle>{translate('BOOKMARKS')}</InformationTitle>
      </Col>
      <Col xs={4}>
        <CloseBtn onClick={onClickBack} showBg>
          <CloseIcon />
        </CloseBtn>
      </Col>
      {journey?.bookmarks && journey?.bookmarks?.length > 0 ? (
        <Col xs={24}>
          <Row gutter={[12, 24]}>
            {journey.bookmarks.map((item, index) => (
              <Col md={24} lg={12} xl={8} key={index}>
                {renderItem(item, index)}
              </Col>
            ))}
          </Row>
        </Col>
      ) : (
        <EmptyLayoutComponent tag='EMPTY_BOOKMARKS' />
      )}
    </Row>
  )
}
BookmarksList.propTypes = {
  translate: PropTypes.func.isRequired,
  onClickBack: PropTypes.func,
  activeJourney: PropTypes.object,
  completedJourney: PropTypes.object
}

BookmarksList.defaultProps = {
  activeJourney: undefined,
  onClickBack: undefined,
  completedJourney: undefined
}
const mapStateToProps = (state) => ({
  activeJourney: state.user.active_journey
})

export default withLocalize(connect(mapStateToProps)(BookmarksList))
