import React from 'react'

import {PropTypes} from 'prop-types'
import {Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'

import {ReactComponent as CloseIcon} from '../../assets/icons/ic_close_blue.svg'
import LinkComponent from '../review_components/LinkComponent'
import {
  Modal,
  ModalContainer,
  ModalHeader,
  ModalTitle
} from './BaseModalStyles'

const LinkModal = ({open, onClose, width, title, onSubmit}) => (
  <Modal
    padding='0px'
    visible={open}
    onCancel={onClose}
    width={width}
    footer={null}
    closeIcon={<CloseIcon />}
  >
    <ModalContainer>
      {title && (
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
      )}
      <Field
        name='new_link'
        refField='new_link'
        component={LinkComponent}
        onSubmit={onSubmit}
        editable
      />
    </ModalContainer>
  </Modal>
)

LinkModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  width: PropTypes.number,
  onSubmit: PropTypes.func
}

LinkModal.defaultProps = {
  title: undefined,
  width: 500,
  onSubmit: undefined
}

export default withLocalize(LinkModal)
