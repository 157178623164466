import React, {useRef, useState} from 'react'

import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import ReCAPTCHA from 'react-google-recaptcha'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import Show from '../../../assets/icons/eye-off.png'
import BaseButton from '../../../components/buttons/BaseButton'
import DefaultCheckbox from '../../../components/input/DefaultCheckbox'
import TextInput from '../../../components/input/TextInput'
import {SignUp} from '../../../infra/requests/BaseRequests'
import FormValidator from '../../../infra/services/validations/FormValidator'
import {InputErrorColor, DarkGrey} from '../../../styles/_theme'
import {PageForm, SmallText, MainText} from '../../../styles/BasicStyles'
import {LoginActions} from '../LoginPageStyles'

const ValidateRegister = FormValidator.make({
  email: 'required|email',
  name: 'required',
  password: 'required|min:6',
  terms: 'required',
  code: 'required'
})

const WithCode = ({translate}) => {
  const [showPassword, setShowPassword] = useState('password')
  const [error, setError] = useState(false)
  const [message, setMessage] = useState(false)
  const recaptchaRef = useRef()

  const onSubmit = async (values) => {
    try {
      const recaptchaValue = recaptchaRef.current.getValue()

      if (!recaptchaValue) return setError(translate('MUST_CHECK_RECAPTCHA'))

      const result = await SignUp({...values, recaptchaValue})
      if (result.success) {
        setMessage(true)
      } else if (result.message?.includes('NO_RECAPTCHA_KEY')) {
        setError(translate('NO_RECAPTCHA_KEY'))
      } else if (result.message?.includes('ERROR_VALIDATING_RECAPTCHA')) {
        setError(translate('ERROR_VALIDATING_RECAPTCHA'))
      } else if (result.message) {
        setError('The code is not for a valid group.')
      } else setError(translate('AN_ERROR_OCCURRED'))
    } catch (e) {
      console.warn(e)
    }
  }

  const ShowPassword = () => {
    if (showPassword === 'password') {
      setShowPassword('text')
    } else {
      setShowPassword('password')
    }
  }

  return (
    <>
      {message ? (
        <MainText color={DarkGrey} style={{padding: '30px 0px'}}>
          {translate('REGISTER_SUCCESS')}
        </MainText>
      ) : (
        <Form onSubmit={onSubmit} validate={ValidateRegister}>
          {({handleSubmit, valid, pristine, submitting}) => (
            <PageForm onSubmit={handleSubmit}>
              <Field
                auth
                component={TextInput}
                name='code'
                label={translate('CODE')}
                placeholder={translate('COMPANY_CODE')}
              />
              <Field
                auth
                component={TextInput}
                hasTopSpace={30}
                name='name'
                label={translate('NAME')}
                placeholder={translate('FIRST_LAST_NAME')}
              />
              <Field
                auth
                component={TextInput}
                hasTopSpace={30}
                name='email'
                label={translate('EMAIL')}
                placeholder='email@email.com'
              />
              <Field
                auth
                component={TextInput}
                hasTopSpace={30}
                typeText={showPassword}
                name='password'
                label={translate('PASSWORD')}
                placeholder={translate('PASSWORD')}
                onTouchIcon={() => ShowPassword()}
                icon={Show}
              />
              <Field
                component={DefaultCheckbox}
                name='terms'
                hasTopSpace={30}
              />
              {error && (
                <SmallText style={{marginTop: 10}} color={InputErrorColor}>
                  {error}
                </SmallText>
              )}
              <LoginActions>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                />
                <BaseButton
                  icon
                  category='primary'
                  label={translate('SIGN_IN')}
                  type='submit'
                  disabled={submitting || pristine || !valid}
                />
              </LoginActions>
            </PageForm>
          )}
        </Form>
      )}
    </>
  )
}

WithCode.propTypes = {
  translate: PropTypes.func.isRequired
}

const mapStateToProps = () => ({})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(WithCode)
)
