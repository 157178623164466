import React from 'react'

import PropTypes from 'prop-types'

import {DrawerWindow} from './DrawerStyles'

const Drawer = ({open, children, onClose, title, destroyOnClose}) => (
  <DrawerWindow
    title={title}
    closable={false}
    onClose={onClose}
    placement='right'
    destroyOnClose={destroyOnClose}
    visible={open}
    width={500}
  >
    {children}
  </DrawerWindow>
)

Drawer.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  destroyOnClose: PropTypes.bool
}

Drawer.defaultProps = {
  destroyOnClose: false
}

export default Drawer
