import React from 'react'

import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import {FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'

import {RemoveUsers} from '../../infra/requests/CommunityRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {updateChat} from '../../redux/data/chat/ChatActions'
import {LightGey} from '../../styles/_theme'
import EmptyLayoutComponent from '../empty_layout/EmptyLayoutComponent'
import BaseModalForm from '../modal/BaseModalForm'
import PeerBlockComponent from '../peer_block/PeerBlockComponent'

const ValidateMembers = FormValidator.make({
  users: 'array'
})

const RemoveMembersModal = ({users, open, onClose, translate}) => {
  const {chat} = useSelector((state) => state)
  const dispatch = useDispatch()

  const onSubmit = async (fields) => {
    try {
      const result = await RemoveUsers(chat.channel, fields)
      if (result.success) {
        dispatch(updateChat({...chat, ...result.data}))
        onClose()
      }
    } catch (error) {
      console.warn(error)
    }
  }

  const onPressListItem = (peer, values, form) => {
    const selected = values.users || []
    let changes = [...selected]
    const exist = changes.findIndex((u) => peer._id === u._id)

    if (exist >= 0) {
      changes = values.users.filter((u) => peer._id !== u._id)
    } else {
      changes.push(peer)
    }
    form.change('users', changes)
  }

  return (
    <BaseModalForm
      open={open}
      onClose={onClose}
      title={translate('REMOVE_GROUP_MEMBER')}
      onSubmit={onSubmit}
      validate={ValidateMembers}
      okText={translate('REMOVE')}
      mutators={{...arrayMutators}}
    >
      <FormSpy subscription={{values: true}}>
        {({values, form}) => (
          <>
            {users && users.length ? (
              <FieldArray name='users'>
                {({fields}) => (
                  <>
                    {users.map((item, index) => (
                      <PeerBlockComponent
                        key={index}
                        index={index}
                        item={item}
                        onPressItem={(peer) =>
                          onPressListItem(peer, values, form)
                        }
                        bgColor={LightGey}
                        selectedPeers={values.users || []}
                        marginB='6px'
                      />
                    ))}
                  </>
                )}
              </FieldArray>
            ) : (
              <EmptyLayoutComponent tag='EMPTY_PEERS' />
            )}
          </>
        )}
      </FormSpy>
    </BaseModalForm>
  )
}

RemoveMembersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired
}

export default withLocalize(RemoveMembersModal)
