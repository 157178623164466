import React, {useState, useEffect} from 'react'

import {Collapse} from 'antd'
import arrayMutators from 'final-form-arrays'
import {PropTypes} from 'prop-types'
import {Field, FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'

import {ReactComponent as IconDown} from '../../assets/icons/chevron_down_white.svg'
import {GetShare} from '../../infra/requests/CommunityRequests'
import {CreateTask, EditTask} from '../../infra/requests/JourneyRequests'
import {GetValuePreview} from '../../infra/utils/CommonFunctions'
import {LineContainer} from '../../pages/create_journey/CreateJourneyStyles'
import {JourneyIntroductionText} from '../../pages/journeys/JourneyStyles'
import {updatePeersEngaged} from '../../redux/data/user/UserActions'
import {ThirdColor, LightGey} from '../../styles/_theme'
import {EmptyLayout, EmptyText} from '../../styles/BasicStyles'
import {InputBox, InputLabel} from '../input/InputStyles'
import TextAreaInput from '../input/TextAreaInput'
import BaseModalForm from '../modal/BaseModalForm'
import PeerBlockComponent from '../peer_block/PeerBlockComponent'
import {FeedbackRatingValidations} from './validations/FeedbackValidations'

const {Panel} = Collapse

const RequestFeedbackRatingModal = ({
  open,
  onClose,
  onSave,
  translate,
  activeLanguage,
  currentTask
}) => {
  const dispatch = useDispatch()
  const [share, setShare] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const {
    info: {
      data: {
        templates: {feedback}
      }
    },
    user
  } = useSelector((state) => state)
  const journey = user.active_journey

  const getShare = async () => {
    setIsLoading(true)
    try {
      const result = await GetShare()
      setIsLoading(false)
      if (result.success) {
        setShare(result.data)
      }
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(() => {
    if (!share && !isLoading) getShare()
  }, [share, isLoading])

  const onSaveForm = async (fields) => {
    try {
      let questions
      if (!currentTask) return
      if (currentTask.questions) {
        questions = currentTask.questions.map((currentQuestion) => ({
          _id: currentQuestion._id,
          type: currentQuestion.type,
          title: GetValuePreview(
            currentQuestion.title,
            activeLanguage.code
          ),
          placeholder: GetValuePreview(
            currentQuestion.placeholder,
            activeLanguage.code
          )
        }))
      }

      const users =
        fields.to.filter(
          (s) => s.type === 'peer' || s.type === 'friend'
        ) || []
      const companies = fields.to.filter((s) => s.type === 'company') || []
      const idsUsers = []
      users.map((u) => idsUsers.push(u._id))
      const idsCompanies = []
      companies.map((c) => idsCompanies.push(c._id))

      const customFeedback = {
        ...fields,
        journey: journey._id,
        goal: journey.goal,
        intention: journey.intention,
        questions,
        users: idsUsers,
        general: idsCompanies
      }

      let result
      if (currentTask.response) {
        result = await EditTask(currentTask.response._id, {
          ...fields,
          questions,
          users: idsUsers,
          general: idsCompanies
        })
      } else {
        result = await CreateTask({
          ...customFeedback,
          task: currentTask._id
        })
      }
      const join = []
      result.data.feedbacks.forEach((f) => {
        if (f.to?._id) join.push(f.to._id)
      })
      dispatch(updatePeersEngaged(join))

      if (onSave) onSave()
    } catch (error) {
      console.warn(error)
    }
  }

  const renderHeader = (title) => (
    <LineContainer bgColor={ThirdColor} cursor='pointer' spaceT={4}>
      {title}
      <IconDown />
    </LineContainer>
  )

  const onPressListItem = (peer, values, form) => {
    const selected = values.to || []
    let changes = [...selected]
    const exist = changes.findIndex((u) => peer._id === u._id)

    if (exist >= 0) {
      changes = values.to.filter((u) => peer._id !== u._id)
    } else {
      changes.push(peer)
    }
    form.change('to', changes)
  }

  return (
    <BaseModalForm
      open={open}
      onClose={onClose}
      title={translate('REQUEST_FEEDBACK')}
      onSubmit={onSaveForm}
      validate={FeedbackRatingValidations}
      okText={translate('REQUEST')}
      initialValues={{
        message: currentTask
          ? GetValuePreview(
              currentTask.message,
              activeLanguage && activeLanguage.code
            )
          : GetValuePreview(
              feedback.message,
              activeLanguage && activeLanguage.code
            ),
        to: [],
        users: [],
        general: []
      }}
      mutators={{
        ...arrayMutators
      }}
      noPristine
      keepDirtyOnReinitialize
    >
      <FormSpy subscription={{values: true}}>
        {({values, form}) => {
          const peersSelectedTotal = values.to ? values.to.length : 0
          return (
            <>
              <Field
                name='message'
                refField='message'
                label={translate('WRITE_YOUR_MESSAGE_HERE')}
                placeholder={translate('WRITE_FEEDBACK_PLACEHOLDER')}
                component={TextAreaInput}
                numberOfLines={5}
                minHeight={182}
                editable
              />
              <InputBox hasTopSpace={40}>
                <InputLabel>
                  {translate('SELECT_PEERS_OR_GROUP')}
                </InputLabel>
                <JourneyIntroductionText>
                  {translate('SELECT_EMAILS_EXPLANATION')}
                </JourneyIntroductionText>
                <Collapse accordion bordered={false} ghost>
                  <Panel
                    showArrow={false}
                    header={renderHeader(
                      !peersSelectedTotal
                        ? translate('SELECT_PEERS_OR_GROUP')
                        : `${peersSelectedTotal} ${translate('SELECTED')}`
                    )}
                  >
                    {share && share.length > 0 ? (
                      <FieldArray name='to'>
                        {() =>
                          share.map((i, index) => {
                            const type =
                              i.type === 'company'
                                ? 'isGeneral'
                                : i.type === 'channel'
                                ? 'isChannel'
                                : 'isPeer'
                            if (type === 'isChannel') return
                            return (
                              <PeerBlockComponent
                                key={index}
                                index={index}
                                item={i}
                                onPressItem={(chat) =>
                                  onPressListItem(chat, values, form)
                                }
                                bgColor={LightGey}
                                selectedPeers={values.to || []}
                                marginB='6px'
                                type={type || false}
                              />
                            )
                          })
                        }
                      </FieldArray>
                    ) : (
                      <EmptyLayout>
                        <EmptyText>
                          {translate('EMPTY_PEERS_FEEDBACK')}
                        </EmptyText>
                      </EmptyLayout>
                    )}
                  </Panel>
                </Collapse>
              </InputBox>
            </>
          )
        }}
      </FormSpy>
    </BaseModalForm>
  )
}
RequestFeedbackRatingModal.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  currentTask: PropTypes.object
}

RequestFeedbackRatingModal.defaultProps = {
  currentTask: undefined
}

export default withLocalize(RequestFeedbackRatingModal)
