import React, {Component} from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import UserDefault from '../../assets/default_user.png'
import JourneyModal from '../../components/modal/JourneyModal'
import {ContainerTitle} from '../../components/sliders/SliderStyles'
import {GetActivity} from '../../infra/requests/ActivityRequests'
import {updateActivity} from '../../redux/data/activity/ActivityActions'
import {updateModal} from '../../redux/data/modal/ModalActions'
import {LoadingSpin} from '../../styles/BasicStyles'
import {
  CardContainer,
  ActivitySection,
  ActivityDescription,
  MainText,
  PeerPhoto,
  CardTimeHeader,
  NoActivity
} from './DashboardStyles'

const limit = 20
class Activity extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      total: undefined,
      journeyModal: false
    }
  }

  componentDidMount() {
    this.getActivity()
  }

  getActivity = async () => {
    const {loading, total} = this.state
    const {dispatch, activity} = this.props

    if (loading || total <= activity.items.length) return

    try {
      this.setState({loading: true})
      const result = await GetActivity(activity.page, limit)
      if (result.success) {
        const {items} = result.data
        dispatch(
          updateActivity({
            items: activity.items.concat(items),
            page: activity.page + 1
          })
        )
        this.setState({
          loading: false,
          total: result.data.total
        })
      }
    } catch (error) {
      console.warn(error)
    }
  }

  renderItem = (item, index) => {
    const {user, translate, dispatch} = this.props
    if (!item.object) return null

    return (
      <div key={index}>
        {item.action === 'JOURNEY_CREATED' && (
          <ActivitySection
            onClick={() => this.setState({journeyModal: item.object})}
          >
            <PeerPhoto
              image={user.photo ? user.photo : {url: UserDefault}}
            />
            <ActivityDescription>
              <CardTimeHeader>
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </CardTimeHeader>
              <MainText>{translate('ACTIVITY_JOURNEY_CREATED')}</MainText>
            </ActivityDescription>
          </ActivitySection>
        )}
        {item.action === 'JOURNEY_COMPLETED' && (
          <ActivitySection
            onClick={() => this.setState({journeyModal: item.object})}
          >
            <PeerPhoto
              image={user.photo ? user.photo : {url: UserDefault}}
            />
            <ActivityDescription>
              <CardTimeHeader>
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </CardTimeHeader>
              <MainText>
                {translate('ACTIVITY_JOURNEY_COMPLETED')}
              </MainText>
            </ActivityDescription>
          </ActivitySection>
        )}
        {item.action === 'REFLECTION_CREATED' && (
          <ActivitySection
            onClick={() =>
              dispatch(
                updateModal({
                  type: 'reflection',
                  object: item.object,
                  isShare: true,
                  isFeed: true
                })
              )
            }
          >
            <PeerPhoto
              image={user.photo ? user.photo : {url: UserDefault}}
            />
            <ActivityDescription>
              <CardTimeHeader>
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </CardTimeHeader>
              <MainText>
                {translate('ACTIVITY_REFLECTION_CREATED')}
              </MainText>
            </ActivityDescription>
          </ActivitySection>
        )}
        {item.action === 'JOURNEY_UNARCHIVED' && (
          <ActivitySection
            onClick={() => this.setState({journeyModal: item.object})}
          >
            <PeerPhoto
              image={user.photo ? user.photo : {url: UserDefault}}
            />
            <ActivityDescription>
              <CardTimeHeader>
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </CardTimeHeader>
              <MainText numberOfLines={1}>
                {translate('ACTIVITY_JOURNEY_UNARCHIVED')}
              </MainText>
            </ActivityDescription>
          </ActivitySection>
        )}
        {item.action === 'JOURNEY_ARCHIVED' && (
          <ActivitySection
            onClick={() => this.setState({journeyModal: item.object})}
          >
            <PeerPhoto
              image={user.photo ? user.photo : {url: UserDefault}}
            />
            <ActivityDescription>
              <CardTimeHeader>
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </CardTimeHeader>
              <MainText numberOfLines={1}>
                {translate('ACTIVITY_JOURNEY_ARCHIVED')}
              </MainText>
            </ActivityDescription>
          </ActivitySection>
        )}
        {item.action === 'JOURNEY_DELETED' && (
          <ActivitySection
            onClick={() => this.setState({journeyModal: item.object})}
          >
            <PeerPhoto
              image={user.photo ? user.photo : {url: UserDefault}}
            />
            <ActivityDescription>
              <CardTimeHeader>
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </CardTimeHeader>
              <MainText numberOfLines={1}>
                {translate('ACTIVITY_JOURNEY_DELETED')}
              </MainText>
            </ActivityDescription>
          </ActivitySection>
        )}
        {item.action === 'FEEDBACK_RESPONSE' && (
          <ActivitySection
            onClick={() =>
              dispatch(
                updateModal({
                  type: 'feedback',
                  object: item.object,
                  isShare: true,
                  isFeed: true
                })
              )
            }
          >
            <PeerPhoto
              image={
                item.object.to && item.object.to.photo
                  ? item.object.to.photo
                  : {url: UserDefault}
              }
            />
            <ActivityDescription>
              <CardTimeHeader>
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </CardTimeHeader>
              <MainText numberOfLines={1}>
                {translate('ACTIVITY_FEEDBACK_RESPONSE')}
              </MainText>
            </ActivityDescription>
          </ActivitySection>
        )}
        {item.action === 'NEW_FEEDBACK' && (
          <ActivitySection
            onClick={() =>
              dispatch(
                updateModal({
                  type: 'feedback',
                  object: item.object,
                  isShare: true,
                  isFeed: true,
                  title: translate('FEEDBACK_REQUESTED')
                })
              )
            }
          >
            <PeerPhoto
              image={
                item.object.to && item.object.to.photo
                  ? item.object.to.photo
                  : {url: UserDefault}
              }
            />
            <ActivityDescription>
              <CardTimeHeader>
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </CardTimeHeader>
              <MainText numberOfLines={1}>
                {translate('ACTIVITY_NEW_FEEDBACK')}{' '}
                {item.object && item.object.to
                  ? item.object.to.name
                  : item.object.email
                  ? item.object.email
                  : false}
              </MainText>
            </ActivityDescription>
          </ActivitySection>
        )}

        {item.action === 'RECEIVED_KUDOS' && (
          <ActivitySection
            onClick={() =>
              dispatch(
                updateModal({
                  type: 'kudos',
                  object: item.object,
                  isShare: true,
                  isFeed: true
                })
              )
            }
          >
            <PeerPhoto
              image={
                item.object.from && item.object.from.photo
                  ? item.object.from.photo
                  : {url: UserDefault}
              }
            />
            <ActivityDescription>
              <CardTimeHeader>
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </CardTimeHeader>
              <MainText>
                {translate('ACTIVITY_RECEIVE_KUDOS', {
                  name:
                    item.object &&
                    item.object.from &&
                    item.object.from.name
                      ? item.object.from.name
                      : ''
                })}
              </MainText>
            </ActivityDescription>
          </ActivitySection>
        )}

        {item.action === 'JOURNEY_VALIDATED' && (
          <ActivitySection
            onClick={() =>
              dispatch(
                updateModal({
                  type: 'validation',
                  object: item.object
                })
              )
            }
          >
            <PeerPhoto
              image={user.photo ? user.photo : {url: UserDefault}}
            />
            <ActivityDescription>
              <CardTimeHeader>
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </CardTimeHeader>
              <MainText>
                {translate('ACTIVITY_JOURNEY_VALIDATION')}
              </MainText>
            </ActivityDescription>
          </ActivitySection>
        )}

        {item.action === 'NEW_FEEDBACK_REQUEST' && (
          <ActivitySection
            onClick={() =>
              dispatch(
                updateModal({
                  type: 'feedback',
                  object: item.object,
                  isShare: true,
                  isFeed: true,
                  title: translate('FEEDBACK_REQUESTED')
                })
              )
            }
          >
            <PeerPhoto
              image={
                item.object.from && item.object.from.photo
                  ? item.object.from.photo
                  : {url: UserDefault}
              }
            />
            <ActivityDescription>
              <CardTimeHeader>
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </CardTimeHeader>
              <MainText>
                {translate('ACTIVITY_NEW_FEEDBACK_REQUEST')}{' '}
                {item.object && item.object.from
                  ? item.object.from.name
                  : item.object.email
                  ? item.object.email
                  : false}
              </MainText>
            </ActivityDescription>
          </ActivitySection>
        )}

        {item.action === 'FEEDBACK_ANSWERED' && (
          <ActivitySection
            onClick={() =>
              dispatch(
                updateModal({
                  type: 'feedback',
                  object: item.object,
                  isShare: true,
                  isFeed: true,
                  title: translate('FEEDBACK_ANSWERED')
                })
              )
            }
          >
            <PeerPhoto
              image={
                item.object.from && item.object.from.photo
                  ? item.object.from.photo
                  : {url: UserDefault}
              }
            />
            <ActivityDescription>
              <CardTimeHeader>
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </CardTimeHeader>
              <MainText>
                {translate('ACTIVITY_FEEDBACK_ANSWERED')}{' '}
                {item.object && item.object.from
                  ? item.object.from.name
                  : item.object.email
                  ? item.object.email
                  : false}
              </MainText>
            </ActivityDescription>
          </ActivitySection>
        )}

        {item.action === 'NEW_FRIEND_INVITE' && (
          <ActivitySection
            onClick={() => {
              document.getElementById('openCommunityDrawer').click()
            }}
          >
            <PeerPhoto
              image={
                item.object?.user && item.object?.user?.photo
                  ? item.object.user.photo
                  : {url: UserDefault}
              }
            />
            <ActivityDescription>
              <CardTimeHeader>
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </CardTimeHeader>
              <MainText>
                {translate('ACTIVITY_NEW_FRIEND_INVITE')}
              </MainText>
            </ActivityDescription>
          </ActivitySection>
        )}
        {item.action === 'FRIEND_REJECTED' && (
          <ActivitySection
            onClick={() => {
              document.getElementById('openCommunityDrawer').click()
            }}
          >
            <PeerPhoto
              image={
                item.object?.invited && item.object?.invited?.photo
                  ? item.object.invited.photo
                  : {url: UserDefault}
              }
            />
            <ActivityDescription>
              <CardTimeHeader>
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </CardTimeHeader>
              <MainText>{translate('ACTIVITY_FRIEND_REJECTED')}</MainText>
            </ActivityDescription>
          </ActivitySection>
        )}
        {item.action === 'FRIEND_ACCEPTED' && (
          <ActivitySection
            onClick={() => {
              document.getElementById('openCommunityDrawer').click()
            }}
          >
            <PeerPhoto
              image={
                item.object?.invited
                  ? item.object.invited.photo
                  : {url: UserDefault}
              }
            />
            <ActivityDescription>
              <CardTimeHeader>
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </CardTimeHeader>
              <MainText>{translate('ACTIVITY_FRIEND_ACCEPTED')}</MainText>
            </ActivityDescription>
          </ActivitySection>
        )}
        {item.action === 'HAS_ACCEPTED_FRIEND' && (
          <ActivitySection
            onClick={() => {
              document.getElementById('openCommunityDrawer').click()
            }}
          >
            <PeerPhoto
              image={
                item.object?.invited
                  ? item.object.invited.photo
                  : {url: UserDefault}
              }
            />
            <ActivityDescription>
              <CardTimeHeader>
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </CardTimeHeader>
              <MainText>
                {translate('ACTIVITY_HAS_ACCEPTED_FRIEND')}
              </MainText>
            </ActivityDescription>
          </ActivitySection>
        )}
      </div>
    )
  }

  render() {
    const {total, journeyModal} = this.state
    const {activity, translate} = this.props

    return (
      <CardContainer style={{maxHeight: 375, display: 'grid'}}>
        <ContainerTitle>{translate('ACTIVITY')}</ContainerTitle>
        {activity && activity.items && activity.items.length > 0 ? (
          <>
            <InfiniteScroll
              pageStart={0}
              loadMore={this.getActivity}
              hasMore={total > activity.items.length}
              loader={<LoadingSpin min='200px' />}
              useWindow={false}
              style={{margin: '0px -15px'}}
            >
              {activity.items.map((item, index) =>
                this.renderItem(item, index)
              )}
            </InfiniteScroll>
            {journeyModal && (
              <JourneyModal
                open={!!journeyModal}
                journey={journeyModal}
                onClose={() => this.setState({journeyModal: false})}
                isInactive
              />
            )}
          </>
        ) : (
          <NoActivity>
            <div>{translate('NO_ACTIVITY')}</div>
          </NoActivity>
        )}
      </CardContainer>
    )
  }
}

Activity.propTypes = {
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  activity: PropTypes.object
}

Activity.defaultProps = {
  user: false,
  activity: {items: [], page: 1}
}

const mapStateToProps = (state) => ({
  user: state.user,
  activity: state.activity
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(Activity)
)
