export const isEven = (num) => num % 2 === 0

export const GetValuePreview = (value, lang) => {
  if (!value) return null
  if (typeof value === 'string') return value
  if (value && value[lang] && value[lang] !== '') return value[lang]
  if (value && value.en && value.en !== '') return value.en
  return value[Object.keys(value)[0]]
}

export const hasNotification = (not, tag) => {
  const notifications = not || []
  return notifications.find(
    (notification) =>
      notification.tag.toUpperCase().indexOf(tag.toUpperCase()) > -1
  )
}

export const hasNotificationGroup = (not, tag) => {
  const notifications = not || []
  if (!tag || notifications?.length < 1) return false
  if (typeof tag === 'string') {
    return notifications.find(
      (notification) =>
        notification.tag.toUpperCase().indexOf(tag.toUpperCase()) > -1
    )
  }
  if (typeof tag === 'object') {
    for (let i = 0; i < tag.length; i++) {
      const exist = notifications.find(
        (notification) =>
          notification.tag.toUpperCase().indexOf(tag[i].toUpperCase()) > -1
      )
      if (exist) return true
    }
  }
  return false
}

export const countNotification = (notifications, tag) => {
  const total = notifications.filter(
    (notification) =>
      notification.tag.toUpperCase().indexOf(tag.toUpperCase()) > -1
  ).length

  if (total > 100) return '100+'

  return total
}

export const questionsByType = (questions, starMedia) => {
  if (!questions && questions.length < 1) return null
  let questionsReverse = [...questions]
  questionsReverse = questionsReverse.reverse()
  let question1 = questionsReverse.find((x) => x.type === 1)
  if (!question1) question1 = questionsReverse.find((x) => x.type === 2)
  let question3 = questionsReverse.find((x) => x.type === 3)
  if (starMedia && question3) {
    const questions3 = questionsReverse.filter((x) => x.type === 3)
    let count = 0
    questions3.map((q) => {
      count += parseInt(q.response)
      return q
    })
    question3 = {
      ...question3,
      response: parseInt(count / questions3.length)
    }
  }

  if (!question3) question3 = questionsReverse.find((x) => x.type === 6)
  if (!question3) question3 = questionsReverse.find((x) => x.type === 7)
  return {question1, question3}
}

export const GetTaskName = (id, activeJourney, activeLanguage) => {
  if (!id || !activeJourney || !activeJourney.guided_steps) return false

  let task = false
  activeJourney.guided_steps.map((step) => {
    const result = step.tasks.find((t) => t._id === id)
    if (result) task = result
  })

  return task?.title
    ? GetValuePreview(task.title, activeLanguage?.code)
    : false
}

export const GetTask = (id, activeJourney) => {
  if (!id || !activeJourney) return false

  let task = false
  activeJourney.guided_steps.map((step) => {
    const result = step.tasks.find((t) => t._id === id)
    if (result) task = result
    return step
  })

  return task || false
}

export const CalcJourneyStepsLeft = (item) => {
  let steps = 0
  if (item.type === 'guided') {
    steps = item.guided_steps.filter(
      (s) => s.completion != 100 && !s.ignored
    ).length
  } else {
    steps = item.steps.filter((s) => s.completion != 100).length
  }
  return steps
}

export const CalcJourneySteps = (item) => {
  let steps = 0
  if (typeof item === 'string') return false
  if (item.type === 'guided') {
    steps = item.guided_steps.filter((g) => !g.ignored).length
  } else {
    steps = item.steps.length
  }
  return steps
}

export const CalculateProgress = (step) => {
  let stepTasks = 0
  let stepCompletion = 0
  step.tasks.map((task) => {
    stepTasks++
    if (task && task.response && task.response.completed) {
      stepCompletion++
    }
    return task
  })
  return ((stepCompletion / stepTasks) * 100).toFixed()
}

export const getFileType = (type) => {
  if (!type) return ''
  if (type.indexOf('application/pdf') > -1) return 'PDF'
  if (type.indexOf('image/jpeg') > -1 || type.indexOf('image/jpg') > -1) {
    return 'JPG'
  }
  if (type.indexOf('video/mp4') > -1) return 'MP4'
  if (type.indexOf('presentation') > -1) return 'PPT'
  if (type.indexOf('spreadsheet') > -1) return 'XLS'
  if (type.indexOf('word') > -1) return 'DOC'
  if (type.indexOf('text') > -1) return 'TXT'

  return 'file'
}

export const GetH5PRatio = (file) => {
  const matchW = file.match(/width="\s*(\d+)"/)
  const matchH = file.match(/height="\s*(\d+)"/)
  console.log(matchW, matchH)
  const w = matchW && matchW.length ? matchW[1] : false
  const h = matchH && matchH.length ? matchH[1] : false
  if (h && !w) return {isPx: `${h}px`}

  const r = h && w ? (parseInt(h) / parseInt(w)) * 100 : 100

  return r
}
