import {Input, Select, TimePicker} from 'antd'
import styled from 'styled-components'

import {
  TextColor,
  ErrorColor,
  BorderColor,
  PrimaryColor,
  LightGey,
  DarkGey,
  white,
  ThirdColor,
  DefaultGey,
  SuccessColor,
  SecondaryColor,
  InputErrorColor,
  DarkGrey,
  mediumBlue
} from '../../styles/_theme'

const {TextArea} = Input

export const InputBox = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  margin-top: ${({hasTopSpace}) => hasTopSpace || 0}px;

  .ant-collapse
    > .ant-collapse-item.ant-collapse-no-arrow
    > .ant-collapse-header {
    padding: 10px 0px 0px;
  }
`

export const InputLabel = styled.div`
  font-family: 'Raleway', 'sans-serif' !important;
  font-size: 16px;
  line-height: 20px;
  color: ${({auth}) => (auth ? ThirdColor : TextColor)};
  margin-bottom: 5px;
  font-weight: 600;
  margin-top: ${({spaceT}) => spaceT || 0}px;
`

export const InputError = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 11px;
  line-height: 16px;
  color: ${ErrorColor};
`

export const TextInputStyled = styled.input`
  background: ${({auth}) => (auth ? LightGey : white)};
  border: 1px solid
    ${({hasError, auth}) =>
      hasError ? ErrorColor : auth ? 'transparent' : BorderColor};
  border-radius: ${({auth}) => (auth ? 0 : 4)}px;
  padding: 5px 10px;
  cursor: text;
  display: inline-block;
  width: 100%;
  height: 36px;
  color: ${DarkGrey};

  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: 1px solid ${PrimaryColor};
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
  }

  ::placeholder {
    opacity: 0.5;
  }
  :-ms-input-placeholder {
    opacity: 0.5;
  }
  ::-ms-input-placeholder {
    opacity: 0.5;
  }
`

export const TextAreaInputField = styled(TextArea)`
  background: white;
  border: 1px solid
    ${({$hasError}) => ($hasError ? ErrorColor : BorderColor)};
  border-radius: 4px;
  padding: 5px 10px;
  cursor: text;
  display: inline-block;
  width: 100%;
  color: ${DarkGrey};
  min-height: ${({min}) => min || 36}px;

  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: 1px solid ${PrimaryColor};
    outline: none;
  }
`

export const SearchSection = styled.div`
  display: inline-block;
  width: 100%;
  padding: 20px;
  position: relative;
`

export const SearchInputStyled = styled.input`
  display: inline-block;
  width: 100%;
  border-radius: 55px;
  background: ${LightGey};
  padding: 10px 50px 10px 15px;
  font-size: 14px;
  line-height: 18px;
  color: ${DarkGey};
  border: none;
  outline: none;

  &:hover,
  &:active,
  &:focus {
    border: none;
    outline: none;
  }
`

export const SearchIcon = styled.img`
  position: absolute;
  top: 26px;
  right: 35px;
  width: 24px;
`

export const ChatInputStyled = styled(TextArea)`
  display: inline-block;
  width: 100%;
  border-radius: 20px;
  background: ${white};
  padding: 6px 14px 6px 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  color: ${DarkGey};
  border: none;
  outline: none;
  resize: none;
  overflow: hidden;

  &:hover,
  &:active,
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
`

export const StepsInputContainer = styled.div`
  margin-top: ${({hasTopSpace}) => (hasTopSpace ? '20px' : '0px')};
  background-color: ${({isNew, isEmail}) =>
    isNew && isEmail
      ? 'transparent'
      : isEmail
      ? ThirdColor
      : isNew
      ? BorderColor
      : mediumBlue};
  border: ${({isEmail}) => (isEmail ? '1px solid' : '0px solid')};
  border-color: ${({isNew}) => (isNew ? DefaultGey : ThirdColor)};
  margin-bottom: ${({isEmail, isNew}) => (isEmail && !isNew ? 10 : 12)}px;
  display: flex;

  &.base {
    &:hover,
    &:active,
    &:focus {
      background-color: ${ThirdColor};
    }
  }
  &.isNew {
    &:hover,
    &:active,
    &:focus {
      .isNew {
        background-color: ${DarkGey};
      }
    }
  }
`

export const IndexBlock = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({isNew, isCompleted}) =>
    isCompleted ? SuccessColor : isNew ? DefaultGey : ThirdColor};
  width: 40px;
  height: auto;
  margin-right: 14px;
  display: flex;
`

export const InputView = styled.div`
  min-height: 46px;
  display: flex;
`

export const TextInputField = styled.input`
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  padding: 12px 14px;
  max-height: ${({maxHeight}) => (maxHeight ? `${maxHeight}px` : '100%')};
  color: ${({hasError, color}) =>
    hasError ? InputErrorColor : color || DarkGey};
  align-self: center;
  display: inline-block;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;

  &:hover,
  &:active,
  &:focus {
    border: none;
    outline: none;
  }
`

export const StepsRightBtn = styled.div`
  min-height: 46px;
  justify-content: center;
  width: 44px;
  display: flex;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    svg {
      background-color: #fff;
      border-radius: 50%;
      width: 24px;
      min-height: 24px;
      margin: 0px 10px;
      align-self: center;

      path {
        fill: ${ThirdColor};
      }
    }
  }
`

export const DefaultInputContainer = styled.div`
  width: 100%;
  margin-top: ${({hasTopSpace}) => hasTopSpace || 0}px;
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const DefaultCheckboxContainer = styled.button`
  display: flex;
  margin-top: ${({hasTopSpace}) => (hasTopSpace ? '20px' : '0px')};
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${white};
`

export const DefaultCheckboxInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 20px;
  height: 20px;
  margin-right: 6px;
  border: 1px solid
    ${({hasError}) => (hasError ? InputErrorColor : DarkGrey)};
  background-color: ${({value}) =>
    value ? SecondaryColor : 'transparent'};
`

export const SmallText = styled.div`
  font-size: 14px;
  line-height: 1.2px;
  color: ${({color}) => color || DarkGrey};
  cursor: ${({cursor}) => cursor || null};
  min-height: 20px;
`

export const PasswordIconContainer = styled.div`
  cursor: pointer;
  display: flex;
  background-color: ${LightGey};
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
`

export const PasswordIcon = styled.img`
  width: 20px;
  height: 20px;
`

export const InputSubContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const StyledSelectInput = styled(Select)`
  width: ${({maxwidth}) => maxwidth || '100%'};
  background-color: ${({bgcolor}) => bgcolor || white};
  height: 42px;
  border-radius: ${({radius}) => radius || 21}px;
  font-size: 14px;
  font-weight: normal;
  color: ${DarkGrey};
  line-height: 18px;
  align-items: center;
  display: flex;
  padding: 0px 4px;

  .ant-select-arrow {
    color: ${ThirdColor};
  }

  &:focus-within,
  &:hover {
    .ant-select-selection {
      border: 1px solid ${ThirdColor};
    }
  }

  ${(p) =>
    p.error &&
    `.ant-select-selection{
        border: 1px solid ${ErrorColor};
      }
     
  `}
`

export const StyledTimeInput = styled(TimePicker)`
  width: ${({maxwidth}) => maxwidth || '100%'};
  background-color: ${({bgcolor}) => bgcolor || white};
  height: 42px;
  border-radius: ${({radius}) => radius || 0}px;
  font-size: 14px;
  font-weight: normal;
  color: ${DarkGrey};
  line-height: 18px;
  align-items: center;
  display: flex;
  padding: 0px 4px;
`

export const TimeBox = styled.div`
  width: ${({maxwidth}) => maxwidth || '100%'};
  background-color: ${({bgcolor}) => bgcolor || white};
  height: 42px;
  border-radius: ${({radius}) => radius || 0}px;
  font-size: 14px;
  font-weight: normal;
  color: ${DarkGrey};
  line-height: 18px;
  align-items: center;
  display: flex;
  padding: 12px 14px;

  .ant-select-arrow {
    color: ${ThirdColor};
  }

  &:focus-within,
  &:hover {
    .ant-select-selection {
      border: 1px solid ${ThirdColor};
    }
  }

  ${(p) =>
    p.error &&
    `.ant-select-selection{
      border: 1px solid ${ErrorColor};
    }
  `}
`
