import React from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import LockIcon from '../../assets/icons/ic_lock.svg'
import IconPlay from '../../assets/icons/ic_play.svg'
// import OkIcon from '../../assets/icons/ic_ok.svg'
import {
  StepsContainer,
  StepsText
} from '../../components/journey_block/JourneyBlockComponentStyles'
import {H5P, H5PDisabled} from '../../components/modal/BaseModalStyles'
import ProgressBarComponent from '../../components/progress_bar/ProgressBarComponent'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {
  CalculateProgress,
  GetValuePreview,
  GetH5PRatio
} from '../../infra/utils/CommonFunctions'
import {white, DarkGrey} from '../../styles/_theme'
import {VideoBox} from '../../styles/BasicStyles'
import {
  InformationTitle,
  GuidedStepContainer,
  GuidedStepPhoto,
  GuidedStepsInfo,
  StepDescription,
  StepTitle,
  StepName,
  StepTasks,
  StatusBlock
} from './ActiveJourneyStyles'

export const getStepStatus = (index, progress, step, journey) => {
  let stepStatus = 'locked'
  if (progress == 100) stepStatus = 'done'
  else if (step.trigger === 'open') stepStatus = 'unlocked'
  else if (step.trigger === 'close' && index === 0) stepStatus = 'unlocked'
  else if (
    step.trigger === 'close' &&
    CalculateProgress(journey.guided_steps[index - 1]) == 100
  ) {
    stepStatus = 'unlocked'
  } else if (
    step.trigger === 'date' &&
    moment(moment()).isSameOrAfter(
      moment(step.trigger_value, 'DD-MM-YYYY')
    )
  ) {
    stepStatus = 'unlocked'
  } else if (
    step.trigger === 'day' &&
    moment(moment()).isSameOrAfter(
      moment(journey.createdAt).add(step.trigger_value, 'days')
    )
  ) {
    stepStatus = 'unlocked'
  }
  return stepStatus
}

const renderImage = (task, activeLanguage) => {
  switch (task?.media?.type) {
    case 'images':
      return (
        <GuidedStepPhoto
          ratio={0}
          image={task.media.file}
          position='top'
        />
      )
    case 'videos': {
      const image = {
        url: GetLanguageValue(
          task.media.file.thumbnail,
          activeLanguage.code
        ),
        color: '#fff'
      }
      return (
        <VideoBox>
          <img
            src={IconPlay}
            alt='video'
            style={{position: 'absolute', zIndex: 1}}
          />
          <GuidedStepPhoto ratio={0} image={image} />
        </VideoBox>
      )
    }
    case 'h5p':
    case 'embedded': {
      const file = GetLanguageValue(
        task.media.file.iframe,
        activeLanguage.code
      )
      const r = GetH5PRatio(file)
      return (
        <H5PDisabled
          style={{
            paddingTop: r.isPx || `${r}%`
          }}
          dangerouslySetInnerHTML={{
            __html: `${file}<div class="H5PTop" />`
          }}
        />
      )
    }
    default:
      return false
  }
}

const StepsList = ({
  translate,
  activeLanguage,
  activeJourney,
  onClickStep,
  completedJourney
}) => {
  const journey = completedJourney || activeJourney
  return (
    <Row gutter={[12, 24]}>
      <Col xs={24}>
        <InformationTitle>{translate('STEPS')}</InformationTitle>
      </Col>
      {journey.guided_steps.map((step, index) => {
        if (step.ignored) return
        const task = step.tasks.find((t) => t.media)
        const progress = CalculateProgress(step)
        const stepStatus = getStepStatus(index, progress, step, journey)
        const availableDate =
          stepStatus === 'locked'
            ? step?.trigger === 'date'
              ? moment(step.trigger_value, 'DD/MM/YYYY').format(
                  'DD/MM/YYYY'
                )
              : step.trigger === 'day'
              ? moment(journey.createdAt)
                  .add(step.trigger_value, 'days')
                  .format('DD/MM/YYYY')
              : false
            : false
        return (
          <Col key={index} sm={24} lg={12} xl={8}>
            <GuidedStepContainer
              isCompleted={progress == 100}
              isLocked={stepStatus === 'locked'}
              key={index}
              onClick={() =>
                stepStatus === 'locked' ? {} : onClickStep(step, index)
              }
            >
              {step.updated && (
                <StepsContainer
                  style={{
                    position: 'absolute',
                    zIndex: 1,
                    boxShadow: '1px 1px 7px rgba(0,0,0,0.5)'
                  }}
                >
                  <StepsText>{translate('UPDATED')}</StepsText>
                </StepsContainer>
              )}
              {renderImage(task, activeLanguage)}

              <GuidedStepsInfo
                style={{
                  padding:
                    step.updated && !task?.media
                      ? '40px 16px 10px'
                      : '14px 16px 10px'
                }}
              >
                <div
                  style={{
                    height: '100%',
                    justifyContent: 'space-between',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <div>
                    {stepStatus === 'locked' && (
                      <StatusBlock>
                        {availableDate && (
                          <>
                            {translate('AVAILABLE_AT')} {availableDate}
                          </>
                        )}
                        <img
                          src={LockIcon}
                          alt='LockIcon'
                          style={{float: 'right'}}
                        />
                      </StatusBlock>
                    )}
                    <StepTitle>
                      {translate('STEP')} {index + 1}
                    </StepTitle>
                    <StepName>
                      {GetValuePreview(
                        step.title,
                        activeLanguage && activeLanguage.code
                      )}
                    </StepName>
                    {step.description && (
                      <StepDescription>
                        {GetValuePreview(
                          step.description,
                          activeLanguage && activeLanguage.code
                        )}
                      </StepDescription>
                    )}
                    <StepTasks>
                      {step.tasks.length}{' '}
                      {step.tasks.length === 1
                        ? translate('TASK')
                        : translate('TASKS')}
                    </StepTasks>
                  </div>
                  <div>
                    <ProgressBarComponent
                      progress={`${progress}%`}
                      borderRadius={13}
                      width='100%'
                      height={22}
                      marginB={0}
                      color={white}
                      unfilledColor='rgba(227, 227, 227, 0.4)'
                      unfilledTextColor={white}
                      filledTextColor={DarkGrey}
                    />
                  </div>
                </div>
              </GuidedStepsInfo>
            </GuidedStepContainer>
          </Col>
        )
      })}
    </Row>
  )
}

StepsList.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  activeJourney: PropTypes.object,
  onClickStep: PropTypes.func,
  completedJourney: PropTypes.object
}

StepsList.defaultProps = {
  activeJourney: undefined,
  onClickStep: undefined,
  completedJourney: undefined
}
const mapStateToProps = (state) => ({
  activeJourney: state.user.active_journey
})

export default withLocalize(connect(mapStateToProps)(StepsList))
