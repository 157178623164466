import {createAsyncThunk} from '@reduxjs/toolkit'
import moment from 'moment'
import {renderToStaticMarkup} from 'react-dom/server'
import {initialize, addTranslationForLanguage} from 'react-localize-redux'

import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/pt'
import 'moment/locale/es'
import 'moment/locale/it'
import {GetInfo, GetTranslations} from '../../infra/requests/BaseRequests'
import GlobalNotificationService from '../../infra/services/socket/GlobalNotificationService'
import {Languages} from '../../infra/services/translations/TranslationsService'
import {updateInfo} from '../data/info/InfoActions'
import {userSave} from '../data/user/UserActions'
import ReduxStore from '../ReduxConfig'
import {UpdateAllData} from './UpdateThunk'

const DefineLanguage = (languages, defaultLanguage) => {
  const cookieLang = localStorage.getItem('LANG_COOKIE')

  if (cookieLang && languages.find((x) => x.code === cookieLang)) {
    moment.locale(cookieLang)
    return cookieLang
  }
  const browserLang = navigator.language || navigator.userLanguage

  if (browserLang) {
    const code = browserLang.substring(0, 2)
    if (languages.find((x) => x.code === code)) {
      moment.locale(code)
      return code
    }
  }
  if (defaultLanguage) {
    moment.locale(defaultLanguage)
    return defaultLanguage
  }
  moment.locale('en')
  return 'en'
}

const AppStartup = createAsyncThunk(
  'info/fetchData',
  async (payload, {dispatch}) => {
    const {data} = await GetInfo()
    const dataTranslations = await GetTranslations()

    const languages = data.languages.map((lang) =>
      Languages.find((x) => x.code === lang)
    )

    const defaultLanguage = DefineLanguage(
      languages,
      data.default_language
    )

    dispatch(
      initialize({
        languages,
        options: {
          renderToStaticMarkup,
          defaultLanguage
        }
      })
    )

    languages.map(({code}) =>
      dispatch(
        addTranslationForLanguage(dataTranslations.data[code], code)
      )
    )

    dispatch(userSave(data.user))
    dispatch(updateInfo({ready: true, data}))
    if (data.user) {
      dispatch(UpdateAllData())
      GlobalNotificationService.create(ReduxStore)
    }
  }
)

export default AppStartup
