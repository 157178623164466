import React, {useState} from 'react'

import {Popconfirm} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {ReactComponent as ArrowIcon} from '../../assets/icons/shape_blue.svg'
import {AuthTokenKey} from '../../infra/config/LocalStorageKeys'
import {DeleteAccount} from '../../infra/requests/BaseRequests'
import SocketClient from '../../infra/services/socket/SocketConnection'
import {updateChats} from '../../redux/data/chats/ChatsActions'
import {CleanAndUpdateAllData} from '../../redux/thunks/UpdateThunk'
import {BaseLeftMenu, BaseRightContent} from '../../styles/BasicStyles'
import Details from './Details'
import JoinGroup from './JoinGroup'
import Languages from './Languages'
import ManageGroups from './ManageGroups'
import Notifications from './Notifications'
import Privacy from './Privacy'
import ResetPassword from './ResetPassword'
import {
  SettingsContainer,
  MenuTitle,
  MenuSection,
  MenuItem,
  OptionsList
} from './SettingsStyles'

const SettingsPage = ({translate, user, router, dispatch}) => {
  const [section, setSection] = useState(false)
  const permissions =
    !user.roles || !user.roles.filter((r) => r.role === 7).length
      ? false
      : 1

  const renderContent = () => {
    switch (section) {
      case 'details':
        return <Details />
      case 'notifications':
        return <Notifications />
      case 'privacy':
        return <Privacy />
      case 'resetPassword':
        return <ResetPassword />
      case 'languages':
        return <Languages />
      case 'groups':
        return <ManageGroups onLeaveGroup={() => setSection('details')} />
      case 'joinGroup':
        return <JoinGroup onSave={() => setSection('groups')} />
      default:
        return setSection('details')
    }
  }

  const onPressDeleteAccount = async () => {
    try {
      const result = await DeleteAccount()
      localStorage.removeItem(AuthTokenKey)
      if (result.success) {
        const socketClient = SocketClient.create()
        socketClient.disconnect()
        dispatch(updateChats())
        dispatch(CleanAndUpdateAllData())
        router.history.push('/login')
      }
    } catch (error) {
      console.warn(error)
    }
  }

  return (
    <>
      <BaseLeftMenu>
        <MenuTitle spaceL={20} spaceB={32}>
          {translate('SETTINGS')}
        </MenuTitle>
        <OptionsList>
          <MenuSection>{translate('ACCOUNT_AND_PRIVACY')}</MenuSection>
          <MenuItem
            borderB
            onClick={() => setSection('details')}
            selected={section === 'details'}
          >
            {translate('DETAILS')}
            <ArrowIcon className={section === 'details' ? 'active' : ''} />
          </MenuItem>
          <MenuItem
            borderB
            onClick={() => setSection('notifications')}
            selected={section === 'notifications'}
          >
            {translate('NOTIFICATIONS')}
            <ArrowIcon
              className={section === 'notifications' ? 'active' : ''}
            />
          </MenuItem>
          <MenuItem
            borderB
            onClick={() => setSection('privacy')}
            selected={section === 'privacy'}
          >
            {translate('PRIVACY_SETTINGS')}
            <ArrowIcon className={section === 'privacy' ? 'active' : ''} />
          </MenuItem>
          <MenuItem
            borderB
            onClick={() => setSection('resetPassword')}
            selected={section === 'resetPassword'}
          >
            {translate('RESET_PASSWORD')}
            <ArrowIcon
              className={section === 'resetPassword' ? 'active' : ''}
            />
          </MenuItem>
          <MenuItem
            onClick={() => setSection('languages')}
            selected={section === 'languages'}
          >
            {translate('LANGUAGES')}
            <ArrowIcon
              className={section === 'languages' ? 'active' : ''}
            />
          </MenuItem>
        </OptionsList>

        <OptionsList spaceT={60}>
          <MenuSection>{translate('GROUPS')}</MenuSection>
          {permissions && (
            <MenuItem
              borderB
              onClick={() => setSection('groups')}
              selected={section === 'groups'}
            >
              {translate('MANAGE_GROUPS')}
              <ArrowIcon
                className={section === 'groups' ? 'active' : ''}
              />
            </MenuItem>
          )}
          <MenuItem
            onClick={() => setSection('joinGroup')}
            selected={section === 'joinGroup'}
          >
            {translate('JOIN_GROUP')}
            <ArrowIcon
              className={section === 'joinGroup' ? 'active' : ''}
            />
          </MenuItem>
        </OptionsList>
        <OptionsList spaceT={60}>
          <Popconfirm
            placement='top'
            title={translate('DELETE_ACCOUNT_ALERT_MESSAGE')}
            onConfirm={onPressDeleteAccount}
          >
            <MenuItem
              borderB
              onClick={(e) => e.stopPropagation()}
            >
              {translate('DELETE_ACCOUNT')}
            </MenuItem>
          </Popconfirm>
        </OptionsList>
      </BaseLeftMenu>
      <BaseRightContent>
        <SettingsContainer>{renderContent()}</SettingsContainer>
      </BaseRightContent>
    </>
  )
}

SettingsPage.propTypes = {
  translate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = () => ({})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(SettingsPage)
)
