import FormValidator from '../../../infra/services/validations/FormValidator'

export const FeedbackValidations = (values) => {
  const errors = FormValidator.make({
    message: 'required'
  })(values)

  if (
    (!values.to || !values.to.length) &&
    (!values.emails || !values.emails.length)
  ) {
    errors.to = {_error: 'PEERS_OR_EMAILS_REQUIRED'}
    errors.emails = {_error: 'PEERS_OR_EMAILS_REQUIRED'}
  }
  return errors
}

export const RespondFeedbackValidations = (values) => {
  const errors = FormValidator.make({
    questions: 'array'
  })(values)

  if (values.questions && values.questions.length > 0) {
    errors.questions = []
    values.questions.map((question, index) => {
      errors.questions[index] = FormValidator.make({
        response: 'required'
      })(question)
      return question
    })
  }

  return errors
}

export const FeedbackRatingValidations = (values) => {
  const errors = FormValidator.make({
    message: 'required'
  })(values)

  if (!values.to || !values.to.length) {
    errors.to = {_error: 'PEERS_OR_GROUPS_REQUIRED'}
  }

  return errors
}
