import React, {useState} from 'react'

import {Col} from 'antd'
import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {
  EditAssignmentTask,
  CreateAssignmentTask
} from '../../infra/requests/JourneyRequests'
import {GetValuePreview} from '../../infra/utils/CommonFunctions'
import {updateTask} from '../../redux/data/user/UserActions'
import {WhiteLayout, PageForm} from '../../styles/BasicStyles'
import AttachmentsList from '../attachments/AttachmentsList'
import {ActiveButtonGroup} from '../buttons/ButtonsStyles'
import ReviewComponent from '../review_components/ReviewComponent'
import ShareComponent from '../share/ShareComponent'
import TaskHeader from './TaskHeader'
import {TaskInfo, TaskTitle, StepTitle} from './TaskStyles'
import AssignmentTaskValidations from './validations/TaskValidations'

const AssignmentTask = ({
  task,
  taskIndex,
  stepIndex,
  totalTasks,
  activeLanguage,
  nextTask,
  previousTask,
  idJourney,
  dispatch,
  isCompleted,
  translate,
  renderNextStep
}) => {
  const [loading, setLoading] = useState(false)
  const completion = !!(task && task.response && task.response.completed)

  const onSubmit = async (fields, isArrow) => {
    if (loading || isCompleted) return

    setLoading(true)
    try {
      if (task.response) {
        const result = await EditAssignmentTask(
          task.response._id,
          {
            questions: fields.questions
          },
          activeLanguage
        )
        dispatch(updateTask(task._id, result.data))
        setLoading(false)

        // if (isArrow !== 'next') nextTask()
      } else {
        const questions = fields.questions.map((question) => ({
          ...question,
          title: GetValuePreview(
            question.title,
            activeLanguage && activeLanguage.code
          ),
          placeholder: question.placeholder
            ? GetValuePreview(
                question.placeholder,
                activeLanguage && activeLanguage.code
              )
            : false
        }))
        const result = await CreateAssignmentTask(
          {
            journey: idJourney,
            task: task._id,
            questions
          },
          activeLanguage
        )
        dispatch(updateTask(task._id, result.data))
        setLoading(false)

        // if (isArrow !== 'next') nextTask()
      }
    } catch (e) {
      console.warn(e)
      setLoading(false)
    }
  }

  const onPressArrow = async (values, isDirty, isNext) => {
    if (isDirty) await onSubmit({questions: values.questions}, 'next')

    if (isNext) nextTask()
    else previousTask()
  }

  return (
    <Col xs={24}>
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators
        }}
        initialValues={{
          questions:
            task.response &&
            task.response.questions &&
            task.response.questions.length > 0
              ? task.response.questions
              : task.questions
        }}
        validate={AssignmentTaskValidations}
      >
        {({handleSubmit, form, dirty, values, valid}) => (
          <PageForm
            onSubmit={(event) => {
              const promise = handleSubmit(event).then(() => {
                form.reset()
              })
              return promise
            }}
          >
            <TaskHeader
              task={task}
              loading={loading || !dirty || !valid}
              taskIndex={taskIndex}
              totalTasks={totalTasks}
              nextTask={() => onPressArrow(values, dirty, true)}
              previousTask={() => onPressArrow(values, dirty)}
              renderNextStep={renderNextStep}
            />
            <StepTitle style={{marginTop: 16}}>
              {translate('STEP')} {stepIndex}
            </StepTitle>
            <TaskTitle>
              {GetValuePreview(
                task.title,
                activeLanguage && activeLanguage.code
              )}
            </TaskTitle>

            {task.description && (
              <TaskInfo
                dangerouslySetInnerHTML={{
                  __html: GetValuePreview(
                    task.description,
                    activeLanguage && activeLanguage.code
                  )
                }}
              />
            )}
            {task.attachments && task.attachments.length > 0 && (
              <AttachmentsList
                items={task.attachments}
                edit={!isCompleted}
              />
            )}
            <WhiteLayout space=' 0px 30px 30px'>
              <FieldArray name='questions'>
                {({fields}) =>
                  fields.map((item, index) => {
                    const question = fields.value[index]
                    return (
                      <Field
                        key={question._id}
                        name={`questions[${index}].response`}
                        refField={`questions${index}`}
                        label={GetValuePreview(
                          question.title,
                          activeLanguage && activeLanguage.code
                        )}
                        placeholder={
                          question.placeholder
                            ? GetValuePreview(
                                question.placeholder,
                                activeLanguage && activeLanguage.code
                              )
                            : ''
                        }
                        component={ReviewComponent}
                        editable={!isCompleted}
                        reviewType={question.type.toString()}
                      />
                    )
                  })
                }
              </FieldArray>
            </WhiteLayout>
            {!isCompleted && completion && (
              <ActiveButtonGroup>
                <ShareComponent
                  item={{
                    media: task.media,
                    questions: task.response?.questions,
                    attachments: task.attachments,
                    title: GetValuePreview(
                      task.title,
                      activeLanguage && activeLanguage.code
                    ),
                    description: GetValuePreview(
                      task.description,
                      activeLanguage && activeLanguage.code
                    )
                  }}
                  type='reflection'
                  isCircle
                  style={{minWidth: 150}}
                />
              </ActiveButtonGroup>
            )}
          </PageForm>
        )}
      </Form>
    </Col>
  )
}

AssignmentTask.propTypes = {
  task: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  taskIndex: PropTypes.number,
  totalTasks: PropTypes.number,
  stepIndex: PropTypes.number,
  previousTask: PropTypes.func.isRequired,
  nextTask: PropTypes.func.isRequired,
  idJourney: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  isCompleted: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  renderNextStep: PropTypes.func
}

AssignmentTask.defaultProps = {
  taskIndex: 1,
  totalTasks: 1,
  stepIndex: 1,
  isCompleted: false,
  renderNextStep: undefined
}

const mapActionToProps = (dispatch) => ({dispatch})

export default withLocalize(
  connect(() => ({}), mapActionToProps)(AssignmentTask)
)
