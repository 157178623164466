import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import DefaultImg from '../../assets/default_custom.png'
import IconPlay from '../../assets/icons/ic_play.svg'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {
  CalcJourneyStepsLeft,
  CalcJourneySteps,
  GetValuePreview
} from '../../infra/utils/CommonFunctions'
import {
  LoadingSpin,
  VideoBox,
  H5PJourneyBlock
} from '../../styles/BasicStyles'
import BaseButton from '../buttons/BaseButton'
import {
  JourneyBlockContainer,
  StepsContainer,
  StepsText,
  JourneyPhoto,
  JourneyInfo,
  JourneyDescription,
  JourneyBottom,
  JourneyTitle,
  JourneyBtnBlock,
  JourneyBottomIn
} from './JourneyBlockComponentStyles'

const JourneyBlockComponent = ({
  item,
  onPressItem,
  translate,
  activeLanguage,
  onPressBtn,
  onPressDelete,
  isFull,
  isGrey,
  height,
  isInactive,
  isArchived,
  isCompleted,
  onPressArchive,
  loading,
  isDeleting,
  isDeleted,
  isActiveJourney
}) => {
  if (!item || isDeleted) return null
  const isCustom = item.type === 'custom'

  const renderImage = () => {
    const {media} = item
    switch (media?.type) {
      case 'images':
        return (
          <JourneyPhoto
            ratio={isActiveJourney ? 1 : 0}
            image={media.file}
            isActiveJourney={isActiveJourney}
          />
        )
      case 'videos': {
        const image = {
          url: GetLanguageValue(media.file.thumbnail, activeLanguage.code),
          color: '#fff'
        }
        return (
          <VideoBox isActiveJourney={isActiveJourney}>
            <img
              src={IconPlay}
              alt='video'
              style={{position: 'absolute', zIndex: 1}}
            />
            <JourneyPhoto ratio={0} image={image} />
          </VideoBox>
        )
      }
      case 'h5p':
      case 'embedded': {
        return (
          <H5PJourneyBlock
            dangerouslySetInnerHTML={{
              __html: GetLanguageValue(
                media.file.iframe,
                activeLanguage.code
              )
            }}
          />
        )
      }

      default:
        return (
          <JourneyPhoto
            ratio={isActiveJourney ? 1 : 0}
            image={{url: DefaultImg}}
            isActiveJourney={isActiveJourney}
          />
          // <img style={{width: '100%'}} src={DefaultImg} alt='Default' />
        )
    }
  }

  return (
    <JourneyBlockContainer
      isCustom={isCustom}
      isFull={isFull}
      height={height}
      disabled={loading || isDeleting}
      isDeleting={isDeleting}
      onClick={() => (onPressItem ? onPressItem(item) : {})}
      isActiveJourney={isActiveJourney}
    >
      {renderImage()}
      <JourneyInfo>
        <StepsContainer
          isInactive={isInactive}
          isGrey={isGrey}
          isFull={isFull}
          isCompleted={isCompleted}
        >
          {isInactive ? (
            <StepsText>
              {translate('STEPS_LEFT', {
                number: CalcJourneyStepsLeft(item)
              })}
            </StepsText>
          ) : (
            <StepsText>
              {CalcJourneySteps(item)} {translate('STEPS')}
            </StepsText>
          )}
        </StepsContainer>
        {onPressDelete && (
          <BaseButton
            className='small'
            type='button'
            onClick={(e) => {
              e.stopPropagation()
              onPressDelete(item)
            }}
            label={translate('DELETE')}
            category='error'
            disabled={loading || isDeleting}
            style={{position: 'absolute', right: 10, top: 10}}
          />
        )}
        {onPressArchive && (
          <BaseButton
            className='small'
            type='button'
            onClick={(e) => {
              e.stopPropagation()
              onPressArchive(item)
            }}
            label={translate('ARCHIVE')}
            category='primary'
            disabled={loading || isDeleting}
            style={{position: 'absolute', right: 10, top: 10}}
          />
        )}
        <JourneyDescription>
          {translate(isCustom ? 'CUSTOM' : 'GUIDED')}
          <JourneyBottom>
            <JourneyBottomIn>
              <JourneyTitle>
                {GetValuePreview(
                  item.title,
                  activeLanguage && activeLanguage.code
                )}
              </JourneyTitle>
              {!isCompleted && onPressBtn && (
                <JourneyBtnBlock>
                  {isArchived ? (
                    <BaseButton
                      className='small'
                      BaseBtn='button'
                      onClick={(e) => {
                        e.stopPropagation()
                        onPressBtn(item)
                      }}
                      label={translate('UNARCHIVE')}
                      category='primary'
                      disabled={loading || isDeleting}
                    />
                  ) : (
                    <BaseButton
                      className='small'
                      type='button'
                      onClick={(e) => {
                        e.stopPropagation()
                        onPressBtn(item)
                      }}
                      label={translate('OPEN')}
                      category='secondary'
                      disabled={loading || isDeleting}
                    />
                  )}
                </JourneyBtnBlock>
              )}
              {isCompleted && onPressBtn && (
                <JourneyBtnBlock>
                  <BaseButton
                    className='small'
                    type='button'
                    onClick={(e) => {
                      e.stopPropagation()
                      onPressBtn(item)
                    }}
                    label={translate('VIEW')}
                    category='secondary'
                    disabled={loading || isDeleting}
                  />
                </JourneyBtnBlock>
              )}
            </JourneyBottomIn>
          </JourneyBottom>
        </JourneyDescription>
      </JourneyInfo>
      {isDeleting && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            justifyContent: 'center'
          }}
        >
          <LoadingSpin min='200px' />
        </div>
      )}
    </JourneyBlockContainer>
  )
}

JourneyBlockComponent.propTypes = {
  item: PropTypes.object.isRequired,
  onPressItem: PropTypes.func,
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  onPressBtn: PropTypes.func,
  onPressDelete: PropTypes.func,
  isFull: PropTypes.bool,
  isGrey: PropTypes.bool,
  height: PropTypes.string,
  isInactive: PropTypes.bool,
  isArchived: PropTypes.bool,
  isCompleted: PropTypes.bool,
  onPressArchive: PropTypes.func,
  loading: PropTypes.bool,
  isDeleting: PropTypes.string,
  isDeleted: PropTypes.string,
  isActiveJourney: PropTypes.bool
}

JourneyBlockComponent.defaultProps = {
  onPressItem: undefined,
  onPressBtn: undefined,
  onPressDelete: undefined,
  isFull: false,
  isGrey: false,
  height: undefined,
  isInactive: false,
  isArchived: false,
  isCompleted: false,
  onPressArchive: undefined,
  loading: false,
  isActiveJourney: false,
  isDeleting: undefined,
  isDeleted: undefined
}

export default withLocalize(JourneyBlockComponent)
