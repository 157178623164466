import React, {useState, useEffect} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import IconPlay from '../../assets/icons/ic_play.svg'
import {GetSuggestedLessons} from '../../infra/requests/LessonRequests'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {VideoBox} from '../../styles/BasicStyles'
import {H5PDisabled} from '../modal/BaseModalStyles'
import {
  Container,
  ContainerTitle,
  StyledCarousel,
  CarouselCard,
  CardImage,
  CardTitle,
  SeeAll
} from './SliderStyles'

const SuggestedLessons = ({translate, activeLanguage, router}) => {
  const [suggested, setSuggested] = useState([])

  useEffect(() => {
    const getSuggested = async () => {
      const {data, success} = await GetSuggestedLessons()
      if (success) setSuggested(data)
    }
    getSuggested()
  }, [])

  const renderImage = (lesson = {}) => {
    const {media} = lesson
    switch (media?.type) {
      case 'images':
        return <CardImage image={media.file} ratio={0.6} />
      case 'videos': {
        const image = {
          url: GetLanguageValue(media.file.thumbnail, activeLanguage.code),
          color: '#fff'
        }
        return (
          <VideoBox>
            <img
              src={IconPlay}
              alt='video'
              style={{position: 'absolute', zIndex: 1}}
            />
            <CardImage image={image} ratio={0.6} />
          </VideoBox>
        )
      }
      case 'h5p':
      case 'embedded': {
        return (
          <H5PDisabled
            style={{height: 220}}
            dangerouslySetInnerHTML={{
              __html: `${GetLanguageValue(
                media.file.iframe,
                activeLanguage.code
              )}<div class="H5PTop" />`
            }}
          />
        )
      }
      default:
        return <CardImage ratio={0.6} />
    }
  }

  if (!suggested || !suggested.length) return null

  return (
    <Container style={{marginBottom: 0}}>
      <Row>
        <Col xs={24}>
          <ContainerTitle>{translate('SUGGESTED_LESSONS')}</ContainerTitle>
          <SeeAll onClick={() => router.history.push('/library')}>
            {translate('SEE_LIBRARY')}
          </SeeAll>
        </Col>
        <Col xs={24}>
          <StyledCarousel dots autoplay effect='fade'>
            {suggested.map((lesson) => (
              <CarouselCard
                key={lesson._id}
                onClick={() =>
                  router.history.push(`/lesson/${lesson._id}`, {
                    lesson,
                    back: true
                  })
                }
              >
                {renderImage(lesson)}
                <CardTitle>
                  {GetLanguageValue(lesson.title, activeLanguage.code)}
                </CardTitle>
              </CarouselCard>
            ))}
          </StyledCarousel>
        </Col>
      </Row>
    </Container>
  )
}

SuggestedLessons.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
}

export default withLocalize(SuggestedLessons)
