import React from 'react'

import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import {Field, FormSpy} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'

import {EditChannel} from '../../infra/requests/CommunityRequests'
import {updateChat} from '../../redux/data/chat/ChatActions'
import {GetAndUpdateChats} from '../../redux/thunks/UpdateThunk'
import {InputBox, InputLabel} from '../input/InputStyles'
import TextInput from '../input/TextInput'
import BaseModalForm from '../modal/BaseModalForm'
import MediaComponent from '../review_components/MediaComponent'

const ValidateGroup = (values) => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Required'
  }
  return errors
}

const EditGroupModal = ({open, onClose, onSave, translate}) => {
  const {chat} = useSelector((state) => state)
  const dispatch = useDispatch()

  const onSubmit = async (fields) => {
    try {
      const values = {
        image: fields.image.object ? fields.image : false,
        name: fields.name
      }
      const result = await EditChannel(chat.channel, values)
      if (result.success) {
        dispatch(updateChat({...chat, ...result.data}))
        dispatch(GetAndUpdateChats())
        onSave()
      }
    } catch (error) {
      console.warn(error)
    }
  }

  const onInputChange = (form, value) => {
    form.change('image', value)
  }

  return (
    <BaseModalForm
      open={open}
      onClose={onClose}
      title={translate('EDIT_GROUP')}
      onSubmit={onSubmit}
      validate={ValidateGroup}
      okText={translate('SAVE')}
      mutators={{
        ...arrayMutators
      }}
      initialValues={{
        name: chat.name,
        image: {type: 'image', image: {_id: chat?.image?.file}}
      }}
    >
      <FormSpy subscription={{values: true}}>
        {({values, form}) => (
          <>
            <Field
              component={TextInput}
              name='name'
              label={translate('NAME_THE_CHANNEL')}
              placeholder={translate('WRITE_NAME_HERE')}
            />
            <InputBox hasTopSpace={40}>
              <InputLabel>{translate('CHOOSE_GROUP_PHOTO')}</InputLabel>
              <Field
                editable
                name='image'
                refField='image'
                label={translate('UPLOAD_MEDIA')}
                component={MediaComponent}
                onInputChange={(value) => onInputChange(form, value)}
                showValues
                acceptedTypes='image/jpeg'
              />
            </InputBox>
          </>
        )}
      </FormSpy>
    </BaseModalForm>
  )
}
EditGroupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(EditGroupModal)
