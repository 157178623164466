import React from 'react'

import {Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import DefaultFile from '../../assets/default_file.png'
import DefaultLink from '../../assets/default_link.png'
import CloseIcon from '../../assets/icons/ic_close_white.svg'
import PinIcon from '../../assets/icons/ic_pin.svg'
import IconPlay from '../../assets/icons/ic_play.svg'
import BuildFileUrl from '../../infra/services/files/FileService'
import {getFileType} from '../../infra/utils/CommonFunctions'
import {BorderColor} from '../../styles/_theme'
import {VideoBox2} from '../../styles/BasicStyles'
import {
  AttachmentType,
  CloseBtn,
  Label,
  AttachImage,
  AttachBtn
} from './AttachmentsStyles'

const AttachmentComponent = ({item, edit, onPressClose, marginB}) => {
  if (
    edit &&
    !item.object &&
    (!item.file || !item.file.contentType) &&
    (!item.media || !item.media.contentType) &&
    (!item.link || !item.link.url) &&
    (!item.image || !item.image._id)
  ) {
    return null
  }
  const {type} = item

  const fileType =
    type === 'file'
      ? item.object?.name
        ? item.object.name
        : item.media?.filename
        ? item.media.filename
        : getFileType(
            item.object
              ? item.object.type
              : item.file
              ? item.file.contentType
              : item.media
              ? item.media.contentType
              : false
          )
      : item.file?.filename
      ? item.file.filename
      : item.file?.name
      ? item.file.name
      : type

  const title = item.object
    ? item.object.title || false
    : item.file
    ? item.file.title || false
    : item.link
    ? item.link.title
    : false

  const renderImage = (children) => {
    switch (type) {
      case 'image': {
        const image = {
          url: item?.object?.preview
            ? item.object.preview
            : BuildFileUrl(
                item.media
                  ? item.media._id
                  : item.file
                  ? item.file._id
                  : item.image
                  ? item.image._id
                  : false
              ) || DefaultLink,
          color: '#fff'
        }
        return (
          <AttachBtn href={image.url} rel='noreferrer' target='_blank'>
            <AttachImage image={image} ratio={0} />
            {children}
          </AttachBtn>
        )
      }
      case 'video': {
        const image = item?.object?.preview
          ? item.object.preview
          : BuildFileUrl(
              item.object
                ? item.object.path || false
                : item.file
                ? item.file._id || false
                : item.media
                ? item.media._id || false
                : false
            ) || DefaultLink
        return (
          <AttachBtn href={image} rel='noreferrer' target='_blank'>
            <VideoBox2>
              <img
                src={IconPlay}
                alt='video'
                style={{position: 'absolute'}}
              />
              <video
                key={Math.random()}
                style={{
                  width: '100%'
                }}
              >
                <source src={image} type='video/mp4' />
              </video>
            </VideoBox2>
            {children}
          </AttachBtn>
        )
      }
      case 'link': {
        const image = item.object
          ? item.object.image || DefaultLink
          : item.link
          ? item.link.image || DefaultLink
          : DefaultLink

        const url = item.object?.url ?
          item.object.url
          :
          item.object?.link ?
          item.object?.link
          :
          item.link ?
          item.link.url
          :
          false

        return (
          <AttachBtn href={url} rel='noreferrer' target='_blank'>
            <AttachImage image={{url: image}} ratio={0} /> {children}
          </AttachBtn>
        )
      }
      case 'links': {
        const image = item.file.thumbnail || DefaultLink
        return (
          <AttachBtn href={item.file.url} rel='noreferrer' target='_blank'>
            <AttachImage
              image={{url: image, color: BorderColor}}
              ratio={0}
            />{' '}
            {children}
          </AttachBtn>
        )
      }
      default:
        return (
          <AttachBtn
            href={
              item.object
                ? ''
                : BuildFileUrl(
                    item.file
                      ? item.file._id
                      : item.media
                      ? item.media._id
                      : false
                  )
            }
            rel='noreferrer'
            target='_blank'
          >
            <AttachImage ratio={0} image={{url: DefaultFile}} /> {children}
          </AttachBtn>
        )
    }
  }

  return (
    <Col style={{marginBottom: marginB || 40}}>
      {renderImage(
        <>
          {edit && onPressClose && (
            <CloseBtn
              onClick={(e) => {
                e.preventDefault()
                onPressClose()
              }}
              showBg
              style={{width: 36, height: 36}}
            >
              <img src={CloseIcon} alt='remove' />
            </CloseBtn>
          )}
          {fileType && (
            <AttachmentType>
              <img src={PinIcon} alt='pin' style={{marginRight: 8}} />
              {fileType === 'links'
                ? `Link: ${item.file.url}`
                : fileType === 'link'
                ? `Link: ${
                    item.object?.url ?
                    item.object.url
                    :
                    item.object?.link ?
                    item.object?.link
                    :
                    item.link ?
                    item.link.url
                    :
                    false
                  }`
                : fileType}
            </AttachmentType>
          )}
        </>
      )}
      {title && <Label style={{marginTop: 8}}>{title}</Label>}
    </Col>
  )
}

AttachmentComponent.propTypes = {
  item: PropTypes.object.isRequired,
  onPressClose: PropTypes.func,
  edit: PropTypes.bool,
  marginB: PropTypes.string
}

AttachmentComponent.defaultProps = {
  onPressClose: undefined,
  edit: false,
  marginB: undefined
}

export default withLocalize(AttachmentComponent)
