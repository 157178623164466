import React from 'react'

import PropTypes from 'prop-types'

import {StyledButtonSquare} from './ButtonsStyles'

const SquareButton = ({
  type,
  category,
  children,
  onClick,
  disabled,
  loading,
  className,
  isRight
}) => (
  <StyledButtonSquare
    className={className}
    category={category}
    type={type}
    onClick={onClick}
    disabled={disabled || loading}
    isRight={isRight}
  >
    {children}
  </StyledButtonSquare>
)

SquareButton.propTypes = {
  category: PropTypes.oneOf(['secondary']),
  type: PropTypes.oneOf(['reset', 'submit', 'button']),
  children: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  isRight: PropTypes.bool
}

SquareButton.defaultProps = {
  category: 'secondary',
  type: 'button',
  onClick: undefined,
  disabled: false,
  loading: false,
  className: undefined,
  isRight: false
}

export default SquareButton
