import 'antd/dist/antd.css'
import React from 'react'

import ReactDOM from 'react-dom'
import {LocalizeProvider} from 'react-localize-redux'
import {Provider} from 'react-redux'

import client from './infra/config/AxiosConfig'
import ReduxStore from './redux/ReduxConfig'
import AppStartup from './redux/thunks/AppStartup'
import ApplicationRoutes from './routes/ApplicationRoutes'
import * as serviceWorker from './serviceWorker'
import {BasicStyles} from './styles/BasicStyles'

client.setupInterceptors(ReduxStore)
ReduxStore.dispatch(AppStartup())

ReactDOM.render(
  <Provider store={ReduxStore}>
    <LocalizeProvider store={ReduxStore}>
      <BasicStyles />
      <ApplicationRoutes />
    </LocalizeProvider>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
