import React, {useState} from 'react'

import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import {withLocalize} from 'react-localize-redux'

import {red} from '../../styles/_theme'
import AttachmentComponent from '../attachments/AttachmentComponent'
import BaseButton from '../buttons/BaseButton'
import {SmallText} from '../input/InputStyles'

const MediaComponent = ({
  input,
  label,
  editable,
  error,
  translate,
  acceptedTypes,
  multiple,
  onInputChange,
  showValues
}) => {
  const [errorMessage, setErrorMessage] = useState(false)

  const handleImageDrop = (accepted, rejected) => {
    if (accepted.length <= 0 || (rejected && rejected.length > 0)) {
      input.onChange()
      if (rejected[0]?.size > 5000000) {
        return setErrorMessage(translate('ERROR_FILE_SIZE'))
      }
      return setErrorMessage(translate('ERROR_FILE'))
    }
    const type =
      accepted[0].type.indexOf('video') > -1
        ? 'video'
        : accepted[0].type.indexOf('image') > -1
        ? 'image'
        : 'file'

    const uploaded = {
      type,
      object: {
        type,
        path: accepted[0].path,
        name: accepted[0].name,
        preview: URL.createObjectURL(accepted[0])
      },
      file: accepted[0]
    }
    input.onChange(uploaded)
    setErrorMessage(false)
    if (onInputChange) onInputChange(uploaded)
  }
  return (
    <>
      {editable && (
        <Dropzone
          onDrop={handleImageDrop}
          style={{
            display: 'inline-block',
            float: 'right',
            marginBottom: '30px'
          }}
          accept={acceptedTypes}
          maxSize={5000000}
          multiple={multiple}
        >
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps()} style={{outline: 'none'}}>
              <input {...getInputProps()} />
              <BaseButton
                type='button'
                label={label || translate('ADD_MEDIA')}
                category='tertiary'
                icon
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 14,
                  width: '100%'
                }}
              />
              {errorMessage && (
                <SmallText color={red}>{errorMessage}</SmallText>
              )}
            </div>
          )}
        </Dropzone>
      )}
      {showValues && input.value && (
        <AttachmentComponent
          onPressClose={(e) => {
            if (e) e.stopPropagation()
            input.onChange(false)
          }}
          item={input.value}
          edit={editable}
        />
      )}
    </>
  )
}

MediaComponent.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  translate: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  error: PropTypes.bool,
  acceptedTypes: PropTypes.string,
  multiple: PropTypes.bool,
  onInputChange: PropTypes.func,
  showValues: PropTypes.bool
}

MediaComponent.defaultProps = {
  error: false,
  editable: false,
  acceptedTypes: 'image/jpeg, video/mp4, application/pdf',
  multiple: false,
  label: undefined,
  onInputChange: undefined,
  showValues: false
}

export default withLocalize(MediaComponent)
