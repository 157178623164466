import {createReducer} from '@reduxjs/toolkit'

import {updateChats} from './ChatsActions'

const initialState = []

const ChatsReducer = createReducer(initialState, {
  [updateChats]: (state, action) => action.payload
})

export default ChatsReducer
