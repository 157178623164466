import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import DefaultImage from '../../assets/default_file.png'
import IconPlay from '../../assets/icons/ic_play.svg'
import BuildFileUrl from '../../infra/services/files/FileService'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {GetValuePreview} from '../../infra/utils/CommonFunctions'
import {white, DarkGrey} from '../../styles/_theme'
import {MainText, VideoBox, VideoBox2} from '../../styles/BasicStyles'
import {
  CardContainer,
  CardTop,
  LessonPhoto,
  H5PLesson
} from './CardsStyles'

const LessonCard = ({
  item,
  activeLanguage,
  isUser,
  bgColor,
  onClick,
  isShare
}) => {
  if (!item) return null

  let attachments = []
  if (item.attachments && item.attachments.length > 0) {
    attachments = [...item.attachments]
  }
  if (item.questions && item.attachments.length > 0) {
    item.questions.map((question) => {
      if (question.type === 5 && question.response) {
        attachments.push(question.response)
      }
      if (question.type === 4 && question.response) {
        attachments.push({
          type: 'link',
          link: {url: question.response.link, ...question.response}
        })
      }
      return question
    })
  }

  const mediaItem = item.user
    ? (item.attachments &&
        item.attachments.find(
          (i) =>
            i.type === 'image' || i.type === 'video' || i.type === 'link'
        )) ||
      false
    : item.media
    ? item.media
    : false

  const renderImage = () => {
    const {type} = mediaItem
    switch (type) {
      case 'image':
        return (
          <LessonPhoto
            image={{url: BuildFileUrl(mediaItem.media?._id)}}
            isShare={isShare}
          />
        )
      case 'video': {
        const image = BuildFileUrl(
          mediaItem.media
            ? mediaItem.media?._id
            : mediaItem.file
            ? mediaItem.file?._id
            : false
        )

        return (
          <VideoBox2
            style={{height: isShare ? '140px' : '273px'}}
            className='lesson'
          >
            <img
              src={IconPlay}
              alt='video'
              style={{position: 'absolute'}}
            />
            <video
              style={{
                width: '100%'
              }}
            >
              <source src={image} type='video/mp4' />
            </video>
          </VideoBox2>
        )
      }
      case 'images':
        return <LessonPhoto image={mediaItem.file} isShare={isShare} />
      case 'videos': {
        const image = {
          url: GetLanguageValue(
            mediaItem.file.thumbnail,
            activeLanguage.code
          ),
          color: '#fff'
        }
        return (
          <VideoBox className='lesson'>
            <img
              src={IconPlay}
              alt='video'
              style={{position: 'absolute', zIndex: 1}}
            />
            <LessonPhoto image={image} isShare={isShare} />
          </VideoBox>
        )
      }
      case 'h5p':
      case 'embedded': {
        const file = GetLanguageValue(
          mediaItem.file.iframe,
          activeLanguage.code
        )
        return (
          <H5PLesson
            style={{
              height: isShare ? '140px' : '273px'
            }}
            dangerouslySetInnerHTML={{
              __html: `${file}<div class="H5PTop" />`
            }}
          />
        )
      }
      case 'link':
        return (
          <LessonPhoto
            image={{url: mediaItem.link.image}}
            isShare={isShare}
          />
        )
      default:
        return (
          <LessonPhoto image={{url: DefaultImage}} isShare={isShare} />
        )
    }
  }

  return (
    <CardContainer
      isFeed
      library
      onClick={onClick}
      $color={bgColor || 'rgba(211, 219, 242, 0.4)'}
    >
      {item && <CardTop>{renderImage()}</CardTop>}
      {item.title && (
        <MainText color={!isUser ? DarkGrey : white} margin='10px 6px'>
          {GetValuePreview(
            item.title,
            activeLanguage && activeLanguage.code
          ) || item.title}
        </MainText>
      )}
    </CardContainer>
  )
}

LessonCard.propTypes = {
  item: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  isUser: PropTypes.bool,
  bgColor: PropTypes.string,
  onClick: PropTypes.func,
  isShare: PropTypes.bool
}

LessonCard.defaultProps = {
  isUser: false,
  bgColor: undefined,
  onClick: undefined,
  isShare: undefined
}

export default withLocalize(LessonCard)
