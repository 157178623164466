import React, {useState} from 'react'

import {Row, Col} from 'antd'
import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {ReactComponent as CloseIcon} from '../../assets/icons/ic_close_blue.svg'
import {
  EditAssignmentTask,
  UpdateJourneyReflection,
  CreateJourneyReflection
} from '../../infra/requests/JourneyRequests'
import {GetValuePreview} from '../../infra/utils/CommonFunctions'
import {
  InformationTitle,
  CloseBtn
} from '../../pages/active_journey/ActiveJourneyStyles'
import {clearModal} from '../../redux/data/modal/ModalActions'
import {
  updateTaskReflection,
  updateReflection,
  newReflection
} from '../../redux/data/user/UserActions'
import {LightGey} from '../../styles/_theme'
import {WhiteLayout, PageForm} from '../../styles/BasicStyles'
import AttachmentComponent from '../attachments/AttachmentComponent'
import BaseButton from '../buttons/BaseButton'
import SelectInput from '../input/SelectInput'
import LinkModal from '../modal/LinkModal'
import MediaComponent from '../review_components/MediaComponent'
import ReviewComponent from '../review_components/ReviewComponent'
import {
  Label,
  ReviewMainContainer,
  ReviewInputLabelContainer
} from '../review_components/ReviewStyles'
import ReflectionValidations from './validations/ReflectionValidations'

const CreateReflection = ({
  translate,
  activeLanguage,
  journey,
  onClickBack,
  dispatch,
  currentReflection,
  info: {
    data: {templates: {reflection}} = {description: {}, questions: []}
  },
  isShare
}) => {
  const [linkModal, setLinkModal] = useState(false)
  const [attach, setAttach] = useState(
    currentReflection ? currentReflection.attachments || [] : []
  )

  if (!journey && onClickBack) return onClickBack()

  const initialValues = currentReflection
    ? {
        ...currentReflection,
        step:
          !currentReflection.step ||
          currentReflection.step === 'null' ||
          currentReflection.step === 'undefined'
            ? ''
            : currentReflection.step
      }
    : {
        ...reflection,
        questions: reflection.questions.map((question) => ({...question})),
        step: ''
      }

  const valueS = journey ? [...journey.steps] : []
  if (
    journey?.type === 'custom' &&
    currentReflection?.step &&
    currentReflection.step?.trim().length > 0 &&
    currentReflection.step !== 'null' &&
    currentReflection.step !== 'undefined' &&
    !journey?.steps.find((step) => step.name === currentReflection.step)
  ) {
    valueS.push({name: currentReflection.step})
  }

  const valueSteps = []
  valueS.map((s) => {
    valueSteps.push({label: s.name, value: s.name, key: s.name})
    return s
  })

  const onSubmit = async (fields) => {
    try {
      if (currentReflection) {
        if (currentReflection.task_id) {
          const result = await EditAssignmentTask(
            currentReflection._id,
            {
              ...fields
            },
            activeLanguage
          )

          if (result.success) {
            dispatch(
              updateTaskReflection(currentReflection.task_id, {
                ...currentReflection,
                response: {
                  ...result.data,
                  task_id: currentReflection.task_id
                }
              })
            )
          }
        } else {
          const result = await UpdateJourneyReflection(
            {
              ...fields,
              id: currentReflection._id,
              journey: journey._id,
              step: journey.type === 'custom' ? fields.step : '',
              attachments: attach
            },
            activeLanguage
          )
          if (result.success) dispatch(updateReflection(result.data))
        }
      } else {
        const questions = []
        fields.questions.map((q) =>
          questions.push({
            ...q,
            title: GetValuePreview(q.title, activeLanguage.code),
            placeholder: GetValuePreview(
              q.placeholder,
              activeLanguage.code
            )
          })
        )
        const result = await CreateJourneyReflection(
          {
            ...fields,
            goal: journey.goal,
            intention: journey.intention,
            journey: journey._id,
            step: journey.type === 'custom' ? fields.step : '',
            questions,
            attachments: attach
          },
          activeLanguage
        )
        if (result.success) dispatch(newReflection(result.data))
      }

      if (onClickBack) onClickBack()
      else dispatch(clearModal())
    } catch (e) {
      console.warn(e)
    }
  }

  const onInputChange = (form, value) => {
    const newAttachs = [...attach]
    newAttachs.push({...value})
    form.change('attachments', newAttachs)
    setAttach(newAttachs)
    form.change('new_attachment', '')
  }

  const onSubmitLink = (form, value) => {
    const newAttachs = [...attach]
    newAttachs.push({...value})
    form.change('attachments', newAttachs)
    setAttach(newAttachs)
    form.change('new_link', '')
    setLinkModal(false)
  }

  return (
    <Row gutter={[12, 24]}>
      <Col xs={20}>
        <InformationTitle>
          {translate(currentReflection ? 'REFLECTION' : 'NEW_REFLECTION')}
        </InformationTitle>
      </Col>
      {!currentReflection && (
        <Col xs={4}>
          <CloseBtn onClick={onClickBack} showBg>
            <CloseIcon />
          </CloseBtn>
        </Col>
      )}
      <Col xs={24}>
        <Row>
          <Form
            keepDirtyOnReinitialize
            onSubmit={onSubmit}
            mutators={{
              ...arrayMutators
            }}
            initialValues={initialValues}
            validate={ReflectionValidations}
          >
            {({handleSubmit, form, submitting, valid, dirty}) => (
              <PageForm
                onSubmit={(event) => {
                  const promise = handleSubmit(event).then(() => {
                    form.reset()
                  })
                  return promise
                }}
              >
                <WhiteLayout space=' 0px 30px 30px'>
                  {journey?.type === 'custom' && !isShare && (
                    <Field
                      items={valueSteps}
                      name='step'
                      placeholder={translate('SELECT_STEP')}
                      component={SelectInput}
                      label={translate('CONNECT_REFLECTION_TO_STEP')}
                      hasTopSpace={30}
                      radius={21}
                      bgcolor={LightGey}
                      allowClear
                      editable={!isShare}
                    />
                  )}
                  <FieldArray name='questions'>
                    {({fields}) =>
                      fields.map((item, index) => {
                        const question = fields.value[index]
                        return (
                          <Field
                            key={index.toString()}
                            name={`questions[${index}].response`}
                            refField={`questions${index}`}
                            label={GetValuePreview(
                              question.title,
                              activeLanguage && activeLanguage.code
                            )}
                            placeholder={
                              question.placeholder
                                ? GetValuePreview(
                                    question.placeholder,
                                    activeLanguage && activeLanguage.code
                                  )
                                : ''
                            }
                            component={ReviewComponent}
                            editable={!isShare}
                            reviewType={question.type.toString()}
                          />
                        )
                      })
                    }
                  </FieldArray>
                  {!currentReflection?.task_id && (
                    <ReviewMainContainer>
                      {!isShare && (
                        <ReviewInputLabelContainer>
                          <Label>{translate('ADD_ATTACHMENT')}</Label>
                        </ReviewInputLabelContainer>
                      )}
                      {isShare && attach && attach.length > 0 && (
                        <ReviewInputLabelContainer>
                          <Label>{translate('ATTACHMENTS')}</Label>
                        </ReviewInputLabelContainer>
                      )}
                      <FieldArray name='attachments'>
                        {({fields}) =>
                          attach.map((item, index) => (
                            <AttachmentComponent
                              onPressClose={() => {
                                fields.remove(index)
                                const newAttachs = attach.filter(
                                  (a, i) => i !== index
                                )
                                setAttach(newAttachs)
                              }}
                              item={item}
                              key={index}
                              edit={!isShare}
                              marginB='20px'
                            />
                          ))
                        }
                      </FieldArray>
                      {!isShare && (
                        <Row gutter={[6, 0]}>
                          <Col xs={24} lg={12}>
                            <Field
                              editable
                              name='new_attachment'
                              refField='new_attachment'
                              label={translate('UPLOAD_MEDIA')}
                              component={MediaComponent}
                              onInputChange={(value) =>
                                onInputChange(form, value)
                              }
                            />
                          </Col>
                          <Col xs={24} lg={12}>
                            <BaseButton
                              type='button'
                              onClick={() => setLinkModal(true)}
                              label={translate('UPLOAD_LINK')}
                              category='tertiary'
                              icon
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                      {linkModal && (
                        <LinkModal
                          open={linkModal}
                          onClose={() => setLinkModal(false)}
                          title={translate('LINK')}
                          onSubmit={(value) => onSubmitLink(form, value)}
                        />
                      )}
                    </ReviewMainContainer>
                  )}
                </WhiteLayout>
                {!isShare && (
                  <BaseButton
                    type='submit'
                    label={translate('SAVE')}
                    category='secondary'
                    style={{float: 'right', marginTop: 24}}
                    disabled={submitting || !valid || !dirty}
                  />
                )}
              </PageForm>
            )}
          </Form>
        </Row>
      </Col>
    </Row>
  )
}

CreateReflection.propTypes = {
  translate: PropTypes.func.isRequired,
  onClickBack: PropTypes.func,
  journey: PropTypes.object,
  activeLanguage: PropTypes.object.isRequired,
  info: PropTypes.object,
  currentReflection: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  isShare: PropTypes.bool
}

CreateReflection.defaultProps = {
  journey: undefined,
  info: undefined,
  onClickBack: undefined,
  currentReflection: undefined,
  isShare: false
}
const mapStateToProps = (state) => ({
  journey: state.user.active_journey,
  info: state.info
})

const mapActionToProps = (dispatch) => ({dispatch})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(CreateReflection)
)
