import React from 'react'

import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/pt'
import 'moment/locale/es'
import 'moment/locale/it'
import PropTypes from 'prop-types'
import {withLocalize, setActiveLanguage} from 'react-localize-redux'
import {useDispatch} from 'react-redux'

import SelectedIcon from '../../assets/icons/selected.png'
import {LineContainer, Label, MenuTitle} from './SettingsStyles'

const Languages = ({translate, languages, activeLanguage}) => {
  const dispatch = useDispatch()
  const changeLanguage = async (code) => {
    moment.locale(code)
    localStorage.setItem('LANG_COOKIE', code)
    dispatch(setActiveLanguage(code))
  }
  return (
    <>
      <MenuTitle spaceT={30} spaceB={36}>
        {translate('LANGUAGES')}
      </MenuTitle>
      {languages.map((language, index) => {
        const selected = language.code === activeLanguage.code
        return (
          <LineContainer
            cursor='pointer'
            spaceT={4}
            key={index}
            onClick={() => changeLanguage(language.code)}
          >
            <Label>{translate(language.code)}</Label>
            {selected ? (
              <img
                src={SelectedIcon}
                style={{width: 15, height: 15}}
                alt='selected'
              />
            ) : (
              <div style={{width: 15, height: 15}} />
            )}
          </LineContainer>
        )
      })}
    </>
  )
}

Languages.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  languages: PropTypes.object.isRequired
}

export default withLocalize(Languages)
