import {createAction} from '@reduxjs/toolkit'

export const updateInfo = createAction('info/ready')

export const updateCategory = createAction(
  'info/category/save',
  (data) => ({
    payload: data
  })
)

export const removeCategory = createAction(
  'info/category/remove',
  (data) => ({
    payload: data
  })
)
