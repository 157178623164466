import styled from 'styled-components'

import {white, lightBlue, LightGey, ThirdColor} from '../../styles/_theme'
import ImageComponent from '../images/ImageComponent'

export const ContainerCircle = styled.div`
  width: 140px;
  height: 140px;
  display: flex;
  position: relative;
  border-radius: 50%;
  border-width: 4px;
  border-color: ${white};
  background-color: ${lightBlue};
`
export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
`

export const Image = styled(ImageComponent)`
  border-radius: 50%;
  background-color: grey;
  with: auto;
  height: auto;
`

export const CloseBtn = styled.button`
  display: flex;
  position: absolute;
  border-radius: 50%;
  background-color: rgba(12, 12, 12, 0.4);
  top: 0px;
  right: 0px;
  padding: 5px;
  margin: 4px;
  align-items: center;
  border: none;
  transition: all 0.3s ease;
  outline: none;
  cursor: pointer;

  &:hover,
  &:visited,
  &:active {
    outline: none;
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
  }
`
export const PhotoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ImagePicker = styled.div`
  display: flex;
  width: 100%;
  height: 172px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${lightBlue};
  cursor: pointer;
`

export const AttachImage = styled(ImageComponent)`
  width: 100%;
  height: 172px;
  ratio: 0;
  background-color: ${LightGey};
  cursor: pointer;
  border: ${({isSelected}) =>
    isSelected ? `2px solid ${ThirdColor}` : 'none'};
`
