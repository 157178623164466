import client from '../config/AxiosConfig'

export const GetInfo = async () => client.get('/info')

export const GetTranslations = async () => client.get('/info/translations')

export const Login = async (data) => client.post('/auth/login/web', data)

export const ForgotPassword = async (data) =>
  client.post('/auth/recover', data)

export const SignUp = async (data) =>
  client.post('/auth/register/web', data)

export const Logout = async (data) => client.post('/auth/logout', data)

export const RemoveNotification = async (tag) =>
  client.put('/notifications/read', {tag})

export const GetLinkInfo = async (link) =>
  client.post('/links/getMetadata', {link})

export const DeleteAccount = async () => client.delete('/auth/forget')
