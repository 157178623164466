import {createReducer} from '@reduxjs/toolkit'

import {updateActivity} from './ActivityActions'

const initialState = {items: [], page: 1}

const ActivityReducer = createReducer(initialState, {
  [updateActivity]: (state, action) => action.payload
})

export default ActivityReducer
