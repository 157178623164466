import React from 'react'

import {Spin} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import {
  InputBox,
  InputLabel,
  StyledTimeInput,
  TimeBox
} from './InputStyles'

const TimeInput = ({
  input,
  meta,
  label,
  placeholder,
  notFoundMessage,
  fetching,
  disabled,
  hasTopSpace,
  maxwidth,
  radius,
  bgcolor
}) => {
  const {invalid, submitFailed} = meta
  const showError = invalid && submitFailed

  return (
    <InputBox hasTopSpace={hasTopSpace}>
      {label && <InputLabel>{label}</InputLabel>}
      <TimeBox
        error={showError ? 1 : 0}
        maxwidth={maxwidth}
        radius={radius}
        bgcolor={bgcolor}
      >
        <StyledTimeInput
          value={input.value}
          format='HH:mm'
          bordered={false}
          disabled={disabled}
          placeholder={placeholder}
          notFoundContent={
            fetching ? <Spin size='small' /> : notFoundMessage
          }
          showArrow
          allowClear={false}
          onChange={input.onChange}
        />
      </TimeBox>
    </InputBox>
  )
}

TimeInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  notFoundMessage: PropTypes.string,
  fetching: PropTypes.bool,
  disabled: PropTypes.bool,
  hasTopSpace: PropTypes.number,
  maxwidth: PropTypes.string,
  radius: PropTypes.number,
  bgcolor: PropTypes.string
}

TimeInput.defaultProps = {
  placeholder: undefined,
  notFoundMessage: undefined,
  fetching: false,
  disabled: false,
  hasTopSpace: undefined,
  label: undefined,
  maxwidth: undefined,
  radius: undefined,
  bgcolor: undefined
}

export default withLocalize(TimeInput)
