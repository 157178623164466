import React, {useState} from 'react'

import {Col} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import BaseButton from '../buttons/BaseButton'
import {ButtonCalendar} from './AddToCalendarStyles'

const AddToCalendar = ({event, buttonLabel, listItems}) => {
  const [showListItems, setShowListItems] = useState(false)

  const getRandomKey = () => {
    const n = Math.floor(Math.random() * 999999999999).toString()
    return `${new Date().getTime().toString()}_${n}`
  }

  const formatTime = (date) => {
    const formattedDate = moment.utc(date).format('YYYYMMDDTHHmmssZ')
    return formattedDate.replace('+00:00', 'Z')
  }

  const calculateDuration = (startTime, endTime) => {
    const end = moment.utc(endTime).format('DD/MM/YYYY HH:mm:ss')
    const start = moment.utc(startTime).format('DD/MM/YYYY HH:mm:ss')

    const difference = (0, moment)(end, 'DD/MM/YYYY HH:mm:ss').diff(
      (0, moment)(start, 'DD/MM/YYYY HH:mm:ss')
    )

    const duration = moment.duration(difference)

    return (
      Math.floor(duration.asHours()) + moment.utc(difference).format(':mm')
    )
  }

  const buildUrl = (type) => {
    let calendarUrl = ''

    switch (type) {
      case 'google':
        calendarUrl = 'https://calendar.google.com/calendar/render'
        calendarUrl += '?action=TEMPLATE'
        calendarUrl += `&dates=${formatTime(event.startTime)}`
        calendarUrl += `/${formatTime(event.endTime)}`
        calendarUrl += `&location=${encodeURIComponent(event.location)}`
        calendarUrl += `&text=${encodeURIComponent(event.title)}`
        calendarUrl += `&details=${encodeURIComponent(event.description)}`
        break

      case 'yahoo':
        const duration = calculateDuration(event.startTime, event.endTime)
        calendarUrl = 'https://calendar.yahoo.com/?v=60&view=d&type=20'
        calendarUrl += `&title=${encodeURIComponent(event.title)}`
        calendarUrl += `&st=${formatTime(event.startTime)}`
        calendarUrl += `&dur=${duration}`
        calendarUrl += `&desc=${encodeURIComponent(event.description)}`
        calendarUrl += `&in_loc=${encodeURIComponent(event.location)}`
        break

      case 'outlookcom':
        calendarUrl = 'https://outlook.live.com/owa/?rru=addevent'
        calendarUrl += `&startdt=${formatTime(event.startTime)}`
        calendarUrl += `&enddt=${formatTime(event.endTime)}`
        calendarUrl += `&subject=${encodeURIComponent(event.title)}`
        calendarUrl += `&location=${encodeURIComponent(event.location)}`
        calendarUrl += `&body=${encodeURIComponent(event.description)}`
        calendarUrl += '&allday=false'
        calendarUrl += `&uid=${getRandomKey()}`
        calendarUrl += '&path=/calendar/view/Month'
        break

      default:
        calendarUrl = [
          'BEGIN:VCALENDAR',
          'VERSION:2.0',
          'BEGIN:VEVENT',
          `URL:${window.location.origin}${event.url}`,
          `DTSTART:${formatTime(event.startTime)}`,
          `DTEND:${formatTime(event.endTime)}`,
          `SUMMARY:${event.title}`,
          `DESCRIPTION:${event.description}`,
          `LOCATION:${event.location}`,
          'END:VEVENT',
          'END:VCALENDAR'
        ].join('\n')
    }

    return calendarUrl
  }

  const handleLinkClick = (e, type) => {
    e.preventDefault()
    const url = buildUrl(type)

    const filename = 'download.ics'
    const blob = new Blob([url], {type: 'text/calendar;charset=utf-8'})

    if (url.startsWith('data') || url.startsWith('BEGIN')) {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      window.open(url, '_blank')
    }

    setShowListItems(false)
  }

  return (
    <Col>
      <Col style={{display: 'flex', justifyContent: 'center'}}>
        <BaseButton
          BaseBtn='button'
          onClick={() => setShowListItems(!showListItems)}
          label={buttonLabel}
          category='secondary'
        />
      </Col>
      {showListItems && (
        <div>
          {listItems.map((item, index) => (
            <ButtonCalendar
              key={index}
              onClick={(e) => handleLinkClick(e, item.type)}
              target='_blank'
            >
              {item.name}
            </ButtonCalendar>
          ))}
        </div>
      )}
    </Col>
  )
}

AddToCalendar.propTypes = {
  event: PropTypes.object.isRequired,
  buttonLabel: PropTypes.string,
  listItems: PropTypes.array
}

AddToCalendar.defaultProps = {
  buttonLabel: 'Add to My Calendar',
  listItems: [
    {type: 'apple', name: 'Apple Calendar'},
    {type: 'google', name: 'Google'},
    {type: 'outlook', name: 'Outlook'},
    {type: 'outlookcom', name: 'Outlook.com'},
    {type: 'yahoo', name: 'Yahoo'}
  ]
}

export default withLocalize(AddToCalendar)
