import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import {LightGey} from '../../styles/_theme'
import BaseModalList from '../modal/BaseModalList'
import PeerBlockComponent from '../peer_block/PeerBlockComponent'

const MembersModal = ({open, onClose, translate, users}) => (
  <BaseModalList
    open={open}
    onClose={onClose}
    title={translate('GROUP_MEMBERS')}
  >
    <>
      {users.map((user, index) => (
        <PeerBlockComponent
          key={index}
          marginB='6px'
          item={user}
          bgColor={LightGey}
        />
      ))}
    </>
  </BaseModalList>
)

MembersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired
}
export default withLocalize(MembersModal)
