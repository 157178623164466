import {Tabs} from 'antd'
import styled from 'styled-components'

import {ErrorColor} from '../../styles/_theme'

const {TabPane} = Tabs

export const StyledTabs = styled(Tabs)`
  &&& {
    .ant-tabs-nav {
      margin: 0;
    }
    .ant-tabs-nav-list {
      width: 100%;
    }
    .ant-tabs-tab {
      padding: 12px ${({$tabSize}) => ($tabSize ? '0' : '30px')};
      margin: 0;
      width: ${({$tabSize}) => $tabSize || 'auto'};
      text-align: center;

      .ant-tabs-tab-btn {
        width: 100%;

        span {
          ::after {
            display: inline-block;
            vertical-align: super;
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: ${ErrorColor};
          }
        }
      }
    }
  }
`

export const StyledPane = styled(TabPane)``
