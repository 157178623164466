import React from 'react'

import PropTypes from 'prop-types'
import {Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {CreateCategory} from '../../infra/requests/LibraryRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {JourneyIntroductionText} from '../../pages/journeys/JourneyStyles'
import {updateCategory} from '../../redux/data/info/InfoActions'
import TextInput from '../input/TextInput'
import BaseModalForm from '../modal/BaseModalForm'

const ValidateCategory = FormValidator.make({
  name: 'required'
})

const ModalCategory = ({open, onClose, translate, dispatch}) => {
  const onSubmit = async (values) => {
    try {
      const result = await CreateCategory(values)

      dispatch(updateCategory(result.data))
      onClose()
    } catch (error) {
      console.warn(error)
    }
  }

  return (
    <>
      <BaseModalForm
        open={open}
        onClose={onClose}
        title={translate('NEW_CATEGORY')}
        onSubmit={onSubmit}
        validate={ValidateCategory}
        okText={translate('SAVE')}
      >
        <>
          <JourneyIntroductionText>
            {translate('NEW_CATEGORY_INTRODUCTION')}
          </JourneyIntroductionText>
          <Field
            name='name'
            refField='name'
            label={translate('TITLE')}
            placeholder={translate('ADD_TITLE')}
            component={TextInput}
            editable
            hasTopSpace={16}
          />
        </>
      </BaseModalForm>
    </>
  )
}

ModalCategory.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  info: state.info,
  user: state.user
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(ModalCategory)
)
