import React from 'react'

import PropTypes from 'prop-types'

import {white, DarkGrey} from '../../styles/_theme'

const ProgressBarComponent = ({
  progress,
  borderRadius,
  height,
  color,
  unfilledColor,
  marginB,
  marginT,
  unfilledTextColor,
  filledTextColor,
  maxWidth
}) => (
  <div
    style={{
      height,
      marginBottom: marginB || 0,
      marginTop: marginT || 0,
      maxWidth: maxWidth || '100%'
    }}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        position: 'relative',
        borderRadius,
        backgroundColor: unfilledColor
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          position: 'relative',
          borderRadius,
          height,
          width: progress,
          backgroundColor: color
        }}
      >
        {progress !== '0%' && (
          <div
            style={{
              color: filledTextColor || white,
              position: 'absolute',
              left: 5,
              right: 10,
              fontSize: 12,
              fontWeight: 'normal',
              lineHeight: 1.17
            }}
          >
            {progress}
          </div>
        )}
      </div>
      {progress === '0%' && (
        <div
          style={{
            color: unfilledTextColor || DarkGrey,
            position: 'absolute',
            left: 10,
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: 1.17
          }}
        >
          0%
        </div>
      )}
    </div>
  </div>
)

ProgressBarComponent.propTypes = {
  progress: PropTypes.string,
  borderRadius: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  unfilledColor: PropTypes.string.isRequired,
  marginB: PropTypes.number,
  marginT: PropTypes.number,
  unfilledTextColor: PropTypes.string,
  filledTextColor: PropTypes.string,
  maxWidth: PropTypes.number
}

ProgressBarComponent.defaultProps = {
  progress: '0%',
  marginB: 0,
  marginT: 0,
  unfilledTextColor: DarkGrey,
  filledTextColor: white,
  maxWidth: undefined
}

export default ProgressBarComponent
