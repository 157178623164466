import React from 'react'

import {PropTypes} from 'prop-types'
import {FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import InfiniteScroll from 'react-infinite-scroller'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {GetColleagues} from '../../infra/requests/CommunityRequests'
import {LightGey} from '../../styles/_theme'
import {LoadingSpin} from '../../styles/BasicStyles'
import SearchInput from '../input/SearchInput'
import PeerBlockComponent from '../peer_block/PeerBlockComponent'

const limit = 20

class PeersList extends React.Component {
  constructor(props) {
    super(props)
    this.scrollComponent = null
    this.state = {
      isLoading: false,
      colleagues: [],
      page: 1,
      search: undefined,
      hasMore: true
    }
  }

  componentDidMount = () => {
    const {user} = this.props
    const permissions =
      !user.roles || !user.roles.filter((r) => r.role === 7).length
        ? false
        : 1
    const subscription = user.subscription ? user.subscription : false

    if (permissions === 1 && !subscription) this.GetColleagues()
    else {
      this.GetColleagues()
    }
  }

  GetColleagues = async () => {
    const {page, colleagues, isLoading, search, hasMore} = this.state
    if (isLoading || !hasMore) return
    this.setState({isLoading: true})
    try {
      const result = await GetColleagues(page, limit, search)
      this.setState({isLoading: false})
      if (result.success) {
        this.setState({
          colleagues: [...colleagues, ...result.data],
          page: page + 1
        })
      }

      if (!result.data || !result.data.length) {
        this.setState({hasMore: false})
      }
    } catch (error) {
      console.warn(error)
    }
  }

  onPressListItem = (peer, values, form) => {
    if (values.invitedUsers && values.invitedUsers.length) return
    const selected = values.users || []
    let changes = [...selected]
    const exist = changes.findIndex((u) => peer._id === u._id)

    if (exist >= 0) {
      changes = values.users.filter((u) => peer._id !== u._id)
    } else {
      changes.push(peer)
    }
    form.change('users', changes)
  }

  render() {
    const {translate} = this.props
    const {colleagues, search, hasMore} = this.state

    return (
      <FormSpy subscription={{values: true}}>
        {({values, form}) => (
          <>
            <SearchInput
              placeholder={translate('SEARCH_PLACEHOLDER')}
              input={{
                value: search,
                onChange: (e) => this.setState({search: e.target.value})
              }}
              style={{padding: '20px 0px'}}
            />
            <FieldArray name='users'>
              {({fields}) => (
                <div
                  style={{
                    height: colleagues.length * 68,
                    overflow: 'hidden'
                  }}
                >
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={this.GetColleagues}
                    hasMore={hasMore}
                    loader={<LoadingSpin min='200px' />}
                    useWindow={false}
                  >
                    {colleagues.map((item, index) => (
                        <PeerBlockComponent
                          key={index}
                          index={index}
                          item={item}
                          onPressItem={(peer) =>
                            this.onPressListItem(peer, values, form)
                          }
                          bgColor={LightGey}
                          selectedPeers={values.users || []}
                          marginB='6px'
                        />
                      ))}
                  </InfiniteScroll>
                </div>
              )}
            </FieldArray>
          </>
        )}
      </FormSpy>
    )
  }
}

PeersList.propTypes = {
  translate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(PeersList))
