import React, {useState, useEffect} from 'react'

import {Row} from 'antd'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'

import {SetJourneyActive} from '../../infra/requests/JourneyRequests'
import {updateActiveJourney} from '../../redux/data/user/UserActions'
import {
  LoadingSpin,
  EmptyText,
  EmptyLayout
} from '../../styles/BasicStyles'
import {InformationContainer} from '../active_journey/ActiveJourneyStyles'
import {InformationPageScroll} from '../journeys/JourneyStyles'

const ActivateJourneyPage = ({router}) => {
  const [hasFetchJourney, setHasFetchJourney] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const dispatch = useDispatch()

  const {
    match: {
      params: {id}
    }
  } = router

  useEffect(() => {
    async function getJourney() {
      setLoading(true)
      setError(false)
      try {
        const result = await SetJourneyActive(id)
        if (result.success) {
          setHasFetchJourney(true)
          dispatch(updateActiveJourney({active_journey: result.data}))
          router.history.push('/active-journey')
        } else setError(result.message)
      } catch (e) {
        console.warn(e)
      } finally {
        setLoading(false)
      }
    }
    if (!hasFetchJourney) {
      getJourney()
    }
  }, [hasFetchJourney, dispatch, id, router])

  if (loading) return <LoadingSpin min='200px' />

  return (
    <InformationPageScroll>
      <InformationContainer>
        <Row>
          {error && (
            <EmptyLayout>
              <EmptyText>{error}</EmptyText>
            </EmptyLayout>
          )}
        </Row>
      </InformationContainer>
    </InformationPageScroll>
  )
}

ActivateJourneyPage.propTypes = {
  router: PropTypes.object.isRequired
}

export default ActivateJourneyPage
