import React, {useState} from 'react'

import {Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {CreateTask} from '../../infra/requests/JourneyRequests'
import {GetValuePreview} from '../../infra/utils/CommonFunctions'
import {updateTask} from '../../redux/data/user/UserActions'
import AttachmentsList from '../attachments/AttachmentsList'
import TaskHeader from './TaskHeader'
import {TaskInfo, TaskTitle, StepTitle} from './TaskStyles'

const LessonTask = ({
  task,
  taskIndex,
  stepIndex,
  totalTasks,
  activeLanguage,
  nextTask,
  previousTask,
  idJourney,
  dispatch,
  translate,
  isCompleted,
  renderNextStep
}) => {
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    if ((task.response && task.response.completed) || isCompleted) {
      return
    }
    setLoading(true)
    try {
      const result = await CreateTask({
        journey: idJourney,
        task: task._id
      })
      dispatch(updateTask(task._id, result.data))
      setLoading(false)
      // nextTask()
    } catch (e) {
      setLoading(false)
      console.warn(e)
    }
  }

  return (
    <Col xs={24}>
      <TaskHeader
        task={task}
        loading={loading}
        taskIndex={taskIndex}
        totalTasks={totalTasks}
        nextTask={nextTask}
        previousTask={previousTask}
        onSubmit={onSubmit}
        renderNextStep={renderNextStep}
      />
      <StepTitle style={{marginTop: 16}}>
        {translate('STEP')} {stepIndex}
      </StepTitle>
      <TaskTitle>
        {GetValuePreview(
          task.title,
          activeLanguage && activeLanguage.code
        )}
      </TaskTitle>

      {task.description && (
        <TaskInfo
          dangerouslySetInnerHTML={{
            __html: GetValuePreview(
              task.description,
              activeLanguage && activeLanguage.code
            )
          }}
        />
      )}
      {task.attachments && task.attachments.length > 0 && (
        <AttachmentsList items={task.attachments} />
      )}
    </Col>
  )
}

LessonTask.propTypes = {
  task: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  taskIndex: PropTypes.number,
  totalTasks: PropTypes.number,
  stepIndex: PropTypes.number,
  previousTask: PropTypes.func.isRequired,
  nextTask: PropTypes.func.isRequired,
  idJourney: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  isCompleted: PropTypes.bool,
  renderNextStep: PropTypes.func
}

LessonTask.defaultProps = {
  taskIndex: 1,
  totalTasks: 1,
  stepIndex: 1,
  isCompleted: false,
  renderNextStep: undefined
}

const mapActionToProps = (dispatch) => ({dispatch})

export default withLocalize(
  connect(() => ({}), mapActionToProps)(LessonTask)
)
