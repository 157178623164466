import React, {useState} from 'react'

import {Collapse, Row, Col} from 'antd'
import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'
import {useLocation} from 'react-router-dom'

import {ReactComponent as IconDown} from '../../assets/icons/chevron_down_white.svg'
import CheckIcon from '../../assets/icons/ic_check.svg'
import {ReactComponent as DeleteIcon} from '../../assets/icons/ic_trash_red.svg'
import AttachmentComponent from '../../components/attachments/AttachmentComponent'
import BaseButton from '../../components/buttons/BaseButton'
import TextAreaInput from '../../components/input/TextAreaInput'
import TextInput from '../../components/input/TextInput'
import ModalCategory from '../../components/lessons/ModalCategory'
import LinkModal from '../../components/modal/LinkModal'
import {
  PeerSection,
  PeerLine,
  PeerDescription,
  SelectCircle,
  SelectContainer
} from '../../components/peer_block/PeerBlockComponentStyles'
import MediaComponent from '../../components/review_components/MediaComponent'
import {
  ReviewMainContainer,
  ReviewInputLabelContainer,
  Label
} from '../../components/review_components/ReviewStyles'
import {
  CreateLesson,
  DeleteCategory,
  UpdateLesson
} from '../../infra/requests/LibraryRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {GetValuePreview} from '../../infra/utils/CommonFunctions'
import {removeCategory} from '../../redux/data/info/InfoActions'
import {ThirdColor, LightGey, InputErrorColor} from '../../styles/_theme'
import {PageForm, WhiteLayout, SmallText} from '../../styles/BasicStyles'
import {InformationContainer} from '../active_journey/ActiveJourneyStyles'
import {LineContainer} from '../create_journey/CreateJourneyStyles'
import {HeaderTitle, Text, CategoryBtns} from './LessonPageStyles'

const {Panel} = Collapse

const ValidateNewLesson = FormValidator.make({
  title: 'required',
  content: 'required',
  category: 'required'
})

const ManageLessonPage = ({translate, activeLanguage, router}) => {
  const {
    info: {
      data: {categories}
    },
    user
  } = useSelector((state) => state)
  const location = useLocation()
  const currentLesson = location?.state?.lesson

  const dispatch = useDispatch()
  const [categoryModal, setCategoryModal] = useState(false)
  const [attach, setAttach] = useState(
    currentLesson ? currentLesson.attachments || [] : []
  )
  const [linkModal, setLinkModal] = useState(false)
  const [error, setError] = useState(false)

  const initialValues = currentLesson
    ? {...currentLesson, category: currentLesson.category._id || false}
    : {}

  const onSubmit = async (fields) => {
    try {
      if (currentLesson) {
        await UpdateLesson({
          ...fields,
          id: currentLesson._id
        })
        router.history.replace('/library')
      } else {
        await CreateLesson(fields)
        router.history.goBack()
      }
    } catch (e) {
      console.warn(e)
    }
  }

  const renderHeader = (title) => (
    <LineContainer bgColor={ThirdColor} cursor='pointer' spaceT={4}>
      {title}
      <IconDown />
    </LineContainer>
  )

  const onInputChange = (form, value) => {
    const newAttachs = [...attach]
    newAttachs.push({...value})
    form.change('attachments', newAttachs)
    setAttach(newAttachs)
    form.change('new_attachment', '')
  }

  const onSubmitLink = (form, value) => {
    const newAttachs = [...attach]
    newAttachs.push({...value})
    form.change('attachments', newAttachs)
    setAttach(newAttachs)
    form.change('new_link', '')
    setLinkModal(false)
  }

  const onPressListItem = (category, values, form) => {
    const selected = values.category || false
    let changes = selected

    if (category === selected) {
      changes = false
    } else {
      changes = category
    }
    form.change('category', changes)
  }

  const deleteCategory = async (id) => {
    try {
      const result = await DeleteCategory(id)
      if (result.success) dispatch(removeCategory(id))
      else setError('AN_ERROR_OCCURRED')
    } catch (e) {
      console.warn(e)
      if (e.message) setError(e.message)
      else setError('AN_ERROR_OCCURRED')
    }
  }

  const sortCategories = [...categories]?.sort((a, b) => GetValuePreview(a.name, activeLanguage.code).localeCompare(GetValuePreview(b.name, activeLanguage.code)))

  return (
    <InformationContainer>
      <Form
        keepDirtyOnReinitialize
        onSubmit={onSubmit}
        validate={ValidateNewLesson}
        mutators={{
          ...arrayMutators
        }}
        initialValues={initialValues}
      >
        {({handleSubmit, submitting, valid, form, values}) => (
          <PageForm
            onSubmit={(event) => {
              const promise = handleSubmit(event).then(() => {
                form.reset()
              })
              return promise
            }}
          >
            <Row gutter={[12, 24]}>
              <Col xs={12}>
                <HeaderTitle>
                  {translate(currentLesson ? 'EDIT_LESSON' : 'NEW_LESSON')}
                </HeaderTitle>
              </Col>
              <Col xs={12}>
                <BaseButton
                  type='submit'
                  label={translate(currentLesson ? 'SAVE' : 'CREATE')}
                  category='enter'
                  style={{float: 'right'}}
                  disabled={submitting || !valid}
                />
                <BaseButton
                  type='button'
                  label={translate('CANCEL')}
                  category='default'
                  onClick={() => router.history.goBack()}
                  style={{float: 'right', marginRight: 10}}
                />
              </Col>
            </Row>
            <WhiteLayout space='40px'>
              <Text>{translate('INTRODUCTION_LESSON_STEP1')}</Text>
              <Field
                component={TextInput}
                hasTopSpace={24}
                name='title'
                label={translate('TITLE_(OBLIGATORY)')}
                placeholder={translate('ADD_TITLE')}
              />
              <ReviewMainContainer>
                <ReviewInputLabelContainer>
                  <Label>{translate('ADD_ATTACHMENT')}</Label>
                </ReviewInputLabelContainer>
                <FieldArray name='attachments'>
                  {({fields}) =>
                    attach.map((item, index) => (
                      <AttachmentComponent
                        onPressClose={() => {
                          fields.remove(index)
                          const newAttachs = attach.filter(
                            (a, i) => i !== index
                          )
                          setAttach(newAttachs)
                        }}
                        edit
                        item={item}
                        key={index}
                        marginB='20px'
                      />
                    ))
                  }
                </FieldArray>
                <Row gutter={[6, 0]}>
                  <Col xs={12}>
                    <Field
                      editable
                      name='new_attachment'
                      refField='new_attachment'
                      label={translate('UPLOAD_MEDIA')}
                      component={MediaComponent}
                      onInputChange={(value) => onInputChange(form, value)}
                    />
                  </Col>
                  <Col xs={12}>
                    <BaseButton
                      type='button'
                      onClick={() => setLinkModal(true)}
                      label={translate('UPLOAD_LINK')}
                      category='tertiary'
                      icon
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    />
                  </Col>
                </Row>
                {linkModal && (
                  <LinkModal
                    open={linkModal}
                    onClose={() => setLinkModal(false)}
                    title={translate('LINK')}
                    onSubmit={(value) => onSubmitLink(form, value)}
                  />
                )}
              </ReviewMainContainer>
              <Text style={{marginTop: 40}}>
                {translate('INTRODUCTION_LESSON_STEP2')}
              </Text>
              <Field
                component={TextAreaInput}
                hasTopSpace={24}
                name='content'
                label={translate('DESCRIPTION')}
                placeholder={translate('EXAMPLE_OF_DESCRIPTION_HERE')}
                editable
              />
              <ReviewMainContainer>
                <ReviewInputLabelContainer>
                  <Label>{translate('CATEGORY')}</Label>
                </ReviewInputLabelContainer>
                <Collapse accordion bordered={false} ghost>
                  <Panel
                    showArrow={false}
                    header={renderHeader(
                      values.category
                        ? GetValuePreview(
                            categories.find(
                              (c) => c._id === values.category
                            )?.name,
                            activeLanguage
                          )
                        : translate('CHOOSE_CATEGORY')
                    )}
                  >
                    <BaseButton
                      BaseBtn='button'
                      onClick={() => setCategoryModal(true)}
                      label={translate('NEW_CATEGORY')}
                      category='primary'
                      style={{float: 'right', marginBottom: 16}}
                    />
                    <ModalCategory
                      open={categoryModal}
                      onClose={() => setCategoryModal(false)}
                    />
                    {error && (
                      <SmallText color={InputErrorColor}>
                        {translate(error)}
                      </SmallText>
                    )}
                    {sortCategories?.map((cat, index) => (
                      <PeerSection
                        key={index}
                        onClick={() =>
                          onPressListItem(cat._id, values, form)
                        }
                        bgColor={LightGey}
                        marginB='6px'
                      >
                        <PeerLine>
                          <PeerDescription>
                            {GetValuePreview(
                              cat.name,
                              activeLanguage && activeLanguage.code
                            )}
                          </PeerDescription>
                          <CategoryBtns>
                            {user._id === cat.user && (
                              <SelectContainer
                                style={{
                                  justifyContent: 'center',
                                  marginRight: 10
                                }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  deleteCategory(cat._id)
                                }}
                              >
                                <DeleteIcon />
                              </SelectContainer>
                            )}
                            {values.category &&
                            values.category === cat._id ? (
                              <SelectCircle isSelected>
                                <img src={CheckIcon} alt='CheckIcon' />
                              </SelectCircle>
                            ) : (
                              <SelectCircle />
                            )}
                          </CategoryBtns>
                        </PeerLine>
                      </PeerSection>
                    ))}
                  </Panel>
                </Collapse>
              </ReviewMainContainer>
            </WhiteLayout>
          </PageForm>
        )}
      </Form>
    </InformationContainer>
  )
}

ManageLessonPage.propTypes = {
  activeLanguage: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired
}

export default withLocalize(ManageLessonPage)
