import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {GetColleagues} from '../../infra/requests/CommunityRequests'
import {updateKudos} from '../../redux/data/kudos/KudosActions'
import {LightGey} from '../../styles/_theme'
import {LoadingSpin} from '../../styles/BasicStyles'
import SearchInput from '../input/SearchInput'
import PeerBlockComponent from '../peer_block/PeerBlockComponent'
import {ChatListSection} from './CommunityStyles'

const limit = 20

class PeersComponent extends React.Component {
  constructor(props) {
    super(props)
    this.scrollMessages = null
    this.state = {
      searchValue: undefined,
      isLoading: false,
      peers: [],
      page: 1,
      total: undefined
    }
  }

  componentDidMount = () => {
    this.getPeers(true)
  }

  getPeers = async (setLoading) => {
    const {isLoading, total, peers, page, searchValue} = this.state
    if (isLoading || total <= peers.length) return

    try {
      if (setLoading) this.setState({isLoading: true})
      const result = await GetColleagues(page, limit, searchValue)
      this.setState({
        peers: [...peers, ...result.data],
        page: page + 1,
        total: result.data.total
      })
    } catch (error) {
      console.warn(error)
    } finally {
      this.setState({isLoading: false})
    }
  }

  onSearch = (value) => {
    this.setState(
      {
        page: 1,
        total: undefined,
        searchValue: value && value.trim() !== '' ? value : undefined,
        peers: []
      },
      this.getPeers
    )
  }

  handleScroll = () => {
    const {isLoading} = this.state
    if (
      !isLoading &&
      this.scrollMessages &&
      this.scrollMessages.scrollHeight - this.scrollMessages.scrollTop <=
        this.scrollMessages.clientHeight
    ) {
      this.getPeers()
    }
  }

  renderList = () => {
    const {isLoading, peers} = this.state
    const {onClickChat, dispatch} = this.props

    if (isLoading) return <LoadingSpin min='200px' />

    return peers.map((line, index) => (
      <PeerBlockComponent
        key={index}
        item={line}
        onPressItem={(peer) =>
          onClickChat({
            type: 'private',
            user: peer._id,
            name: peer.name
          })
        }
        giveKudos={(peer) => dispatch(updateKudos(peer))}
        bgColor={LightGey}
        marginB='8px'
      />
    ))
  }

  render() {
    const {searchValue} = this.state
    const {translate} = this.props
    return (
      <ChatListSection
        onScroll={this.handleScroll}
        ref={(ref) => {
          this.scrollMessages = ref
        }}
      >
        <SearchInput
          placeholder={translate('SEARCH_PLACEHOLDER')}
          input={{
            value: searchValue,
            onChange: (e) => this.onSearch(e.target.value)
          }}
        />

        {this.renderList()}
      </ChatListSection>
    )
  }
}

PeersComponent.propTypes = {
  onClickChat: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(() => ({}), mapActionToProps)(PeersComponent)
)
