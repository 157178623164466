import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useLocation} from 'react-router-dom'

import DefaultImg from '../../assets/default_custom.png'
import IconPlay from '../../assets/icons/ic_play.svg'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {
  CalcJourneySteps,
  GetValuePreview,
  GetH5PRatio
} from '../../infra/utils/CommonFunctions'
import {VideoBox} from '../../styles/BasicStyles'
import {
  JourneyCard,
  JourneyImage,
  InformationPage,
  InformationContainer,
  JourneyDescription,
  JourneyTitle,
  JourneyTop
} from '../active_journey/ActiveJourneyStyles'
import {JourneyHeaderStepsGroup, H5P} from '../journeys/JourneyStyles'
import JourneyForm from './JourneyForm'

const GetImage = (media, code) => {
  switch (media?.type) {
    case 'images':
      return <JourneyImage image={media.file} ratio={0.6} />
    case 'videos': {
      const file = {
        url: GetLanguageValue(media.file.thumbnail, code),
        color: '#fff'
      }
      return (
        <VideoBox isAuto style={{marginBottom: 20}}>
          <img
            src={IconPlay}
            alt='video'
            style={{position: 'absolute', zIndex: 1}}
          />
          <JourneyImage image={file} ratio={0.6} marginB={1} />
        </VideoBox>
      )
    }
    case 'h5p':
    case 'embedded': {
      const file = GetLanguageValue(media.file.iframe, code)
      const r = GetH5PRatio(file)
      return (
        <H5P
          style={{
            paddingTop: r.isPx || `${r}%`,
            overflow: 'hidden',
            marginBottom: 35
          }}
          dangerouslySetInnerHTML={{
            __html: file
          }}
        />
      )
    }
    default:
      return <JourneyImage image={{url: DefaultImg}} ratio={0.6} />
  }
}

const CreateJourneyPage = ({activeLanguage, translate, router}) => {
  const location = useLocation()
  const journey = location?.state?.journey
  const language = location?.state?.language
  const lang = language || activeLanguage.code

  // let image = GetImage(journey?.media, lang)
  // if (!image) image = {url: DefaultImg}

  return (
    <>
      {journey ? (
        <>
          <JourneyCard>
            {GetImage(journey.media, lang)}
            <JourneyDescription>
              <JourneyTop>
                <JourneyTitle>
                  {GetValuePreview(
                    journey.title,
                    activeLanguage && lang
                  )}
                </JourneyTitle>
                <JourneyHeaderStepsGroup spaceB={16}>
                  <div style={{whiteSpace: 'nowrap'}}>
                    {CalcJourneySteps(journey)} {translate('STEPS')}
                  </div>
                </JourneyHeaderStepsGroup>
                {journey.description && (
                  <JourneyDescription
                    style={{padding: '0px 0px 50px'}}
                    dangerouslySetInnerHTML={{
                      __html: GetValuePreview(
                        journey.description,
                        lang
                      )
                    }}
                  />
                )}
              </JourneyTop>
            </JourneyDescription>
          </JourneyCard>
          <InformationPage>
            <InformationContainer>
              <JourneyForm journey={journey} router={router} lang={lang} />
            </InformationContainer>
          </InformationPage>
        </>
      ) : (
        <InformationContainer>
          <JourneyForm journey={journey || null} router={router} />
        </InformationContainer>
      )}
    </>
  )
}

CreateJourneyPage.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
}

export default withLocalize(CreateJourneyPage)
