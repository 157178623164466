import React from 'react'

import {Collapse} from 'antd'
import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import {Field, FormSpy} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'

import {ReactComponent as IconDown} from '../../assets/icons/chevron_down_white.svg'
import {CreateChannel} from '../../infra/requests/CommunityRequests'
import {LineContainer} from '../../pages/create_journey/CreateJourneyStyles'
import {GetAndUpdateChats} from '../../redux/thunks/UpdateThunk'
import {ThirdColor} from '../../styles/_theme'
import {InputBox, InputLabel} from '../input/InputStyles'
import TextInput from '../input/TextInput'
import BaseModalForm from '../modal/BaseModalForm'
import MediaComponent from '../review_components/MediaComponent'
import FriendsList from './FriendsList'
import PeersList from './PeersList'

const {Panel} = Collapse

const ValidateGroup = (values) => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Required'
  }
  if (
    (!values.users && !values.invitedUsers) ||
    (values.users?.length < 2 && values.invitedUsers?.length < 2)
  ) {
    errors.users = 'Required'
    errors.invitedUsers = 'Required'
  }
  return errors
}

const NewGroupModal = ({open, onClose, onSave, translate}) => {
  const {user} = useSelector((state) => state)
  const dispatch = useDispatch()

  const permissions =
    !user.roles || !user.roles.filter((r) => r.role === 7).length
      ? false
      : 1
  const subscription = user.subscription ? user.subscription : false

  const onInputChange = (form, value) => {
    form.change('image', value)
  }

  const renderHeader = (title) => (
    <LineContainer bgColor={ThirdColor} cursor='pointer' spaceT={4}>
      {title}
      <IconDown />
    </LineContainer>
  )

  const onSubmit = async (fields) => {
    try {
      const users =
        fields.users && fields.users.length > 0
          ? fields.users
          : fields.invitedUsers
      const type =
        fields.users && fields.users.length > 0 ? 'peers' : 'friends'
      const values = {
        image: fields.image,
        name: fields.name,
        users,
        context: type
      }
      const result = await CreateChannel(values)
      if (result.success) {
        dispatch(GetAndUpdateChats())
        onSave()
      }
    } catch (error) {
      console.warn(error)
    }
  }

  return (
    <BaseModalForm
      open={open}
      onClose={onClose}
      title={translate('NEW_GROUP')}
      onSubmit={onSubmit}
      validate={ValidateGroup}
      okText={translate('CREATE')}
      mutators={{
        ...arrayMutators
      }}
      initialValues={{users: [], invitedUsers: []}}
    >
      <FormSpy subscription={{values: true}}>
        {({values, form}) => {
          const peersSelectedTotal = values.users ? values.users.length : 0
          const friendsSelectedTotal = values.invitedUsers
            ? values.invitedUsers.length
            : 0
          return (
            <>
              <Field
                component={TextInput}
                name='name'
                label={translate('NAME_THE_CHANNEL')}
                placeholder={translate('WRITE_NAME_HERE')}
              />
              <InputBox hasTopSpace={40}>
                <InputLabel>{translate('CHOOSE_GROUP_PHOTO')}</InputLabel>
                <Field
                  editable
                  name='image'
                  refField='image'
                  label={translate('UPLOAD_MEDIA')}
                  component={MediaComponent}
                  onInputChange={(value) => onInputChange(form, value)}
                  showValues
                  acceptedTypes='image/jpeg'
                />
              </InputBox>
              <InputBox hasTopSpace={20}>
                <InputLabel>{translate('SELECT_PEER(S)')}</InputLabel>
                {permissions === 1 && !subscription ? (
                  <Collapse accordion bordered={false} ghost>
                    <Panel
                      showArrow={false}
                      header={renderHeader(
                        !peersSelectedTotal
                          ? translate('SELECT_PEER(S)')
                          : peersSelectedTotal === 1
                          ? `${peersSelectedTotal} ${translate(
                              'PEER_SELECTED'
                            )}`
                          : `${peersSelectedTotal} ${translate(
                              'PEERS_SELECTED'
                            )}`
                      )}
                    >
                      <PeersList />
                    </Panel>
                  </Collapse>
                ) : !permissions && subscription ? (
                  <Collapse accordion bordered={false} ghost>
                    <Panel
                      showArrow={false}
                      header={renderHeader(
                        !friendsSelectedTotal
                          ? translate('FRIENDS')
                          : friendsSelectedTotal === 1
                          ? `${friendsSelectedTotal} ${translate(
                              'FRIEND_SELECTED'
                            )}`
                          : `${friendsSelectedTotal} ${translate(
                              'FRIENDS_SELECTED'
                            )}`
                      )}
                    >
                      <FriendsList />
                    </Panel>
                  </Collapse>
                ) : (
                  <Collapse accordion bordered={false} ghost>
                    <Panel
                      showArrow={false}
                      header={renderHeader(
                        !peersSelectedTotal
                          ? translate('SELECT_PEER(S)')
                          : peersSelectedTotal === 1
                          ? `${peersSelectedTotal} ${translate(
                              'PEER_SELECTED'
                            )}`
                          : `${peersSelectedTotal} ${translate(
                              'PEERS_SELECTED'
                            )}`
                      )}
                    >
                      <PeersList />
                    </Panel>
                    <Panel
                      showArrow={false}
                      header={renderHeader(
                        !friendsSelectedTotal
                          ? translate('FRIENDS')
                          : friendsSelectedTotal === 1
                          ? `${friendsSelectedTotal} ${translate(
                              'FRIEND_SELECTED'
                            )}`
                          : `${friendsSelectedTotal} ${translate(
                              'FRIENDS_SELECTED'
                            )}`
                      )}
                    >
                      <FriendsList />
                    </Panel>
                  </Collapse>
                )}
              </InputBox>
            </>
          )
        }}
      </FormSpy>
    </BaseModalForm>
  )
}
NewGroupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(NewGroupModal)
