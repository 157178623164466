import React from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useDispatch} from 'react-redux'
import {SRLWrapper} from 'simple-react-lightbox'

import {DuplicateLesson} from '../../infra/requests/LibraryRequests'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {GetValuePreview} from '../../infra/utils/CommonFunctions'
import {InformationTitle} from '../../pages/active_journey/ActiveJourneyStyles'
import {
  LessonTextContent,
  LessonTitle,
  LessonCategory,
  LessonDescription,
  LessonButton
} from '../../pages/lessons/LessonPageStyles'
import {clearModal} from '../../redux/data/modal/ModalActions'
import {GetAndUpdateInfo} from '../../redux/thunks/UpdateThunk'
import {WhiteLayout} from '../../styles/BasicStyles'
import AttachmentsList from '../attachments/AttachmentsList'
import BaseButton from '../buttons/BaseButton'
import {TaskImage} from '../task/TaskStyles'
import VideoPlayer from '../video/VideoPlayer'

function isHTML(str) {
  const a = document.createElement('div')
  a.innerHTML = str

  for (let c = a.childNodes, i = c.length; i--;) {
    if (c[i].nodeType == 1) return true
  }

  return false
}

const LessonDetail = ({translate, activeLanguage, lesson, isUser}) => {
  const dispatch = useDispatch()

  const renderImage = () => {
    const {media} = lesson
    switch (media?.type) {
      case 'images':
        return (
          <TaskImage style={{height: 500}}>
            <img
              src={media.file.url}
              style={{width: '100%'}}
              alt='media'
            />
          </TaskImage>
        )
      case 'videos': {
        const video = GetLanguageValue(media.file.url, activeLanguage.code)
        return <VideoPlayer video={video} height='500px' width='100%' />
      }
      default:
        return false
    }
  }

  const AddToOwnLibrary = async () => {
    try {
      const result = await DuplicateLesson(lesson._id)
      if (result.success) {
        dispatch(GetAndUpdateInfo())
        dispatch(clearModal())
      }
    } catch (error) {
      console.warn(error)
    }
  }

  return (
    <Row>
      <Col xs={24}>
        <InformationTitle>{translate('LESSON')}</InformationTitle>
      </Col>
      <Col xs={24}>
        <Row>
          <WhiteLayout space='0px'>
            <SRLWrapper hideControlsAfter='100'>
              {renderImage()}
            </SRLWrapper>
            <LessonTextContent>
              <LessonTitle>
                {GetValuePreview(lesson.title, activeLanguage.code)}
              </LessonTitle>
              <LessonCategory>
                {GetValuePreview(lesson.category, activeLanguage.code)}
              </LessonCategory>
              {lesson?.description && (
                <>
                  {isHTML(
                    GetValuePreview(
                      lesson.description,
                      activeLanguage.code
                    )
                  ) ? (
                    <LessonDescription
                      dangerouslySetInnerHTML={{
                        __html: GetValuePreview(
                          lesson.description,
                          activeLanguage.code
                        )
                      }}
                    />
                  ) : (
                    <LessonDescription>
                      {GetValuePreview(
                        lesson.description,
                        activeLanguage.code
                      )}
                    </LessonDescription>
                  )}
                </>
              )}
              {lesson?.attachments && lesson.attachments.length > 0 && (
                <AttachmentsList items={lesson.attachments} edit />
              )}
              {!isUser && lesson._id && (
                <LessonButton style={{height: 'auto'}}>
                  <BaseButton
                    type='button'
                    label={translate('ADD_TO_MY_LIBRARY')}
                    category='secondary'
                    onClick={() => AddToOwnLibrary()}
                  />
                </LessonButton>
              )}
            </LessonTextContent>
          </WhiteLayout>
        </Row>
      </Col>
    </Row>
  )
}

LessonDetail.propTypes = {
  translate: PropTypes.func.isRequired,
  lesson: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  isUser: PropTypes.bool
}

LessonDetail.defaultProps = {
  isUser: false
}

export default withLocalize(LessonDetail)
