import React from 'react'

import moment from 'moment'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import UserDefault from '../../assets/default_user.png'
import CloseIcon from '../../assets/icons/ic_close_white.svg'
import IconPlay from '../../assets/icons/ic_play.svg'
import BuildFileUrl from '../../infra/services/files/FileService'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {updateModal} from '../../redux/data/modal/ModalActions'
import {white} from '../../styles/_theme'
import {
  WhiteLayout,
  VideoBox2,
  ClickableBox
} from '../../styles/BasicStyles'
import {ShadowKudos} from '../kudos/KudosStyles'
import PeerBlockComponent from '../peer_block/PeerBlockComponent'
import {KudosMessage} from '../sliders/SliderStyles'
import {
  CardContainer,
  CardTimeHeader,
  CardTask,
  CardImage,
  PeerBlockBox,
  CardTaskText
} from './CardsStyles'

const KudosCard = ({
  item,
  translate,
  marginH,
  isFeed,
  activeLanguage,
  onClose,
  isShare,
  onPressItem,
  dispatch,
  isList
}) => {
  const renderImage = () => {
    if (item.attachment) {
      switch (item.attachment.contentType) {
        case 'image/jpeg':
        case 'image/png': {
          const image = {
            url: BuildFileUrl(item.attachment._id),
            color: '#fff'
          }
          return <CardImage ratio={0} image={image} isFeed={isFeed} />
        }
        case 'video/mp4': {
          const image = BuildFileUrl(item.attachment._id)
          return (
            <VideoBox2 isFeed={isFeed}>
              <img
                src={IconPlay}
                alt='video'
                style={{position: 'absolute'}}
              />
              <video
                style={{
                  width: '100%'
                }}
              >
                <source src={image} type='video/mp4' />
                <track default kind='captions' src={UserDefault} />
                Sorry, your browser does not support embedded videos.
              </video>
            </VideoBox2>
          )
        }
        default:
          return null
      }
    } else {
      const image = {
        url: BuildFileUrl(
          item.kudos && item.kudos.image && item.kudos.image.file
        ),
        color: '#fff'
      }
      return <CardImage ratio={0} image={image} isFeed={isFeed} />
    }
  }

  const renderTitle = (selected = {}) => {
    let title
    if (selected.kudos) {
      title = GetLanguageValue(selected.kudos.title, activeLanguage.code)
    }
    if (selected.message) title = selected.message
    return <KudosMessage>{title}</KudosMessage>
  }

  return (
    <CardContainer
      marginH={marginH}
      isFeed={isFeed}
      isShare={isShare}
      style={{flex: isFeed ? 1 : 0}}
      onClick={() =>
        onPressItem
          ? onPressItem()
          : dispatch(
              updateModal({
                type: 'kudos',
                object: item,
                isShare,
                isFeed
              })
            )
      }
    >
      {!isList && (
        <CardTask style={{marginBottom: 0}}>
          <CardTaskText>{translate('KUDOS')}</CardTaskText>
          {onClose && (
            <ClickableBox
              onClick={(e) => {
                e.stopPropagation()
                onClose(item)
              }}
            >
              <img src={CloseIcon} alt='close' />
            </ClickableBox>
          )}
        </CardTask>
      )}
      <WhiteLayout
        space='0px'
        spaceB={isShare || isFeed ? 0 : 10}
        style={{
          backgroundColor: isShare ? 'transparent' : white,
          position: 'relative'
        }}
      >
        <PeerBlockBox>
          <PeerBlockComponent
            noPadding
            item={item.from}
            bgColor='transparent'
            color={white}
          />
          {!isShare && (
            <CardTimeHeader isAbsolute color={white}>
              {moment(item.createdAt).fromNow()}
            </CardTimeHeader>
          )}
        </PeerBlockBox>
        {renderImage()}
        <ShadowKudos />
        {renderTitle(item)}
      </WhiteLayout>
    </CardContainer>
  )
}

KudosCard.propTypes = {
  item: PropTypes.object.isRequired,
  onPressItem: PropTypes.func,
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  isShare: PropTypes.bool,
  isFeed: PropTypes.bool,
  marginH: PropTypes.number,
  isList: PropTypes.bool
}

KudosCard.defaultProps = {
  onPressItem: undefined,
  onClose: undefined,
  isShare: false,
  isFeed: false,
  marginH: 0,
  isList: false
}

const mapStateToProps = (state) => ({
  activeJourney: state.user.activeJourney
})

const mapActionToProps = (dispatch) => ({
  dispatch
})
export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(KudosCard)
)
