import React, {Component} from 'react'

import {Row, Col} from 'antd'
import {PropTypes} from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {ReactComponent as CloseIcon} from '../../assets/icons/ic_close_blue.svg'
import {GetReceivedKudos} from '../../infra/requests/ProfileRequests'
import {InformationTitle} from '../../pages/active_journey/ActiveJourneyStyles'
import {LoadingSpin} from '../../styles/BasicStyles'
import KudosCard from '../cards/KudosCard'
import {Modal} from '../modal/BaseModalStyles'

const limit = 10

class KudosListModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      kudos: [],
      page: 1,
      total: undefined
    }
  }

  componentDidMount() {
    this.GetKudos()
  }

  GetKudos = async () => {
    const {page, kudos} = this.state

    try {
      const result = await GetReceivedKudos(page, limit)
      if (result.success) {
        this.setState({
          kudos: [...kudos, ...result.data.items],
          page: page + 1,
          total: result.data.total
        })
      }
    } catch (error) {
      console.warn(error)
    }
  }

  render() {
    const {kudos, total} = this.state
    const {translate, open, onClose, width} = this.props

    return (
      <Modal
        padding='20px'
        visible={open}
        onCancel={onClose}
        width={width}
        footer={null}
        closeIcon={<CloseIcon />}
      >
        <Row>
          <Col xs={24}>
            <InformationTitle>{translate('KUDOS_LIST')}</InformationTitle>
          </Col>
          <Col xs={24}>
            <Row gutter={10}>
              <div
                style={{
                  width: '100%',
                  height: Math.round(parseFloat(kudos.length / 2)) * 198,
                  overflow: 'hidden'
                }}
              >
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.GetKudos}
                  hasMore={total > kudos.length}
                  loader={<LoadingSpin min='200px' />}
                  useWindow={false}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexFlow: 'row wrap'
                  }}
                >
                  {kudos.map((kudo, index) => (
                    <Col xs={12} key={index}>
                      <KudosCard item={kudo} isFeed isShare isList />
                    </Col>
                  ))}
                </InfiniteScroll>
              </div>
            </Row>
          </Col>
        </Row>
      </Modal>
    )
  }
}

KudosListModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  width: PropTypes.number,
  translate: PropTypes.func.isRequired
}

KudosListModal.defaultProps = {
  width: 500
}

const mapActionToProps = (dispatch) => ({dispatch})

export default withLocalize(
  connect(() => ({}), mapActionToProps)(KudosListModal)
)
