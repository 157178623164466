import React from 'react'

import PropTypes from 'prop-types'

import IconDrag from '../../assets/icons/ic_draganddrop.svg'
import {white, DarkGey, lightBlue2} from '../../styles/_theme'
import {MainText} from '../../styles/BasicStyles'
import {
  StepsInputContainer,
  IndexBlock,
  InputView,
  TextInputField,
  StepsRightBtn
} from './InputStyles'

const AddStepsInput = ({
  placeholder,
  meta,
  input,
  onEnter,
  hasTopSpace,
  onPressIcon,
  icon,
  hasError,
  autoFocus,
  index,
  isEmail,
  isNew,
  onChange
}) => {
  const {invalid, submitFailed} = meta
  const error = (invalid && submitFailed) || (hasError && submitFailed)

  return (
    <StepsInputContainer
      isNew={isNew}
      isEmail={isEmail}
      hasTopSpace={hasTopSpace}
      className={isEmail ? 'isEmail' : isNew ? 'isNew' : 'base'}
    >
      {!isEmail && (
        <IndexBlock
          isNew={isNew}
          className={isEmail ? 'isEmail' : isNew ? 'isNew' : 'base'}
        >
          <MainText color={white}>{index + 1}</MainText>
        </IndexBlock>
      )}
      <div style={{flex: 1}}>
        <InputView>
          <TextInputField
            autoFocus={autoFocus}
            hasError={error}
            onChange={(i) => {
              input.onChange(i)
              onChange()
            }}
            placeholder={placeholder}
            value={input.value}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault()
            }}
            color={
              isEmail && isNew
                ? DarkGey
                : isEmail
                ? white
                : isNew
                ? DarkGey
                : white
            }
          />
          {icon && (
            <StepsRightBtn
              onClick={
                onPressIcon ||
                ((e) => {
                  e.stopPropagation()
                  onEnter(input.value)
                })
              }
            >
              {icon}
            </StepsRightBtn>
          )}
        </InputView>
      </div>
      {!isEmail && !isNew && (
        <img
          src={IconDrag}
          alt='IconDrag'
          style={{
            padding: 7,
            backgroundColor: lightBlue2
          }}
        />
      )}
    </StepsInputContainer>
  )
}

AddStepsInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  onEnter: PropTypes.func,
  placeholder: PropTypes.string,
  hasTopSpace: PropTypes.bool,
  onPressIcon: PropTypes.func,
  icon: PropTypes.object,
  hasError: PropTypes.bool,
  autoFocus: PropTypes.bool,
  index: PropTypes.number,
  isEmail: PropTypes.bool,
  isNew: PropTypes.bool,
  onChange: PropTypes.func
}

AddStepsInput.defaultProps = {
  onEnter: undefined,
  placeholder: undefined,
  hasTopSpace: false,
  onPressIcon: undefined,
  icon: undefined,
  hasError: false,
  autoFocus: false,
  index: undefined,
  isEmail: false,
  isNew: false,
  onChange: () => {}
}

export default AddStepsInput
