import styled from 'styled-components'

import {
  white,
  LightGey,
  ThirdColor,
  DarkGey,
  lightBlue,
  BorderColor
} from '../../styles/_theme'
import ImageComponent from '../images/ImageComponent'

export const CardContainer = styled.div`
  margin-bottom: ${({isFeed}) => (isFeed ? 8 : 30)}px;
  background-color: ${({$color}) => $color || 'white'};
  position: relative;
  min-height: ${({isShare, isFeed}) =>
    isShare ? 80 : isFeed ? 190 : 140}px;
  border-radius: ${({library}) => (library ? 0 : 4)}px;
  overflow: hidden;
  cursor: pointer;
  height: ${({isFeed}) => (isFeed ? 'auto' : '100%')};

  &:hover,
  &:visited,
  &:active {
    background-color: ${lightBlue};
  }
`
export const CardImage = styled(ImageComponent)`
  width: 100%;
  height: ${({isFeed}) => (isFeed ? 190 : 140)}px;
  background-color: ${LightGey};
`

export const LessonPhoto = styled(ImageComponent)`
  width: 100%;
  height: ${({isShare}) => (isShare ? '140px' : '273px')};
  background-color: ${BorderColor};
  transition: all 0.3s ease;

  &:hover,
  &:visited,
  &:active {
    transform: scale(1.2);
  }
`

export const CardTimeHeader = styled.div`
  position: ${({isAbsolute}) => (isAbsolute ? 'absolute' : 'relative')};
  width: 100%;
  min-height: 30px;
  padding: 6px 10px;
  top: 0px;
  z-index: 1;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({color}) => color || DarkGey};
  text-align: right;
`

export const CardTask = styled.div`
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
  width: 100%;
  height: 38px;
  background-color: ${ThirdColor};
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${white};
  overflow: hidden;
  display: -webkit-box;
`

export const CardTaskText = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const CardTop = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden;
`

export const Shadow = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  box-shadow: rgb(0, 0, 0) 0px 50px 40px -40px inset;
`

export const CardBottom = styled.div`
  z-index: -1;
  background-color: ${LightGey};
  height: 19px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`

export const KudosCardImage = styled.div`
  height: 140px;
  width: 100%;
  resize-mode: cover;
  margin-top: 10px;
`

export const PeerBlockBox = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  padding-top: 6px;
  width: 100%;
  box-shadow: rgb(0, 0, 0) 0px 60px 25px -45px inset;
`

export const H5PLesson = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;

  &&& iframe {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
  }

  &&& .H5PTop {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding-horizontal: 10px;
    z-index: 10;
    display: flex;
  }
`
