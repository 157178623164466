import moment from 'moment'

const InitialJourneyData = {
  title: '',
  intention: '',
  review_weeks: 4,
  type: 'custom',
  reminder: {
    time: moment().hours(10).minutes(30).seconds(0)
.milliseconds(0),
    days: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']
  },
  privacy: {
    content: true,
    engagement: true,
    progress: true,
    network: true
  }
}
export default InitialJourneyData
