import {createReducer} from '@reduxjs/toolkit'

import {updateCategory, updateInfo, removeCategory} from './InfoActions'

const initialState = {
  ready: false,
  data: {}
}

const InfoReducer = createReducer(initialState, {
  [updateInfo]: (state, action) => action.payload,
  [updateCategory]: ({data}, action) => {
    data.categories.push(action.payload)
  },
  [removeCategory]: ({data}, action) => {
    const result = data.categories.filter((c) => c._id !== action.payload)
    data.categories = result
  }
})

export default InfoReducer
