import React from 'react'

import PropTypes from 'prop-types'
import {FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'

import CheckIcon from '../../assets/icons/ic_check.svg'
import {GetValuePreview} from '../../infra/utils/CommonFunctions'
import {WhiteLayout} from '../../styles/BasicStyles'
import {JourneyIntroductionText} from '../journeys/JourneyStyles'
import {
  LineSection,
  SelectCircle,
  SelectContainer,
  SmallText,
  StepsText
} from './CreateJourneyStyles'
import CustomSteps from './CustomSteps'
import Wizard from './Wizard'

const JourneyStep3 = ({translate, activeLanguage, journey, edit}) => {
  const type = edit ? journey.type : journey ? 'guided' : 'custom'
  const onPressItem = (fields, idStep, form) => {
    const selected = fields || []
    const exist = selected.findIndex((id) => idStep === id)
    let changes = [...selected]
    if (exist >= 0) {
      changes = fields.filter((id) => idStep !== id)
    } else {
      changes.push(idStep)
    }
    form.change('ignored', changes)
  }

  const stepsArray = (input) => {
    const {steps, form} = input
    const currentSteps = input.fields.value || []

    return (
      <>
        {steps.map((step, index) => (
          <LineSection
            key={index}
            isSelected
            onClick={() =>
              step.required
                ? {}
                : onPressItem(currentSteps, step._id, form)
            }
          >
            <StepsText>
              {GetValuePreview(
                step.title,
                activeLanguage && activeLanguage.code
              )}
              {step.required && (
                <SmallText>{translate('MANDATORY')}</SmallText>
              )}
            </StepsText>
            {!step.required && (
              <SelectContainer>
                {currentSteps &&
                currentSteps.findIndex((a) => step._id === a) >= 0 ? (
                  <SelectCircle />
                ) : (
                  <SelectCircle isSelected>
                    <img src={CheckIcon} alt='CheckIcon' />
                  </SelectCircle>
                )}
              </SelectContainer>
            )}
          </LineSection>
        ))}
      </>
    )
  }

  return (
    <>
      {!(type === 'guided' && edit) && (
        <Wizard.Page>
          <WhiteLayout space='40px'>
            {type === 'guided' ? (
              <JourneyIntroductionText spaceB={30}>
                {translate('INTRODUCTION_JOURNEY_STEP3')}
              </JourneyIntroductionText>
            ) : (
              <JourneyIntroductionText spaceB={30}>
                {translate('INTRODUCTION_JOURNEY_STEP3_CUSTOM')}
              </JourneyIntroductionText>
            )}
            <FormSpy subscription={{values: true}}>
              {({form}) =>
                type === 'guided' ? (
                  <FieldArray
                    name='ignored'
                    component={stepsArray}
                    steps={journey.steps}
                    form={form}
                    edit={edit}
                  />
                ) : (
                  <FieldArray
                    name='steps'
                    component={CustomSteps}
                    form={form}
                    edit={edit}
                  />
                )
              }
            </FormSpy>
          </WhiteLayout>
        </Wizard.Page>
      )}
    </>
  )
}

JourneyStep3.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  journey: PropTypes.object,
  edit: PropTypes.bool
}

JourneyStep3.defaultProps = {
  journey: undefined,
  edit: false
}

export default withLocalize(JourneyStep3)
