import React, {Component} from 'react'

import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import SendIcon from '../../assets/icons/ic_send.svg'
import {
  GetPrivateMessages,
  GetChannelMessages,
  GetGeneralMessages,
  SendPrivateMessage,
  SendChannelMessage,
  SendGeneralMessage,
  GetPeer,
  GetChannelInfo,
  GetGeneralInfo
} from '../../infra/requests/CommunityRequests'
import ChatChannelService from '../../infra/services/socket/ChatChannelService'
import {
  ChannelName,
  ChannelMessage,
  PrivateName,
  GeneralName,
  GeneralMessage,
  PrivateMessage
} from '../../infra/services/socket/SocketFunctions'
import FormValidator from '../../infra/services/validations/FormValidator'
import {updateChat} from '../../redux/data/chat/ChatActions'
import {removeNotification} from '../../redux/data/user/UserActions'
import {GetAndUpdateChats} from '../../redux/thunks/UpdateThunk'
import {red} from '../../styles/_theme'
import {PageForm} from '../../styles/BasicStyles'
import ChatInput from '../input/ChatInput'
import {SmallText} from '../input/InputStyles'
import ChatMessageComponent from './ChatMessageComponent'
import {
  ChatFooter,
  ChatSendSection,
  SendButton,
  ChatMessages
} from './ChatStyles'

const ValidateMessage = FormValidator.make({
  message: 'required|min:1'
})

const limit = 30

class ChatComponent extends Component {
  constructor(props) {
    super(props)
    this.scrollMessages = null
    this.state = {
      isLoading: false,
      messages: [],
      page: 1,
      total: 0,
      error: false
    }
  }

  componentDidMount() {
    const {dispatch, chat, user} = this.props

    if (chat.type === 'private') this.GetPeer()
    else this.GetInfo()

    this.getMessages()

    this.chatService = ChatChannelService.create()
    this.chatService.join(
      chat.type === 'private'
        ? PrivateName(user._id, chat.user)
        : chat.type === 'channel'
        ? ChannelName(chat.channel)
        : GeneralName(chat.company)
    )
    this.chatService.onMessageReceived(
      chat.type === 'private'
        ? PrivateMessage(user._id, chat.user)
        : chat.type === 'channel'
        ? ChannelMessage(chat.channel)
        : GeneralMessage(chat.company),
      (message) => {
        const {messages} = this.state
        const exist = messages.find(
          (oldMessage) => oldMessage && oldMessage._id === message._id
        )
        if (!exist) this.setState({messages: [message, ...messages]})
      }
    )

    dispatch(
      removeNotification(
        chat.type === 'private'
          ? PrivateName(user._id, chat.user)
          : chat.type === 'channel'
          ? ChannelName(chat.channel)
          : GeneralName(chat.company)
      )
    )
  }

  componentWillUnmount() {
    const {chat, user} = this.props
    this.chatService.leave(
      chat.type === 'private'
        ? PrivateName(user._id, chat.user)
        : chat.type === 'channel'
        ? ChannelName(chat.channel)
        : GeneralName(chat.company)
    )
  }

  GetPeer = async () => {
    const {chat, dispatch} = this.props
    this.setState({isLoading: true})
    try {
      const result = await GetPeer(chat.user)
      if (result.success) {
        dispatch(updateChat({...chat, ...result.data}))
      }
    } catch (error) {
      console.warn(error)
    }
  }

  GetInfo = async () => {
    const {chat, dispatch} = this.props

    if (
      (chat.type === 'channel' && !chat.channel) ||
      (chat.type === 'general' && !chat.company)
    ) {
      return
    }
    this.setState({isLoading: true})
    try {
      const result = await (chat.type === 'channel'
        ? GetChannelInfo(chat.channel)
        : GetGeneralInfo(chat.company))
      if (result.success) {
        dispatch(updateChat({...chat, ...result.data}))
      }
    } catch (error) {
      console.warn(error)
    }
  }

  getMessages = async () => {
    const {chat} = this.props
    const {isLoading, total, messages, page} = this.state

    if (isLoading || (total && total <= messages.length)) return
    this.setState({isLoading: true})

    try {
      const result = await (chat.type === 'private'
        ? GetPrivateMessages(chat.user, page, limit)
        : chat.type === 'channel'
        ? GetChannelMessages(chat.channel, page, limit)
        : GetGeneralMessages(chat.company, page, limit))

      if (result.success) {
        this.setState({
          messages: [...messages, ...result.data.items],
          total: result.data.total,
          page: page + 1
        })
      }
    } catch (error) {
      console.warn(error)
    } finally {
      this.setState({isLoading: false})
    }
  }

  handleScroll = () => {
    if (
      this.scrollMessages &&
      this.scrollMessages.scrollHeight <=
        -this.scrollMessages.scrollTop + 800
    ) {
      this.getMessages()
    }
  }

  onSubmit = async (fields) => {
    const {chat, dispatch, translate} = this.props
    // const {messages} = this.state
    try {
      // if (fields.message) {
      //   let message = {
      //     message: fields.message,
      //     pending: true,
      //     _id: 'pending'
      //   }
      //   if (chat.type === 'channel') {
      //     message = {...message, channel: chat.channel, from: user}
      //   } else if (chat.type === 'private') {
      //     message = {...message, to: chat.user, from: user._id}
      //   } else message = {...message, company: chat.company, from: user}
      //   this.setState({messages: [message, ...messages]})
      // }
      const res = await (chat.type === 'private'
        ? SendPrivateMessage({
            ...fields,
            to: chat.user
          })
        : chat.type === 'channel'
        ? SendChannelMessage(chat.channel, fields)
        : SendGeneralMessage({
            ...fields,
            company: chat.company
          }))

      if (res.success) {
        const msgs = this.state.messages
        const exist = msgs.find(
          (oldMessage) => oldMessage && oldMessage._id === res.data._id
        )
        if (!exist) this.setState({messages: [res.data, ...msgs]})
        dispatch(GetAndUpdateChats())
      } else {
        console.log(res)
        this.setState({error: translate('CANNOT_SEND_MESSAGE')})
      }
    } catch (error) {
      console.warn(error)
    }
  }

  renderList = () => {
    const {messages} = this.state
    const {user} = this.props
    return messages.map((item, index) => {
      const isUser = !!(
        user._id === item.from || user._id === item.from?._id
      )
      return (
        <ChatMessageComponent
          key={index}
          index={index}
          item={item}
          isChannel
          isUser={isUser}
        />
      )
    })
  }

  render() {
    const {translate} = this.props
    const {error} = this.state
    return (
      <>
        <ChatMessages
          onScroll={this.handleScroll}
          ref={(ref) => {
            this.scrollMessages = ref
          }}
        >
          {this.renderList()}
        </ChatMessages>
        <ChatFooter>
          <Form onSubmit={this.onSubmit} validate={ValidateMessage}>
            {({handleSubmit, form}) => (
              <PageForm
                onSubmit={(event) => {
                  const promise = handleSubmit(event).then(() => {
                    form.reset()
                  })
                  return promise
                }}
              >
                {error && (
                  <SmallText style={{display: 'inline'}} color={red}>
                    {error}
                  </SmallText>
                )}
                <ChatSendSection>
                  <Field
                    component={ChatInput}
                    name='message'
                    placeholder={translate('CHAT_INPUT_PLACEHOLDER')}
                    onChange={() => this.setState({error: false})}
                  />
                  <SendButton type='submit'>
                    <img src={SendIcon} alt='SendIcon' />
                  </SendButton>
                </ChatSendSection>
              </PageForm>
            )}
          </Form>
        </ChatFooter>
      </>
    )
  }
}

ChatComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  chat: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  chat: state.chat,
  user: state.user
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(ChatComponent)
)
