import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import IconWarning from '../../assets/icons/ic_warning.svg'
import {
  StyledNotFoundContainer,
  StyledTitle,
  StyledDescription,
  StyledNotFoundGroup,
  StyledCompanyLogo
} from './NotFoundStyles'

const NotFound = ({translate}) => (
  <StyledNotFoundContainer>
    <StyledNotFoundGroup>
      <StyledCompanyLogo src={IconWarning} alt='warning' />
      <StyledTitle>{translate('PAGE_NOT_FOUND_TITLE')}</StyledTitle>
      <StyledDescription>
        {translate('PAGE_NOT_FOUND_DESCRIPTION')}
      </StyledDescription>
    </StyledNotFoundGroup>
  </StyledNotFoundContainer>
)

NotFound.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(NotFound)
