import styled from 'styled-components'

import {
  ThirdColor,
  white,
  mediumBlue,
  PrimaryColor
} from '../../styles/_theme'

export const LanguageContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`

export const CircleButton = styled.div`
  display: flex;
  cursor: pointer;
  margin-right: 14px;
  border: 1px solid;
  background: ${({selected}) => (selected ? ThirdColor : 'transparent')};
  border-color: ${({selected}) => (selected ? ThirdColor : mediumBlue)};
  height: 42px;
  width: 42px;
  border-radius: 21px;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  color: ${({selected}) => (selected ? white : ThirdColor)};
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-transform: uppercase;

  &:hover,
  &:visited,
  &:active {
    background-color: ${PrimaryColor};
    color: ${white};
  }
`
