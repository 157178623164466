import React, {useState, useEffect} from 'react'

import {Row, Col} from 'antd'
import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {
  GetJourneyValidation,
  JourneyValidation
} from '../../infra/requests/ActivityRequests'
import {GetValuePreview} from '../../infra/utils/CommonFunctions'
import {InformationTitle} from '../../pages/active_journey/ActiveJourneyStyles'
import {clearModal} from '../../redux/data/modal/ModalActions'
import {updateActiveJourney} from '../../redux/data/user/UserActions'
import {
  GetAndUpdateFeed,
  GetAndUpdateActivity
} from '../../redux/thunks/UpdateThunk'
import {DarkGey} from '../../styles/_theme'
import {WhiteLayout, PageForm, MainText} from '../../styles/BasicStyles'
import BaseButton from '../buttons/BaseButton'
import LikertRatingComponent from '../review_components/LikertRatingComponent'
import PointRatingComponent from '../review_components/PointRatingComponent'
import ReviewComponent from '../review_components/ReviewComponent'
import {
  Label,
  ReviewMainContainer,
  ReviewInputLabelContainer
} from '../review_components/ReviewStyles'
import StarRatingComponent from '../review_components/StarRatingComponent'
import JourneyValidationValidations from './validations/JourneyValidationValidations'

const ValidationDetail = ({
  translate,
  activeLanguage,
  journey,
  dispatch,
  currentValidation
}) => {
  const [loading, setLoading] = useState(false)
  const [validation, setValidation] = useState(
    currentValidation && typeof currentValidation === 'object'
      ? currentValidation
      : false
  )

  useEffect(() => {
    const getJourneyValidation = async () => {
      const {data, success} = await GetJourneyValidation({
        journey: currentValidation.journey
      })
      if (success) {
        setValidation(data)
      }
      setLoading(false)
    }

    if (!validation && !loading) getJourneyValidation()
  }, [validation, currentValidation, loading])

  const initialValues = {...validation}

  const onSubmit = async (fields) => {
    try {
      const params = {
        questions: fields.questions.map((q, index) => ({
          ...q,
          ...validation.questions[index]
        })),
        description: validation.description
      }

      const result = await JourneyValidation(validation._id, params)
      if (result.success) {
        if (journey?._id === validation.journey) {
          dispatch(
            updateActiveJourney({
              active_journey: {...journey, validation: result.data}
            })
          )
        }
        dispatch(GetAndUpdateActivity())
        dispatch(GetAndUpdateFeed())
        dispatch(clearModal())
      }
    } catch (e) {
      console.warn(e)
    }
  }

  return (
    <Row gutter={[12, 24]}>
      <Col xs={20}>
        <InformationTitle>
          {translate('JOURNEY_VALIDATION')}
        </InformationTitle>
      </Col>
      <Col xs={24}>
        <Row>
          {validation.completed ? (
            <WhiteLayout space=' 0px 30px 30px'>
              {validation.questions.map((question, i) => (
                <ReviewMainContainer key={i}>
                  <ReviewInputLabelContainer>
                    <Label>
                      {GetValuePreview(
                        question.title,
                        activeLanguage && activeLanguage.code
                      )}
                    </Label>
                  </ReviewInputLabelContainer>
                  {question.type === 3 ? (
                    <StarRatingComponent
                      noEditable
                      input={{value: question.response}}
                      spacet={14}
                    />
                  ) : question.type === 6 ? (
                    <PointRatingComponent
                      noEditable
                      input={{value: question.response}}
                      spacet={14}
                    />
                  ) : question.type === 7 ? (
                    <LikertRatingComponent
                      noEditable
                      input={{value: question.response}}
                      placeholder={question.placeholder}
                    />
                  ) : (
                    <MainText color={DarkGey}>
                      {question.response}
                    </MainText>
                  )}
                </ReviewMainContainer>
              ))}
            </WhiteLayout>
          ) : (
            <Form
              keepDirtyOnReinitialize
              onSubmit={onSubmit}
              mutators={{
                ...arrayMutators
              }}
              initialValues={initialValues}
              validate={JourneyValidationValidations}
            >
              {({handleSubmit, form, submitting, valid, dirty}) => (
                <PageForm
                  onSubmit={(event) => {
                    const promise = handleSubmit(event).then(() => {
                      form.reset()
                    })
                    return promise
                  }}
                >
                  <WhiteLayout space=' 0px 30px 30px'>
                    <FieldArray name='questions'>
                      {({fields}) =>
                        fields.map((item, index) => {
                          const question = fields.value[index]
                          return (
                            <Field
                              key={index.toString()}
                              name={`questions[${index}].response`}
                              refField={`questions${index}`}
                              label={GetValuePreview(
                                question.title,
                                activeLanguage && activeLanguage.code
                              )}
                              placeholder={
                                question.placeholder
                                  ? GetValuePreview(
                                      question.placeholder,
                                      activeLanguage && activeLanguage.code
                                    )
                                  : ''
                              }
                              component={ReviewComponent}
                              editable
                              reviewType={question.type.toString()}
                            />
                          )
                        })
                      }
                    </FieldArray>
                  </WhiteLayout>
                  <BaseButton
                    type='submit'
                    label={translate('SAVE')}
                    category='secondary'
                    style={{float: 'right', marginTop: 24}}
                    disabled={submitting || !valid || !dirty}
                  />
                </PageForm>
              )}
            </Form>
          )}
        </Row>
      </Col>
    </Row>
  )
}

ValidationDetail.propTypes = {
  translate: PropTypes.func.isRequired,
  journey: PropTypes.object,
  activeLanguage: PropTypes.object.isRequired,
  currentValidation: PropTypes.object,
  dispatch: PropTypes.func.isRequired
}

ValidationDetail.defaultProps = {
  journey: undefined,
  currentValidation: undefined
}
const mapStateToProps = (state) => ({
  journey: state.user.active_journey,
  info: state.info
})

const mapActionToProps = (dispatch) => ({dispatch})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(ValidationDetail)
)
