import React from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import UserDefault from '../../assets/default_user.png'
import LatestFeed from '../../components/sliders/LatestFeed'
import LatestKudos from '../../components/sliders/LatestKudos'
import SuggestedJourneys from '../../components/sliders/SuggestedJourneys'
import SuggestedLessons from '../../components/sliders/SuggestedLessons'
import {Margin} from '../../styles/BasicStyles'
import ActiveJourney from './ActiveJourney'
import Activity from './Activity'
import {
  DashboardContainer,
  UserPhoto,
  UserName,
  Message,
  DashboardRow,
  DashboardCol
} from './DashboardStyles'

const DashboardPage = ({user, router, translate}) => (
  <div
    style={{
      height: '100%',
      width: '100%',
      overflow: 'auto',
      display: 'flex',
      justifyContent: 'center'
    }}
  >
    <DashboardContainer>
      <Row gutter={16}>
        <Col xs={18}>
          <div
            style={{cursor: 'pointer'}}
            onClick={() => router.history.push('/settings')}
          >
            <UserPhoto
              width='100px'
              ratio={1}
              image={user.photo ? user.photo : {url: UserDefault}}
            />
          </div>
          <UserName>{user.name}</UserName>
          <Message>{translate('WELCOME_BACK')}</Message>
        </Col>
      </Row>
      <Margin size={30} />
      <Row gutter={16} style={{paddingBottom: 20}}>
        <Col xs={24} sm={12} lg={8}>
          <ActiveJourney journey={user.active_journey} router={router} />
        </Col>
        <DashboardCol xs={24} sm={12} lg={8}>
          <LatestKudos />
          <LatestFeed />
        </DashboardCol>
        <Col xs={24} sm={24} lg={8}>
          <DashboardRow gutter={{sm: 16, lg: 0}}>
            <Col xs={13} sm={12} lg={24}>
              <Activity />
            </Col>
            <DashboardCol xs={11} sm={12} lg={24}>
              <SuggestedLessons router={router} />
            </DashboardCol>
          </DashboardRow>
        </Col>
      </Row>
    </DashboardContainer>
  </div>
)

const mapStateToProps = (state) => ({
  user: state.user
})

DashboardPage.propTypes = {
  user: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(connect(mapStateToProps, null)(DashboardPage))
