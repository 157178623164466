import React from 'react'

import PropTypes from 'prop-types'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {ReactComponent as MinusIcon} from '../../assets/icons/ic_minus.svg'
import {ReactComponent as PlusIcon} from '../../assets/icons/ic_plus.svg'
import AddStepsInput from '../../components/input/AddStepsInput'
import {DefaultGey} from '../../styles/_theme'

const onDragEnd = (data, fields, form) => {
  if (!data.destination) return
  const newFields = [...fields]
  const fromIndex = data.source.index || 0
  const toIndex = data.destination.index || 0
  const moved = fields[fromIndex]
  newFields.splice(fromIndex, 1)
  newFields.splice(toIndex, 0, moved)
  form.change('steps', newFields)
}

const onAdd = async (value, input, form) => {
  if (!value || !value.trim().length) return
  const selected = input?.value || []
  const changes = [...selected]
  changes.push({
    name: value.trim()
  })
  form.change('steps', changes)
  form.change('addNew', '')
}

const CustomSteps = ({translate, form, fields}) => {
  const currentSteps = fields.value || []
  return (
    <>
      <DragDropContext
        onDragEnd={(data) => onDragEnd(data, currentSteps, form)}
      >
        <Droppable droppableId='droppableSteps'>
          {(provided) => (
            <div {...provided.props} ref={provided.innerRef}>
              {currentSteps.map((field, index) => (
                <Draggable
                  key={index}
                  draggableId={`step-${index}`}
                  index={index}
                >
                  {(p) => (
                    <div
                      ref={p.innerRef}
                      {...p.draggableProps}
                      {...p.dragHandleProps}
                    >
                      <Field
                        key={index}
                        data={field}
                        onEnter={() => {}}
                        component={AddStepsInput}
                        index={index}
                        onPressIcon={() => fields.remove(index)}
                        name={`steps[${index}].name`}
                        refField={`step_${index}_name`}
                        icon={<MinusIcon style={{alignSelf: 'center'}} />}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Field
        component={AddStepsInput}
        keyboardType='default'
        icon={<PlusIcon style={{alignSelf: 'center'}} />}
        iconColor={DefaultGey}
        isSecondary
        name='addNew'
        refField='addNew'
        onEnter={(value) => onAdd(value, fields, form)}
        placeholder={translate('SET_NEW_STEP_NAME_HERE')}
        returnKeyType='done'
        secureTextEntry={false}
        label={false}
        autoFocus
        blurOnSubmit={false}
        numberOfLines={1}
        index={currentSteps.length}
        isNew
      />
    </>
  )
}

CustomSteps.propTypes = {
  translate: PropTypes.func.isRequired,
  form: PropTypes.object,
  fields: PropTypes.object
}

CustomSteps.defaultProps = {
  form: undefined,
  fields: undefined
}

const mapStateToProps = (state) => ({
  journey: state.user.active_journey
})

export default withLocalize(connect(mapStateToProps)(CustomSteps))
