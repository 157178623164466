import React, {useState} from 'react'

import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import {withLocalize} from 'react-localize-redux'

import CloseButton from '../../assets/icons/ic_close_white.svg'
import Photo from '../../assets/icons/image.svg'
import {red} from '../../styles/_theme'
import {SmallText, LoadingSpin} from '../../styles/BasicStyles'
import {
  ContainerCircle,
  ImageContainer,
  Image,
  CloseBtn,
  PhotoContainer
} from './PhotoComponentStyles'

const PhotoComponent = ({
  onPressClose,
  image,
  translate,
  onSelectImage,
  loading
}) => {
  const [errorMessage, setErrorMessage] = useState()

  const handleImageDrop = (accepted, rejected) => {
    if (accepted.length <= 0 || (rejected && rejected.length > 0)) {
      if (rejected[0]?.size > 5000000) {
        return setErrorMessage(translate('ERROR_FILE_SIZE'))
      }
      return setErrorMessage(translate('ERROR_FILE'))
    }
    const type =
      accepted[0].type.indexOf('video') > -1
        ? 'video'
        : accepted[0].type.indexOf('image') > -1
        ? 'image'
        : 'file'

    const uploaded = {
      type,
      object: {
        type,
        path: accepted[0].path,
        name: accepted[0].name,
        preview: URL.createObjectURL(accepted[0])
      },
      file: accepted[0]
    }

    if (onSelectImage) onSelectImage(uploaded)
  }

  return (
    <>
      <Dropzone
        onDrop={handleImageDrop}
        style={{
          display: 'inline-block',
          float: 'right',
          marginBottom: '30px'
        }}
        accept='image/jpeg'
        maxSize={5000000}
      >
        {({getRootProps, getInputProps}) => (
          <ContainerCircle {...getRootProps()} style={{outline: 'none'}}>
            <input {...getInputProps()} />
            {image && !loading ? (
              <ImageContainer>
                <Image image={image} />
                <CloseBtn onClick={onPressClose}>
                  <img src={CloseButton} alt='CloseButton' />
                </CloseBtn>
              </ImageContainer>
            ) : (
              <PhotoContainer>
                {loading ? (
                  <LoadingSpin min='200px' />
                ) : (
                  <>
                    <img src={Photo} alt='profile img' />
                    {errorMessage && (
                      <SmallText color={red}>{errorMessage}</SmallText>
                    )}
                  </>
                )}
              </PhotoContainer>
            )}
          </ContainerCircle>
        )}
      </Dropzone>
    </>
  )
}

PhotoComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  image: PropTypes.object,
  onPressClose: PropTypes.func,
  onSelectImage: PropTypes.func,
  loading: PropTypes.bool
}

PhotoComponent.defaultProps = {
  image: undefined,
  onPressClose: undefined,
  onSelectImage: undefined,
  loading: false
}

export default withLocalize(PhotoComponent)
