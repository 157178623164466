import styled from 'styled-components'

import {
  white,
  LightGey,
  DarkGrey,
  SecondaryColor
} from '../../styles/_theme'
import ImageComponent from '../images/ImageComponent'

export const KudoContainer = styled.div`
  height: 172px;
  position: relative;
  cursor: pointer;
  width: 100%;
`

export const KudoText = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: ${white};
  position: absolute;
  bottom: 0;
  padding: 10px;
`

export const KudosImage = styled(ImageComponent)`
  width: 100%;
  height: 100%;
  background-color: ${LightGey};
`

export const ShadowKudos = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  box-shadow: rgb(0, 0, 0) 0px -60px 40px -40px inset;
`

export const KudosImageCard = styled(ImageComponent)`
  width: 100%;
  height: 100%;
  background-color: ${LightGey};
`

export const ListLabel = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 10px;
  color: ${DarkGrey};
`

export const SeeAllLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 10px;
  color: ${SecondaryColor};
`
