import styled from 'styled-components'

import {
  blackTwo,
  InputErrorColor,
  greyishBrown,
  DefaultGey,
  white,
  DarkGrey,
  DarkGey,
  ThirdColor,
  WarningColor,
  LightGey,
  lightBlue2,
  BorderColor
} from '../../styles/_theme'
import ImageComponent from '../images/ImageComponent'

export const ReviewMainContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  padding-horizontal: 11px;
  justify-content: center;
  align-items: center;
  flex: 1;
`

export const ReviewTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${({hasError}) => (hasError ? InputErrorColor : blackTwo)};
  max-width: 80%;
`

export const ReviewSubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0px;
  color: ${greyishBrown};
  margin-bottom: 15px;
`

export const StarRatingContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-bottom: ${({spaceb}) => spaceb || 0}px;
  justify-content: ${({isRight}) => (isRight ? 'flex-end' : 'flex-start')};
  margin-top: ${({spacet}) => spacet || 0}px;

  &:not(:focus-within):not(:hover) {
    svg path {
      fill: ${BorderColor};
    }
    svg.selected path {
      fill: ${ThirdColor};
    }
  }

  div,
  div:hover {
    svg path {
      fill: ${lightBlue2};
    }
  }

  div:hover ~ div {
    svg path {
      fill: ${BorderColor};
    }
  }
`
export const StarRatingContainerNoEditable = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-bottom: ${({spaceb}) => spaceb || 0}px;
  justify-content: ${({isRight}) => (isRight ? 'flex-end' : 'flex-start')};
  margin-top: ${({spacet}) => spacet || 0}px;
`

export const ReviewInputLabelContainer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`

export const TextSlider = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${DarkGrey};
  width: 20px;
  text-align: center;
  position: absolute;
`

export const LikertBtn = styled.div`
  width: ${({small}) => (small ? 20 : 22)}px;
  height: ${({small}) => (small ? 20 : 22)}px;
  background-color: ${white};
  border-radius: 11px;
  border: 1px solid;
  border-color: ${({color}) => color || DefaultGey};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;

  &:hover,
  &:visited,
  &:active {
    &.editable {
      background-color: ${lightBlue2};
    }
  }
`

export const LikertBtnIn = styled.div`
  width: ${({small}) => (small ? 12 : 14)}px;
  height: ${({small}) => (small ? 12 : 14)}px;
  background-color: ${ThirdColor};
  border-radius: 7px;
  align-items: center;
  justify-content: center;
`

export const LikertSlider = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({selected}) => (selected ? DarkGrey : DarkGey)};
  text-align: center;
  margin-top: 4px;
`

export const LikertContent = styled.div`
  flex-direction: column;
  align-items: center;
  margin-right: 9px;
  display: inline-block;
`

export const StarBtn = styled.div`
  margin-right: 10px;
  cursor: pointer;
`

export const Label = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: ${({hasError}) => (hasError ? WarningColor : DarkGrey)};
  margin: ${({margin}) => margin || '0px'};
`

export const PreviewImage = styled(ImageComponent)`
  width: 100%;
  height: 100%;
  ratio: 0;
  background-color: ${LightGey};
  cursor: pointer;
`

export const AttachBtn = styled.a`
  width: 100%;
  height: 70px;
  position: relative;
  background-color: ${LightGey};
  cursor: pointer;
  display: flex;
`

export const LinkContainer = styled.div`
  margin-bottom: 10px;
  height: 70px;
  background-color: ${LightGey};
`
