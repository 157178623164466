import {createReducer} from '@reduxjs/toolkit'

import {updateLatestKudos, clearLatestKudos} from './LatestKudosActions'

const initialState = []

const LatestKudosReducer = createReducer(initialState, {
  [updateLatestKudos]: (state, action) => action.payload,
  [clearLatestKudos]: () => initialState
})

export default LatestKudosReducer
