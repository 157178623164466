import {createReducer} from '@reduxjs/toolkit'

import {updateFeed, clearFeed} from './FeedActions'

const initialState = []

const FeedReducer = createReducer(initialState, {
  [updateFeed]: (state, action) => action.payload,
  [clearFeed]: () => initialState
})

export default FeedReducer
