import React from 'react'

import {Row, Col, Collapse} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {ReactComponent as ArrowIcon} from '../../assets/icons/ic_arrow.svg'
import ProgressBarComponent from '../../components/progress_bar/ProgressBarComponent'
import {white, DarkGrey} from '../../styles/_theme'
import {
  InformationTitle,
  CustomStepHeader,
  IndexBlock,
  CustomStepInfo
} from './ActiveJourneyStyles'
import CustomStep from './CustomStep'

const {Panel} = Collapse

const renderHeader = (step, index, noArrow) => (
  <CustomStepHeader isCompleted={step.completion === 100} key={index}>
    <IndexBlock isCompleted={step.completion === 100}>
      {index + 1}
    </IndexBlock>
    <CustomStepInfo>
      {step.name}
      <ProgressBarComponent
        progress={`${step.completion}%`}
        borderRadius={13}
        width={null}
        height={18}
        color={white}
        unfilledColor='rgba(227, 227, 227, 0.4)'
        maxWidth={220}
        marginT={16}
        unfilledTextColor={white}
        filledTextColor={DarkGrey}
      />
    </CustomStepInfo>
    {!noArrow && <ArrowIcon style={{alignSelf: 'center', margin: 16}} />}
  </CustomStepHeader>
)

const CustomSteps = ({translate, activeJourney, completedJourney}) => {
  const journey = completedJourney || activeJourney

  return (
    <Row gutter={[12, 24]}>
      <Col xs={24}>
        <InformationTitle>{translate('STEPS')}</InformationTitle>
      </Col>
      <Col xs={24}>
        {completedJourney ? (
          <>
            {journey.steps.map((step, index) => (
              <div style={{marginBottom: 8}}>
                {renderHeader(step, index, true)}
              </div>
            ))}
          </>
        ) : (
          <Collapse accordion bordered={false} ghost>
            {journey.steps.map((step, index) => (
              <Panel
                header={renderHeader(step, index)}
                key={index}
                showArrow={false}
              >
                {!completedJourney && <CustomStep step={step} />}
              </Panel>
            ))}
          </Collapse>
        )}
      </Col>
    </Row>
  )
}

CustomSteps.propTypes = {
  translate: PropTypes.func.isRequired,
  activeJourney: PropTypes.object,
  completedJourney: PropTypes.object
}

CustomSteps.defaultProps = {
  activeJourney: undefined,
  completedJourney: undefined
}

const mapStateToProps = (state) => ({
  activeJourney: state.user.active_journey
})

export default withLocalize(connect(mapStateToProps)(CustomSteps))
