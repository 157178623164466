import React, {Component} from 'react'

import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import {FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import InfiniteScroll from 'react-infinite-scroller'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {
  GetChannelPeersList,
  InviteUsers
} from '../../infra/requests/CommunityRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {updateChat} from '../../redux/data/chat/ChatActions'
import {LightGey} from '../../styles/_theme'
import {LoadingSpin} from '../../styles/BasicStyles'
import EmptyLayoutComponent from '../empty_layout/EmptyLayoutComponent'
import BaseModalForm from '../modal/BaseModalForm'
import PeerBlockComponent from '../peer_block/PeerBlockComponent'

const limit = 10

const ValidateMembers = FormValidator.make({
  users: 'array'
})

class AddMembersModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      peers: [],
      page: 1,
      total: undefined
    }
  }

  componentDidMount() {
    this.GetPeers()
  }

  GetPeers = async () => {
    const {page, peers, isLoading, total} = this.state
    const {chat} = this.props
    if (isLoading || total <= peers.length) return
    this.setState({isLoading: true})
    try {
      const result = await GetChannelPeersList(chat.channel, page, limit)
      this.setState({isLoading: false})
      if (result.success) {
        this.setState({
          peers: [...peers, ...result.data.items],
          page: page + 1,
          total: result.data.total
        })
      }
    } catch (error) {
      console.warn(error)
    }
  }

  onSubmit = async (fields) => {
    const {chat, dispatch, onClose} = this.props
    this.setState({isLoading: true})
    try {
      const result = await InviteUsers(chat.channel, fields)
      if (result.success) {
        dispatch(updateChat({...chat, ...result.data}))
        onClose()
      }
    } catch (error) {
      console.warn(error)
    } finally {
      this.setState({isLoading: false})
    }
  }

  onPressListItem = (peer, values, form) => {
    const selected = values.users || []
    let changes = [...selected]
    const exist = changes.findIndex((u) => peer._id === u._id)

    if (exist >= 0) {
      changes = values.users.filter((u) => peer._id !== u._id)
    } else {
      changes.push(peer)
    }
    form.change('users', changes)
  }

  render() {
    const {peers, total} = this.state
    const {open, onClose, translate} = this.props
    return (
      <BaseModalForm
        open={open}
        onClose={onClose}
        title={translate('ADD_MEMBER_GROUP')}
        onSubmit={this.onSubmit}
        validate={ValidateMembers}
        okText={translate('SAVE')}
        mutators={{...arrayMutators}}
      >
        <FormSpy subscription={{values: true}}>
          {({values, form}) => (
            <>
              {peers && peers.length ? (
                <FieldArray name='users'>
                  {({fields}) => (
                    <div
                      style={{
                        height: peers.length * 68,
                        overflow: 'hidden'
                      }}
                    >
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={this.GetPeers}
                        hasMore={total > peers.length}
                        loader={<LoadingSpin min='200px' />}
                        useWindow={false}
                      >
                        {peers.map((item, index) => (
                          <PeerBlockComponent
                            key={index}
                            index={index}
                            item={item}
                            onPressItem={(peer) =>
                              this.onPressListItem(peer, values, form)
                            }
                            bgColor={LightGey}
                            selectedPeers={values.users || []}
                            marginB='6px'
                          />
                        ))}
                      </InfiniteScroll>
                    </div>
                  )}
                </FieldArray>
              ) : (
                <EmptyLayoutComponent tag='EMPTY_PEERS' />
              )}
            </>
          )}
        </FormSpy>
      </BaseModalForm>
    )
  }
}

AddMembersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  chat: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  chat: state.chat
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(AddMembersModal)
)
