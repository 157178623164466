import React from 'react'

import {Switch, Collapse} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {ReactComponent as IconDown} from '../../assets/icons/chevron_down_white.svg'
import {UpdatePermissions} from '../../infra/requests/ProfileRequests'
import {userSave} from '../../redux/data/user/UserActions'
import {
  Label,
  LineContainer,
  MenuTitle,
  LabelDescription
} from './SettingsStyles'

const {Panel} = Collapse

const renderHeader = (page, index, translate) => (
  <LineContainer
    bgColor='#3378C7'
    cursor='pointer'
    style={{marginBottom: 16}}
  >
    <Label color='#FFFFFF'>{translate(page.tag.toUpperCase())}</Label>
    <IconDown />
  </LineContainer>
)

const Privacy = ({translate, user, dispatch, activeLanguage, info}) => {
  const onChangeBadges = async () => {
    const result = await UpdatePermissions({
      profile_badges: !user.profile_badges
    })
    if (result.success) {
      dispatch(
        userSave({...user, profile_badges: result.data.profile_badges})
      )
    }
  }

  const onChangeKudos = async () => {
    const result = await UpdatePermissions({
      profile_kudos: !user.profile_kudos
    })
    if (result.success) {
      dispatch(
        userSave({...user, profile_kudos: result.data.profile_kudos})
      )
    }
  }

  return (
    <>
      <MenuTitle spaceT={30} spaceB={36}>
        {translate('SHOW_IN_PROFILE')}
      </MenuTitle>
      <LineContainer>
        <Label>{translate('SHOW_BADGES')}</Label>
        <Switch onChange={onChangeBadges} checked={user.profile_badges} />
      </LineContainer>
      <LineContainer spaceT={4}>
        <Label>{translate('SHOW_KUDOS')}</Label>
        <Switch onChange={onChangeKudos} checked={user.profile_kudos} />
      </LineContainer>
      <MenuTitle spaceT={56} spaceB={36}>
        {translate('PRIVACY')}
      </MenuTitle>
      <Collapse accordion bordered={false} ghost>
        {info.data.pages.map((page, index) => (
          <Panel
            header={renderHeader(page, index, translate)}
            key={index}
            showArrow={false}
          >
            <LabelDescription
              dangerouslySetInnerHTML={{
                __html: page?.content[activeLanguage?.code]
              }}
            />
          </Panel>
        ))}
      </Collapse>
    </>
  )
}

Privacy.propTypes = {
  translate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user,
  info: state.info
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(Privacy)
)
