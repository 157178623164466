/* eslint-disable camelcase */
/* eslint no-useless-escape: "off" */

import * as moment from 'moment'

import ErrorMessages from './FormRuleMessages'

class FormRules {
  static required(label, rule, value) {
    if (!value || value === '') {
      return `RULE_${label}_${rule}`.toUpperCase()
    }
    return undefined
  }

  static array(label, rule, value) {
    if (
      !Array.isArray(value) ||
      (Array.isArray(value) && value.length < 1)
    ) {
      return `RULE_${label}_${rule}_EMPTY`.toUpperCase()
    }
    return undefined
  }

  static repeat_password(label, rule, value, params, values) {
    if (value !== values.password) {
      const errorMessage = ErrorMessages.PASSWORD_MATCH
      return errorMessage
    }
    return undefined
  }

  static min(label, rule, value, params) {
    if (params.length > 0) {
      const minLength = params[0]
      if (!value || value.length < minLength) {
        return `RULE_${label}_${rule}_ERROR`.toUpperCase()
      }
    }
    return undefined
  }

  static max(label, rule, value = '', params) {
    if (params.length > 0) {
      const maxLength = params[0]
      if (value) {
        if (value.length > maxLength) {
          return `RULE_${label}_${rule}_ERROR`.toUpperCase()
        }
      }
    }
    return undefined
  }

  static email(label, rule, value) {
    const expression = /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i
    const isInvalid = expression.test(value) === false
    if (isInvalid) {
      return `RULE_${label}_INVALID_EMAIL`.toUpperCase()
    }
    return undefined
  }

  static date(label, rule, value) {
    const isInValid = moment(value).isValid() === false
    if (isInValid) {
      return `RULE_${label}_${rule}_INVALID`.toUpperCase()
    }
    return undefined
  }

  static noSpaces(label, rule, value) {
    if (!value) return `${label}_${rule}`.toUpperCase()
    if (value && value.indexOf(' ') >= 0) {
      return `RULE_${label}_${rule}`.toUpperCase()
    }
    return undefined
  }

  static domain(label, rule, value) {
    const expression = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i
    const isInvalid = expression.test(value) === false
    if (isInvalid) {
      return `RULE_${label}_${rule}_INVALID`.toUpperCase()
    }
    return undefined
  }
}

export default FormRules
