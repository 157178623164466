import React from 'react'

import {Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {SRLWrapper} from 'simple-react-lightbox'

import {ReactComponent as CloseIcon} from '../../assets/icons/ic_close_blue.svg'
import AttachmentsList from '../../components/attachments/AttachmentsList'
import BaseButton from '../../components/buttons/BaseButton'
import {H5P} from '../../components/modal/BaseModalStyles'
import ShareComponent from '../../components/share/ShareComponent'
import {TaskImage} from '../../components/task/TaskStyles'
import VideoPlayer from '../../components/video/VideoPlayer'
import {DeleteLesson} from '../../infra/requests/LibraryRequests'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {
  GetValuePreview,
  GetH5PRatio
} from '../../infra/utils/CommonFunctions'
import {CloseBtn} from '../active_journey/ActiveJourneyStyles'
import {
  LessonContent,
  LessonContainer,
  LessonBox,
  LessonTextContent,
  LessonTitle,
  LessonCategory,
  LessonDescription,
  LessonButton
} from './LessonPageStyles'

const LessonPage = ({translate, activeLanguage, user, router}) => {
  const location = useLocation()
  const {lesson, back} = location.state

  const removeLesson = async () => {
    try {
      const result = await DeleteLesson(lesson._id)
      if (result.success) {
        router.history.goBack()
      }
    } catch (error) {
      console.warn(error)
    }
  }

  const renderImage = () => {
    const {media} = lesson
    switch (media?.type) {
      case 'images':
        return (
          <TaskImage style={{height: 'auto', maxHeight: 500}}>
            <img
              src={media.file.url}
              style={{width: '100%'}}
              alt='media'
            />
          </TaskImage>
        )
      case 'videos': {
        const video = GetLanguageValue(media.file.url, activeLanguage.code)
        return <VideoPlayer video={video} height='500px' width='100%' />
      }
      case 'h5p':
      case 'embedded': {
        const file = GetLanguageValue(
          media.file.iframe,
          activeLanguage.code
        )
        const r = GetH5PRatio(file)
        return (
          <H5P
            style={{
              paddingTop: r.isPx || `${r}%`,
              overflow: 'hidden',
              position: 'relative'
            }}
            dangerouslySetInnerHTML={{
              __html: file
            }}
          />
        )
      }
      default:
        return false
    }
  }

  return (
    <LessonContent>
      <LessonContainer>
        <LessonBox>
          <Col xs={24}>
            <CloseBtn
              onClick={() =>
                back
                  ? router.history.goBack()
                  : router.history.push('/library')
              }
              showBg
              style={{
                width: 44,
                height: 44,
                float: 'right',
                marginBottom: 30,
                position: 'relative'
              }}
            >
              <CloseIcon />
            </CloseBtn>
          </Col>
          <SRLWrapper hideControlsAfter='100'>{renderImage()}</SRLWrapper>
          <LessonTextContent>
            <LessonTitle>
              {GetValuePreview(lesson.title, activeLanguage.code)}
            </LessonTitle>
            <LessonCategory>
              {GetValuePreview(lesson.category.name, activeLanguage.code)}
            </LessonCategory>
            {lesson?.content && (
              <LessonDescription
                dangerouslySetInnerHTML={{
                  __html: GetValuePreview(
                    lesson.content,
                    activeLanguage.code
                  )
                }}
              />
            )}
            {lesson?.attachments && lesson.attachments.length > 0 && (
              <AttachmentsList items={lesson.attachments} edit />
            )}
            {lesson.user === user._id && (
              <>
                <LessonButton>
                  <ShareComponent
                    item={{
                      _id: lesson._id,
                      media: lesson.media,
                      title: GetValuePreview(
                        lesson.title,
                        activeLanguage.code
                      ),
                      category: GetValuePreview(
                        lesson.category.name,
                        activeLanguage.code
                      ),
                      description: GetValuePreview(
                        lesson.content,
                        activeLanguage.code
                      ),
                      attachments: lesson.attachments
                    }}
                    type='lesson'
                    isCircle
                    style={{marginRight: 16}}
                    title={translate('SHARE_LESSON')}
                  />
                  <BaseButton
                    type='button'
                    label={translate('EDIT_LESSON')}
                    category='tertiary'
                    onClick={() =>
                      router.history.push(`/create-lesson/${lesson._id}`, {
                        lesson
                      })
                    }
                    style={{marginRight: 16}}
                  />
                  <BaseButton
                    type='button'
                    label={translate('DELETE_LESSON')}
                    category='error'
                    onClick={() => removeLesson(lesson)}
                  />
                </LessonButton>
              </>
            )}
          </LessonTextContent>
        </LessonBox>
      </LessonContainer>
    </LessonContent>
  )
}

LessonPage.propTypes = {
  translate: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(LessonPage))
