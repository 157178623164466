import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import UserDefault from '../../assets/default_user.png'
import GroupIcon from '../../assets/icons/group_icon.png'
import CheckIcon from '../../assets/icons/ic_check.svg'
import CheckGreenIcon from '../../assets/icons/ic_check_green.svg'
import KudosIcon from '../../assets/icons/ic_kudos.svg'
import {ReactComponent as TrashRedIcon} from '../../assets/icons/ic_trash_red.svg'
import XIcon from '../../assets/icons/ic_x.svg'
import {isEven} from '../../infra/utils/CommonFunctions'
import {ThirdColor} from '../../styles/_theme'
import {
  PeerPhoto,
  PeerDescription,
  PeerName,
  PeerInfo,
  PeerCompany,
  PeerJob,
  SelectContainer,
  SelectCircle,
  SelectContainerBtn,
  PeerSection,
  PeerSectionLine,
  PeerLine
} from './PeerBlockComponentStyles'

const PeerBlockComponent = ({
  item,
  index,
  onPressItem,
  selectedPeers,
  multicolor,
  isEmail,
  noPadding,
  bgColor,
  color,
  type,
  giveKudos,
  deletePeer,
  cancelPeer,
  acceptPeer,
  isInvited,
  marginB
}) => {
  let user = item
  if (isInvited) user = item.user
  const renderItem = () => (
    <PeerLine>
      <PeerPhoto
        image={
          type === 'isChannel'
            ? user.image
              ? user.image
              : {url: UserDefault}
            : type === 'isGeneral'
            ? {url: GroupIcon}
            : !isEmail
            ? user.photo
              ? user.photo
              : {url: UserDefault}
            : {url: UserDefault}
        }
      />
      <PeerDescription>
        <PeerName color={color}>
          {!isEmail
            ? type && type !== 'isPeer'
              ? `#${user.name}`
              : user.name
            : user}
        </PeerName>
        {!isEmail && !type && (
          <PeerInfo>
            {user.company?.name && (
              <PeerCompany>{user.company.name}</PeerCompany>
            )}
            <PeerJob color={color}>{user.email}</PeerJob>
          </PeerInfo>
        )}
      </PeerDescription>
      {selectedPeers && (
        <SelectContainer>
          {selectedPeers &&
          selectedPeers.find((a) => a._id === user._id) ? (
            <SelectCircle isSelected>
              <img src={CheckIcon} alt='CheckIcon' />
            </SelectCircle>
          ) : (
            <SelectCircle />
          )}
        </SelectContainer>
      )}
      {giveKudos && (
        <SelectContainerBtn
          onClick={(event) => {
            event.stopPropagation()
            giveKudos(user)
          }}
          bgColor={ThirdColor}
        >
          <img
            style={{width: 20, height: 20}}
            src={KudosIcon}
            alt='KudosIcon'
          />
        </SelectContainerBtn>
      )}
      {deletePeer && (
        <SelectContainerBtn
          onClick={(event) => {
            event.stopPropagation()
            deletePeer(user)
          }}
          bgColor='transparent'
        >
          <TrashRedIcon style={{width: 24, height: 24}} />
        </SelectContainerBtn>
      )}
      {acceptPeer && (
        <SelectContainerBtn
          onClick={(event) => {
            event.stopPropagation()
            acceptPeer(item)
          }}
          bgColor='transparent'
        >
          <img
            style={{width: 24, height: 24}}
            src={CheckGreenIcon}
            alt='CheckGreenIcon'
          />
        </SelectContainerBtn>
      )}
      {cancelPeer && (
        <SelectContainerBtn
          onClick={(event) => {
            event.stopPropagation()
            cancelPeer(item)
          }}
          bgColor='transparent'
        >
          <img style={{width: 24, height: 24}} src={XIcon} alt='XIcon' />
        </SelectContainerBtn>
      )}
    </PeerLine>
  )

  if (!user) return null
  return (
    <div key={!isEmail ? user._id : user}>
      {onPressItem ? (
        <PeerSection
          key={!isEmail ? user._id : user}
          onClick={() => onPressItem(isInvited ? item : user)}
          multicolor={multicolor}
          isOdd={!isEven(index)}
          bgColor={bgColor}
          marginB={marginB}
        >
          {renderItem()}
        </PeerSection>
      ) : (
        <PeerSectionLine
          noPadding={noPadding}
          color={bgColor}
          key={!isEmail ? user._id : user}
          multicolor={multicolor}
          isOdd={!isEven(index)}
          marginB={marginB}
        >
          {renderItem()}
        </PeerSectionLine>
      )}
    </div>
  )
}

PeerBlockComponent.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
    PropTypes.string
  ]).isRequired,
  index: PropTypes.number,
  onPressItem: PropTypes.func,
  selectedPeers: PropTypes.array,
  multicolor: PropTypes.bool,
  isEmail: PropTypes.bool,
  noPadding: PropTypes.bool,
  bgColor: PropTypes.string,
  type: PropTypes.string,
  giveKudos: PropTypes.func,
  deletePeer: PropTypes.func,
  cancelPeer: PropTypes.func,
  acceptPeer: PropTypes.func,
  isInvited: PropTypes.bool,
  marginB: PropTypes.string,
  color: PropTypes.string
}

PeerBlockComponent.defaultProps = {
  index: undefined,
  onPressItem: undefined,
  selectedPeers: undefined,
  multicolor: false,
  isEmail: false,
  noPadding: false,
  bgColor: undefined,
  type: undefined,
  giveKudos: undefined,
  deletePeer: undefined,
  cancelPeer: undefined,
  acceptPeer: undefined,
  isInvited: false,
  marginB: undefined,
  color: undefined
}

export default withLocalize(PeerBlockComponent)
