import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import {InputErrorColor, DarkGrey} from '../../styles/_theme'
import {
  DefaultInputContainer,
  DefaultCheckboxContainer,
  DefaultCheckboxInput,
  SmallText
} from './InputStyles'

const DefaultCheckbox = ({
  input,
  onPressLink,
  meta,
  translate,
  hasTopSpace
}) => {
  const {invalid, submitFailed} = meta
  const error = invalid && submitFailed
  return (
    <DefaultInputContainer hasTopSpace={hasTopSpace}>
      <DefaultCheckboxContainer
        onClick={() => input.onChange(!input.value)}
      >
        <DefaultCheckboxInput value={input.value} hasError={error} />
        <SmallText
          color={error ? InputErrorColor : DarkGrey}
          style={{
            lineHeight: 'inherit'
          }}
        >
          {translate('I_AGREE_WITH')}
        </SmallText>
      </DefaultCheckboxContainer>
      <SmallText
        cursor='pointer'
        color={error ? InputErrorColor : DarkGrey}
        style={{
          textDecorationLine: 'underline',
          lineHeight: 'inherit'
        }}
      >
        {translate('TERMS_AND_CONDITIONS')}
      </SmallText>
    </DefaultInputContainer>
  )
}

DefaultCheckbox.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  translate: PropTypes.func.isRequired,
  hasTopSpace: PropTypes.number
}

DefaultCheckbox.defaultProps = {
  input: {value: undefined, onChange: () => null},
  meta: {},
  hasTopSpace: undefined
}

export default withLocalize(DefaultCheckbox)
