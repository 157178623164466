import React from 'react'

import {PropTypes} from 'prop-types'

import {ReactComponent as CloseIcon} from '../../assets/icons/ic_close_blue.svg'
import {Modal, ModalHeader, ModalTitle} from './BaseModalStyles'

const BaseModalList = ({
  open,
  onClose,
  title,
  width,
  children,
  maskClosable
}) => (
  <Modal
    visible={open}
    onCancel={onClose}
    maskClosable={maskClosable}
    width={width}
    footer={null}
    closeIcon={<CloseIcon />}
  >
    {title && (
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
    )}
    {children}
  </Modal>
)

BaseModalList.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.elementType
  ]).isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
  maskClosable: PropTypes.bool
}

BaseModalList.defaultProps = {
  title: undefined,
  width: 500,
  maskClosable: false
}

export default BaseModalList
