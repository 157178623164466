import React from 'react'

import {PropTypes} from 'prop-types'

import {ChatInputStyled} from './InputStyles'

const ChatInput = ({input, type, placeholder, onChange}) => (
  <ChatInputStyled
    type={type}
    placeholder={placeholder}
    value={input.value}
    onChange={(i) => {
      input.onChange(i)
      if (onChange) onChange()
    }}
    rows={1}
    autoSize={{minRows: 1, maxRows: 6}}
  />
)

ChatInput.propTypes = {
  input: PropTypes.object,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func
}

ChatInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  placeholder: undefined,
  type: 'text',
  onChange: undefined
}

export default ChatInput
