import {createReducer} from '@reduxjs/toolkit'

import {
  userSave,
  userEdit,
  userDelete,
  removeNotification,
  addNotification,
  updateActiveJourney,
  updateTask,
  updateTaskReflection,
  updateReflection,
  newReflection,
  updatePeersEngaged,
  updateFeedbacksRequested,
  toggleBookmark,
  toggleBookmarkTask
} from './UserActions'

const initialState = {}

const calcCompletionResult = (result, state) => {
  let totalTasks = 0
  let completion = 0
  result.guided_steps.map((step) => {
    if (step.ignored) return
    step.tasks.map((task) => {
      totalTasks++
      if (task && task.response && task.response.completed) {
        completion++
      }
      return task
    })
  })
  completion = ((completion / totalTasks) * 100).toFixed()
  state.completion = completion
  const journey = {...result, completion}
  return journey
}

const updateAJTask = (state, id, response) => {
  const result = {
    ...state,
    guided_steps: state.guided_steps.map((step) => {
      step.tasks.map((task) => {
        if (task._id === id) task.response = response
        return task
      })
      return step
    }),
    bookmarks: state.bookmarks.map((bookmark) => {
      if (bookmark.type === 'assignment' && bookmark.object._id === id) {
        bookmark.object.response = response
      }
      if (
        bookmark.type === 'reflection' &&
        bookmark.object &&
        bookmark.object._id === id
      ) {
        bookmark.object.response = response
      }
      return bookmark
    })
  }
  return calcCompletionResult(result, state)
}

const updateAJTaskReflection = (state, id, task) => {
  if (task.response.type === 'reflection' && task.response.completed) {
    if (
      state.reflections.find(
        (reflection) => reflection._id === task.response._id
      )
    ) {
      const reflections = state.reflections.filter(
        (reflection) => reflection._id !== task.response._id
      )
      reflections.unshift(task.response)
      state.reflections = reflections
    } else {
      state.reflections.unshift(task.response)
    }
  }

  const result = {
    ...state,
    guided_steps: state.guided_steps.map((step) => {
      step.tasks.map((t) => {
        if (t._id === id) t.response = task.response
        return t
      })
      return step
    }),
    bookmarks: state.bookmarks.map((bookmark) => {
      if (bookmark.type === 'assignment' && bookmark.object._id === id) {
        bookmark.object.response = task.response
      }
      if (
        bookmark.type === 'reflection' &&
        bookmark.object &&
        bookmark.object._id === task.response._id
      ) {
        bookmark.object = task.response
      }
      return bookmark
    })
  }

  return calcCompletionResult(result, state)
}

const updateReflections = (state, reflection) => {
  const result = state.reflections.filter(
    (currentReflection) => currentReflection._id !== reflection._id
  )
  result.unshift(reflection)
  state.reflections = result
  return {
    ...state,
    // reflections: [reflection, ...result],
    bookmarks: state.bookmarks.map((bookmark) => {
      if (
        bookmark.type === 'reflection' &&
        bookmark.object._id === reflection._id
      ) {
        bookmark.object = reflection
      }
      return bookmark
    })
  }
}

const ToggleBookmark = (state, type, object) => {
  if (object.object.bookmarked) state.bookmarks.unshift(object)
  else {
    const bookmarks = state.bookmarks.filter(
      (bookmark) => bookmark.object._id !== object.object._id
    )
    state.bookmarks = bookmarks
  }

  let result = {
    ...state,
    [type]: state[type].map((data) => {
      if (data._id === object.object._id) {
        data.bookmarked = !data.bookmarked
      }
      return data
    })
  }
  if (object.object.task && type === 'feedbacks') {
    result = {
      ...result,
      guided_steps: state.guided_steps.map((step) => {
        step.tasks.map((task) => {
          if (task.response) {
            task.response.feedbacks.map((feedback) => {
              if (feedback && feedback._id === object.object._id) {
                feedback.bookmarked = !feedback.bookmarked
              }
              return feedback
            })
          }
          return task
        })
        return step
      })
    }
  }

  return result
}

const ToggleBookmarkTask = (state, object) => {
  if (object.object.response.bookmarked) {
    if (object.object.response.type === 'reflection') {
      state.bookmarks.unshift({
        type: object.type,
        object: {...object.object.response}
      })
    } else state.bookmarks.unshift(object)
  } else {
    const bookmarks = state.bookmarks.filter(
      (bookmark) => bookmark.object._id !== object.object._id
    )
    state.bookmarks = bookmarks
  }

  const result = {
    ...state,
    guided_steps: state.guided_steps.map((step) => {
      step.tasks.map((task) => {
        if (task.response && task.response._id === object.object._id) {
          task.response.bookmarked = !task.response.bookmarked
        }
        if (
          task.response &&
          task.type === 'reflection' &&
          task._id === object.object._id
        ) {
          task.response.bookmarked = object.object.response.bookmarked
        }
        return task
      })
      return step
    }),
    reflections: state.reflections.map((reflection) => {
      if (
        object.type === 'reflection' &&
        reflection &&
        reflection._id === object.object._id
      ) {
        reflection.bookmarked = object.object.response.bookmarked
      }

      return reflection
    })
  }
  return result
}

const UserReducer = createReducer(initialState, {
  [userSave]: (state, action) => action.payload,
  [userEdit]: (state, action) => ({...state, ...action.payload}),
  [userDelete]: () => initialState,
  [removeNotification]: (state, action) => ({
    ...state,
    notifications: state.notifications.filter(
      (notification) =>
        notification.tag
          .toUpperCase()
          .indexOf(action.payload.toUpperCase()) === -1
    )
  }),
  [addNotification]: (state, action) => ({
    ...state,
    notifications: [action.payload, ...state.notifications]
  }),
  [updateActiveJourney]: (state, action) => ({
    ...state,
    active_journey: action.payload
  }),
  [updateTask]: ({active_journey}, action) => {
    active_journey = updateAJTask(
      active_journey,
      action.payload.id,
      action.payload.data
    )
  },
  [updateTaskReflection]: ({active_journey}, action) => {
    active_journey = updateAJTaskReflection(
      active_journey,
      action.payload.id,
      action.payload.data
    )
  },
  [updateReflection]: ({active_journey}, action) => {
    active_journey = updateReflections(active_journey, action.payload)
  },
  [newReflection]: ({active_journey}, action) => {
    active_journey.reflections.unshift(action.payload)
  },
  [updatePeersEngaged]: ({active_journey}, action) => {
    action.payload.map((peer) => {
      if (peer._id) {
        if (
          !active_journey.peers_engaged.find(
            (existPeer) => existPeer === peer._id
          )
        ) {
          active_journey.peers_engaged.push(peer._id)
        }
      } else if (
        !active_journey.emails_engaged.find(
          (existPeer) => existPeer === peer
        )
      ) {
        active_journey.emails_engaged.push(peer)
      }
      return peer
    })
  },
  [updateFeedbacksRequested]: ({active_journey}, action) => {
    active_journey = {
      ...active_journey,
      feedbacks_requested: active_journey.feedbacks_requested
        ? active_journey.feedbacks_requested + action.payload
        : action.payload
    }
  },
  [toggleBookmark]: ({active_journey}, action) => {
    active_journey = ToggleBookmark(
      active_journey,
      action.payload.type,
      action.payload.object
    )
  },
  [toggleBookmarkTask]: ({active_journey}, action) => {
    active_journey = ToggleBookmarkTask(active_journey, action.payload)
  }
})

export default UserReducer
