import React, {useState, useEffect} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useLocation} from 'react-router-dom'
import {SRLWrapper} from 'simple-react-lightbox'

import DefaultImg from '../../assets/default_custom.png'
import {ReactComponent as CloseIcon} from '../../assets/icons/ic_close_blue.svg'
import NoJourneyImg from '../../assets/no_journey.png'
import BookmarksList from '../../components/bookmarks/BookmarksList'
import BaseButton from '../../components/buttons/BaseButton'
import FeedbacksList from '../../components/feedbacks/FeedbacksList'
import ProgressBarComponent from '../../components/progress_bar/ProgressBarComponent'
import ReflectionsList from '../../components/reflections/ReflectionsList'
import TasksComponent from '../../components/task/TasksComponent'
import {TaskImage} from '../../components/task/TaskStyles'
import VideoPlayer from '../../components/video/VideoPlayer'
import {GetJourneyInfo} from '../../infra/requests/JourneyRequests'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {
  CalcJourneySteps,
  GetH5PRatio
} from '../../infra/utils/CommonFunctions'
import {ThirdColor, LightGey} from '../../styles/_theme'
import {LoadingSpin} from '../../styles/BasicStyles'
import {
  JourneyCard,
  InformationPage,
  InformationContainer,
  BookmarksBox,
  CountNumber,
  CountLabel,
  JourneyDescription,
  JourneyLabel,
  JourneyTitle,
  JourneyIntention,
  JourneyTop,
  CloseBtn
} from '../active_journey/ActiveJourneyStyles'
import CustomSteps from '../active_journey/CustomSteps'
import StepsList from '../active_journey/StepsList'
import {
  JourneyHeaderStepsGroup,
  ClickableDiv,
  InformationPageScroll,
  NoJourneyBlock,
  NoJourneyImage,
  NoJourneyInfoBlock,
  HeaderTitle,
  H5P
} from './JourneyStyles'

const GetImage = (media, code) => {
  switch (media?.type) {
    case 'images':
      return (
        <TaskImage style={{height: '100%', maxHeight: 380}}>
          <img src={media.file.url} style={{width: '100%'}} alt='media' />
        </TaskImage>
      )
    case 'videos': {
      const video = GetLanguageValue(media.file.url, code)
      return <VideoPlayer video={video} height='380px' width='100%' />
    }
    case 'h5p':
    case 'embedded': {
      const file = GetLanguageValue(media.file.iframe, code)
      const r = GetH5PRatio(file)
      return (
        <H5P
          style={{
            paddingTop: r.isPx || `${r}%`,
            overflow: 'hidden',
            marginBottom: 35
          }}
          dangerouslySetInnerHTML={{
            __html: file
          }}
        />
      )
    }
    default:
      return <img src={DefaultImg} style={{width: '100%'}} alt='media' />
  }
}

const CompletedJourneyPage = ({translate, activeLanguage, router}) => {
  const [section, setSection] = useState('steps')
  const [step, setStep] = useState(undefined)
  const [index, setIndex] = useState(undefined)
  const [hasFetchJourney, setHasFetchJourney] = useState(false)
  const [loading, setLoading] = useState(false)

  const location = useLocation()
  const [journey, setJourney] = useState(location?.state?.journey || false)

  useEffect(() => {
    async function getJourney() {
      setLoading(true)
      try {
        const result = await GetJourneyInfo(journey._id)
        setJourney(result.data)
        setHasFetchJourney(true)
      } catch (error) {
        console.warn(error)
      }
      setLoading(false)
    }
    if (!hasFetchJourney) {
      getJourney()
    }
  }, [hasFetchJourney, journey])

  if (!journey) router.history.goBack()

  const renderContent = () => {
    switch (section) {
      case 'tasks':
        return (
          <TasksComponent
            step={step}
            index={index + 1}
            onClickBack={() => setSection('steps')}
            completedJourney={journey}
            isCompleted
          />
        )
      case 'reflections':
        return (
          <ReflectionsList
            completedJourney={journey}
            onClickBack={() => setSection('steps')}
          />
        )
      case 'feedbacks':
        return (
          <FeedbacksList
            completedJourney={journey}
            onClickBack={() => setSection('steps')}
          />
        )
      case 'bookmarks':
        return (
          <BookmarksList
            completedJourney={journey}
            onClickBack={() => setSection('steps')}
          />
        )
      default:
        return (
          <div>
            {journey.type === 'guided' ? (
              <StepsList
                completedJourney={journey}
                onClickStep={(s, i) => {
                  setSection('tasks')
                  setStep(s)
                  setIndex(i)
                }}
              />
            ) : (
              <CustomSteps completedJourney={journey} />
            )}
          </div>
        )
    }
  }

  if (loading) return <LoadingSpin min='200px' />

  return (
    <>
      {journey ? (
        <>
          <JourneyCard>
            <SRLWrapper hideControlsAfter='100'>
              {GetImage(journey.media, activeLanguage.code)}
            </SRLWrapper>
            <div style={{position: 'relative'}}>
              <BookmarksBox
                onClick={() => setSection('reflections')}
                isSelected={section === 'reflections'}
              >
                <CountNumber isSelected={section === 'reflections'}>
                  {journey.reflections?.length}
                </CountNumber>
                <CountLabel isSelected={section === 'reflections'}>
                  {translate('REFLECTIONS')}
                </CountLabel>
              </BookmarksBox>
              <BookmarksBox
                left={1}
                onClick={() => setSection('feedbacks')}
                isSelected={section === 'feedbacks'}
              >
                <CountNumber isSelected={section === 'feedbacks'}>
                  {journey.feedbacks?.length}
                </CountNumber>
                <CountLabel isSelected={section === 'feedbacks'}>
                  {translate('FEEDBACKS')}
                </CountLabel>
              </BookmarksBox>
              <BookmarksBox
                left={2}
                onClick={() => setSection('bookmarks')}
                isSelected={section === 'bookmarks'}
              >
                <CountNumber isSelected={section === 'bookmarks'}>
                  {journey.bookmarks?.length}
                </CountNumber>
                <CountLabel isSelected={section === 'bookmarks'}>
                  {translate('BOOKMARKS')}
                </CountLabel>
              </BookmarksBox>
            </div>
            <JourneyDescription style={{paddingTop: 90}}>
              <JourneyTop>
                <ClickableDiv onClick={() => setSection('steps')}>
                  <JourneyLabel>
                    {translate('ACTIVE_JOURNEY')}
                  </JourneyLabel>
                  <JourneyTitle>{journey.title}</JourneyTitle>
                  <JourneyHeaderStepsGroup spaceB={16}>
                    <div style={{whiteSpace: 'nowrap'}}>
                      {CalcJourneySteps(journey)} {translate('STEPS')}
                    </div>
                  </JourneyHeaderStepsGroup>
                  <JourneyIntention>{journey.intention}</JourneyIntention>
                </ClickableDiv>
                <ProgressBarComponent
                  progress={`${journey.completion}%`}
                  borderRadius={19}
                  height={22}
                  marginB={32}
                  color={ThirdColor}
                  unfilledColor={LightGey}
                />
              </JourneyTop>
            </JourneyDescription>
          </JourneyCard>
          <InformationPage>
            <InformationContainer>{renderContent()}</InformationContainer>
          </InformationPage>
        </>
      ) : (
        <InformationPageScroll>
          <InformationContainer>
            <Row>
              <Col xs={24}>
                <CloseBtn onClick={() => router.history.goBack()} showBg>
                  <CloseIcon />
                </CloseBtn>
              </Col>
              <NoJourneyBlock style={{marginTop: 63}}>
                <NoJourneyImage ratio={0} image={{url: NoJourneyImg}} />
                <NoJourneyInfoBlock>
                  <HeaderTitle>
                    {translate('DONT_HAVE_ACTIVE_JOURNEY')}
                  </HeaderTitle>
                  <BaseButton
                    type='button'
                    onClick={() => router.history.push('/create-journey')}
                    label={translate('CREATE_CUSTOM_JOURNEY')}
                    category='tertiary'
                    style={{marginTop: 24}}
                  />
                </NoJourneyInfoBlock>
              </NoJourneyBlock>
            </Row>
          </InformationContainer>
        </InformationPageScroll>
      )}
    </>
  )
}

CompletedJourneyPage.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
}

export default withLocalize(CompletedJourneyPage)
