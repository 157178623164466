import io from 'socket.io-client'
import {AuthTokenKey} from '../../config/LocalStorageKeys'

const host = process.env.REACT_APP_SOCKETS_URL || 'http://localhost:3000'

const INTERNAL_EVENTS = {
  CONNECT: 'connect',
  AUTHENTICATE: 'authenticate',
  AUTHENTICATED: 'authenticated',
  UNAUTHORIZED: 'unauthorized',
  ERROR: 'error'
}

class SocketClient {
  constructor(callback) {
    this._socketGetConnection()
    this._socketAuthenticate()
    this.subscribe(INTERNAL_EVENTS.UNAUTHORIZED, (msg) =>
      console.warn('REJECTED: ', msg)
    )
    this.subscribe(INTERNAL_EVENTS.ERROR, (msg) =>
      console.warn('REJECTED: ', msg)
    )

    if (callback) callback(this)
  }

  static create = (callback) => {
    if (!SocketClient.instance) {
      SocketClient.instance = new SocketClient(callback)
    }
    return SocketClient.instance
  }

  disconnect = () => {
    this.socket.close()
    delete SocketClient.instance
  }

  subscribe = (eventName, callBack) => {
    this.socket.on(eventName, callBack)
    return this
  }

  emit = (eventName, args) => {
    this.socket.emit(eventName, args)
    return this
  }

  _socketGetConnection = () => {
    return (this.socket = io.connect(host, {
      secure: true,
      reconnection: true,
      rejectUnauthorized: false,
      forceNode: true
    }))
  }

  _socketAuthenticate = () =>
    this.socket.on(INTERNAL_EVENTS.CONNECT, () =>
      this._onSocketConnected()
    )

  _onSocketConnected = () =>
    this.socket
      .emit(INTERNAL_EVENTS.AUTHENTICATE, {
        token: localStorage.getItem(AuthTokenKey)
      })
      .on(INTERNAL_EVENTS.AUTHENTICATED, () => {})
}

export default SocketClient
