import FormValidator from '../../../infra/services/validations/FormValidator'

const AssignmentTaskValidations = (values) => {
  const errors = FormValidator.make({
    questions: 'array'
  })(values)
  if (values.questions && values.questions.length > 0) {
    errors.questions = []
    values.questions.map((question, index) => {
      errors.questions[index] = FormValidator.make({
        response: 'required'
      })(question)
      if (
        question.type === 4 &&
        (!question.response?.link || !question.response?.title)
      ) {
        errors.questions[index].response = {_error: 'RESPONSE_REQUIRED'}
      }
      return question
    })
  }
  return errors
}

export default AssignmentTaskValidations
