import {Row, Col} from 'antd'
import styled from 'styled-components'

import ImageComponent from '../../components/images/ImageComponent'
import {
  brownGrey,
  DarkGey,
  LightGey,
  ThirdColor
} from '../../styles/_theme'
import {ResponsiveContainer} from '../../styles/BasicStyles'
import device from '../../styles/Responsive'

export const DashboardContainer = styled(ResponsiveContainer)``

export const UserPhoto = styled(ImageComponent)`
  width: 100px;
  border-radius: 50%;
  background-color: grey;
  float: left;
  margin-right: 20px;
  cursor: pointer;
`

export const UserName = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-top: 15px;
`

export const Message = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
`

export const SettingsButton = styled.div`
  float: right;
  margin-top: 35px;
  background: white;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const SettingsIcon = styled.img`
  width: 24px;
  height: 24px;
`

export const CardContainer = styled.div`
  display: inline-block;
  width: 100%;
  background: white;
  padding: 15px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  overflow: auto;

  @media ${device.laptop} {
    margin-bottom: 0px;
  }
`

export const CardTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`

export const JourneyTitle = styled.div`
  font-size: 22px;
  line-height: 24px;
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 16px;
`

export const ActivitySection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 12px 15px;

  &:hover,
  &:visited,
  &:active {
    background-color: ${LightGey};
  }
`

export const ActivityDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 58px);
`

export const MainText = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const PeerPhoto = styled(ImageComponent)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${brownGrey};
  margin-right: 8px;
`

export const CardTimeHeader = styled.div`
  width: 100%;
  top: 0px;
  z-index: 1;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${DarkGey};
  text-align: right;
  margin-bottom: 6px;
`

export const JourneyInfo = styled.div`
  display: grid;
  padding: 0px 15px 15px;
`

export const NoActivity = styled.div`
  font-size: 20px;
  font-weight: 600;
  min-height: 248px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`

export const NoFeed = styled.div`
  font-size: 26px;
  font-weight: 800;
  line-height: 32px;
  margin-top: 24px;
  text-align: center;
  color: ${ThirdColor};
`

export const H5P = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  margin-bottom: 35px;

  &&& iframe {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
  }
`

export const DashboardRow = styled(Row)`
  height: 100%;

  @media ${device.laptop} {
    height: auto;
    margin-top: 16px;
  }
`
export const DashboardCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`
