import client from '../config/AxiosConfig'
import {
  BuildAssignmentTaskFormData,
  BuildReflectionFormData
} from './TransformToFormData'

export const GetSuggestedJourneys = async () =>
  client.get('/guided/suggested')

export const GetGuidedJourneys = async (page, limit) =>
  client.get(`/guided/suggested/${page}/${limit}`)

export const GetInactiveJourneys = async () => client.get('/journeys')

export const GetArchivedJourneys = async () =>
  client.get('/journeys/archived')

export const ToggleJourneyArchived = async (id) =>
  client.put(`/journeys/${id}/archive`)

export const SetJourneyActive = async (id) =>
  client.put(`/journeys/${id}/activate`)

export const CompleteJourney = async (id) =>
  client.put(`/journeys/${id}/complete`)

export const EditJourney = async (id, fields) =>
  client.put(`/journeys/${id}`, fields)

export const CreateJourney = async (fields) =>
  client.post('/journeys', fields)

export const DeleteJourney = async (id) => client.delete(`/journeys/${id}`)

export const CreateTask = async (fields) => client.post('/tasks', fields)

export const EditTask = async (id, fields) =>
  client.put(`/tasks/${id}`, fields)

export const CreateAssignmentTask = async (
  assignmentData,
  currentLanguage
) => {
  const data = BuildAssignmentTaskFormData(assignmentData, currentLanguage)
  return client.post('/tasks', data, {
    headers: {
      common: {'Content-Type': 'multipart/form-data'}
    }
  })
}

export const EditAssignmentTask = async (
  id,
  assignmentData,
  currentLanguage
) => {
  const data = BuildAssignmentTaskFormData(
    assignmentData,
    currentLanguage,
    true
  )
  return client.put(`/tasks/${id}`, data, {
    headers: {
      common: {'Content-Type': 'multipart/form-data'}
    }
  })
}

export const GetJourneyInfo = async (id) => client.get(`/journeys/${id}`)

export const CreateJourneyReflection = async (
  reflectionData,
  currentLanguage
) => {
  const data = BuildReflectionFormData(reflectionData, currentLanguage)
  return client.post('/reflections', data, {
    headers: {
      common: {'Content-Type': 'multipart/form-data'}
    }
  })
}

export const UpdateJourneyReflection = async (
  reflectionData,
  currentLanguage
) => {
  const data = BuildReflectionFormData(
    reflectionData,
    currentLanguage,
    true
  )
  return client.put(`/reflections/${reflectionData.id}`, data, {
    headers: {
      common: {'Content-Type': 'multipart/form-data'}
    }
  })
}

export const SearchPeers = async (page, limit, search) =>
  search
    ? client.get(`/peers/search/${page}/${limit}?search=${search}`)
    : client.get(`/peers/search/${page}/${limit}`)

export const RequestFeedback = async (fields) =>
  client.post('/feedbacks', fields)

export const ToggleFeedbackBookmark = async (id) =>
  client.put(`/feedbacks/${id}/bookmark`)

export const ToggleReflectionBookmark = async (id) =>
  client.put(`/reflections/${id}/bookmark`)

export const ToggleTaskBookmark = async (id) =>
  client.put(`/tasks/${id}/bookmark`)

export const RemoveUpdatedInfoTask = async (id, fields) =>
  client.put(`/journeys/${id}/view`, fields)

export const verifyIfExistJourney = async (id) =>
  client.get(`/journeys/guided/${id}`)
