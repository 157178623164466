import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useDispatch, useSelector} from 'react-redux'

import EmptyIcon from '../../assets/icons/empty_feed.svg'
import {
  IgnoreFeedback,
  MarkFeedbackSeen,
  MarkSeen
} from '../../infra/requests/ActivityRequests'
import {NoFeed} from '../../pages/dashboard/DashboardStyles'
import {GetAndUpdateFeed} from '../../redux/thunks/UpdateThunk'
import {H5P} from '../../styles/BasicStyles'
import FeedbackCard from '../cards/FeedbackCard'
import JourneyVersionCard from '../cards/JourneyVersionCard'
import KudosCard from '../cards/KudosCard'
import RequestFeedbackCard from '../cards/RequestFeedbackCard'
import ValidationsCard from '../cards/ValidationsCard'
import VideoPlayer from '../video/VideoPlayer'
import {
  Container,
  ContainerTitle,
  SeeAll,
  StyledCarousel,
  CarouselCard
} from './SliderStyles'

const LatestFeed = ({translate}) => {
  const dispatch = useDispatch()
  const feed = useSelector((state) => state.feed)
  // const [ratio, setRatio] = useState(false)

  const onRemove = async (item) => {
    if (!item.seen) {
      switch (item.feedType) {
        case 'feedback_requests':
          await IgnoreFeedback(item._id)
          dispatch(GetAndUpdateFeed())
          break
        case 'feedbacks_received':
          await MarkFeedbackSeen(item._id)
          dispatch(GetAndUpdateFeed())
          break
        case 'kudos':
          await MarkSeen(item._id)
          dispatch(GetAndUpdateFeed())
          break
        default:
          break
      }
    }
  }

  const renderList = (item, index) => {
    switch (item.feedType) {
      case 'feedback_requests':
        return (
          <RequestFeedbackCard
            key={index}
            item={item}
            onClose={onRemove}
            isFeed
          />
        )

      case 'feedbacks_received':
        return (
          <FeedbackCard
            key={index}
            item={item}
            onClose={onRemove}
            isFeed
            bgColor='transparent'
          />
        )

      case 'kudos':
        return (
          <KudosCard key={index} item={item} onClose={onRemove} isFeed />
        )

      case 'validations':
        return <ValidationsCard key={index} item={item} isFeed />

      case 'journeys_versions':
        return (
          <JourneyVersionCard
            key={index}
            item={item}
            isFeed
            onClose={onRemove}
          />
        )

      default:
        return []
    }
  }

  // useEffect(() => {
  //   document.querySelector('iframe').addEventListener('load', (e) => {
  //     console.log(
  //       document.querySelector('iframe'),
  //       document.querySelector('iframe').height
  //     )
  //     const {height} = document.querySelector('iframe')
  //     const {width} = document.querySelector('iframe')
  //     const r = (parseInt(height) / parseInt(width)) * 100
  //     setRatio(r)
  //   })
  // })

  return (
    <Container style={{marginBottom: 0}}>
      <Row>
        <Col xs={24} style={{display: 'flex'}}>
          <ContainerTitle>{translate('LATEST_FEED')}</ContainerTitle>
          {feed && feed.length > 0 && (
            <SeeAll
              onClick={() =>
                document.getElementById('openNotificationsDrawer').click()
              }
            >
              {translate('SEE_ALL')}
            </SeeAll>
          )}
        </Col>
        <Col xs={24}>
          {/* <div style={{width: '100%', padding: '24px 0px'}}>
            <H5P
              style={{
                paddingTop: `${ratio}%`,
                overflow: 'hidden',
                marginBottom: 24
              }}
              dangerouslySetInnerHTML={{
                __html:
                  '<iframe src="https://h5p.org/h5p/embed/617" width=1090 height=675 frameborder="0" allowfullscreen="allowfullscreen" allow="geolocation *; microphone *; camera *; midi *; encrypted-media *" title="New video"></iframe><script src="https://setima.h5p.com/js/h5p-resizer.js" charset="UTF-8"></script><script src="https://setima.h5p.com/js/h5p-resizer.js" charset="UTF-8"></script><script type="application/javascript"> function resizeIFrameToFitContent( iFrame ) { iFrame.width  = iFrame.contentWindow.document.body.scrollWidth; iFrame.height = iFrame.contentWindow.document.body.scrollHeight; } window.addEventListener("DOMContentLoaded", function(e) { var iFrame = document.getElementById( "iFrame1" ); resizeIFrameToFitContent( iFrame ); var iframes = document.querySelectorAll("iframe"); for( var i = 0; i < iframes.length; i++) { resizeIFrameToFitContent( iframes[i] );}} );</script>'
              }}
            />
          </div> */}
          {feed && feed.length > 0 ? (
            <StyledCarousel dots autoplay effect='fade'>
              {feed.slice(0, 4).map((item, index) => (
                <CarouselCard key={item._id}>
                  {renderList(item, index)}
                </CarouselCard>
              ))}
            </StyledCarousel>
          ) : (
            <>
              <NoFeed>{translate('EMPTY_FEED_DASHBOARD')}</NoFeed>
              <img
                style={{marginBottom: 24, marginTop: 24, width: '100%'}}
                src={EmptyIcon}
                alt='Empty'
              />
            </>
          )}
        </Col>
      </Row>
    </Container>
  )
}

LatestFeed.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(LatestFeed)
