import React from 'react'

import PropTypes from 'prop-types'

import {LanguageContainer, CircleButton} from './LanguageComponentStyles'

function LanguageComponent({lang, onPress, languages}) {
  if (!languages || languages.length < 1) return null
  return (
    <LanguageContainer>
      {languages.map((language) => (
        <CircleButton
          key={language}
          selected={language === lang}
          onClick={() => onPress(language)}
        >
          {language}
        </CircleButton>
      ))}
    </LanguageContainer>
  )
}

LanguageComponent.propTypes = {
  lang: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
  onPress: PropTypes.func.isRequired
}

export default LanguageComponent
