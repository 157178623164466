import React from 'react'

import {message} from 'antd'
import {PropTypes} from 'prop-types'

import BaseButton from '../buttons/BaseButton'
import {
  AlertContainer,
  AlertBar,
  CloseIcon,
  AlertTitle,
  AlertDescription,
  AlertActions
} from './BaseAlertStyles'

const BaseAlert = ({title, description, type, callback}) => (
  <AlertContainer>
    <AlertBar type={type} />
    <AlertTitle>{title}</AlertTitle>
    <AlertDescription>{description}</AlertDescription>
    <CloseIcon role='button' onClick={() => message.destroy()} />
    {callback && (
      <AlertActions>
        <BaseButton type='secondary' onClick={() => message.destroy()}>
          No, cancel
        </BaseButton>
        <BaseButton
          type='primary'
          onClick={() => {
            message.destroy()
            callback()
          }}
        >
          Yes, confirm
        </BaseButton>
      </AlertActions>
    )}
  </AlertContainer>
)

BaseAlert.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error', 'warning']),
  callback: PropTypes.func,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.elementType
  ])
}

BaseAlert.defaultProps = {
  title: 'Success',
  type: 'success',
  callback: undefined,
  description: undefined
}

export default BaseAlert
