import React from 'react'

import {PropTypes} from 'prop-types'
import {FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'

import {LightGey} from '../../styles/_theme'
import PeerBlockComponent from '../peer_block/PeerBlockComponent'

const FriendsList = ({translate}) => {
  const {friends} = useSelector((state) => state.friends)

  const onPressListItem = (peer, values, form) => {
    if (values.users && values.users.length) return
    const selected = values.invitedUsers || []
    let changes = [...selected]
    const exist = changes.findIndex((u) => peer.id === u._id)

    if (exist >= 0) {
      changes = values.invitedUsers.filter((u) => peer.id !== u._id)
    } else {
      changes.push(peer)
    }
    form.change('invitedUsers', changes)
  }

  return (
    <FormSpy subscription={{values: true}}>
      {({values, form}) => (
        <>
          <FieldArray name='invitedUsers'>
            {({fields}) => (
              <div
                style={{
                  height: (friends?.length || 0) * 68,
                  overflow: 'hidden'
                }}
              >
                {friends?.map((item, index) => (
                  <PeerBlockComponent
                    key={index}
                    index={index}
                    item={item}
                    onPressItem={(peer) =>
                      onPressListItem(peer, values, form)
                    }
                    bgColor={LightGey}
                    selectedPeers={values.invitedUsers || []}
                    marginB='6px'
                  />
                ))}
              </div>
            )}
          </FieldArray>
        </>
      )}
    </FormSpy>
  )
}

FriendsList.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(FriendsList)
