import styled from 'styled-components'

import BaseButton from '../../components/buttons/BaseButton'
import ImageComponent from '../../components/images/ImageComponent'
import {
  NavbarSize,
  ThirdColor,
  SuccessColor,
  DarkGey,
  LightGey,
  white,
  mediumBlue,
  DefaultGey,
  DarkGrey,
  lightBlue2,
  PrimaryColor,
  BorderColor
} from '../../styles/_theme'
import device from '../../styles/Responsive'

export const JourneyCard = styled.div`
  position: fixed;
  width: 400px;
  height: calc(100vh - ${NavbarSize});
  top: ${NavbarSize};
  left: 0;
  background: white;
  overflow-x: auto;
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    width: 350px;
  }
`

export const JourneyImage = styled(ImageComponent)`
  width: 100%;
  position: relative;
  overflow: visible;
  margin-bottom: ${({marginB}) => marginB || 60}px;
`

export const BookmarksBox = styled.div`
  position: absolute;
  bottom: -45px;
  left: ${({left}) =>
    left === 1 ? '145px' : left === 2 ? '270px' : '20px'};
  background: ${({isSelected}) => (isSelected ? ThirdColor : white)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px 0;
  width: 110px;
  border-radius: 5px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover,
  &:visited,
  &:active {
    background-color: ${lightBlue2};
  }

  @media ${device.laptop} {
    left: ${({left}) =>
      left === 1 ? '120px' : left === 2 ? '235px' : '5px'};
  }
`
export const CountNumber = styled.div`
  font-size: 26px;
  font-weight: bold;
  color: ${({isSelected}) => (isSelected ? white : ThirdColor)};
`
export const CountLabel = styled.div`
  font-size: 14px;
  color: ${({isSelected}) => (isSelected ? white : DarkGrey)};
`

export const SettingsButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  background: white;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:visited,
  &:active {
    background-color: ${lightBlue2};

    svg path {
      fill: ${PrimaryColor};
    }
  }
`

export const SettingsIcon = styled.img`
  width: 20px;
  height: 20px;
`
export const JourneyDescription = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
`
export const JourneyLabel = styled.div`
  height: 22px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
`
export const JourneyTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 1.09;
  margin-bottom: 14px;
`
export const JourneyIntention = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  margin-bottom: 40px;
`

export const CreateButton = styled(BaseButton)`
  width: 100%;
  margin-bottom: 15px;
`

export const InformationPage = styled.div`
  width: 100%;
  display: flex;
  margin-left: 400px;
  justify-content: center;
  background-color: ${LightGey};
  height: fit-content;

  @media ${device.laptop} {
    margin-left: 350px;
  }
`

export const InformationContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  padding: 30px;
  background-color: ${LightGey};
  height: fit-content;

  .ant-collapse {
    width: 100%;

    &.ant-collapse-ghost > .ant-collapse-item {
      margin-top: 6px;

      .ant-collapse-content {
        background-color: ${white};
      }
    }

    .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
      padding: 0px;
    }
  }
`

export const InformationTitle = styled.div`
  font-size: 26px;
  font-weight: 800;
  float: left;
  margin-bottom: 20px;
  color: ${ThirdColor};
`

export const JourneyTop = styled.div``

export const JourneyBottom = styled.div``

export const GuidedStepContainer = styled.div`
  height: 100%;
  background-color: ${({isCompleted, isLocked}) =>
    isCompleted ? SuccessColor : isLocked ? DarkGey : ThirdColor};
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &:hover,
  &:visited,
  &:active {
    opacity: ${({noOpacity}) => (noOpacity ? 1 : 0.8)};
  }
`

export const GuidedStepPhoto = styled(ImageComponent)`
  width: 100%;
  height: 100px;
  ratio: 0;
  background-color: ${LightGey};
`

export const GuidedStepPhotoHeight = styled(ImageComponent)`
  height: 100%;
  ratio: 0;
  background-color: ${LightGey};
`

export const GuidedStepPhotoHeight2 = styled(ImageComponent)`
  width: 100%;
  flex: 1;
  ratio: 0;
  background-color: ${LightGey};
`

export const GuidedStepsInfo = styled.div`
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  flex: 1;
  padding: 14px 16px 10px;
  width: 100%;
`

export const StepDescription = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: ${white};
  margin-bottom: 12px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const StepTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: ${white};
  margin-bottom: 12px;
`

export const StepName = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: ${white};
  margin-bottom: 12px;
`

export const StepTasks = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  color: ${white};
  margin-bottom: 20px;
`

export const StatusBlock = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  flex: 1;
  width: 100%;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  color: ${white};
`

export const CustomStepHeader = styled.div`
  background-color: ${({isCompleted}) =>
    isCompleted ? SuccessColor : mediumBlue};
  min-height: 88px;
  bottom: 8px;
  position: relative;
  cursor: pointer;
  display: flex;

  &:hover,
  &:visited,
  &:active {
    svg path {
      fill: ${PrimaryColor};
    }
  }
`

export const IndexBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({isNew, isCompleted}) =>
    isCompleted ? SuccessColor : isNew ? DefaultGey : ThirdColor};
  height: auto;
  font-size: 20px;
  font-weight: normal;
  line-height: 22px;
  color: ${white};
  text-align: center;
  width: 50px;
`

export const CustomStepInfo = styled.div`
  padding: 12px 14px;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  color: ${white};
  flex: auto;
  width: 100%;
`

export const CustomStepPanel = styled.div`
  width: 100%;
  max-width: 570px;
  padding: 30px;
  color: ${DarkGrey};
  margin: auto;

  .react-add-to-calendar__wrapper {
    display: flex;
    justify-content: center;
  }

  .react-add-to-calendar__button {
    display: inline-block;
    padding: 10px 25px;
    outline: none;
    border-radius: 25px;
    cursor: pointer;
    background-color: #17ac9d;
    border: 1px solid #17ac9d;
    color: white;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &:hover,
    &:visited,
    &:active {
      outline: none;
      background-color: white;
      border: 1px solid #17ac9d;
      color: #17ac9d;
    }
  }

  ul {
    list-style-type: none;
    padding: 0px;
    display: inline-block;

    li {
      float: left;

      a {
        margin: 10px 10px 0px;
        display: inline-block;
        padding: 5px 10px;
        outline: none;
        border-radius: 25px;
        cursor: pointer;
        background-color: #17ac9d;
        border: 1px solid #17ac9d;
        color: white;
        -webkit-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        white-space: nowrap;
      }
    }
  }
`

export const CustomStepLabel = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 24px;
`

export const SliderVal = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 40px;
  text-align: center;
`

export const SetReminder = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 32px;
  display: flex;
  background-color: ${LightGey};
  padding: 20px;
  justify-content: space-between;

  &:hover,
  &:visited,
  &:active {
    background-color: ${BorderColor};
  }
`

export const DateTimeBlock = styled.div`
  .ant-picker.ant-picker-borderless {
    padding: 0;

    .ant-picker-input > input {
      text-align: center;
    }
  }
`

export const CompleteBtn = styled.div`
  height: 62px;
  background-color: ${ThirdColor};
  color: ${white};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  padding: 20px;
  width: 100%;
  cursor: pointer;
  text-align: center;
`

export const CloseBtn = styled.div`
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  position: absolute;
  border-radius: 50%;
  right: 0px;
  background-color: ${({showBg}) => (showBg ? white : 'transparent')};
  width: 44px;
  height: 44px;

  &:hover,
  &:visited,
  &:active {
    svg path {
      fill: ${PrimaryColor};
    }
  }
`
