import {GetFeed, GetActivity} from '../../infra/requests/ActivityRequests'
import {GetInfo} from '../../infra/requests/BaseRequests'
import {
  GetFriends,
  GetChannels
} from '../../infra/requests/CommunityRequests'
import {
  GetInactiveJourneys,
  GetArchivedJourneys,
  GetSuggestedJourneys,
  GetJourneyInfo
} from '../../infra/requests/JourneyRequests'
import {GetLatestKudos} from '../../infra/requests/KudosRequests'
import {updateActivity} from '../data/activity/ActivityActions'
import {updateArchivedJourneys} from '../data/archived_journeys/ArchivedJourneysActions'
import {updateChats} from '../data/chats/ChatsActions'
import {updateFeed, clearFeed} from '../data/feed/FeedActions'
import {updateFriends} from '../data/friends/FriendsActions'
import {updateInactiveJourneys} from '../data/inactive_journeys/InactiveJourneysActions'
import {updateInfo} from '../data/info/InfoActions'
import {
  updateLatestKudos,
  clearLatestKudos
} from '../data/latest_kudos/LatestKudosActions'
import {updateSuggestedJourneys} from '../data/suggested_journeys/SuggestedJourneysActions'
import {userSave, updateActiveJourney} from '../data/user/UserActions'

export function GetAndUpdateFriends() {
  return async (dispatch) => {
    try {
      const result = await GetFriends()
      dispatch(updateFriends(result.data))
    } catch (error) {
      console.warn(error)
    }
  }
}

export function GetAndUpdateFeed() {
  return async (dispatch) => {
    try {
      const result = await GetFeed()
      const sortFeed = (first, second) =>
        new Date(second.updatedAt) - new Date(first.updatedAt)
      const orderedFeed = []

      if (result.success) {
        Object.keys(result.data).map((currentFeedType) => {
          if (currentFeedType !== 'journeys') {
            result.data[currentFeedType].map((f) =>
              orderedFeed.push({...f, feedType: currentFeedType})
            )
          }
          return currentFeedType
        })

        orderedFeed.sort(sortFeed)
      }
      dispatch(updateFeed(orderedFeed))
    } catch (error) {
      console.warn(error)
    }
  }
}

export function GetAndUpdateChats() {
  return async (dispatch) => {
    try {
      const result = await GetChannels()
      dispatch(updateChats(result.data))
    } catch (error) {
      console.warn(error)
    }
  }
}

export function GetAndUpdateInactiveJourneys() {
  return async (dispatch) => {
    try {
      const result = await GetInactiveJourneys()
      dispatch(updateInactiveJourneys(result.data))
    } catch (error) {
      console.warn('GetAndUpdateInactiveJourneys', error)
    }
  }
}

export function GetAndUpdateArchivedJourneys() {
  return async (dispatch) => {
    try {
      const result = await GetArchivedJourneys()
      dispatch(updateArchivedJourneys(result.data))
    } catch (error) {
      console.warn('GetAndUpdateArchivedJourneys', error)
    }
  }
}

export function GetAndUpdateSuggestedJourneys() {
  return async (dispatch) => {
    try {
      const result = await GetSuggestedJourneys()
      dispatch(updateSuggestedJourneys(result.data))
    } catch (error) {
      console.warn('GetAndUpdateSuggestedJourneys', error)
    }
  }
}

export function GetAndUpdateActiveJourney(id) {
  return async (dispatch) => {
    try {
      const result = await GetJourneyInfo(id)
      dispatch(updateActiveJourney({active_journey: result.data}))
    } catch (error) {
      console.warn(error)
    }
  }
}

export function GetAndUpdateUser() {
  return async (dispatch) => {
    try {
      const result = await GetInfo()
      if (result.data.user) dispatch(userSave(result.data.user))
    } catch (error) {
      console.warn(error)
    }
  }
}

export function GetAndUpdateActivity() {
  return async (dispatch) => {
    try {
      const result = await GetActivity(1, 20)
      if (result.success) {
        const {items} = result.data
        dispatch(
          updateActivity({
            items,
            page: 2
          })
        )
      }
    } catch (error) {
      console.warn(error)
    }
  }
}

export function GetAndUpdateLatestKudos() {
  return async (dispatch) => {
    try {
      const result = await GetLatestKudos(1, 5)
      if (result.success) {
        const {items} = result.data
        dispatch(updateLatestKudos(items))
      }
    } catch (error) {
      console.warn(error)
    }
  }
}

export function GetAndUpdateInfo() {
  return async (dispatch) => {
    try {
      const result = await GetInfo()
      if (result.success) {
        console.log(result.data)
        dispatch(updateInfo({ready: true, data: result.data}))
      }
    } catch (error) {
      console.warn(error)
    }
  }
}

export function UpdateAllData() {
  return (dispatch) => {
    dispatch(GetAndUpdateFeed())
    dispatch(GetAndUpdateInactiveJourneys())
    dispatch(GetAndUpdateSuggestedJourneys())
    dispatch(GetAndUpdateArchivedJourneys())
    dispatch(GetAndUpdateFriends())
    dispatch(GetAndUpdateLatestKudos())
  }
}

export function CleanAndUpdateAllData() {
  return (dispatch) => {
    dispatch(userSave())
    dispatch(updateSuggestedJourneys())
    dispatch(updateArchivedJourneys())
    dispatch(updateInactiveJourneys())
    dispatch(clearFeed())
    dispatch(updateFriends())
    dispatch(clearLatestKudos())
    dispatch(updateActivity({items: [], page: 1}))
  }
}
