import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useDispatch} from 'react-redux'

import {MarkFeedbackSeen} from '../../infra/requests/ActivityRequests'
import {InformationTitle} from '../../pages/active_journey/ActiveJourneyStyles'
import {GetAndUpdateFeed} from '../../redux/thunks/UpdateThunk'
import {DarkGrey} from '../../styles/_theme'
import {WhiteLayout, MainText} from '../../styles/BasicStyles'
import {InputLabel, InputBox} from '../input/InputStyles'
import LikertRatingComponent from '../review_components/LikertRatingComponent'
import PointRatingComponent from '../review_components/PointRatingComponent'
import StarRatingComponent from '../review_components/StarRatingComponent'

const FeedbackDetail = ({translate, isShare, feedback, title}) => {
  const [hasFetchSeen, setHasFetchSeen] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    async function setFeedbackSeen() {
      await MarkFeedbackSeen(feedback._id)
      dispatch(GetAndUpdateFeed())
      setHasFetchSeen(true)
    }
    if (!hasFetchSeen && !feedback.seen) {
      setFeedbackSeen()
    }
  }, [hasFetchSeen, feedback._id, feedback.seen, dispatch])

  return (
    <Row>
      <Col xs={24}>
        <InformationTitle>
          {title || translate('FEEDBACK_RECEIVED')}
        </InformationTitle>
      </Col>
      <Col xs={24}>
        <Row>
          {(feedback.finished || isShare) && (
            <WhiteLayout space=' 0px 30px 30px'>
              {feedback.questions.map((question, i) => (
                <InputBox key={i} hasTopSpace={30}>
                  <InputLabel marginB={6}>{question.title}</InputLabel>
                  {question.type === 3 ? (
                    <StarRatingComponent
                      noEditable
                      input={{value: question.response}}
                      spacet={14}
                    />
                  ) : question.type === 6 ? (
                    <PointRatingComponent
                      noEditable
                      input={{value: question.response}}
                      spacet={14}
                    />
                  ) : question.type === 7 ? (
                    <LikertRatingComponent
                      noEditable
                      input={{value: question.response}}
                      placeholder={question.placeholder}
                    />
                  ) : (
                    <MainText color={DarkGrey}>
                      {question.response}
                    </MainText>
                  )}
                </InputBox>
              ))}
            </WhiteLayout>
          )}
        </Row>
      </Col>
    </Row>
  )
}

FeedbackDetail.propTypes = {
  translate: PropTypes.func.isRequired,
  feedback: PropTypes.object,
  isShare: PropTypes.bool,
  title: PropTypes.string
}

FeedbackDetail.defaultProps = {
  feedback: undefined,
  isShare: false,
  title: false
}

export default withLocalize(FeedbackDetail)
