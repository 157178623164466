import React from 'react'

import moment from 'moment'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useDispatch, useSelector} from 'react-redux'

import Bookmarked from '../../assets/icons/ic_bookmark.svg'
import CloseIcon from '../../assets/icons/ic_close_white.svg'
import Unbookmarked from '../../assets/icons/ic_plus.svg'
import {
  questionsByType,
  GetTaskName
} from '../../infra/utils/CommonFunctions'
import {updateModal} from '../../redux/data/modal/ModalActions'
import {white} from '../../styles/_theme'
import {
  WhiteLayout,
  MainText,
  ClickableBox
} from '../../styles/BasicStyles'
import SquareButton from '../buttons/SquareButton'
import PeerBlockComponent from '../peer_block/PeerBlockComponent'
import ShareComponent from '../share/ShareComponent'
import {
  CardContainer,
  CardTimeHeader,
  CardTask,
  CardBottom
} from './CardsStyles'
import QuestionsComponent from './QuestionsComponent'

const FeedbackCard = ({
  item,
  onPressItem,
  onPressBookmarkFeedback,
  translate,
  marginH,
  isFeed,
  onClose,
  bgColor,
  activeLanguage,
  noSpaceB,
  isShare,
  isUser,
  isCompleted
}) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const activeJourney = user?.active_journey
  const questions = questionsByType(item.questions)
  const header = item.task
    ? GetTaskName(
        typeof item.task === 'string' ? item.task : item.task._id,
        activeJourney,
        activeLanguage
      )
    : item.step
    ? item.step
    : false
  const to = item.to || item.email
  const isEmail = item.to ? typeof item.to === 'string' : true

  return (
    <CardContainer
      marginH={marginH}
      isFeed={isFeed}
      $color={isShare && !isUser ? 'rgba(211, 219, 242, 0.4)' : bgColor}
      style={{paddingTop: isShare ? 6 : 0, flex: isFeed ? 1 : 0}}
      onClick={() =>
        onPressItem
          ? onPressItem()
          : dispatch(
              updateModal({
                type: 'feedback',
                object: item,
                isShare: isShare || isCompleted,
                isFeed: isFeed || isCompleted
              })
            )
      }
    >
      {(header || onClose) && (
        <CardTask>
          {header && `${translate('FROM')} ${header}`}
          {onClose && (
            <>
              {!header && <div />}
              <ClickableBox
                onClick={(e) => {
                  e.stopPropagation()
                  onClose(item)
                }}
              >
                <img src={CloseIcon} alt='close' />
              </ClickableBox>
            </>
          )}
        </CardTask>
      )}
      {!isShare && (
        <CardTimeHeader>{moment(item.updatedAt).fromNow()}</CardTimeHeader>
      )}
      <PeerBlockComponent
        noPadding
        item={to}
        isEmail={isEmail}
        bgColor='transparent'
      />
      <WhiteLayout
        space='12px 11px'
        spaceB={noSpaceB ? 0 : 28}
        style={{
          backgroundColor: 'transparent'
        }}
      >
        <QuestionsComponent
          questions={questions}
          isShare={isShare}
          isUser={isUser}
        />
      </WhiteLayout>
      {onPressBookmarkFeedback && (
        <CardBottom>
          <SquareButton onClick={() => onPressBookmarkFeedback(item)}>
            <MainText color={white}>{translate('BOOKMARK')}</MainText>
            <img
              style={{marginLeft: 8}}
              src={item.bookmarked ? Bookmarked : Unbookmarked}
              alt='Bookmark'
            />
          </SquareButton>
          <ShareComponent
            item={{
              to: !isEmail
                ? {
                    photo: to.photo,
                    email: to.email ? to.email : false,
                    name: to.name
                  }
                : to || false,
              message: item.message,
              goal: item.goal,
              intention: item.intention,
              questions: item.questions
            }}
            type='feedback'
          />
        </CardBottom>
      )}
    </CardContainer>
  )
}

FeedbackCard.propTypes = {
  item: PropTypes.object.isRequired,
  onPressItem: PropTypes.func,
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  onPressBookmarkFeedback: PropTypes.func,
  onClose: PropTypes.func,
  isShare: PropTypes.bool,
  isUser: PropTypes.bool,
  isFeed: PropTypes.bool,
  noSpaceB: PropTypes.bool,
  bgColor: PropTypes.string,
  marginH: PropTypes.number,
  isCompleted: PropTypes.bool
}

FeedbackCard.defaultProps = {
  onPressItem: undefined,
  onPressBookmarkFeedback: undefined,
  onClose: undefined,
  isShare: false,
  isUser: false,
  bgColor: undefined,
  isFeed: false,
  noSpaceB: false,
  marginH: 0,
  isCompleted: false
}

export default withLocalize(FeedbackCard)
