import React, {useState, useEffect} from 'react'

import moment from 'moment'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import UserDefault from '../../assets/default_user.png'
import GroupIcon from '../../assets/icons/group_icon.png'
import {GetChannels} from '../../infra/requests/CommunityRequests'
import {
  GeneralName,
  ChannelName,
  PrivateName
} from '../../infra/services/socket/SocketFunctions'
import {
  hasNotification,
  countNotification
} from '../../infra/utils/CommonFunctions'
import {updateChats} from '../../redux/data/chats/ChatsActions'
import {
  LoadingSpin,
  TabBarNotification,
  TabBarNotificationText
} from '../../styles/BasicStyles'
import BaseButton from '../buttons/BaseButton'
import SearchInput from '../input/SearchInput'
import {
  ChatListSection,
  ChatBox,
  ChatPhoto,
  ChatContent,
  ChatOptions,
  ChatName,
  ChatMessage,
  ChatDate,
  ChatRow
} from './CommunityStyles'

const ChatsComponent = ({
  onClickChat,
  translate,
  notifications,
  user,
  dispatch,
  chats,
  onClickNewGroup
}) => {
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [hasFetchChats, setHasFetchChats] = useState(false)

  const permissions =
    !user.roles || !user.roles.filter((r) => r.role === 7).length
      ? false
      : 1
  const subscription = user.subscription ? user.subscription : false

  useEffect(() => {
    const getChannels = async () => {
      const {data, success} = await GetChannels()
      if (success) {
        setHasFetchChats(true)
        dispatch(updateChats(data))
      }
      setLoading(false)
    }

    if (!hasFetchChats) getChannels()
  }, [loading, dispatch, hasFetchChats])

  const renderList = () => {
    if (loading) return <LoadingSpin min='200px' />
    const show =
      search && search !== ''
        ? chats.filter((chat) =>
            chat.name.toLowerCase().includes(search.toLowerCase())
          )
        : chats

    return show.map((line, index) => {
      const image =
        line.type === 'private'
          ? line.image || {url: UserDefault}
          : line.image && typeof line.image !== 'string'
          ? line.image
          : {url: GroupIcon}

      return (
        <ChatBox
          key={index}
          border={line.type !== 'private'}
          onClick={() => onClickChat(line)}
        >
          <ChatPhoto image={image || {url: GroupIcon}} />
          <ChatContent>
            <ChatName>
              {line.type !== 'private' && '#'}
              {line.name}
            </ChatName>
            <ChatMessage>
              {line?.chat?.message || translate('NO_MESSAGES_YET')}
            </ChatMessage>
          </ChatContent>
          <ChatOptions>
            <ChatDate>
              {moment(line.date).calendar(null, {
                sameDay: 'HH:mm',
                lastWeek: 'dddd',
                lastDay: 'dddd',
                nextWeek: 'dddd'
              })}
            </ChatDate>
            {line.chat &&
              notifications &&
              hasNotification(
                notifications,
                line.type === 'private'
                  ? PrivateName(user._id, line.user)
                  : line.type === 'company'
                  ? GeneralName(line.chat.company)
                  : ChannelName(line.chat.channel)
              ) && (
                <TabBarNotification width={30} height={24}>
                  <TabBarNotificationText>
                    {countNotification(
                      notifications,
                      line.type === 'private'
                        ? PrivateName(user._id, line.user)
                        : line.type === 'company'
                        ? GeneralName(line.chat.company)
                        : ChannelName(line.chat.channel)
                    )}
                  </TabBarNotificationText>
                </TabBarNotification>
              )}
          </ChatOptions>
        </ChatBox>
      )
    })
  }

  return (
    <ChatListSection>
      <ChatRow>
        <SearchInput
          placeholder={translate('SEARCH_PLACEHOLDER')}
          input={{
            value: search,
            onChange: (e) => setSearch(e.target.value)
          }}
        />
        {(permissions || subscription) && (
          <BaseButton
            type='button'
            onClick={onClickNewGroup}
            label={translate('NEW_CHAT')}
            category='tertiary'
            style={{whiteSpace: 'nowrap'}}
          />
        )}
      </ChatRow>
      {renderList()}
    </ChatListSection>
  )
}

ChatsComponent.propTypes = {
  notifications: PropTypes.array.isRequired,
  onClickChat: PropTypes.func,
  translate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  chats: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  onClickNewGroup: PropTypes.func
}

ChatsComponent.defaultProps = {
  onClickChat: undefined,
  chats: undefined,
  onClickNewGroup: undefined
}

const mapStateToProps = (state) => ({
  notifications: state.user.notifications,
  user: state.user,
  chats: state.chats
})

export default withLocalize(connect(mapStateToProps, null)(ChatsComponent))
