import React from 'react'

import {Col} from 'antd'
import moment from 'moment'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useDispatch, useSelector} from 'react-redux'

import UserDefault from '../../assets/default_user.png'
import CloseIcon from '../../assets/icons/ic_close_white.svg'
import {
  questionsByType,
  GetTaskName
} from '../../infra/utils/CommonFunctions'
import {updateModal} from '../../redux/data/modal/ModalActions'
import {WhiteLayout, ClickableBox} from '../../styles/BasicStyles'
import RatingChartComponent from '../chart/RatingChartComponent'
import {UserImageBox} from '../chat/ChatStyles'
import {
  PeerLine,
  PeerPhoto,
  PeerSectionLine,
  PeerDescription,
  PeerName
} from '../peer_block/PeerBlockComponentStyles'
import {CardContainer, CardTimeHeader, CardTask} from './CardsStyles'
import QuestionsComponent from './QuestionsComponent'

const FeedbackRatingCard = ({
  items,
  onPressItem,
  translate,
  marginH,
  isFeed,
  onClose,
  bgColor,
  activeLanguage,
  noSpaceB,
  isShare,
  isUser,
  isCompleted
}) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const activeJourney = user?.active_journey
  const item = items[0]
  let all = []
  items.forEach((i) => {
    if (all.length <= 0) all = i.questions
    else all = all.concat(i.questions)
  })
  const questions = questionsByType(all, true)
  const header = item.task
    ? GetTaskName(
        typeof item.task === 'string' ? item.task : item.task._id,
        activeJourney,
        activeLanguage
      )
    : item.step
    ? item.step
    : false
  const allUsers = []
  items.forEach((i) => {
    if (!allUsers.find((u) => u._id === i.to._id)) allUsers.push(i.to)
  })
  const users = allUsers.sort(() => 0.5 - Math.random()).slice(0, 3)
  const totalUsers = users.length
  let allQuestions = []
  items.forEach((i) => {
    i.questions.forEach((q) => {
      const group = allQuestions.find((a) => a.id === q._id)
      if (allQuestions.length <= 0) {
        allQuestions = [
          {id: q._id, responses: [q.response], title: q.title}
        ]
      } else if (!group) {
        allQuestions.push({
          id: q._id,
          responses: [q.response],
          title: q.title
        })
      } else group.responses.push(q.response)
    })
  })

  allQuestions.map((q) => {
    const t = q.responses.length
    const average = q.responses.reduce(
      (total, value) => total + value / t,
      0
    )
    const v = q.responses.reduce(
      (total, value) => total + Math.pow(average - value, 2) / t,
      0
    )

    const standardDeviation = Math.sqrt(v).toFixed(2)
    q.average = Math.round(average)
    q.standardDeviation = standardDeviation
  })

  return (
    <CardContainer
      marginH={marginH}
      isFeed={isFeed}
      $color={isShare && !isUser ? 'rgba(211, 219, 242, 0.4)' : bgColor}
      style={{paddingTop: isShare ? 6 : 0, flex: isFeed ? 1 : 0}}
      onClick={() =>
        onPressItem
          ? onPressItem()
          : dispatch(
              updateModal({
                type: 'feedbackrating',
                object: {items, allQuestions},
                isShare: isShare || isCompleted,
                isFeed: isFeed || isCompleted
              })
            )
      }
    >
      {(header || onClose) && (
        <CardTask>
          {header && `${translate('FROM')} ${header}`}
          {onClose && (
            <>
              {!header && <div />}
              <ClickableBox
                onClick={(e) => {
                  e.stopPropagation()
                  onClose(item)
                }}
              >
                <img src={CloseIcon} alt='close' />
              </ClickableBox>
            </>
          )}
        </CardTask>
      )}
      {!isShare && (
        <CardTimeHeader>{moment(item.updatedAt).fromNow()}</CardTimeHeader>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'flex-start'
        }}
      >
        <Col xs={12} style={{display: 'flex', flexDirection: 'column'}}>
          <PeerSectionLine color='transparent'>
            <PeerLine
              style={{
                position: 'relative',
                width: totalUsers === 1 ? 48 : totalUsers === 2 ? 64 : 80
              }}
            >
              {users.map((u, index) => {
                const img = u.image
                  ? u.image
                  : u.photo
                  ? u.photo
                  : {url: UserDefault}
                return (
                  <UserImageBox
                    key={index}
                    style={{
                      width: 38,
                      height: 38,
                      left: index === 0 ? 0 : index === 1 ? 16 : 32,
                      zIndex:
                        index === 0
                          ? 8
                          : index === 1
                          ? 9
                          : index === 2
                          ? 10
                          : 0
                    }}
                  >
                    <PeerPhoto key={index} image={img} />
                  </UserImageBox>
                )
              })}
            </PeerLine>
            <PeerDescription>
              <PeerName>
                {users.map((u, index) =>
                  index > 0 ? `, ${u.name}` : u.name
                )}
              </PeerName>
            </PeerDescription>
          </PeerSectionLine>
          <WhiteLayout
            space='12px 11px'
            spaceB={noSpaceB ? 0 : 28}
            style={{
              backgroundColor: 'transparent'
            }}
          >
            <QuestionsComponent
              questions={questions}
              isShare={isShare}
              isUser={isUser}
            />
          </WhiteLayout>
        </Col>
        <div style={{display: 'flex', maxWidth: 200}}>
          <RatingChartComponent list={allQuestions} />
        </div>
      </div>
    </CardContainer>
  )
}

FeedbackRatingCard.propTypes = {
  items: PropTypes.array.isRequired,
  onPressItem: PropTypes.func,
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  isShare: PropTypes.bool,
  isUser: PropTypes.bool,
  isFeed: PropTypes.bool,
  noSpaceB: PropTypes.bool,
  bgColor: PropTypes.string,
  marginH: PropTypes.number,
  isCompleted: PropTypes.bool
}

FeedbackRatingCard.defaultProps = {
  onPressItem: undefined,
  onClose: undefined,
  isShare: false,
  isUser: false,
  bgColor: undefined,
  isFeed: false,
  noSpaceB: false,
  marginH: 0,
  isCompleted: false
}

export default withLocalize(FeedbackRatingCard)
