import {createReducer} from '@reduxjs/toolkit'

import {updateModal, clearModal} from './ModalActions'

const initialState = false

const ModalReducer = createReducer(initialState, {
  [updateModal]: (state, action) => action.payload,
  [clearModal]: () => initialState
})

export default ModalReducer
