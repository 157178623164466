import {Card, Spin} from 'antd'
import styled, {createGlobalStyle} from 'styled-components'

import {
  NavbarSize,
  PageBackground,
  TextColor,
  PrimaryColor,
  red,
  white,
  DefaultGey,
  LightGey,
  DarkGey,
  ThirdColor,
  SecondaryColor,
  brownGrey
} from './_theme'
import device from './Responsive'

export const BasicStyles = createGlobalStyle`
  body {
    font-family: 'Raleway',  'sans-serif' !important;
    margin: 0;
    padding: 0;
    background: ${PageBackground};
    color: ${TextColor};
  }
  html {
    scroll-behavior: smooth;
  }
`

export const PageContainer = styled.div`
  width: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: ${LightGey};
`

export const PageContent = styled.div`
  display: flex;
  height: calc(100vh - ${NavbarSize});
  width: 100%;
  margin-top: ${NavbarSize};
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
`

export const PageTitle = styled.h2`
  font-size: 27px;
  line-height: 34px;
  font-weight: bold;
  margin-top: ${({top}) => top || 0}px;
  margin-bottom: ${({bottom}) => bottom || 0}px;
`

export const PageSubtitle = styled.h6`
  font-size: 18px;
  line-height: 21px;
  font-weight: regular;
  margin-top: ${({top}) => top || 0}px;
  margin-bottom: ${({bottom}) => bottom || 0}px;
`

export const PageForm = styled.form`
  margin: 0;
  width: 100%;
  display: inline-block;
`

export const Margin = styled.div`
  height: ${({size}) => size || 0}px;
`

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-color: ${LightGey};
`

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10%;
  border-bottom: 2px solid ${LightGey};
  align-items: center;
`
export const TabButton = styled.button`
  width: 50%;
  height: 100%;
  outline: none;
  cursor: pointer;
  display: inline-block;
  background-color: ${({active}) => (active ? ThirdColor : white)};
  border: none;
  color: ${({active}) => (active ? white : DarkGey)};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
`

export const ForgotText = styled.div`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  color: ${DarkGey};
  margin-top: 20px;
  cursor: pointer;
`
export const AccountTextContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  margin-top: 40px;
`

export const AccountText = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.13;
  color: ${SecondaryColor};
  cursor: pointer;
`

export const ImageLogin = styled.img`
  width: 100%;
  height: 100%;
`

export const PageCard = styled(Card)`
  background-color: white;
  max-width: 500px;
  width: 50%;
  padding: 30px;
  display: inline-block;
  border-radius: 5px;
`

export const LoadingSpin = styled(Spin)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: ${({min}) => min || '100%'};
  color: ${({color}) => color || PrimaryColor};

  &&& {
    .ant-spin-dot-item {
      background-color: ${({color}) => color || PrimaryColor};
    }
  }
`

// TODO: Add responsive breaks - 1200, 999, etc
export const ResponsiveContainer = styled.div`
  width: 100%;
  display: inline-block;
  max-width: 1440px;
  padding: 30px 24px;
`

export const BaseLeftMenu = styled.div`
  position: fixed;
  width: 323px;
  height: calc(100vh - ${NavbarSize});
  top: ${NavbarSize};
  left: 0;
  background: white;
  overflow-x: auto;
  padding-top: 40px;

  @media ${device.laptop} {
    width: 250px;
  }
`

export const BaseRightContent = styled.div`
  width: 100%;
  display: flex;
  margin-left: 323px;
  justify-content: center;

  @media ${device.laptop} {
    margin-left: 250px;
  }
`

// notifications
export const TabBarNotification = styled.div`
  width: ${({width}) => width || 6}px;
  height: ${({height}) => height || 6}px;
  background: ${red};
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
  float: right;
  margin-top: 20px;
`

export const TabBarNotificationText = styled.div`
  font-size: 12px;
  font-weight: regular;
  line-height: 14px;
  color: ${white};
  text-align: center;
`

export const WhiteLayout = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${white};
  padding: ${({space}) => space || '20px 0px'};
  margin-bottom: ${({spaceB}) => spaceB || 0}px;
  margin-top: ${({spaceT}) => spaceT || 0}px;
  width: 100%;
`

export const MainText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${({color}) => color || DefaultGey};
  margin: ${({margin}) => margin || '0px'};
`

export const SmallText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({color}) => color || DefaultGey};
`

export const EmptyText = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: ${brownGrey};
`

export const EmptyLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 37px 0px;
  width: 100%;
`

export const VideoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  transition: all 0.3s ease;
  height: ${({isAuto}) => (isAuto ? 'auto' : '100%')};

  @media ${device.laptopL} {
    height: ${({isActiveJourney, isAuto}) =>
      isActiveJourney ? '320px' : isAuto ? 'auto' : '100%'};
  }

  &.lesson {
    &:hover,
    &:visited,
    &:active {
      transform: scale(1.2);
    }
  }
`

export const VideoBox2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: ${({isFeed}) => (isFeed ? 190 : 140)}px;
  background-color: ${LightGey};
  transition: all 0.3s ease;

  &.lesson {
    &:hover,
    &:visited,
    &:active {
      transform: scale(1.2);
    }
  }
`

export const ClickableBox = styled.div`
  cursor: pointer;
  position: absolute;
  right: 3px;
  top: 7px;
`

export const H5P = styled.div`
  display: flex;

  &&& iframe {
    position: absolute;
    top: 24px;
    width: 100%;
    height: 100%;
  }
`

export const H5PJourneyBlock = styled.div`
  display: flex;
  overflow: hidden;

  &&& iframe {
    position: absolute;
    top: 0px;
    width: 100%;
  }
`

export const H5PJourneyDetail = styled.div`
  display: flex;
  overflow: hidden;

  &&& iframe {
    width: 100%;
    height: auto;
  }
`
