import React, {useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useDispatch, useSelector} from 'react-redux'

import EmptyIcon from '../../assets/empty_kudos.png'
import {updateModal} from '../../redux/data/modal/ModalActions'
import KudosCard from '../cards/KudosCard'
import KudosListModal from '../kudos/KudosListModal'
import {
  Container,
  ContainerTitle,
  SeeAll,
  StyledCarousel,
  CarouselCard
} from './SliderStyles'

const LatestKudos = ({translate}) => {
  const [kudosModal, setKudosModal] = useState(false)
  const dispatch = useDispatch()
  const kudos = useSelector((state) => state.latest_kudos)

  return (
    <Container>
      <Row>
        <Col xs={24} style={{display: 'flex'}}>
          <ContainerTitle>{translate('LATEST_KUDOS')}</ContainerTitle>
          {kudos && kudos.length > 0 && (
            <SeeAll onClick={() => setKudosModal(true)}>
              {translate('SEE_ALL')}
            </SeeAll>
          )}
        </Col>
        <Col xs={24}>
          {kudos && kudos.length > 0 ? (
            <StyledCarousel dots autoplay effect='fade'>
              {kudos.map((kudo) => (
                <CarouselCard
                  key={kudo._id}
                  onClick={() =>
                    dispatch(
                      updateModal({
                        type: 'kudos',
                        object: kudo,
                        isShare: true,
                        isFeed: true
                      })
                    )
                  }
                >
                  <KudosCard item={kudo} isFeed isShare isList />
                </CarouselCard>
              ))}
            </StyledCarousel>
          ) : (
            <img
              style={{marginBottom: 24, marginTop: 24, width: '100%'}}
              src={EmptyIcon}
              alt='Empty'
            />
          )}
          {kudosModal && (
            <KudosListModal
              open={!!kudosModal}
              onClose={() => setKudosModal(false)}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

LatestKudos.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(LatestKudos)
