import styled from 'styled-components'

import {ResponsiveContainer} from '../../styles/BasicStyles'

export const LibraryContent = styled(ResponsiveContainer)``

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;
`
export const ComboContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
`
