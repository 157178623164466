import React from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import {InformationTitle} from '../../pages/active_journey/ActiveJourneyStyles'
import {DarkGrey, white} from '../../styles/_theme'
import {MainText} from '../../styles/BasicStyles'
import RatingChartComponent from '../chart/RatingChartComponent'
import {InputLabel, InputBox} from '../input/InputStyles'

const FeedbackRatingDetail = ({translate, feedbacks, title}) => {
  const {allQuestions} = feedbacks

  return (
    <Row>
      <Col xs={24}>
        <InformationTitle>
          {title || translate('FEEDBACK_RECEIVED')}
        </InformationTitle>
      </Col>
      <Col xs={24}>
        <Row style={{backgroundColor: white, padding: '0px 30px 30px'}}>
          <Col
            lg={12}
            md={24}
            style={{display: 'flex', flexDirection: 'column'}}
          >
            {allQuestions.map((question, i) => {
              const color =
                i === 1
                  ? 'red'
                  : i === 2
                  ? 'orange'
                  : i === 3
                  ? 'purple'
                  : 'green'
              return (
                <InputBox key={i} hasTopSpace={30}>
                  <InputLabel marginB={6}>{question.title}</InputLabel>
                  <MainText color={DarkGrey} style={{display: 'flex'}}>
                    <div style={{color}}>&#9733;</div>
                    <div style={{marginLeft: 20}}>&#248;</div> =
                    {question.average}
                    <div style={{marginLeft: 20}}>&sigma;</div> =
                    {question.standardDeviation}
                  </MainText>
                </InputBox>
              )
            })}
          </Col>
          <Col
            lg={12}
            md={24}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly'
            }}
          >
            <MainText color={DarkGrey} style={{display: 'flex'}}>
              <div style={{marginLeft: 20}}>&#248;</div> =
              {translate('AVERAGE')}
              <div style={{marginLeft: 20}}>&sigma;</div> =
              {translate('STANDARD_DEVIATION')}
            </MainText>
            <RatingChartComponent list={allQuestions} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

FeedbackRatingDetail.propTypes = {
  translate: PropTypes.func.isRequired,
  feedbacks: PropTypes.object,
  title: PropTypes.string
}

FeedbackRatingDetail.defaultProps = {
  feedbacks: undefined,
  title: false
}

export default withLocalize(FeedbackRatingDetail)
