import {addNotification} from '../../../redux/data/user/UserActions'
import {
  GetAndUpdateFriends,
  GetAndUpdateFeed,
  GetAndUpdateChats,
  GetAndUpdateActiveJourney,
  GetAndUpdateSuggestedJourneys,
  GetAndUpdateUser,
  GetAndUpdateActivity,
  GetAndUpdateLatestKudos
} from '../../../redux/thunks/UpdateThunk'
import SocketClient from './SocketConnection'

class GlobalNotificationService {
  constructor(store) {
    this.store = store
    this.client = SocketClient.create((client) => this.onSubscribe(client))
  }

  static create = (store) => new GlobalNotificationService(store)

  onSubscribe = (client) => {
    client.subscribe('notification', this.onMessageReceived)
  }

  onMessageReceived = (message) => {
    console.log('onMessageReceived', message)
    const {user} = this.store.getState()
    if (this.isMessage(message, 'UPDATE_CONTENT')) {
      this.store.dispatch(GetAndUpdateUser())
      this.store.dispatch(GetAndUpdateSuggestedJourneys())
    } else if (this.isMessage(message, 'UPDATE_COMMUNITY')) {
      this.store.dispatch(GetAndUpdateFriends())
    } else {
      this.store.dispatch(addNotification(message))
    }

    if (this.isNotMessage(message, 'FEEDBACK')) {
      this.store.dispatch(GetAndUpdateChats())
    }

    if (
      this.isMessage(message, 'JOURNEY_VALIDATION') ||
      this.isMessage(message, 'FEEDBACK') ||
      this.isMessage(message, 'KUDOS')
    ) {
      this.store.dispatch(GetAndUpdateFeed())
      this.store.dispatch(GetAndUpdateActivity())
    }

    if (this.isMessage(message, 'UPDATE_JOURNEY')) {
      this.store.dispatch(GetAndUpdateFeed())
    }

    if (this.isMessage(message, 'KUDOS')) {
      this.store.dispatch(GetAndUpdateLatestKudos())
    }

    if (this.isMessage(message, 'FRIEND')) {
      this.store.dispatch(GetAndUpdateFriends())
      this.store.dispatch(GetAndUpdateActivity())
    }
    console.log(
      'onMessageReceived',
      user?.active_journey,
      message.object_id
    )
    if (
      (this.isMessage(message, 'UPDATE_JOURNEY') ||
        this.isMessage(message, 'JOURNEY_VALIDATION') ||
        this.isMessage(message, 'FEEDBACK_RESPONSE')) &&
      user?.active_journey &&
      user.active_journey._id === message.object_id
    ) {
      this.store.dispatch(
        GetAndUpdateActiveJourney(user.active_journey._id)
      )
    }
  }

  isNotMessage = (message, type) =>
    message.tag.toUpperCase().indexOf(type.toUpperCase()) < 0

  isMessage = (message, type) =>
    message.tag.toUpperCase().indexOf(type.toUpperCase()) > -1
}

export default GlobalNotificationService
