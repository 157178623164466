import React from 'react'

import PropTypes from 'prop-types'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer
} from 'recharts'

import {SecondaryColor, DarkGey, DarkGrey} from '../../styles/_theme'

const ChartComponent = ({list, translate}) => {
  const renderCustomAxisTick = ({x, y, payload}) => {
    const path =
      'M -5.290 7.281 L -3.804 1.236 L -8.560 -2.781 L -2.351 -3.236 L -0.000 -9.000 L 2.351 -3.236 L 8.560 -2.781 L 3.804 1.236 L 5.290 7.281 L 0.000 4.000 Z'

    return (
      <g x={x} y={y}>
        <text x={x - 20} y={y} fontSize={14} textAnchor='middle'>
          {payload.value}
        </text>
        <svg
          x={x - 17}
          y={y - 13}
          width={170}
          height={45}
          viewBox='-10,10,200,10'
          fill={DarkGrey}
        >
          <path d={path} />
        </svg>
      </g>
    )
  }

  const renderLegend = (props) => {
    const {payload} = props
    console.log(props)
    return (
      <ul>
        {payload.map((entry, index) => (
          <li key={`item-${index}`}>{entry.value}</li>
        ))}
      </ul>
    )
  }

  return (
    <div
      style={{
        marginBottom: 20,
        width: '100%'
      }}
    >
      <ResponsiveContainer width='100%' height={250}>
        <LineChart
          height={250}
          margin={{
            top: 15,
            right: 10,
            left: -22,
            bottom: 0
          }}
          data={list}
          style={{width: '100%', cursor: 'pointer'}}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey='name'
            type='number'
            domain={[
              1,
              (dataMax) => {
                if (dataMax > 5) {
                  return dataMax
                }
                return 5
              }
            ]}
            axisLine={false}
          />
          <YAxis
            interval={0}
            domain={[1, 5]}
            axisLine={false}
            tick={renderCustomAxisTick}
          />
          <Legend
            verticalAlign='top'
            align='right'
            height={36}
            payload={[
              {
                value: translate('REFLECTIONS'),
                type: 'line',
                color: SecondaryColor
              },
              {
                value: translate('FEEDBACKS'),
                type: 'line',
                color: DarkGey
              }
            ]}
          />
          {/* <Legend content={renderLegend} /> */}
          <Line
            type='monotone'
            dataKey='reflections'
            stroke={SecondaryColor}
            strokeWidth={4}
            dot={false}
          />
          <Line
            type='monotone'
            dataKey='feedbacks'
            stroke={DarkGey}
            strokeWidth={4}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

ChartComponent.propTypes = {
  list: PropTypes.array
}

ChartComponent.defaultProps = {
  list: []
}
export default ChartComponent
