import React from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {
  InformationTitle,
  CreateButton
} from '../../pages/active_journey/ActiveJourneyStyles'
import StepsList from '../../pages/active_journey/StepsList'
import {WhiteLayout, PageSubtitle} from '../../styles/BasicStyles'
import {GetAndUpdateInactiveJourneys} from '../../redux/thunks/UpdateThunk'
import {clearModal} from '../../redux/data/modal/ModalActions'
import {updateActiveJourney} from '../../redux/data/user/UserActions'
import {SetJourneyActive} from '../../infra/requests/JourneyRequests'
import {ErrorColor} from '../../styles/_theme'

const VersionDetail = ({
  router,
  translate,
  activeJourney,
  journey,
  dispatch
}) => {
  const onPressButton = async () => {
    try {
      if (activeJourney._id !== journey._id) {
        const result = await SetJourneyActive(journey._id)
        dispatch(updateActiveJourney({active_journey: result.data}))
        dispatch(GetAndUpdateInactiveJourneys())
      }
      dispatch(clearModal())
      router.history.push('/active-journey')
    } catch (error) {
      console.warn(error)
    }
  }

  return (
    <Row gutter={[12, 24]}>
      <Col xs={24}>
        <Row>
          <Col xs={16}>
            <InformationTitle>
              {translate('JOURNEY_VERSION')}
            </InformationTitle>
          </Col>
          <Col xs={8}>
            {journey.archived ? (
              <PageSubtitle style={{color: ErrorColor}}>
                {translate('THIS_JOURNEY_IS_ARCHIVED')}
              </PageSubtitle>
            ) : (
              <CreateButton
                category='secondary'
                label={translate(
                  activeJourney._id === journey._id
                    ? 'OPEN_JOURNEY'
                    : 'ACTIVATE_JOURNEY'
                )}
                onClick={onPressButton}
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        <Row>
          <WhiteLayout space='20px 30px 30px'>
            <StepsList completedJourney={journey} onClickStep={() => {}} />
          </WhiteLayout>
        </Row>
      </Col>
    </Row>
  )
}

VersionDetail.propTypes = {
  translate: PropTypes.func.isRequired,
  journey: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  activeJourney: PropTypes.object
}

VersionDetail.defaultProps = {
  journey: undefined,
  activeJourney: undefined
}
const mapStateToProps = (state) => ({
  activeJourney: state.user.active_journey,
  info: state.info
})

const mapActionToProps = (dispatch) => ({dispatch})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(VersionDetail)
)
