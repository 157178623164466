import React, {useState} from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import DefaultLink from '../../assets/default_link.png'
import {GetLinkInfo} from '../../infra/requests/BaseRequests'
import {
  InputErrorColor,
  brownGrey,
  veryLightPink
} from '../../styles/_theme'
import {LoadingSpin, SmallText} from '../../styles/BasicStyles'
import BaseButton from '../buttons/BaseButton'
import {TextInputStyled} from '../input/InputStyles'
import {PreviewImage, AttachBtn, LinkContainer} from './ReviewStyles'

const LinkComponent = ({input, editable, error, translate, onSubmit}) => {
  const [timer, setTimer] = useState(false)
  const [preview, setPreview] = useState(
    input.value?.image ? input.value.image : undefined
  )
  const [defaultImage, setDefaultImage] = useState(undefined)
  const [isLoadingMeta, setIsLoadingMeta] = useState(false)
  const [linkHasError, setLinkHasError] = useState(false)

  const onSubmitLink = async (link) => {
    setPreview(undefined)
    setIsLoadingMeta(true)
    setLinkHasError(false)

    try {
      const result = await GetLinkInfo(link)
      if (result.success) {
        setPreview(result.data.image ? result.data.image : undefined)
        setDefaultImage(!result.data.image ? DefaultLink : undefined)
        setLinkHasError(false)
        const object = {
          link: result.data.url,
          image: result.data.image ? result.data.image : '',
          title: result.data.title
        }
        return input.onChange(object)
      }
      setLinkHasError(translate('LINK_ERROR'))
      setDefaultImage(DefaultLink)
    } catch (e) {
      console.warn(e)
      setLinkHasError(translate('LINK_ERROR'))
      setDefaultImage(DefaultLink)
    } finally {
      setIsLoadingMeta(false)
    }
  }

  const changeLink = (event) => {
    const {value} = event.target
    input.onChange(value)

    if (timer) clearTimeout(timer)
    input.onChange({link: value.trim()})
    const timerId = setTimeout(() => {
      onSubmitLink(value.trim())
    }, 1000)

    setTimer(timerId)
  }

  return (
    <div>
      <LinkContainer>
        {
          isLoadingMeta ?
          <LoadingSpin min='200px' />
          :
          preview ? (
            <AttachBtn
              href={input.value.link}
              rel='noreferrer'
              target='_blank'
            >
              <PreviewImage image={{url: preview}} onClick={() => {}} />
            </AttachBtn>
          )
          :
          defaultImage ?
          <PreviewImage image={{url: defaultImage}} />
          :
          null
        }
      </LinkContainer>
      <TextInputStyled
        disabled={!editable}
        name='link'
        hasError={linkHasError}
        placeholder='https://example.com'
        defaultValue={
          input.value && input.value.link ? input.value.link : ''
        }
        onKeyPress={(e) => {
          e.key === 'Enter' && e.preventDefault()
        }}
        onChange={changeLink}
        style={{
          fontSize: 16,
          lineHeight: 22,
          color: linkHasError || error ? InputErrorColor : brownGrey,
          border: 'none',
          borderBottom: '2px solid',
          borderRadius: 0,
          borderBottomColor:
            linkHasError || error ? InputErrorColor : veryLightPink,
          paddingBottom: 4
        }}
      />
      {linkHasError && (
        <SmallText style={{marginTop: 10}} color={InputErrorColor}>
          {linkHasError}
        </SmallText>
      )}
      {onSubmit && (
        <BaseButton
          type='button'
          label={translate('SAVE')}
          category='secondary'
          style={{float: 'right', marginTop: 24, marginBottom: 30}}
          disabled={isLoadingMeta || linkHasError || !input.value}
          onClick={() => onSubmit({type: 'link', object: input.value})}
        />
      )}
    </div>
  )
}

LinkComponent.propTypes = {
  input: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  error: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
}

LinkComponent.defaultProps = {
  error: false,
  editable: false,
  onSubmit: undefined
}

export default withLocalize(LinkComponent)
