import React, {useState} from 'react'

import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import Show from '../../../assets/icons/eye-off.png'
import BaseButton from '../../../components/buttons/BaseButton'
import TextInput from '../../../components/input/TextInput'
import {AuthTokenKey} from '../../../infra/config/LocalStorageKeys'
import {Login} from '../../../infra/requests/BaseRequests'
import GlobalNotificationService from '../../../infra/services/socket/GlobalNotificationService'
import FormValidator from '../../../infra/services/validations/FormValidator'
import {userSave} from '../../../redux/data/user/UserActions'
import ReduxStore from '../../../redux/ReduxConfig'
import {UpdateAllData} from '../../../redux/thunks/UpdateThunk'
import {InputErrorColor} from '../../../styles/_theme'
import {PageForm, ForgotText, SmallText} from '../../../styles/BasicStyles'
import {LoginButton, TabsContainer} from '../LoginPageStyles'
import ForgotPage from './ForgotPage'

const ValidateLogin = FormValidator.make({
  email: 'required|email',
  password: 'required|min:6'
})

const LoginPage = ({translate, router, dispatch}) => {
  const [forgot, setForgot] = useState(false)
  const [showPassword, setShowPassword] = useState('password')
  const [error, setError] = useState(false)

  const onSubmit = async (fields) => {
    try {
      const result = await Login(fields)

      if (result.success) {
        localStorage.setItem(AuthTokenKey, result.data.token)
        dispatch(userSave(result.data.user))
        dispatch(UpdateAllData())
        GlobalNotificationService.create(ReduxStore)
        router.history.push('/')
      } else if (result.message) setError(translate(result.message))
      else setError(translate('AN_ERROR_OCCURRED'))
    } catch (e) {
      console.error(e)
    }
  }

  const ShowPassword = () => {
    if (showPassword === 'password') {
      setShowPassword('text')
    } else {
      setShowPassword('password')
    }
  }
  return (
    <>
      {!forgot ? (
        <TabsContainer>
          <Form onSubmit={onSubmit} validate={ValidateLogin}>
            {({handleSubmit, valid, pristine, submitting}) => (
              <PageForm onSubmit={handleSubmit}>
                <Field
                  auth
                  component={TextInput}
                  hasTopSpace={30}
                  name='email'
                  label={translate('EMAIL')}
                  placeholder='email@email.com'
                />
                <Field
                  auth
                  component={TextInput}
                  hasTopSpace={30}
                  typeText={showPassword}
                  name='password'
                  label={translate('PASSWORD')}
                  placeholder={translate('PASSWORD')}
                  onTouchIcon={() => ShowPassword()}
                  icon={Show}
                />
                {error && (
                  <SmallText
                    style={{marginTop: 10}}
                    color={InputErrorColor}
                  >
                    {error}
                  </SmallText>
                )}
                <ForgotText onClick={() => setForgot(true)}>
                  {translate('FORGOT_YOUR_PASSWORD')}
                </ForgotText>
                <LoginButton>
                  <BaseButton
                    icon
                    category='primary'
                    label={translate('LOGIN')}
                    type='submit'
                    disabled={submitting || pristine || !valid}
                  />
                </LoginButton>
              </PageForm>
            )}
          </Form>
        </TabsContainer>
      ) : (
        <TabsContainer>
          <ForgotPage router={router} goLogin={() => setForgot(false)} />
        </TabsContainer>
      )}
    </>
  )
}

LoginPage.propTypes = {
  translate: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = () => ({})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(LoginPage)
)
