import React from 'react'

import PropTypes from 'prop-types'
import {
  VictoryChart,
  VictoryPolarAxis,
  VictoryScatter,
  VictoryLabel
} from 'victory'

const RatingChartComponent = ({list}) => {
  const feedbacks = []

  list.forEach((q, i) => {
    const color =
      i === 1 ? 'red' : i === 2 ? 'orange' : i === 3 ? 'purple' : 'green'
    q.responses.forEach((element) => {
      if (feedbacks.find((a) => a.y === 6 - parseInt(element, 10))) {
        const total = feedbacks.filter(
          (a) => a.y === 6 - parseInt(element, 10) && a.q === i
        )
        feedbacks.push({
          y: 6 - parseInt(element, 10),
          x: parseFloat(`${i}.${total.length + 1}`),
          q: i,
          color,
          symbol: 'star'
        })
      } else {
        feedbacks.push({
          y: 6 - parseInt(element, 10),
          x: parseFloat(`${i}.1`),
          q: i,
          color,
          symbol: 'star'
        })
      }
    })
  })

  return (
    <div
      style={{
        margin: -30,
        width: '100%'
      }}
    >
      <VictoryChart polar domain={{x: [0, 4], y: [0, 5]}} width={300}>
        <VictoryPolarAxis
          dependentAxis
          domain={[0, 1]}
          labelPlacement='vertical'
          style={{
            axis: {stroke: 'none'},
            grid: {stroke: 'black', strokeWidth: 20}
          }}
          axisAngle={90}
          tickValues={[1]}
          tickFormat={(e) => 5}
          tickLabelComponent={
            <VictoryLabel
              dy={14}
              style={[{fill: 'white'}]}
              backgroundStyle={{fill: 'black'}}
              backgroundPadding={7}
            />
          }
        />
        <VictoryPolarAxis
          dependentAxis
          domain={[0, 1]}
          labelPlacement='vertical'
          style={{
            axis: {stroke: 'none'},
            grid: {stroke: 'white', strokeWidth: 20}
          }}
          axisAngle={90}
          tickValues={[2]}
          tickFormat={(e) => 4}
        />
        <VictoryPolarAxis
          dependentAxis
          domain={[3]}
          labelPlacement='vertical'
          style={{
            axis: {stroke: 'transparent'},
            grid: {stroke: 'black', strokeWidth: 20},
            tickLabels: {fill: 'black'}
          }}
          axisAngle={0}
          tickValues={[3]}
          tickFormat={3}
        />
        <VictoryPolarAxis
          dependentAxis
          domain={[4]}
          labelPlacement='vertical'
          style={{
            axis: {stroke: 'transparent'},
            grid: {stroke: 'white', strokeWidth: 20},
            tickLabels: {fontSize: 15, padding: 15}
          }}
          axisAngle={180}
          tickValues={[4]}
          tickFormat={(e) => 2}
          tickLabelComponent={<VictoryLabel dx={5} />}
        />
        <VictoryPolarAxis
          dependentAxis
          domain={[2, 4]}
          labelPlacement='vertical'
          style={{
            axis: {stroke: 'transparent'}
          }}
          axisAngle={180}
          tickValues={[4, 2]}
          tickFormat={(e) => 6 - e}
          tickLabelComponent={<VictoryLabel dx={5} />}
        />
        <VictoryPolarAxis
          dependentAxis
          domain={[0, 5]}
          labelPlacement='vertical'
          style={{
            axis: {stroke: 'transparent'},
            grid: {stroke: 'black', strokeWidth: 20},
            tickLabels: {fill: 'black'}
          }}
          tickValues={[5]}
          tickFormat={1}
          tickLabelComponent={
            <VictoryLabel dx={-5} style={[{fill: 'black'}]} />
          }
        />
        <VictoryPolarAxis
          dependentAxis
          domain={[0, 5]}
          labelPlacement='vertical'
          style={{
            axis: {stroke: 'transparent'},
            grid: {stroke: 'transparent'}
          }}
          axisAngle={270}
          tickValues={[5, 3]}
          tickFormat={(e) => 6 - e}
          tickLabelComponent={
            <VictoryLabel dy={0} style={[{fill: 'white'}]} />
          }
        />
        <VictoryPolarAxis
          dependentAxis
          domain={[0, 5]}
          labelPlacement='vertical'
          style={{
            axis: {stroke: 'transparent'},
            grid: {stroke: 'transparent'}
          }}
          axisAngle={0}
          tickValues={[2, 4]}
          tickFormat={(e) => 6 - e}
          tickLabelComponent={<VictoryLabel dx={-5} />}
        />
        <VictoryPolarAxis
          dependentAxis
          domain={[0, 5]}
          labelPlacement='vertical'
          style={{
            axis: {stroke: 'transparent'},
            grid: {stroke: 'transparent'}
          }}
          axisAngle={0}
          tickValues={[5, 3]}
          tickFormat={(e) => 6 - e}
          tickLabelComponent={
            <VictoryLabel dx={-5} style={[{fill: 'white'}]} />
          }
        />
        <VictoryPolarAxis
          dependentAxis
          domain={[0, 5]}
          labelPlacement='vertical'
          style={{
            axis: {stroke: 'transparent'},
            grid: {stroke: 'transparent'}
          }}
          axisAngle={90}
          tickValues={[5, 3]}
          tickFormat={(e) => 6 - e}
          tickLabelComponent={
            <VictoryLabel dy={0} style={[{fill: 'white'}]} />
          }
        />
        <VictoryPolarAxis
          dependentAxis
          domain={[0, 5]}
          labelPlacement='vertical'
          style={{
            axis: {stroke: 'transparent'},
            grid: {stroke: 'transparent'}
          }}
          axisAngle={180}
          tickValues={[5, 3]}
          tickFormat={(e) => 6 - e}
          tickLabelComponent={
            <VictoryLabel dx={5} style={[{fill: 'white'}]} />
          }
        />
        <VictoryPolarAxis
          dependentAxis
          domain={[0, 5]}
          labelPlacement='vertical'
          style={{
            axis: {stroke: 'transparent'},
            grid: {stroke: 'transparent'}
          }}
          axisAngle={270}
          tickValues={[4, 2]}
          tickFormat={(e) => 6 - e}
        />
        <VictoryPolarAxis
          dependentAxis
          domain={[0, 5]}
          labelPlacement='vertical'
          style={{
            axis: {stroke: 'transparent'},
            grid: {stroke: 'transparent'}
          }}
          axisAngle={90}
          tickValues={[4, 2]}
          tickFormat={(e) => 6 - e}
        />
        <VictoryScatter
          data={feedbacks}
          style={{
            data: {fill: ({datum}) => datum.color}
          }}
          size={5}
        />
      </VictoryChart>
    </div>
  )
}

RatingChartComponent.propTypes = {
  list: PropTypes.array
}

RatingChartComponent.defaultProps = {
  list: []
}
export default RatingChartComponent
