import React, {useState, useEffect} from 'react'

import {Collapse, Popconfirm} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {ReactComponent as IconDown} from '../../assets/icons/chevron_down_white.svg'
import {ReactComponent as ArrowIcon} from '../../assets/icons/ic_chevron_right_black.svg'
import ModalMembers from '../../components/settings/ModalMembers'
import {
  GetMyGroups,
  LeaveGroup
} from '../../infra/requests/ProfileRequests'
import BuildFileUrl from '../../infra/services/files/FileService'
import {GeneralName} from '../../infra/services/socket/SocketFunctions'
import {removeNotification} from '../../redux/data/user/UserActions'
import {white} from '../../styles/_theme'
import {LoadingSpin} from '../../styles/BasicStyles'
import {
  MenuTitle,
  Label,
  LineContainer,
  BlockContainer,
  MenuSection,
  MainDescription,
  LanguagesContainer,
  MembersContainer,
  LeaveContainer,
  ImageGroup
} from './SettingsStyles'

const {Panel} = Collapse

const renderHeader = (group, index) => (
  <>
    <LineContainer
      bgColor='#3378C7'
      cursor='pointer'
      spaceT={16}
      key={index}
    >
      <Label color='#FFFFFF'>{group.name}</Label>
      <IconDown />
    </LineContainer>
  </>
)

const ManageGroups = ({translate, dispatch, onLeaveGroup}) => {
  const [groups, setGroups] = useState([])
  const [hasFetchGroups, setHasFetchGroups] = useState(false)
  const [groupModal, setGroupModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const GetGroups = async () => {
    const {data, success} = await GetMyGroups()
    if (success) {
      setHasFetchGroups(true)
      setGroups(data)
    }
  }

  useEffect(() => {
    if (!hasFetchGroups) GetGroups()
  }, [groups, hasFetchGroups])

  const onPressLeaveGroup = async (id) => {
    try {
      setIsLoading(true)
      const result = await LeaveGroup(id)
      console.log(result)
      if (result.success) {
        dispatch(removeNotification(GeneralName(id)))
        if (groups.length < 2 && onLeaveGroup) onLeaveGroup()
        GetGroups()
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.warn(error)
    }
  }

  const GroupDetail = (group) => (
    <>
      {group.image && <ImageGroup src={BuildFileUrl(group.image.file)} />}
      <BlockContainer style={{padding: '20px 30px'}}>
        <MenuSection margin='0px'>{translate('LANGUAGES')}</MenuSection>
        <LanguagesContainer>
          {group.languages.map((language, i) => (
            <MainDescription key={i}>
              {group.languages.length - 1 === i &&
                group.languages.length > 1 &&
                'and '}
              {translate(language)}
              {group.languages.length - 1 !== i &&
                group.languages.length - 2 !== i &&
                ','}
              &nbsp;
            </MainDescription>
          ))}
        </LanguagesContainer>
        {group.domain && (
          <>
            <MenuSection margin='0px'>{translate('DOMAIN')}</MenuSection>
            <MainDescription spaceT={12} spaceB={30}>
              {group.domain}
            </MainDescription>
          </>
        )}
        <MenuSection margin='10px 0px 0px'>
          {translate('GROUP_INFO')}
        </MenuSection>
        <MembersContainer onClick={() => setGroupModal(group._id)}>
          {translate('SEE_GROUP_MEMBERS')}
          <ArrowIcon />
        </MembersContainer>

        <Popconfirm
          placement='top'
          title={translate('CONFIRM_LEAVE_GROUP')}
          onConfirm={() => onPressLeaveGroup(group._id)}
        >
          <LeaveContainer onClick={(e) => e.stopPropagation()} isLoading={isLoading}>
            {translate('LEAVE_GROUP')}
            {isLoading && (<LoadingSpin min='50px' color={white} style={{width: 'auto', marginLeft: 20}} />)}
          </LeaveContainer>
        </Popconfirm>
      </BlockContainer>
      <ModalMembers
        open={groupModal === group._id}
        group={group._id}
        onClose={() => setGroupModal(false)}
      />
    </>
  )

  return (
    <>
      <MenuTitle spaceT={30} spaceB={36}>
        {translate('MANAGE_GROUPS')}
      </MenuTitle>
      <Collapse accordion bordered={false} ghost>
        {groups.length > 0 &&
          groups.map((group, index) => (
            <Panel
              header={renderHeader(group, index)}
              key={index}
              showArrow={false}
            >
              {GroupDetail(group)}
            </Panel>
          ))}
      </Collapse>
    </>
  )
}

ManageGroups.propTypes = {
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  onLeaveGroup: PropTypes.func
}

ManageGroups.defaultProps = {
  onLeaveGroup: undefined
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(ManageGroups)
)
