import React from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'

import {JourneyTitle} from '../../pages/journeys/JourneyStyles'

const DefaultList = ({title, data, renderItem}) => {
  const renderList = () => (
    <Row gutter={12}>
      {data.map((item, index) => (
        <Col key={index} xs={24} lg={8}>
          {renderItem(item)}
        </Col>
      ))}
    </Row>
  )

  return (
    <div style={{width: '100%', marginBottom: 26}}>
      {title && <JourneyTitle>{title}</JourneyTitle>}
      {renderList()}
    </div>
  )
}

DefaultList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired
}

DefaultList.defaultProps = {
  title: undefined
}

export default DefaultList
