import React from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useDispatch} from 'react-redux'

import {updateModal} from '../../redux/data/modal/ModalActions'
import FeedbackCard from '../cards/FeedbackCard'
import KudosCard from '../cards/KudosCard'
import LessonCard from '../cards/LessonCard'
import ReflectionCard from '../cards/ReflectionCard'
import {
  ChatMessageContent,
  MessageContainer,
  MessageUserName,
  MessageText,
  MessageTime,
  LinkText
} from './ChatStyles'

const ChatMessageComponent = ({
  item,
  index,
  isChannel,
  isUser,
  isPrivate
}) => {
  const dispatch = useDispatch()
  const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
  const expression2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
  const regex = new RegExp(expression)
  const regex2 = new RegExp(expression2)

  return (
    <ChatMessageContent key={index}>
      <MessageContainer
        isUser={(isChannel && isUser) || false}
        pending={item.pending}
      >
        {isChannel && !isUser && !isPrivate && (
          <MessageUserName>{item.from.name}</MessageUserName>
        )}
        {item.shared && item.shared.type === 'feedback' && (
          <FeedbackCard
            item={item.shared.object}
            isFeed
            noSpaceB
            isShare
            isUser={(isChannel && isUser) || false}
            bgColor={isChannel && isUser ? 'white' : 'transparent'}
          />
        )}
        {item.shared && item.shared.type === 'reflection' && (
          <ReflectionCard
            item={item.shared.object}
            isShare
            isUser={(isChannel && isUser) || false}
            bgColor={isChannel && isUser ? 'white' : 'transparent'}
          />
        )}
        {item.shared && item.shared.type === 'lesson' && (
          <LessonCard
            item={item.shared.object}
            isUser={(isChannel && isUser) || false}
            bgColor={
              isChannel && isUser ? 'rgba(0,0,0,0.4)' : 'transparent'
            }
            isShare
            onClick={() =>
              dispatch(
                updateModal({
                  type: 'lesson',
                  object: item.shared.object,
                  isShare: true,
                  isUser
                })
              )
            }
          />
        )}
        {item.shared && item.shared.type === 'kudos_sent' && (
          <KudosCard item={item.shared.object} isFeed isShare />
        )}
        <MessageText isUser={(isChannel && isUser) || false}>
          {item.message.split(' ').map((string, i) =>
            string.match(regex) ? (
              <LinkText
                isUser={(isChannel && isUser) || false}
                key={i}
                href={string}
                rel='noreferrer'
                target='_blank'
              >
                {i > 0 && ' '}
                {string}
              </LinkText>
            ) : string.match(regex2) ? (
              <LinkText
                isUser={(isChannel && isUser) || false}
                key={i}
                href={`https://${string}`}
                rel='noreferrer'
                target='_blank'
              >
                {i > 0 && ' '}
                {string}
              </LinkText>
            ) : (
              <span key={i}>
                {i > 0 && ' '}
                {string}
              </span>
            )
          )}
        </MessageText>
        <MessageTime>
          {moment(item.createdAt).calendar(null, {
            sameDay: 'HH:mm',
            lastWeek: 'dddd',
            lastDay: 'dddd',
            nextWeek: 'dddd'
          })}
        </MessageTime>
      </MessageContainer>
    </ChatMessageContent>
  )
}

ChatMessageComponent.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  isChannel: PropTypes.bool,
  isUser: PropTypes.bool,
  isPrivate: PropTypes.bool
}

ChatMessageComponent.defaultProps = {
  item: undefined,
  index: undefined,
  isChannel: false,
  isUser: false,
  isPrivate: false
}

export default withLocalize(ChatMessageComponent)
