import React from 'react'

import {PropTypes} from 'prop-types'
import {Form} from 'react-final-form'

import {ReactComponent as CloseIcon} from '../../assets/icons/ic_close_blue.svg'
import {PageForm} from '../../styles/BasicStyles'
import BaseButton from '../buttons/BaseButton'
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalAction
} from './BaseModalStyles'

const BaseModalForm = ({
  open,
  onClose,
  onSubmit,
  okText,
  title,
  width,
  children,
  validate,
  maskClosable,
  initialValues,
  mutators,
  noPristine,
  keepDirtyOnReinitialize
}) => (
  <Modal
    visible={open}
    onCancel={onClose}
    maskClosable={maskClosable}
    width={width}
    footer={null}
    closeIcon={<CloseIcon />}
  >
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      mutators={mutators}
      keepDirtyOnReinitialize={keepDirtyOnReinitialize}
    >
      {({handleSubmit, submitting, form, pristine, valid}) => (
        <PageForm
          onSubmit={(event) => {
            const promise = handleSubmit(event).then(() => {
              form.reset()
            })
            return promise
          }}
        >
          {(title || onSubmit) && (
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>
              <ModalAction>
                <BaseButton
                  type='submit'
                  category='enter'
                  label={okText}
                  disabled={
                    submitting || (!noPristine && pristine) || !valid
                  }
                />
              </ModalAction>
            </ModalHeader>
          )}
          {children}
        </PageForm>
      )}
    </Form>
  </Modal>
)

BaseModalForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func,
  okText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.elementType
  ]).isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
  maskClosable: PropTypes.bool,
  initialValues: PropTypes.object,
  mutators: PropTypes.object,
  noPristine: PropTypes.bool,
  keepDirtyOnReinitialize: PropTypes.bool
}

BaseModalForm.defaultProps = {
  title: undefined,
  okText: '',
  validate: undefined,
  width: 500,
  maskClosable: false,
  initialValues: undefined,
  mutators: undefined,
  noPristine: false,
  keepDirtyOnReinitialize: false
}

export default BaseModalForm
