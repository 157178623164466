import React, {useEffect, useState} from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import {GetGeneralInfo} from '../../infra/requests/CommunityRequests'
import {LightGey} from '../../styles/_theme'
import BaseModalList from '../modal/BaseModalList'
import PeerBlockComponent from '../peer_block/PeerBlockComponent'

const ModalMembers = ({open, onClose, translate, group}) => {
  const [users, setUsers] = useState([])
  const [hasFetchUsers, setHasFetchUsers] = useState(false)

  useEffect(() => {
    async function GetInfo() {
      try {
        const result = await GetGeneralInfo(group)
        if (result.success) {
          setUsers(result.data.users)
          setHasFetchUsers(true)
        }
      } catch (error) {
        console.warn(error)
      }
    }
    if (!hasFetchUsers) {
      GetInfo()
    }
  }, [hasFetchUsers, group])

  return (
    <BaseModalList
      open={open}
      onClose={onClose}
      title={translate('GROUP_MEMBERS')}
    >
      <>
        {users.map((user, index) => (
          <PeerBlockComponent
            key={index}
            marginB='6px'
            item={user}
            bgColor={LightGey}
          />
        ))}
      </>
    </BaseModalList>
  )
}

ModalMembers.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  group: PropTypes.number.isRequired
}
export default withLocalize(ModalMembers)
