import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import AttachmentComponent from './AttachmentComponent'
import {Title} from './AttachmentsStyles'

const AttachmentsList = ({
  items,
  onPressItem,
  onRemoveItem,
  translate,
  edit,
  isCard
}) => {
  const renderItem = (item, index) => {
    if (!item || !item.type) return null
    if (onRemoveItem) {
      return (
        <AttachmentComponent
          onPressItem={onPressItem}
          onPressClose={() => onRemoveItem(index, item)}
          item={item}
          edit={edit}
          key={index}
        />
      )
    }
    return (
      <AttachmentComponent
        item={item}
        onPressItem={onPressItem}
        key={index}
      />
    )
  }

  return (
    <>
      {!isCard && <Title>{translate('ATTACHMENTS')}</Title>}
      {items.map((item, index) => renderItem(item, index))}
    </>
  )
}

AttachmentsList.propTypes = {
  items: PropTypes.array.isRequired,
  onPressItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  translate: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  isCard: PropTypes.bool
}

AttachmentsList.defaultProps = {
  onPressItem: undefined,
  onRemoveItem: undefined,
  edit: false,
  isCard: false
}

export default withLocalize(AttachmentsList)
