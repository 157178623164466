import React from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useDispatch, useSelector} from 'react-redux'

import UserDefault from '../../assets/default_user.png'
import {ReactComponent as Bookmark} from '../../assets/icons/ic_bookmark.svg'
import {ReactComponent as Bookmarked} from '../../assets/icons/ic_bookmarked.svg'
import {ReactComponent as CloseIcon} from '../../assets/icons/ic_close_blue.svg'
import {ReactComponent as KudosIcon} from '../../assets/icons/ic_kudos.svg'
import {ReactComponent as ChatIcon} from '../../assets/icons/ic_message_circle.svg'
import {
  ToggleFeedbackBookmark,
  ToggleTaskBookmark,
  ToggleReflectionBookmark
} from '../../infra/requests/JourneyRequests'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {
  GetValuePreview,
  GetH5PRatio
} from '../../infra/utils/CommonFunctions'
import {
  InformationContainer,
  JourneyDescription
} from '../../pages/active_journey/ActiveJourneyStyles'
import {updateChat} from '../../redux/data/chat/ChatActions'
import {updateKudos} from '../../redux/data/kudos/KudosActions'
import {clearModal, updateModal} from '../../redux/data/modal/ModalActions'
import {
  toggleBookmark,
  toggleBookmarkTask
} from '../../redux/data/user/UserActions'
import {ThirdColor, LightGey, white} from '../../styles/_theme'
import {Title} from '../attachments/AttachmentsStyles'
import SquareButton from '../buttons/SquareButton'
import {CardTimeHeader} from '../cards/CardsStyles'
import FeedbackDetail from '../feedbacks/FeedbackDetail'
import FeedbackRatingDetail from '../feedbacks/FeedbackRatingDetail'
import RespondFeedback from '../feedbacks/RespondFeedback'
import KudosDetail from '../kudos/KudosDetail'
import LessonDetail from '../lessons/LessonDetail'
import {
  PeerDescription,
  PeerName,
  PeerInfo,
  PeerJob
} from '../peer_block/PeerBlockComponentStyles'
import CreateReflection from '../reflections/CreateReflection'
import ShareComponent from '../share/ShareComponent'
import ValidationDetail from '../validation/ValidationDetail'
import VersionDetail from '../version/VersionDetail'
import VideoPlayer from '../video/VideoPlayer'
import {
  InformationPage,
  Modal,
  UserCard,
  UserBackground,
  UserImage,
  UserBtns,
  RoundBtn,
  UserImageContainer,
  UserDescription,
  UserBottom,
  JourneyImage,
  H5P
} from './BaseModalStyles'

const FullModal = ({info, translate, activeLanguage, router}) => {
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.user)
  const {object, type, isShare, isFeed, task, title, isUser} = info

  const isEmail =
    type === 'feedback'
      ? object.to
        ? typeof object.to === 'string'
        : true
      : !object.from && object.email

  const user = isEmail
    ? object.email
    : type === 'feedback'
    ? object.to
    : object.from

  const userImage = user?.photo?.url || UserDefault

  const renderContent = () => {
    switch (type) {
      case 'respond_feedback':
        return <RespondFeedback feedback={object} />
      case 'feedback':
        return (
          <FeedbackDetail
            feedback={object}
            isShare={isShare}
            title={title}
          />
        )
      case 'kudos':
        return <KudosDetail kudo={object} isShare={isShare} />
      case 'validation':
        return (
          <ValidationDetail currentValidation={object} isShare={isShare} />
        )
      case 'version':
        return <VersionDetail journey={object.journey} router={router} />
      case 'lesson':
        return <LessonDetail lesson={object} isUser={isUser} />
      case 'feedbackrating':
        return <FeedbackRatingDetail feedbacks={object} />
      default:
        return (
          <CreateReflection currentReflection={object} isShare={isShare} />
        )
    }
  }

  const onPressBookmark = async () => {
    if (type === 'feedback') {
      try {
        const result = await ToggleFeedbackBookmark(object._id)
        dispatch(
          toggleBookmark('feedbacks', {
            type: 'feedback',
            object: result.data
          })
        )
        dispatch(
          updateModal({
            type,
            object: result.data,
            isShare,
            isFeed
          })
        )
      } catch (error) {
        console.warn(error)
      }
    }
  }

  const onPressBookmarkReflection = async () => {
    if (type !== 'reflection') return
    if (object.task_id) {
      try {
        const result = await ToggleTaskBookmark(object._id)
        dispatch(
          toggleBookmarkTask({
            type: 'reflection',
            object: {
              _id: object._id,
              response: {...result.data, task_id: object.task_id}
            }
          })
        )
        dispatch(
          updateModal({
            type,
            object: {...result.data, task_id: object.task_id},
            isShare,
            isFeed,
            task
          })
        )
      } catch (error) {
        console.warn(error)
      }
    } else {
      try {
        const result = await ToggleReflectionBookmark(object._id)
        dispatch(
          toggleBookmark('reflections', {
            type: 'reflection',
            object: result.data
          })
        )
        dispatch(
          updateModal({
            type,
            object: {...result.data, task_id: object.task_id},
            isShare,
            isFeed,
            task
          })
        )
      } catch (error) {
        console.warn(error)
      }
    }
  }

  const renderImage = (t) => {
    const {media} = t

    switch (media?.type) {
      case 'images':
        return <JourneyImage ratio={0} image={media.file} />
      case 'videos': {
        const video = GetLanguageValue(media.file.url, activeLanguage.code)
        return <VideoPlayer video={video} height='380px' width='100%' />
      }
      case 'h5p':
      case 'embedded': {
        const file = GetLanguageValue(
          media.file.iframe,
          activeLanguage.code
        )
        const r = GetH5PRatio(file)
        return (
          <H5P
            style={{
              paddingTop: r.isPx || `${r}%`,
              overflow: 'hidden',
              marginBottom: 35
            }}
            dangerouslySetInnerHTML={{
              __html: file
            }}
          />
        )
      }
      default:
        return false
    }
  }

  const renderAllContent = (size) => (
    <Col
      xs={size || 24}
      style={{
        backgroundColor: LightGey,
        overflow: 'auto',
        maxHeight: 'calc(100vh - 200px)'
      }}
    >
      <InformationPage>
        <InformationContainer>{renderContent()}</InformationContainer>
      </InformationPage>
    </Col>
  )

  const renderUsersImages = () => {
    const allUsers = []
    object.items.forEach((i) => {
      if (!allUsers.find((u) => u._id === i.to._id)) allUsers.push(i.to)
    })
    const users = [...allUsers]
    users.sort(() => 0.5 - Math.random()).slice(0, 3)
    const total = users.length

    return (
      <>
        <UserBackground />
        <UserImageContainer>
          {users.map((u, index) => {
            const img = u.image
              ? u.image
              : u.photo
              ? u.photo
              : {url: UserDefault}
            return (
              <div
                key={index}
                style={{
                  position: 'absolute',
                  left:
                    index === 0 && total === 1
                      ? 'calc(50% - 70px)'
                      : index === 0 && total === 2
                      ? 'calc(50% - 95px)'
                      : index === 0
                      ? 'calc(50% - 120px)'
                      : index === 1 && total === 2
                      ? 'calc(50% - 45px)'
                      : index === 1
                      ? 'calc(50% - 70px)'
                      : 'calc(50% - 20px)',
                  zIndex:
                    index === 0
                      ? 8
                      : index === 1
                      ? 9
                      : index === 2
                      ? 10
                      : 0
                }}
              >
                <UserImage image={img} />
              </div>
            )
          })}
        </UserImageContainer>
        <UserBackground color={ThirdColor} />
        <UserDescription>
          <CardTimeHeader>
            {moment(object.items[0].updatedAt).fromNow()}
          </CardTimeHeader>
          <PeerDescription style={{marginBottom: 32}}>
            <PeerName>
              {allUsers.map((u, index) => {
                if (index === 0) return u.name
                return <>, {u.name}</>
              })}
            </PeerName>
          </PeerDescription>
          <>{object.items[0].message}</>
        </UserDescription>
      </>
    )
  }

  return (
    <Modal
      padding='0px'
      visible={!!info}
      onCancel={() => dispatch(clearModal())}
      width='calc(100% - 160px)'
      maxWidth='1200px'
      footer={null}
      closeIcon={<CloseIcon />}
      className='isFull'
    >
      <Row>
        {type !== 'validation' &&
        type !== 'version' &&
        type !== 'reflection' &&
        type !== 'lesson' &&
        type !== 'feedbackrating' ? (
          <>
            <Col
              xs={8}
              style={{
                maxHeight: 'calc(100vh - 200px)',
                backgroundColor: white,
                overflow: 'auto'
              }}
            >
              <UserCard>
                <UserBackground />
                <UserImageContainer>
                  <UserImage image={{url: userImage}} />
                </UserImageContainer>
                <UserBackground color={ThirdColor} />
                {!isEmail && user._id && userLogin._id !== user._id && (
                  <UserBtns>
                    <RoundBtn
                      margin='0px 12px 0px 0px'
                      onClick={() => {
                        document
                          .getElementById('openCommunityDrawer')
                          .click()
                        dispatch(
                          updateChat({
                            type: 'private',
                            user: user._id,
                            name: user.name
                          })
                        )
                      }}
                    >
                      <ChatIcon />
                    </RoundBtn>
                    <RoundBtn
                      margin='0px 0px 0px 12px'
                      onClick={() => dispatch(updateKudos(user))}
                    >
                      <KudosIcon />
                    </RoundBtn>
                  </UserBtns>
                )}
                <UserDescription>
                  <CardTimeHeader>
                    {moment(object.updatedAt).fromNow()}
                  </CardTimeHeader>
                  <PeerDescription style={{marginBottom: 32}}>
                    <PeerName>{!isEmail ? user.name : user}</PeerName>
                    {!isEmail && (
                      <PeerInfo>
                        <PeerJob>{user.email}</PeerJob>
                      </PeerInfo>
                    )}
                  </PeerDescription>
                  {type !== 'kudos' && <>{object.message}</>}
                  {type === 'respond_feedback' && (
                    <>
                      <Title
                        style={{margin: '32px 0px 8px'}}
                        color={ThirdColor}
                      >
                        {translate('GOAL')}
                      </Title>
                      {object.goal}
                      <Title
                        style={{margin: '24px 0px 8px'}}
                        color={ThirdColor}
                      >
                        {translate('INTENTION')}
                      </Title>
                      {object.intention}
                    </>
                  )}
                </UserDescription>
                {!isFeed && type !== 'respond_feedback' && (
                  <UserBottom>
                    <SquareButton onClick={onPressBookmark}>
                      <>
                        {translate('BOOKMARK')}
                        {object.bookmarked ? (
                          <Bookmarked style={{marginLeft: 8}} />
                        ) : (
                          <Bookmark style={{marginLeft: 8}} />
                        )}
                      </>
                    </SquareButton>
                    <ShareComponent
                      isRight
                      item={{
                        to: object.to
                          ? {
                              photo: object.to.photo,
                              company_role: object.to.company_role
                                ? object.to.company_role
                                : '',
                              name: object.to.name,
                              email: object.to.email,
                              _id: object.to._id
                            }
                          : object.email || false,
                        message: object.message,
                        questions: object.questions
                      }}
                      type='feedback'
                    />
                  </UserBottom>
                )}
              </UserCard>
            </Col>
            {renderAllContent(16)}
          </>
        ) : type === 'reflection' &&
          isShare &&
          object.title &&
          object.description ? (
          <>
            <Col
              xs={8}
              style={{
                maxHeight: 'calc(100vh - 200px)',
                // display: 'flex',
                // flexDirection: 'column',
                backgroundColor: white,
                overflow: 'auto'
              }}
            >
              <UserCard>
                {object && renderImage(object)}
                <UserDescription style={{zIndex: 1}}>
                  <Title
                    style={{margin: '32px 0px 10px'}}
                    color={ThirdColor}
                  >
                    {GetValuePreview(object.title, activeLanguage.code)}
                  </Title>
                  <JourneyDescription
                    style={{padding: '0px 0px 20px'}}
                    dangerouslySetInnerHTML={{
                      __html: GetValuePreview(
                        object.description,
                        activeLanguage.code
                      )
                    }}
                  />
                </UserDescription>
              </UserCard>
            </Col>
            {renderAllContent(16)}
          </>
        ) : type === 'reflection' && !isShare ? (
          <>
            <Col
              xs={8}
              style={{
                maxHeight: 'calc(100vh - 200px)',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: white
              }}
            >
              {task && renderImage(task)}
              <UserCard style={{zIndex: 1}}>
                <UserDescription>
                  <CardTimeHeader>
                    {moment(object.updatedAt).fromNow()}
                  </CardTimeHeader>
                  {task ? (
                    <>
                      {GetValuePreview(
                        task.title,
                        activeLanguage && activeLanguage.code
                      )}
                    </>
                  ) : (
                    <>
                      <Title
                        style={{margin: '32px 0px 8px'}}
                        color={ThirdColor}
                      >
                        {translate('GOAL')}
                      </Title>
                      {object.goal}
                      <Title
                        style={{margin: '24px 0px 8px'}}
                        color={ThirdColor}
                      >
                        {translate('INTENTION')}
                      </Title>
                      {object.intention}
                    </>
                  )}
                </UserDescription>
                <UserBottom>
                  <SquareButton onClick={onPressBookmarkReflection}>
                    <>
                      {translate('BOOKMARK')}
                      {object.bookmarked ? (
                        <Bookmarked style={{marginLeft: 8}} />
                      ) : (
                        <Bookmark style={{marginLeft: 8}} />
                      )}
                    </>
                  </SquareButton>
                  <ShareComponent
                    isRight
                    item={{
                      media: task.media,
                      questions: object.questions,
                      attachments: object.attachments,
                      title: object.goal,
                      description: object.intention
                    }}
                    type='reflection'
                  />
                </UserBottom>
              </UserCard>
            </Col>
            {renderAllContent(16)}
          </>
        ) : (type === 'validation' || type === 'version') && !isShare ? (
          <>
            <Col
              xs={8}
              style={{
                maxHeight: 'calc(100vh - 200px)',
                // display: 'flex',
                // flexDirection: 'column',
                backgroundColor: white,
                overflow: 'auto'
              }}
            >
              {object.journey && renderImage(object.journey)}
              <UserCard style={{zIndex: 1}}>
                <UserDescription>
                  <CardTimeHeader>
                    {moment(object.updatedAt).fromNow()}
                  </CardTimeHeader>
                  {object.journey && (
                    <>
                      <Title
                        style={{margin: '32px 0px 8px'}}
                        color={ThirdColor}
                      >
                        {translate('GOAL')}
                      </Title>
                      {object.journey.goal}
                      <Title
                        style={{margin: '24px 0px 8px'}}
                        color={ThirdColor}
                      >
                        {translate('INTENTION')}
                      </Title>
                      {object.journey.intention}
                    </>
                  )}
                </UserDescription>
              </UserCard>
            </Col>
            {renderAllContent(16)}
          </>
        ) : type === 'feedbackrating' ? (
          <>
            <Col
              xs={8}
              style={{
                maxHeight: 'calc(100vh - 200px)',
                backgroundColor: white,
                overflow: 'auto',
                overflowX: 'hidden'
              }}
            >
              <UserCard>{renderUsersImages()}</UserCard>
            </Col>
            {renderAllContent(16)}
          </>
        ) : (
          renderAllContent()
        )}
      </Row>
    </Modal>
  )
}

FullModal.propTypes = {
  translate: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
}

export default withLocalize(FullModal)
