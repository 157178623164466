import React, {useState} from 'react'

import {Collapse} from 'antd'
import arrayMutators from 'final-form-arrays'
import {PropTypes} from 'prop-types'
import {Field, FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'

import {ReactComponent as IconDown} from '../../assets/icons/chevron_down_white.svg'
import {ReactComponent as MinusIcon} from '../../assets/icons/ic_minus.svg'
import {ReactComponent as PlusIcon} from '../../assets/icons/ic_plus.svg'
import {
  CreateTask,
  EditTask,
  RequestFeedback
} from '../../infra/requests/JourneyRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {GetValuePreview} from '../../infra/utils/CommonFunctions'
import {LineContainer} from '../../pages/create_journey/CreateJourneyStyles'
import {JourneyIntroductionText} from '../../pages/journeys/JourneyStyles'
import {
  updateTask,
  updatePeersEngaged,
  updateFeedbacksRequested
} from '../../redux/data/user/UserActions'
import {ThirdColor, white, red} from '../../styles/_theme'
import AddStepsInput from '../input/AddStepsInput'
import {
  InputBox,
  InputLabel,
  TextInputField,
  StepsInputContainer,
  InputView,
  StepsRightBtn,
  SmallText
} from '../input/InputStyles'
import TextAreaInput from '../input/TextAreaInput'
import BaseModalForm from '../modal/BaseModalForm'
import PeersList from './PeersList'
import {FeedbackValidations} from './validations/FeedbackValidations'

const {Panel} = Collapse

const ValidateEmailForm = (values) =>
  FormValidator.make({
    addNew: 'required|email'
  })(values)

const RequestFeedbackModal = ({
  open,
  onClose,
  onSave,
  translate,
  activeLanguage,
  currentTask
}) => {
  const [hasError, setError] = useState(false)
  const dispatch = useDispatch()
  const {
    info: {
      data: {
        templates: {feedback}
      }
    },
    user
  } = useSelector((state) => state)
  const journey = user.active_journey

  const onSaveForm = async (fields) => {
    try {
      let questions
      if (currentTask && currentTask.questions) {
        questions = currentTask.questions.map((currentQuestion) => ({
          _id: currentQuestion._id,
          type: currentQuestion.type,
          title: GetValuePreview(
            currentQuestion.title,
            activeLanguage.code
          ),
          placeholder: GetValuePreview(
            currentQuestion.placeholder,
            activeLanguage.code
          )
        }))
      } else {
        questions = feedback.questions.map((currentQuestion) => ({
          _id: currentQuestion._id,
          type: currentQuestion.type,
          title: GetValuePreview(
            currentQuestion.title,
            activeLanguage.code
          ),
          placeholder: GetValuePreview(
            currentQuestion.placeholder,
            activeLanguage.code
          )
        }))
      }

      const customFeedback = {
        ...fields,
        journey: journey._id,
        goal: journey.goal,
        intention: journey.intention,
        questions
      }

      if (currentTask) {
        let result
        if (currentTask.response) {
          result = await EditTask(currentTask.response._id, {
            ...fields,
            questions
          })
        } else {
          result = await CreateTask({
            ...customFeedback,
            task: currentTask._id
          })
        }
        dispatch(updateTask(currentTask._id, result.data))
        const join = [...fields.to, ...fields.emails]
        dispatch(updatePeersEngaged(join))
        dispatch(updateFeedbacksRequested(join.length))
      } else {
        await RequestFeedback(customFeedback)
        const join = [...fields.to, ...fields.emails]
        dispatch(updatePeersEngaged(join))
        dispatch(updateFeedbacksRequested(join.length))
      }
      if (onSave) onSave()
    } catch (error) {
      console.warn(error)
    }
  }

  const renderHeader = (title) => (
    <LineContainer bgColor={ThirdColor} cursor='pointer' spaceT={4}>
      {title}
      <IconDown />
    </LineContainer>
  )

  const addNewField = (value, values, form) => {
    setError(false)
    if (
      value.trim().length > 0 &&
      !ValidateEmailForm({addNew: value.trim()}).addNew
    ) {
      if (value.trim() === user.email) {
        return setError(translate('ERROR_SAME_USER_EMAIL'))
      }

      const selected = values.emails || []
      const changes = [...selected]
      changes.push(value.trim())

      form.change('emails', changes)
      form.change('addNew', '')
    } else {
      setError(true)
    }
  }

  const onPressListItem = (peer, values, form) => {
    const selected = values.to || []
    let changes = [...selected]
    const exist = changes.findIndex((u) => peer._id === u._id)

    if (exist >= 0) {
      changes = values.to.filter((u) => peer._id !== u._id)
    } else {
      changes.push(peer)
    }
    form.change('to', changes)
  }

  return (
    <BaseModalForm
      open={open}
      onClose={onClose}
      title={translate('REQUEST_FEEDBACK')}
      onSubmit={onSaveForm}
      validate={FeedbackValidations}
      okText={translate('REQUEST')}
      initialValues={{
        message: currentTask
          ? GetValuePreview(
              currentTask.message,
              activeLanguage && activeLanguage.code
            )
          : GetValuePreview(
              feedback.message,
              activeLanguage && activeLanguage.code
            ),
        to: [],
        emails: []
      }}
      mutators={{
        ...arrayMutators
      }}
      noPristine
      keepDirtyOnReinitialize
    >
      <FormSpy subscription={{values: true}}>
        {({values, form}) => {
          const peersSelectedTotal = values.to ? values.to.length : 0
          return (
            <>
              <Field
                name='message'
                refField='message'
                label={translate('WRITE_YOUR_MESSAGE_HERE')}
                placeholder={translate('WRITE_FEEDBACK_PLACEHOLDER')}
                component={TextAreaInput}
                numberOfLines={5}
                minHeight={182}
                editable
              />
              <InputBox hasTopSpace={40}>
                <InputLabel>{translate('SELECT_PEER(S)')}</InputLabel>
                <JourneyIntroductionText>
                  {translate('SELECT_EMAILS_EXPLANATION')}
                </JourneyIntroductionText>
                <Collapse accordion bordered={false} ghost>
                  <Panel
                    showArrow={false}
                    header={renderHeader(
                      !peersSelectedTotal
                        ? translate('SELECT_PEER(S)')
                        : peersSelectedTotal === 1
                        ? `${peersSelectedTotal} ${translate(
                            'PEER_SELECTED'
                          )}`
                        : `${peersSelectedTotal} ${translate(
                            'PEERS_SELECTED'
                          )}`
                    )}
                  >
                    <PeersList
                      selectedPeers={values.to}
                      onPressListItem={(peer) =>
                        onPressListItem(peer, values, form)
                      }
                    />
                  </Panel>
                  <Panel
                    showArrow={false}
                    header={renderHeader(translate('ADD_EMAIL(S)'))}
                  >
                    <>
                      <FieldArray name='emails'>
                        {({fields}) =>
                          fields.map((item, index) => (
                            <StepsInputContainer
                              isEmail
                              className='isEmail'
                              key={index}
                            >
                              <div style={{flex: 1}}>
                                <InputView>
                                  <TextInputField
                                    placeholder={translate(
                                      'WRITE_EMAIL_HERE'
                                    )}
                                    value={fields.value[index]}
                                    color={white}
                                    readOnly
                                  />
                                  <StepsRightBtn
                                    onClick={() => {
                                      fields.remove(index)
                                      setError(false)
                                    }}
                                  >
                                    <MinusIcon
                                      style={{alignSelf: 'center'}}
                                    />
                                  </StepsRightBtn>
                                </InputView>
                              </div>
                            </StepsInputContainer>
                          ))
                        }
                      </FieldArray>
                      <Field
                        component={AddStepsInput}
                        name='addNew'
                        label={translate('ADD_EMAIL')}
                        placeholder={translate('WRITE_EMAIL_HERE')}
                        icon={<PlusIcon style={{alignSelf: 'center'}} />}
                        onEnter={(value) =>
                          addNewField(value, values, form)
                        }
                        onChange={() => {
                          setError(false)
                        }}
                        isEmail
                        isNew
                      />
                      {hasError && (
                        <SmallText color={red}>
                          {typeof hasError === 'string'
                            ? hasError
                            : translate('AN_ERROR_OCCURRED')}
                        </SmallText>
                      )}
                    </>
                  </Panel>
                </Collapse>
              </InputBox>
            </>
          )
        }}
      </FormSpy>
    </BaseModalForm>
  )
}
RequestFeedbackModal.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  currentTask: PropTypes.object
}

RequestFeedbackModal.defaultProps = {
  currentTask: undefined
}

export default withLocalize(RequestFeedbackModal)
