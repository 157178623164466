import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import EmptyIcon from '../../assets/icons/empty-layout.png'
import {EmptyText, EmptyLayout} from '../../styles/BasicStyles'

const EmptyLayoutComponent = ({tag, translate}) => (
  <EmptyLayout>
    <img
      style={{marginBottom: 24, width: 120}}
      src={EmptyIcon}
      alt='Empty'
    />
    <EmptyText>{translate(tag || 'EMPTY_VIEW')}</EmptyText>
  </EmptyLayout>
)

EmptyLayoutComponent.propTypes = {
  tag: PropTypes.string,
  translate: PropTypes.func.isRequired
}

EmptyLayoutComponent.defaultProps = {
  tag: undefined
}

export default withLocalize(EmptyLayoutComponent)
