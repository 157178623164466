import React, {useState} from 'react'

import PropTypes from 'prop-types'
import {Field, FormSpy} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'

import MinusIcon from '../../assets/icons/ic_minus.svg'
import PlusIcon from '../../assets/icons/ic_plus.svg'
import {InviteFriend} from '../../infra/requests/CommunityRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {GetAndUpdateFriends} from '../../redux/thunks/UpdateThunk'
import {InputErrorColor} from '../../styles/_theme'
import {SmallText} from '../../styles/BasicStyles'
import AddStepsInput from '../input/AddStepsInput'
import {InputLabel} from '../input/InputStyles'
import BaseModalForm from '../modal/BaseModalForm'

const ValidateEmail = FormValidator.make({
  addNew: 'required|email'
})

const ValidateForm = FormValidator.make({
  emails: 'required|email'
})

const InviteFriendModal = ({
  open,
  onClose,
  onSave,
  translate,
  dispatch
}) => {
  const [error, setError] = useState(false)

  const onSaveForm = async (fields) => {
    setError(false)
    try {
      const result = await InviteFriend({
        email: fields.emails
      })
      if (result.success) {
        dispatch(GetAndUpdateFriends())
        if (onSave) onSave()
      } else setError(translate('AN_ERROR_OCCURRED'))
    } catch (e) {
      setError(e.message)
      console.warn(e)
    }
  }

  const addNewField = (value, form) => {
    if (
      value.trim().length <= 0 ||
      ValidateEmail({addNew: value.trim()}).addNew
    ) {
      setError(translate(ValidateEmail({addNew: value.trim()}).addNew))
    } else if (form) {
      setError(false)
      form.change('emails', value)
      form.change('addNew', '')
    }
  }

  return (
    <BaseModalForm
      open={open}
      onClose={onClose}
      title={translate('INVITE_PEER')}
      onSubmit={onSaveForm}
      validate={ValidateForm}
      okText={translate('INVITE')}
      initialValues={{
        addNew: '',
        emails: undefined
      }}
      mutators={{
        resetEmails: (args, state, utils) => {
          utils.changeValue(state, 'emails', () => '')
        }
      }}
    >
      <FormSpy subscription={{values: true}}>
        {(props) => (
          <>
            <InputLabel>{translate('ADD_EMAIL')}</InputLabel>
            {props.values?.emails && (
              <Field
                hasTopSpace={false}
                component={AddStepsInput}
                name='emails'
                label={translate('ADD_EMAIL')}
                placeholder={translate('WRITE_EMAIL_HERE')}
                icon={<img src={MinusIcon} alt='MinusIcon' />}
                value={props.values?.emails}
                isSecondary={false}
                onPressIcon={() => {
                  props.form.mutators.resetEmails()
                  setError(false)
                }}
                isEmail
              />
            )}
            {!props.values?.emails && (
              <Field
                component={AddStepsInput}
                name='addNew'
                label={translate('ADD_EMAIL')}
                placeholder={translate('WRITE_EMAIL_HERE')}
                icon={<img src={PlusIcon} alt='PlusIcon' />}
                onEnter={(value) => addNewField(value, props.form)}
                isEmail
                isNew
              />
            )}
            {error && (
              <SmallText color={InputErrorColor}>{error}</SmallText>
            )}
          </>
        )}
      </FormSpy>
    </BaseModalForm>
  )
}

InviteFriendModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default withLocalize(InviteFriendModal)
