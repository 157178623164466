import styled from 'styled-components'

import {
  brownGrey,
  DefaultGey,
  ThirdColor,
  DarkGey,
  SuccessColor,
  white,
  DarkGrey,
  DarkRed
} from '../../styles/_theme'
import ImageComponent from '../images/ImageComponent'

export const PeerSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 14px;
  background: ${({bgColor, multicolor, isOdd}) =>
    bgColor || (multicolor && isOdd ? 'transparent' : white)};
  margin-bottom: ${({marginB, multicolor}) =>
    marginB || (multicolor ? '0px' : '4px')};
  align-items: center;
  cursor: pointer;
`

export const PeerSectionLine = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({noPadding}) => (noPadding ? '0px 12px' : '12px 14px')};
  background: ${({multicolor, isOdd, color}) =>
    color || (multicolor && isOdd ? 'transparent' : white)};
  margin-bottom: ${({marginB, multicolor}) =>
    marginB || (multicolor ? '0px' : '4px')};
  align-items: center;
`

export const PeerLine = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

export const PeerPhoto = styled(ImageComponent)`
  width: 38px;
  height: 38px;
  border-radius: 19px;
  background-color: ${brownGrey};
  margin-right: 8px;
`

export const PeerDescription = styled.div`
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 5;
  width: calc(100% - 46px);
`

export const PeerInfo = styled.div`
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
`

export const PeerName = styled.div`
  font-size: 16px;
  color: ${({color}) => color || DarkGrey};
  font-weigh: 600;
  line-height: 18px;
`

export const PeerCompany = styled.div`
  font-size: 14px;
  color: ${ThirdColor};
  font-weigh: 400;
  line-height: 18px;
  margin-right: 8px;
`

export const PeerJob = styled.div`
  font-size: 14px;
  color: ${({color}) => color || DarkGey};
  font-weigh: 400;
  line-height: 16px;
`

export const SelectContainer = styled.div`
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  &:hover,
  &:visited,
  &:active {
    svg path {
      fill: ${DarkRed};
    }
  }
`

export const SelectContainerBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({bgColor}) => bgColor || 'transparent'};
  border-radius: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;

  &:hover,
  &:visited,
  &:active {
    svg path {
      fill: ${DarkRed};
    }
  }
`

export const SelectCircle = styled.div`
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-width: 1px;
  border-color: ${({isSelected}) =>
    isSelected ? SuccessColor : DefaultGey};
  border-radius: 17px;
  background-color: ${({isSelected}) =>
    isSelected ? SuccessColor : white};
  float: right;
  display: flex;
`
