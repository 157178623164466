import React, {useState} from 'react'

import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import AlertService from '../../components/alert/AlertService'
import BaseButton from '../../components/buttons/BaseButton'
import TextInput from '../../components/input/TextInput'
import PhotoComponent from '../../components/media_picker/PhotoComponent'
import {
  UpdateProfile,
  UpdatePhoto
} from '../../infra/requests/ProfileRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {userSave, userEdit} from '../../redux/data/user/UserActions'
import {InputErrorColor} from '../../styles/_theme'
import {PageForm, SmallText} from '../../styles/BasicStyles'
import {BlockContainer, MenuTitle, PhotoContainer} from './SettingsStyles'

const ValidateEditProfile = FormValidator.make({
  name: 'required',
  email: 'required|email'
})

const Details = ({translate, dispatch, user}) => {
  const [error, setError] = useState(false)
  const [image, setImage] = useState(user.photo)
  const [loading, setLoading] = useState(false)

  const onSubmit = async (values) => {
    try {
      const result = await UpdateProfile({...values})
      if (result.success) {
        AlertService.success(translate('SAVED_SUCCESS'))
        dispatch(userSave({...user, name: result.data.name}))
      } else {
        setError(translate('AN_ERROR_OCCURRED'))
      }
    } catch (e) {
      console.warn(e)
    }
  }

  const onPickImage = async (img) => {
    try {
      setLoading(true)
      const result = await UpdatePhoto(img)
      if (result.success) {
        setImage(result.data.photo)
        dispatch(userEdit({...user, photo: result.data.photo}))
      }
      setLoading(false)
    } catch (e) {
      console.warn(e)
      setLoading(false)
    }
  }

  return (
    <>
      <MenuTitle spaceT={30} spaceB={36}>
        {translate('DETAILS')}
      </MenuTitle>
      <BlockContainer>
        <PhotoContainer>
          <PhotoComponent
            isCropCircle
            onSelectImage={onPickImage}
            onPressClose={() => setImage(false)}
            image={image}
            loading={loading}
          />
        </PhotoContainer>
        <Form
          onSubmit={onSubmit}
          validate={ValidateEditProfile}
          initialValues={user}
        >
          {({handleSubmit, submitting, valid, dirty}) => (
            <PageForm onSubmit={handleSubmit}>
              <Field
                component={TextInput}
                name='name'
                refField='name'
                label={translate('NAME')}
              />
              <Field
                component={TextInput}
                name='email'
                refField='email'
                hasTopSpace={30}
                label={translate('EMAIL')}
                disabled
              />
              {error && (
                <SmallText style={{marginTop: 10}} color={InputErrorColor}>
                  {error}
                </SmallText>
              )}
              <BaseButton
                type='submit'
                label={translate('SAVE')}
                category='secondary'
                style={{float: 'right', marginTop: 24}}
                disabled={submitting || !valid || !dirty}
              />
            </PageForm>
          )}
        </Form>
      </BlockContainer>
    </>
  )
}

Details.propTypes = {
  translate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(Details)
)
