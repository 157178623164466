import React, {useState} from 'react'

import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import Show from '../../assets/icons/eye-off.png'
import AlertService from '../../components/alert/AlertService'
import BaseButton from '../../components/buttons/BaseButton'
import TextInput from '../../components/input/TextInput'
import {ChangePassword} from '../../infra/requests/ProfileRequests'
import {InputErrorColor} from '../../styles/_theme'
import {PageForm, SmallText} from '../../styles/BasicStyles'
import {MenuTitle, BlockContainer} from './SettingsStyles'

const ResetPassword = ({translate}) => {
  const [showNewPassword, setShowNewPassword] = useState('password')
  const [showRepeatPassword, setShowRepeatPassword] = useState('password')
  const [error, setError] = useState(false)

  const onSubmit = async (values) => {
    try {
      const result = await ChangePassword({...values})
      if (result.success) {
        AlertService.success(translate('SAVED_SUCCESS'))
      } else {
        setError(translate('AN_ERROR_OCCURRED'))
      }
    } catch (e) {
      console.warn(e)
    }
  }

  const ShowPassword = (value) => {
    if (value === 'new_password') {
      if (showNewPassword === 'password') {
        setShowNewPassword('text')
      } else {
        setShowNewPassword('password')
      }
    } else if (value === 'repeat_password') {
      if (showRepeatPassword === 'password') {
        setShowRepeatPassword('text')
      } else {
        setShowRepeatPassword('password')
      }
    }
  }

  return (
    <>
      <MenuTitle spaceT={30} spaceB={36}>
        {translate('RESET_PASSWORD')}
      </MenuTitle>
      <BlockContainer>
        <Form onSubmit={onSubmit}>
          {({handleSubmit, form, submitting, valid, dirty}) => (
            <PageForm
              onSubmit={(event) => {
                const promise = handleSubmit(event).then(() => {
                  form.reset()
                })
                return promise
              }}
            >
              <Field
                auth
                component={TextInput}
                name='new_password'
                refField='new_password'
                label={translate('PASSWORD')}
                typeText={showNewPassword}
                placeholder={translate('PASSWORD')}
                onTouchIcon={() => ShowPassword('new_password')}
                icon={Show}
              />
              <Field
                auth
                component={TextInput}
                name='repeat_password'
                refField='repeat_password'
                hasTopSpace={30}
                label={translate('CONFIRM_PASSWORD')}
                typeText={showRepeatPassword}
                placeholder={translate('PASSWORD')}
                onTouchIcon={() => ShowPassword('repeat_password')}
                icon={Show}
              />
              {error && (
                <SmallText style={{marginTop: 10}} color={InputErrorColor}>
                  {error}
                </SmallText>
              )}
              <BaseButton
                type='submit'
                label={translate('SAVE')}
                category='secondary'
                style={{float: 'right', marginTop: 24}}
                disabled={submitting || !valid || !dirty}
              />
            </PageForm>
          )}
        </Form>
      </BlockContainer>
    </>
  )
}

ResetPassword.propTypes = {
  translate: PropTypes.func.isRequired
}

const mapStateToProps = () => ({})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(ResetPassword)
)
