import {createReducer} from '@reduxjs/toolkit'

import {updateChat} from './ChatActions'

const initialState = null

const ChatReducer = createReducer(initialState, {
  [updateChat]: (state, action) => action.payload || initialState
})

export default ChatReducer
