import {createAction} from '@reduxjs/toolkit'

import {RemoveNotification} from '../../../infra/requests/BaseRequests'

export const userSave = createAction('user/save', (data) => ({
  payload: data
}))
export const userEdit = createAction('user/edit')
export const userDelete = createAction('user/delete')

export const removeNotification = createAction(
  'user/notifications/delete',
  (data) => {
    RemoveNotification(data)
    return {
      payload: data
    }
  }
)
export const addNotification = createAction('user/notifications/add')

export const updateActiveJourney = createAction(
  'user/active_journey/update',
  (data) => ({
    payload: data.active_journey
  })
)

export const updateTask = createAction(
  'user/active_journey/task/update',
  (id, data) => ({
    payload: {id, data}
  })
)

export const updateTaskReflection = createAction(
  'user/active_journey/task_reflection/update',
  (id, data) => ({
    payload: {id, data}
  })
)

export const updateReflection = createAction(
  'user/active_journey/reflection/update',
  (data) => ({
    payload: data
  })
)

export const newReflection = createAction(
  'user/active_journey/reflection/create',
  (data) => ({
    payload: data
  })
)

export const updatePeersEngaged = createAction(
  'user/active_journey/peers_engaged/update',
  (data) => ({
    payload: data
  })
)

export const updateFeedbacksRequested = createAction(
  'user/active_journey/feedbacks_requested/update',
  (data) => ({
    payload: data
  })
)

export const toggleBookmark = createAction(
  'user/active_journey/toggle_bookmark',
  (type, object) => ({
    payload: {type, object}
  })
)

export const toggleBookmarkTask = createAction(
  'user/active_journey/toggle_bookmark_task',
  (data) => ({
    payload: data
  })
)
