import React from 'react'

import PropTypes from 'prop-types'

import {DefaultGey, ThirdColor} from '../../styles/_theme'
import {
  StarRatingContainer,
  LikertSlider,
  LikertBtn,
  ReviewSubTitle,
  LikertContent,
  LikertBtnIn
} from './ReviewStyles'

const LikertRatingComponent = ({
  input,
  noEditable,
  isRight,
  editable,
  spacet,
  spaceb,
  placeholder,
  small
}) => {
  const edit = !noEditable && editable

  const renderStartProgress = () => {
    if (edit && (!input.value || !input.value === '')) {
      input.onChange('0')
    }
    return (
      <div
        style={{
          flex: 1,
          height: small ? 20 : 40,
          flexDirection: 'row',
          width: small ? 140 : '100%'
        }}
      >
        <LikertContent>
          <LikertBtn
            small={small}
            color={input.value == -2 ? ThirdColor : DefaultGey}
            onClick={() => (edit ? input.onChange(-2) : {})}
            className={edit ? 'editable' : ''}
          >
            {input.value == -2 && <LikertBtnIn />}
          </LikertBtn>
          <LikertSlider small={small} selected={input.value == -2}>
            -2
          </LikertSlider>
        </LikertContent>
        <LikertContent>
          <LikertBtn
            small={small}
            color={input.value == -1 ? ThirdColor : DefaultGey}
            onClick={() => (edit ? input.onChange(-1) : {})}
            className={edit ? 'editable' : ''}
          >
            {input.value == -1 && <LikertBtnIn />}
          </LikertBtn>
          <LikertSlider small={small} selected={input.value == -1}>
            -1
          </LikertSlider>
        </LikertContent>
        <LikertContent>
          <LikertBtn
            small={small}
            color={input.value == 0 ? ThirdColor : DefaultGey}
            onClick={() => (edit ? input.onChange(0) : {})}
            className={edit ? 'editable' : ''}
          >
            {input.value == 0 && <LikertBtnIn />}
          </LikertBtn>
          <LikertSlider small={small} selected={input.value == 0}>
            0
          </LikertSlider>
        </LikertContent>
        <LikertContent>
          <LikertBtn
            small={small}
            color={input.value == 1 ? ThirdColor : DefaultGey}
            onClick={() => (edit ? input.onChange(1) : {})}
            className={edit ? 'editable' : ''}
          >
            {input.value == 1 && <LikertBtnIn />}
          </LikertBtn>
          <LikertSlider small={small} selected={input.value == 1}>
            1
          </LikertSlider>
        </LikertContent>
        <LikertContent>
          <LikertBtn
            small={small}
            color={input.value == 2 ? ThirdColor : DefaultGey}
            onClick={() => (edit ? input.onChange(2) : {})}
            className={edit ? 'editable' : ''}
          >
            {input.value == 2 && <LikertBtnIn />}
          </LikertBtn>
          <LikertSlider small={small} selected={input.value == 2}>
            2
          </LikertSlider>
        </LikertContent>
      </div>
    )
  }

  return (
    <>
      {placeholder && (
        <div style={{flex: 1, width: '100%'}}>
          <ReviewSubTitle>{placeholder}</ReviewSubTitle>
        </div>
      )}
      <StarRatingContainer
        isRight={isRight}
        spacet={spacet}
        spaceb={spaceb}
      >
        {renderStartProgress()}
      </StarRatingContainer>
    </>
  )
}

LikertRatingComponent.propTypes = {
  input: PropTypes.object.isRequired,
  noEditable: PropTypes.bool,
  isRight: PropTypes.bool,
  editable: PropTypes.bool,
  spacet: PropTypes.number,
  spaceb: PropTypes.number,
  placeholder: PropTypes.string,
  small: PropTypes.bool
}

LikertRatingComponent.defaultProps = {
  noEditable: false,
  isRight: false,
  editable: false,
  spacet: undefined,
  spaceb: undefined,
  small: false,
  placeholder: undefined
}

export default LikertRatingComponent
