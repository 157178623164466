import {RightOutlined} from '@ant-design/icons'
import styled from 'styled-components'

import ImageComponent from '../../components/images/ImageComponent'
import {
  BorderColor,
  TextColor,
  DarkGrey,
  white,
  red,
  LightGey,
  ThirdColor,
  PrimaryColor,
  DarkGey,
  DarkRed
} from '../../styles/_theme'

export const SettingsContainer = styled.div`
  width: 100%;
  max-width: 700px;
  padding: 30px;

  .ant-collapse
    > .ant-collapse-item.ant-collapse-no-arrow
    > .ant-collapse-header {
    padding: 0px;
  }
  .ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 0px;
  }
`

export const MenuTitle = styled.div`
  font-size: 22px;
  line-height: 1.09;
  font-weight: bold;
  color: ${DarkGrey};
  margin-left: ${({spaceL}) => spaceL || 0}px;
  margin-top: ${({spaceT}) => spaceT || 0}px;
  margin-bottom: ${({spaceB}) => spaceB || 0}px;
`

export const MenuSection = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  margin: ${({margin}) => margin || '20px 0px 10px 20px'};
`

export const LanguagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  margin-top: 12px;
`

export const MembersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  padding: 20px;
  background-color: ${BorderColor};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  cursor: pointer;
  margin-top: 8px;

  &:hover,
  &:visited,
  &:active {
    background-color: ${DarkGey};
    color: ${white};
    svg path {
      fill: ${white};
    }
  }
`

export const LeaveContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  padding: 20px;
  background-color: ${red};
  opacity: ${({isLoading}) => isLoading ? 0.5 : 1};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  color: #ffffff;
  cursor: pointer;
  margin-top: 30px;

  &:hover,
  &:visited,
  &:active {
    background-color: ${DarkRed};
  }
`

export const ImageGroup = styled.img`
  width: 100%;
  background-color: #d3dbf2;
`

export const MenuItem = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({selected}) => (selected ? ThirdColor : white)};
  font-size: 16px;
  line-height: 1.38;
  font-weight: 500;
  color: ${({color, selected}) => (color || selected ? white : DarkGrey)};
  cursor: pointer;
  padding: 0px 8px 0px 20px;
  height: 66px;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({borderB}) => (borderB ? '2px solid #e3e3e3' : null)};

  &:hover,
  &:visited,
  &:active {
    background-color: ${PrimaryColor};
    color: ${white};
    svg path {
      fill: ${white};
    }
  }

  svg.active path {
    fill: ${white};
  }
`

export const ArrowIcon = styled(RightOutlined)`
  position: absolute;
  font-size: 14px;
  top: 18px;
  right: 15px;
  color: ${TextColor};
`
export const OptionsList = styled.div`
  width: 100%;
  margin-top: ${({spaceT}) => spaceT || 0}px;
`

export const BottomBack = styled.div`
  display: flex;
  width: 100%;
  height: 66px;
  background-color: ${BorderColor};
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  font-weight: 500;
  color: #0c0c0c;
  margin-bottom: ${({spaceB}) => spaceB || 0}px;
`

export const SaveButton = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 40px;
`

export const EnterButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
`

export const ButtonGroupLeft = styled.div`
  display: flex;
  margin-right: 5px;
  justify-content: flex-end;
  align-self: flex-end;
`

export const ButtonGroupRight = styled.div`
  display: flex;
  margin-left: 5px;
  justify-content: flex-start;
  align-self: flex-start;
`

export const Label = styled.div`
  font-size: 16px;
  line-height: 1.38px;
  font-weight: 500;
  color: ${({color}) => color || DarkGrey};
`

export const LabelDescription = styled.div`
  font-size: 16px;
  font-size: 18px;
  font-weight: 500;
  overflow-wrap: anywhere;
`

export const LabelError = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${red};
  margin-top: 10px;
`

export const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${({spaceT}) => spaceT || 0}px;
  padding-vertical: ${({spaceV}) => spaceV || 16}px;
  padding-horizontal: ${({spaceH}) => spaceH || 14}px;
  background-color: ${({bgColor}) => bgColor || '#FFFFFF'};
  padding: 30px;
`

export const PhotoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`

export const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: ${({cursor}) => cursor || null};
  align-items: center;
  margin-top: ${({spaceT}) => spaceT || 0}px;
  padding-vertical: ${({spaceV}) => spaceV || 16}px;
  padding-horizontal: ${({spaceH}) => spaceH || 14}px;
  background-color: ${({bgColor}) => bgColor || '#FFFFFF'};
  padding: 20px;

  &:hover,
  &:visited,
  &:active {
    svg path {
      fill: ${PrimaryColor};
    }
  }
`

export const LineSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const MainTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${DarkGrey};
  line-height: 20px;
  margin-bottom: 12px;
  margin-top: ${({spaceT}) => spaceT || 0}px;
`

export const MainDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: ${({spaceB}) => spaceB || 0}px;
  margin-top: ${({spaceT}) => spaceT || 0}px;
`

// MODAL
export const ModalContainer = styled.div`
  width: 100%;
  height: 66px;
  background-color: ${LightGey};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
  padding: 20px;
`

export const ModalImage = styled(ImageComponent)`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: grey;
  margin-right: 10px;
`

export const ModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ModalName = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
`

export const ModalTextSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  color: #3378c8;

  & span {
    color: #828282;
  }
`
