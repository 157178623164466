import client from '../config/AxiosConfig'
import {BuildLessonFormData} from './TransformToFormData'

export const GetLibrary = async (page, limit, category = '') =>
  client.get(`/library/${page}/${limit}?category=${category}`)

export const DeleteLesson = async (id) =>
  client.delete(`/library/lesson/${id}`)

export const CreateLesson = async (lessonData) => {
  const data = BuildLessonFormData(lessonData)
  return client.post('/library/lesson', data, {
    headers: {
      common: {'Content-Type': 'multipart/form-data'}
    }
  })
}

export const UpdateLesson = async (lessonData) => {
  const data = BuildLessonFormData(lessonData, true)
  return client.put(`/library/lesson/${lessonData._id}`, data, {
    headers: {
      common: {'Content-Type': 'multipart/form-data'}
    }
  })
}

export const CreateCategory = async (fields) =>
  client.post('/library/category', fields)

export const DeleteCategory = async (id) =>
  client.delete(`/library/category/${id}`)

export const DuplicateLesson = async (id) =>
  client.post(`/library/lesson/${id}/duplicate`)
