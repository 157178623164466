import React, {useEffect, useState} from 'react'

import {isEmpty} from 'lodash'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import SimpleReactLightbox from 'simple-react-lightbox'

import NavigationHeader from '../../components/header/NavigationHeader'
import SendKudosModal from '../../components/kudos/SendKudosModal'
import FullModal from '../../components/modal/FullModal'
import MessagePage from '../../pages/message/MessagePage'
import {
  PageContainer,
  PageContent,
  LoadingSpin
} from '../../styles/BasicStyles'

const AuthenticatedRoute = ({
  user,
  modal,
  kudos,
  route,
  ready,
  routerProps
}) => {
  const [showMessage, setShowMessage] = useState(false)
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) setShowMessage(true)
      else setShowMessage(false)
    })
  }, [])

  if (!ready) {
    return <LoadingSpin min='100vh' size='large' />
  }
  if (isEmpty(user)) {
    return <Redirect to='/login' />
  }

  if (window.innerWidth < 768 || showMessage) {
    return <MessagePage />
  }

  const PageComponent = route.component

  return (
    <SimpleReactLightbox>
      <PageContainer>
        <NavigationHeader router={routerProps} user={user} />
        <PageContent>
          <PageComponent router={routerProps} user={user} />
          {modal && <FullModal info={modal} router={routerProps} />}
          {kudos && <SendKudosModal peer={kudos} />}
        </PageContent>
      </PageContainer>
    </SimpleReactLightbox>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  ready: state.info.ready,
  modal: state.modal,
  kudos: state.kudos
})

AuthenticatedRoute.propTypes = {
  user: PropTypes.object,
  modal: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  kudos: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  ready: PropTypes.bool,
  route: PropTypes.object,
  routerProps: PropTypes.object
}

AuthenticatedRoute.defaultProps = {
  ready: false,
  modal: undefined,
  kudos: undefined,
  user: undefined,
  route: undefined,
  routerProps: undefined
}

export default connect(mapStateToProps)(AuthenticatedRoute)
