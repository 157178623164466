import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

const ImageContainer = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
`

const Image = styled.div`
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
`

const ImageComponent = ({
  width,
  ratio,
  children,
  image,
  placeholder,
  className,
  position
}) => (
  <ImageContainer className={className}>
    <Image
      style={{
        width,
        paddingTop: `${100 * ratio}%`,
        backgroundColor: image ? image.color : '#cccccc',
        backgroundImage: image
          ? `url(${image.url})`
          : placeholder
          ? `url('${placeholder}')`
          : '',
        backgroundSize: 'cover',
        backgroundPosition: position || 'center'
      }}
    />
    {children}
  </ImageContainer>
)

ImageComponent.propTypes = {
  ratio: PropTypes.number,
  className: PropTypes.string,
  width: PropTypes.string,
  image: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
    PropTypes.func
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.elementType
  ]),
  position: PropTypes.string
}

ImageComponent.defaultProps = {
  image: undefined,
  className: undefined,
  ratio: 1,
  width: '100%',
  children: undefined,
  placeholder: undefined,
  position: undefined
}

export default ImageComponent
