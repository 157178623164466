import React, {useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import LoginPhoto from '../../assets/auth_background.png'
import {Container, ImageLogin} from '../../styles/BasicStyles'
import LoginPage from './components/LoginPage'
import RegisterPage from './components/RegisterPage'
import {
  FormContainer,
  FormSubContainer,
  TabButton,
  TabsHeader
} from './LoginPageStyles'

const AuthComponent = ({router, translate}) => {
  const [type, setType] = useState('login')

  const renderType = () => {
    switch (type) {
      case 'login':
        return <LoginPage router={router} />
      case 'register':
        return <RegisterPage router={router} />
      default:
        return false
    }
  }

  return (
    <Row style={{width: '100%'}}>
      <Container>
        <Col xs={12}>
          <ImageLogin src={LoginPhoto} alt='Login' />
        </Col>
        <Col xs={12}>
          <FormContainer>
            <FormSubContainer>
              <TabsHeader>
                <TabButton
                  onClick={() => setType('login')}
                  active={type === 'login'}
                >
                  {translate('LOGIN')}
                </TabButton>
                <TabButton
                  onClick={() => setType('register')}
                  active={type === 'register'}
                >
                  {translate('REGISTER')}
                </TabButton>
              </TabsHeader>
              {renderType()}
            </FormSubContainer>
          </FormContainer>
        </Col>
      </Container>
    </Row>
  )
}

AuthComponent.propTypes = {
  router: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

const mapStateToProps = () => ({})

const mapActionToProps = (dispatch) => ({
  dispatch
})

const AuthPage = connect(mapStateToProps, mapActionToProps)(AuthComponent)

export default withLocalize(AuthPage)
