import axios from 'axios'

import {AuthTokenKey} from './LocalStorageKeys'

// setup default client
const client = axios.create({
  baseURL: process.env.REACT_APP_API,
  responseType: 'json'
})

// merge every request with default headers
const mergeCommonHeaders = async (rest) => {
  const authToken = localStorage.getItem(AuthTokenKey)
  const languageKey = localStorage.getItem('LANG_COOKIE') || 'en'
  return {
    ...rest,
    Authorization: `Bearer ${authToken}`,
    'Accept-Language': languageKey
  }
}
client.interceptors.request.use(async (request) => {
  request.headers.common = await mergeCommonHeaders(request.headers.common)
  return request
})

// intercept server response before sending to request function
client.setupInterceptors = (store) => {
  client.interceptors.response.use(
    (success) => success.data,
    (error) => error.response.data
  )
}

export default client
