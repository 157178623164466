import React from 'react'

import PropTypes from 'prop-types'

import TextAreaInput from '../input/TextAreaInput'
import LikertRatingComponent from './LikertRatingComponent'
import LinkComponent from './LinkComponent'
import MediaComponent from './MediaComponent'
import PointRatingComponent from './PointRatingComponent'
import {
  ReviewMainContainer,
  ReviewInputLabelContainer,
  Label
} from './ReviewStyles'
import StarRatingComponent from './StarRatingComponent'

class ReviewComponent extends React.Component {
  constructor(props) {
    super(props)
    const {reviewType} = props
    let SelectedReviewComponent = []
    let minHeight
    let acceptedTypes
    let showValues
    switch (reviewType) {
      case '1':
      case '2':
        SelectedReviewComponent = TextAreaInput
        minHeight = 94
        break
      case '3':
        SelectedReviewComponent = StarRatingComponent
        break
      case '4': // link
        SelectedReviewComponent = LinkComponent
        break
      case '5': // media
        SelectedReviewComponent = MediaComponent
        acceptedTypes = 'image/jpeg, video/mp4'
        showValues = true
        break
      case '6': // point rating
        SelectedReviewComponent = PointRatingComponent
        break
      case '7': // likert rating
        SelectedReviewComponent = LikertRatingComponent
        break
      default:
        SelectedReviewComponent = TextAreaInput
        break
    }
    this.state = {
      SelectedReviewComponent,
      minHeight,
      acceptedTypes,
      showValues
    }
  }

  // changeRef = (name, ref) => {
  //   this.refs = {
  //     ...this.refs,
  //     [name]: ref
  //   }
  // }

  render() {
    const {meta, input, label, placeholder, editable} = this.props
    const {
      SelectedReviewComponent,
      minHeight,
      acceptedTypes,
      showValues
    } = this.state
    const {invalid, submitFailed} = meta
    const error = invalid && submitFailed

    return (
      <ReviewMainContainer>
        {label && (
          <ReviewInputLabelContainer>
            <Label hasError={error}>{label}</Label>
          </ReviewInputLabelContainer>
        )}
        <SelectedReviewComponent
          input={input}
          meta={meta}
          error={error}
          placeholder={placeholder}
          preActiveTab='image'
          editable={editable}
          // refField={refField}
          // changeRef={this.changeRef}
          onEnter={() => undefined}
          minHeight={minHeight}
          acceptedTypes={acceptedTypes}
          showValues={showValues}
          noEditable={!editable}
        />
      </ReviewMainContainer>
    )
  }
}

ReviewComponent.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  reviewType: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  editable: PropTypes.bool
}

ReviewComponent.defaultProps = {
  label: undefined,
  placeholder: undefined,
  editable: false
}

export default ReviewComponent
