import EN from './english_flag.svg'
import FR from './france_flag.svg'
import DE from './german_flag.svg'
import IT from './italian_flag.svg'
import PT from './portuguese_flag.svg'
import ES from './spanish_flag.svg'

export const Languages = [
  {code: 'en', name: 'English', icon: EN},
  {code: 'de', name: 'German', icon: DE},
  {code: 'fr', name: 'French', icon: FR},
  {code: 'it', name: 'Italian', icon: IT},
  {code: 'es', name: 'Spanish', icon: ES},
  {code: 'pt', name: 'Portuguese', icon: PT}
]

export const GetLanguageValue = (value, active) => {
  if (!value) return ''
  if (typeof value === 'string') return value
  if (value[active] && value[active] !== '') return value[active]
  if (value.en && value.en !== '') return value.en
  return value[Object.keys(value)[0]]
}

