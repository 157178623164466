import React, {useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import EmptyLayoutComponent from '../../components/empty_layout/EmptyLayoutComponent'
import JourneyBlockComponent from '../../components/journey_block/JourneyBlockComponent'
import JourneyModal from '../../components/modal/JourneyModal'
import {
  ToggleJourneyArchived,
  DeleteJourney
} from '../../infra/requests/JourneyRequests'
import {
  GetAndUpdateInactiveJourneys,
  GetAndUpdateArchivedJourneys
} from '../../redux/thunks/UpdateThunk'
import {
  InformationTitle,
  InformationContainer,
  JourneyIntention
} from '../active_journey/ActiveJourneyStyles'
import {InformationPageScroll} from './JourneyStyles'

const ArchivedJourneys = ({
  translate,
  archivedJourneys,
  dispatch,
  router
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(undefined)
  const [isDeleted, setIsDeleted] = useState(undefined)
  const [journeyModal, setJourneyModal] = useState(false)

  const onPressBtn = async (item) => {
    setIsLoading(true)
    setIsDeleted(undefined)
    try {
      setIsDeleting(item._id)
      await ToggleJourneyArchived(item._id)
      dispatch(GetAndUpdateArchivedJourneys())
      dispatch(GetAndUpdateInactiveJourneys())
      setIsDeleting(undefined)
      setIsDeleted(item._id)
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onPressDelete = async (item) => {
    setIsLoading(true)
    setIsDeleted(undefined)
    try {
      setIsDeleting(item._id)
      await DeleteJourney(item._id)

      archivedJourneys.filter((j) => j._id !== item._id)
      dispatch(GetAndUpdateArchivedJourneys())
      setIsDeleting(undefined)
      setIsDeleted(item._id)
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onPressCompleted = (item) => {
    router.history.push(`/completed-journey/${item._id}`, {
      journey: item
    })
  }

  return (
    <InformationPageScroll>
      <InformationContainer>
        <Row>
          <Col xs={24}>
            <InformationTitle>
              {translate('ARCHIVED_JOURNEYS')}
            </InformationTitle>
          </Col>
          <Col xs={24}>
            <JourneyIntention>
              {translate('ARCHIVED_JOURNEYS_DESCRIPTION')}
            </JourneyIntention>
          </Col>
          {archivedJourneys && archivedJourneys.length > 0 ? (
            <Col xs={24}>
              {archivedJourneys.map((item, index) => (
                <JourneyBlockComponent
                  key={index}
                  onPressItem={(i) => setJourneyModal(i)}
                  item={item}
                  height='180px'
                  onPressBtn={(i) =>
                    item.completed ? onPressCompleted(i) : onPressBtn(i)
                  }
                  onPressDelete={onPressDelete}
                  loading={isLoading}
                  isDeleting={
                    isDeleting === item._id ? item._id : undefined
                  }
                  isDeleted={isDeleted === item._id ? item._id : undefined}
                  isGrey
                  isArchived
                  isCompleted={item.completed}
                />
              ))}
            </Col>
          ) : (
            <EmptyLayoutComponent tag='EMPTY_ARCHIVED_JOURNEYS' />
          )}
          {journeyModal && (
            <JourneyModal
              open={!!journeyModal}
              journey={journeyModal}
              onClose={() => setJourneyModal(false)}
              isInactive
              onPressDelete={() => {
                setJourneyModal(false)
                onPressDelete(journeyModal)
              }}
              onPressUnarchive={
                !journeyModal.completed
                  ? () => {
                      setJourneyModal(false)
                      onPressBtn(journeyModal)
                    }
                  : false
              }
              onPressCompleted={
                journeyModal.completed
                  ? () => {
                      setJourneyModal(false)
                      onPressCompleted(journeyModal)
                    }
                  : false
              }
            />
          )}
        </Row>
      </InformationContainer>
    </InformationPageScroll>
  )
}

ArchivedJourneys.propTypes = {
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  archivedJourneys: PropTypes.array,
  router: PropTypes.object.isRequired
}

ArchivedJourneys.defaultProps = {
  archivedJourneys: undefined
}

const mapStateToProps = (state) => ({
  journey: state.user.active_journey,
  archivedJourneys: state.archived_journeys
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(ArchivedJourneys)
)
