export const BuildReflectionFormData = (
  reflectionData = {questions: []},
  currentLanguage,
  isUpdate
) => {
  const formData = new FormData()
  formData.append('journey', reflectionData.journey)
  formData.append('goal', reflectionData.goal)
  formData.append('intention', reflectionData.intention)
  formData.append('step', reflectionData.step)

  reflectionData.questions.map((currentQuestion, index) => {
    formData.append(
      `questions[${index}]`,
      JSON.stringify({
        type: currentQuestion.type,
        title:
          currentQuestion.title[currentLanguage.code] ||
          currentQuestion.title,
        placeholder: currentQuestion?.placeholder
          ? currentQuestion?.placeholder[currentLanguage.code] ||
            currentQuestion.placeholder
          : null,
        response: currentQuestion.response
      })
    )
    return currentQuestion
  })

  if (
    reflectionData.attachments &&
    reflectionData.attachments.length > 0
  ) {
    reflectionData.attachments.map((attch, index) => {
      if (isUpdate && !attch.object) {
        formData.append(`attachments[${index}]`, attch._id)
      } else {
        if (attch.type === 'image') {
          formData.append(
            'image[]',
            isUpdate
              ? new File([attch.file], 'image', {type: attch.file.type})
              : new File(
                  [attch.file],
                  `image%${attch.file.name ? attch.file.name : 'Image'}`,
                  {
                    type: attch.file.type
                  }
                )
          )
        }
        if (attch.type === 'video') {
          formData.append('video[]', attch.file)
        }
        if (attch.type === 'file') {
          formData.append(
            'file[]',
            isUpdate
              ? new File([attch.file], 'file', {type: attch.file.type})
              : new File(
                  [attch.file],
                  `file%${attch.file.name ? attch.file.name : 'File'}`,
                  {
                    type: attch.file.type
                  }
                )
          )
        }
        if (attch.type === 'link') {
          formData.append(
            'links[]',
            JSON.stringify({
              ...attch.object,
              url: attch.object.link,
              image: attch.object.image
            })
          )
        }
      }
      return attch
    })
  }
  return formData
}

export const BuildAssignmentTaskFormData = (
  assignmentData = {questions: []},
  currentLanguage
) => {
  const formData = new FormData()
  formData.append('journey', assignmentData.journey)
  formData.append('task', assignmentData.task)
  assignmentData.questions.map((currentQuestion, index) => {
    formData.append(
      `questions[${index}]`,
      JSON.stringify({
        type: currentQuestion.type,
        title:
          currentQuestion.title[currentLanguage.code] ||
          currentQuestion.title,
        placeholder: currentQuestion?.placeholder
          ? currentQuestion?.placeholder[currentLanguage.code] ||
            currentQuestion.placeholder
          : null,
        response: currentQuestion.response
      })
    )
    if (
      currentQuestion.type === 5 &&
      typeof currentQuestion.response === 'object' &&
      currentQuestion.response.object
    ) {
      formData.append('attachment', currentQuestion.response.file)
    }
    return currentQuestion
  })

  return formData
}

export const BuildGroupFormData = (groupData = {}, isNew) => {
  const formData = new FormData()
  formData.append('name', groupData.name)
  formData.append('context', groupData.context)

  if (isNew) {
    groupData.users.map((user, index) => {
      formData.append(`users[${index}]`, user._id)
      return user
    })
    groupData.image = groupData.image?.file ? groupData.image : false
  }

  if (groupData.image) {
    formData.append('image', groupData.image.file)
  }

  return formData
}

export const BuildLessonFormData = (
  lessonData = {questions: []},
  isUpdate
) => {
  const formData = new FormData()
  formData.append('title', lessonData.title)
  formData.append('content', lessonData.content)
  formData.append('category', lessonData.category)

  if (lessonData.attachments && lessonData.attachments.length > 0) {
    lessonData.attachments.map((attch, index) => {
      if (isUpdate && !attch.object) {
        formData.append(`attachments[${index}]`, attch._id)
      } else {
        if (attch.type === 'image') {
          formData.append(
            'image[]',
            new File([attch.file], 'image', {type: attch.file.type})
          )
        }
        if (attch.type === 'video') {
          formData.append('video[]', attch.file)
        }
        if (attch.type === 'file') {
          formData.append(
            'file[]',
            new File([attch.file], 'file', {type: attch.file.type})
          )
        }
        if (attch.type === 'link') {
          formData.append(
            'links[]',
            JSON.stringify({
              ...attch.object,
              url: attch.object.link,
              image: attch.object.image
            })
          )
        }
      }
      return attch
    })
  }
  return formData
}

export const BuildSendKudosToFormData = (data) => {
  const formData = new FormData()
  if (data.message) formData.append('message', data.message)
  formData.append('to', data.to)

  if (data.id) {
    formData.append('kudos', data.id)
  }

  if (data.attachment) {
    if (data.attachment.file) {
      formData.append('attachment[]', data.attachment.file)
    } else {
      console.warn(
        "There wasn't a valid type for the file ",
        data.attachment
      )
    }
  }
  return formData
}
