import React from 'react'

import PropTypes from 'prop-types'
import {Field} from 'react-final-form'
import {withLocalize} from 'react-localize-redux'

import TextAreaInput from '../../components/input/TextAreaInput'
import TextInput from '../../components/input/TextInput'
import {WhiteLayout} from '../../styles/BasicStyles'
import {JourneyIntroductionText} from '../journeys/JourneyStyles'
import Wizard from './Wizard'

const JourneyStep1 = ({translate, type}) => (
  <Wizard.Page>
    <WhiteLayout space='40px'>
      {type === 'guided' ? (
        <JourneyIntroductionText spaceB={30}>
          {translate('INTRODUCTION_JOURNEY_STEP1')}
        </JourneyIntroductionText>
      ) : (
        <JourneyIntroductionText spaceB={30}>
          {translate('INTRODUCTION_JOURNEY_STEP1_CUSTOM')}
        </JourneyIntroductionText>
      )}
      <Field
        name='goal'
        refField='goal'
        label={translate('TITLE')}
        placeholder={translate('ADD_TITLE')}
        component={TextInput}
        returnKeyType='next'
      />
      <Field
        name='intention'
        refField='intention'
        label={translate('INTENTION')}
        placeholder={translate('WRITE_YOUR_INTENTIONS_HERE')}
        component={TextAreaInput}
        hasTopSpace={24}
        numberOfLines={5}
        minHeight={138}
        editable
      />
    </WhiteLayout>
  </Wizard.Page>
)

JourneyStep1.propTypes = {
  translate: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
}

export default withLocalize(JourneyStep1)
