import styled from 'styled-components'

import {
  PrimaryColor,
  SecondaryColor,
  SuccessColor,
  DefaultGey,
  ErrorColor,
  white,
  ThirdColor
} from '../../styles/_theme'
import device from '../../styles/Responsive'

const DefineColors = ({category, disabled}) => {
  switch (category) {
    case 'primary':
      return `
        background-color: ${PrimaryColor};
        border: 1px solid ${PrimaryColor};
        color: white;
      `
    case 'secondary':
      return `
        background-color: ${SecondaryColor};
        border: 1px solid ${SecondaryColor};
        color: white;
      `
    case 'tertiary':
      return `
        background-color: ${ThirdColor};
        border: 1px solid ${ThirdColor};
        color: white;
      `
    case 'lined':
      return `
        background-color: white;
        border: 1px solid ${DefaultGey};
        color: ${DefaultGey};
      `
    case 'enter':
      return `
          background-color: ${disabled ? DefaultGey : SuccessColor};
          border: 1px solid ${disabled ? DefaultGey : 'transparent'}; 
          color: ${white};
        `
    case 'success':
      return `
        background-color: ${SuccessColor};
        border: 1px solid ${SuccessColor};
        color: white;
      `
    case 'error':
      return `
        background-color: ${ErrorColor};
        border: 1px solid ${ErrorColor};
        color: white;
      `
    default:
      return `
        background-color: ${DefaultGey};
        border: 1px solid ${DefaultGey};
        color: white;
      `
  }
}

const DefineHover = ({category}) => {
  switch (category) {
    case 'primary':
      return `
        background-color: white;
        border: 1px solid ${PrimaryColor};
        color: ${PrimaryColor};

        svg path {
          fill: ${PrimaryColor};
        }
      `
    case 'secondary':
      return `
        background-color: white;
        border: 1px solid ${SecondaryColor};
        color: ${SecondaryColor};

        svg path {
          fill: ${SecondaryColor};
        }
      `
    case 'tertiary':
      return `
        background-color: white;
        border: 1px solid ${ThirdColor};
        color: ${ThirdColor};

        svg path {
          fill: ${ThirdColor};
        }
      `
    case 'lined':
      return `
        background-color: ${DefaultGey};
        border: 1px solid ${DefaultGey};
        color: white;

        svg path {
          fill: ${white};
        }
      `
    case 'enter':
      return `
          background-color: ${DefaultGey};
          border: 1px solid ${DefaultGey};
          color: ${white};

          svg path {
            fill: ${white};
          }
        `
    case 'success':
      return `
        background-color: white;
        border: 1px solid ${SuccessColor};
        color: ${SuccessColor};

        svg path {
          fill: ${SuccessColor};
        }
      `
    case 'error':
      return `
        background-color: white;
        border: 1px solid ${ErrorColor};
        color: ${ErrorColor};

        svg path {
          fill: ${ErrorColor};
        }
      `
    default:
      return `
        background-color: white;
        border: 1px solid ${DefaultGey};
        color: ${DefaultGey};

        svg path {
          fill: ${DefaultGey};
        }
      `
  }
}

export const StyledButton = styled.button`
  display: inline-block;
  padding: 10px 25px;
  outline: none;
  border-radius: 25px;
  cursor: pointer;
  ${DefineColors};
  transition: all 0.3s ease;
  margin-right: ${({spaceR}) => spaceR || 0}px;

  &:not(.noHover):hover,
  &:not(.noHover):visited,
  &:not(.noHover):active {
    outline: none;
    ${DefineHover}
  }

  &:disabled {
    opacity: 0.5;
    ${DefineColors}
  }

  &.small {
    padding: 0px 10px;
    height: 30px;
  }
`

export const FloatButton = styled(StyledButton)`
  position: absolute;
  bottom: 0;
  right: 0;
`

export const StyledButtonSquare = styled.button`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 38px;
  padding: 0px 14px;
  cursor: pointer;
  display: flex;
  width: 100%;
  margin-right: ${({isRight}) => (isRight ? 0 : 1)}px;
  margin-left: ${({isRight}) => (isRight ? 1 : 0)}px;
  ${DefineColors};
  transition: all 0.3s ease;
  outline: none;

  &:hover,
  &:visited,
  &:active {
    outline: none;
    ${DefineHover}

    svg path {
      fill: ${SecondaryColor};
    }
  }

  &:disabled {
    opacity: 0.5;
    ${DefineColors}
  }

  @media ${device.laptop} {
    margin-right: 0px;
    margin-left: 0px;
    margin-top: ${({isRight}) => (isRight ? 2 : 0)}px;
  }
`

export const ButtonGroupFloating = styled.div`
  position: absolute;
  bottom: 19px;
  margin: 0px 12px 40px 12px;
  width: 100%;
`

export const ActiveButtonGroup = styled.div`
  align-self: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
`
