import React, {useState, useEffect} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {SRLWrapper} from 'simple-react-lightbox'

import ArrowIcon from '../../assets/icons/ic_arrow.svg'
import CheckIcon from '../../assets/icons/ic_check.svg'
import {GetLanguageValue} from '../../infra/services/translations/TranslationsService'
import {GetH5PRatio} from '../../infra/utils/CommonFunctions'
import {PrimaryColor, ThirdColor, SuccessColor} from '../../styles/_theme'
import {
  StepsContainer,
  StepsText
} from '../journey_block/JourneyBlockComponentStyles'
import {H5P} from '../modal/BaseModalStyles'
import VideoPlayer from '../video/VideoPlayer'
import {
  TaskHeaderButtons,
  TaskArrowBtn,
  TaskCompletionBtn,
  TaskCenter,
  TaskCompletionClickable,
  TaskBg
} from './TaskStyles'

const TaskHeader = ({
  translate,
  activeLanguage,
  task,
  loading,
  taskIndex,
  totalTasks,
  nextTask,
  previousTask,
  isFeedback,
  onSubmit,
  renderNextStep
}) => {
  const [showAllImage, setShowAllImage] = useState(false)
  const completion = !!(task && task.response && task.response.completed)
  console.log(renderNextStep)
  useEffect(() => {
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault()
    })
  }, [])

  const renderImage = () => {
    const {media} = task
    switch (media?.type) {
      case 'images':
        return (
          <>
            {task.updated && (
              <StepsContainer
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  boxShadow: '1px 1px 7px rgba(0,0,0,0.5)'
                }}
              >
                <StepsText>{translate('UPDATED')}</StepsText>
              </StepsContainer>
            )}
            <TaskBg
              height={showAllImage ? '100%' : '320px'}
              onClick={() => setShowAllImage(!showAllImage)}
            >
              <img src={media.file.url} style={{width: '100%'}} />
            </TaskBg>
          </>
        )
      case 'videos': {
        const video = GetLanguageValue(media.file.url, activeLanguage.code)
        return (
          <>
            {task.updated && (
              <StepsContainer
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  boxShadow: '1px 1px 7px rgba(0,0,0,0.5)'
                }}
              >
                <StepsText>{translate('UPDATED')}</StepsText>
              </StepsContainer>
            )}
            <VideoPlayer video={video} height='320px' width='100%' />
          </>
        )
      }
      case 'h5p':
      case 'embedded': {
        const file = GetLanguageValue(
          media.file.iframe,
          activeLanguage.code
        )
        const r = GetH5PRatio(file)
        return (
          <>
            {task.updated && (
              <StepsContainer
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  boxShadow: '1px 1px 7px rgba(0,0,0,0.5)'
                }}
              >
                <StepsText>{translate('UPDATED')}</StepsText>
              </StepsContainer>
            )}
            <H5P
              style={{
                paddingTop: r.isPx || `${r}%`,
                overflow: 'hidden',
                marginBottom: 35
              }}
              dangerouslySetInnerHTML={{
                __html: file
              }}
            />
          </>
        )
      }
      default:
        return (
          <>
            {task.updated && (
              <StepsContainer
                style={{
                  zIndex: 1,
                  boxShadow: '1px 1px 7px rgba(0,0,0,0.5)'
                }}
              >
                <StepsText>{translate('UPDATED')}</StepsText>
              </StepsContainer>
            )}
          </>
        )
    }
  }

  const renderHeader = () => (
    <>
      <Col
        xs={24}
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 10
        }}
      >
        <Row>
          <Col md={12} lg={16}>
            <TaskHeaderButtons>
              <TaskArrowBtn disabled={loading} onClick={previousTask}>
                <img
                  src={ArrowIcon}
                  alt='ArrowIcon'
                  style={{transform: 'rotate(90deg)'}}
                />
              </TaskArrowBtn>
              <TaskCenter>
                {taskIndex + 1}/{totalTasks}
              </TaskCenter>
              <TaskArrowBtn disabled={loading} onClick={nextTask}>
                <img
                  src={ArrowIcon}
                  alt='ArrowIcon'
                  style={{transform: 'rotate(-90deg)'}}
                />
              </TaskArrowBtn>
            </TaskHeaderButtons>
          </Col>
          <Col md={12} lg={8} style={{backgroundColor: PrimaryColor}}>
            {onSubmit ? (
              <TaskCompletionClickable
                disabled={loading}
                onClick={onSubmit}
                color={completion ? SuccessColor : ThirdColor}
              >
                {completion
                  ? translate('TASK_COMPLETED')
                  : translate('TAP_TO_COMPLETE')}
                {completion && (
                  <img
                    style={{marginLeft: 8}}
                    src={CheckIcon}
                    alt='CheckIcon'
                  />
                )}
              </TaskCompletionClickable>
            ) : (
              <TaskCompletionBtn
                disabled={loading}
                type='submit'
                color={completion ? SuccessColor : ThirdColor}
              >
                {completion
                  ? translate('TASK_COMPLETED')
                  : !isFeedback
                  ? translate('TAP_TO_COMPLETE')
                  : translate('FEEDBACK_TASK_HEADER')}
                {completion && (
                  <img
                    style={{marginLeft: 8}}
                    src={CheckIcon}
                    alt='CheckIcon'
                  />
                )}
              </TaskCompletionBtn>
            )}
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        {renderNextStep()}
        {renderImage()}
      </Col>
    </>
  )

  return renderHeader()
}

TaskHeader.propTypes = {
  translate: PropTypes.func.isRequired,
  previousTask: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  nextTask: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  taskIndex: PropTypes.number,
  totalTasks: PropTypes.number,
  isFeedback: PropTypes.bool
}

TaskHeader.defaultProps = {
  loading: false,
  taskIndex: 1,
  totalTasks: 1,
  onSubmit: undefined,
  isFeedback: false
}

const mapStateToProps = (state) => ({
  journey: state.user.active_journey
})

const mapActionToProps = (dispatch) => ({dispatch})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(TaskHeader)
)
