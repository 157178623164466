import React from 'react'

import {Collapse} from 'antd'
import PropTypes from 'prop-types'
import {Field, FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'

import {ReactComponent as IconDown} from '../../assets/icons/chevron_down_white.svg'
import CheckIcon from '../../assets/icons/ic_check.svg'
import BaseButton from '../../components/buttons/BaseButton'
import {InputLabel} from '../../components/input/InputStyles'
import SelectInput from '../../components/input/SelectInput'
import TimeInput from '../../components/input/TimeInput'
import {ThirdColor, LightGey} from '../../styles/_theme'
import {WhiteLayout} from '../../styles/BasicStyles'
import {
  LineContainer,
  LineSection,
  SelectCircle,
  SelectContainer,
  MediumText,
  StyledPoliciesView,
  PrivacyOptionComponent
} from './CreateJourneyStyles'
import Wizard from './Wizard'

const {Panel} = Collapse

const FieldPrefixContext = React.createContext()
const FieldPrefix = ({prefix, children}) => (
  <FieldPrefixContext.Provider value={prefix}>
    {children}
  </FieldPrefixContext.Provider>
)
const PrefixedField = ({name, type, ...props}) => {
  if (type === 'array') {
    return (
      <FieldPrefixContext.Consumer>
        {(prefix) => <FieldArray name={`${prefix}.${name}`} {...props} />}
      </FieldPrefixContext.Consumer>
    )
  }
  return (
    <FieldPrefixContext.Consumer>
      {(prefix) => <Field name={`${prefix}.${name}`} {...props} />}
    </FieldPrefixContext.Consumer>
  )
}

const renderHeader = (title) => (
  <LineContainer bgColor={ThirdColor} cursor='pointer' spaceT={4}>
    {title}
    <IconDown />
  </LineContainer>
)

const JourneyStep2 = ({translate}) => {
  const onPressIcon = (fields, day, form) => {
    const selected = fields || []
    const existentDay = selected.findIndex((a) => day === a)
    let changes = [...selected]
    if (existentDay >= 0) {
      changes = selected.filter((a) => day !== a)
    } else {
      changes.push(day)
    }
    form.change('reminder.days', changes)
  }

  const DaysPickerOption = (input) => {
    const {invalid} = input.meta
    const error = invalid
    const {fields, form} = input
    const currentDays = fields.value
    const weekDays = [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
      'SUNDAY'
    ]
    return (
      <>
        <InputLabel marginB={20} marginT={30} marginH={14} error={error}>
          {translate('CHOOSE_WHEN_TO_BE_REMINDED')}
        </InputLabel>
        {weekDays.map((day, index) => (
          <LineSection
            key={index}
            onClick={() => onPressIcon(fields.value, day, form)}
          >
            {translate(day)}
            <SelectContainer>
              {currentDays &&
              currentDays.findIndex((a) => day === a) >= 0 ? (
                <SelectCircle isSelected>
                  <img src={CheckIcon} alt='CheckIcon' />
                </SelectCircle>
              ) : (
                <SelectCircle />
              )}
            </SelectContainer>
          </LineSection>
        ))}
      </>
    )
  }

  const privacyContent = [
    {
      label: translate('JOURNEY_CONTENT'),
      value: 'content',
      description: translate('JOURNEY_CONTENT_DESCRIPTION'),
      options: [
        {name: translate('PRIVATE'), value: false},
        {name: translate('ANONYMIZED'), value: true}
      ]
    },
    {
      label: translate('JOURNEY_ENGAGEMENT'),
      value: 'engagement',
      description: translate('JOURNEY_ENGAGEMENT_DESCRIPTION'),
      options: [
        {name: translate('ANONYMIZED'), value: false},
        {name: translate('TRANSPARENT'), value: true}
      ]
    },
    {
      label: translate('JOURNEY_PROGRESS'),
      value: 'progress',
      description: translate('JOURNEY_PROGRESS_DESCRIPTION'),
      options: [
        {name: translate('ANONYMIZED'), value: false},
        {name: translate('TRANSPARENT'), value: true}
      ]
    },
    {
      label: translate('NETWORK_ENGAGEMENT'),
      value: 'network',
      description: translate('NETWORK_ENGAGEMENT_DESCRIPTION'),
      options: [
        {name: translate('ANONYMIZED'), value: false},
        {name: translate('TRANSPARENT'), value: true}
      ]
    }
  ]

  const PrivacyOption = (input) => {
    const {index, label, description, options, form} = input
    const {value, name} = input.input
    return (
      <PrivacyOptionComponent index={index}>
        <InputLabel>{label}</InputLabel>
        <MediumText>{description}</MediumText>
        <StyledPoliciesView>
          {options.map((option, i) => (
            <BaseButton
              key={i}
              type='button'
              onClick={() => {
                form.change(name, option.value)
              }}
              label={option.name}
              category={option.value === value ? 'secondary' : 'lined'}
              style={{marginRight: i ? 0 : 10}}
              className='noHover'
            />
          ))}
        </StyledPoliciesView>
      </PrivacyOptionComponent>
    )
  }

  return (
    <Wizard.Page>
      <WhiteLayout space='40px'>
        <Collapse accordion bordered={false} ghost>
          <Panel
            showArrow={false}
            header={renderHeader(translate('SET_REVIEW_FREQUENCY'))}
          >
            <Field
              name='review_weeks'
              placeholder={translate('SELECT_AN_ITEM')}
              component={SelectInput}
              label={translate('I_WANT_TO_REVIEW_THIS_JOURNEY_EVERY')}
              bgcolor={LightGey}
              radius={21}
              items={[
                {value: 1, label: translate('1_WEEK')},
                {value: 2, label: translate('2_WEEKS')},
                {value: 3, label: translate('3_WEEKS')},
                {value: 4, label: translate('4_WEEKS')},
                {value: 5, label: translate('5_WEEKS')},
                {value: 6, label: translate('6_WEEKS')}
              ]}
            />
          </Panel>
          <Panel
            showArrow={false}
            header={renderHeader(translate('SET_REMINDER'))}
          >
            <FormSpy subscription={{values: true}}>
              {({form}) => (
                <FieldPrefix prefix='reminder'>
                  <PrefixedField
                    name='days'
                    component={DaysPickerOption}
                    form={form}
                    type='array'
                  />
                  <PrefixedField
                    name='time'
                    // defaultValue={reminder.time}
                    placeholder={translate('SELECT_HOUR')}
                    component={TimeInput}
                    isTime
                    label={translate('AT_THIS_HOUR')}
                    hasTopSpace={30}
                    bgcolor={LightGey}
                    radius={21}
                  />
                </FieldPrefix>
              )}
            </FormSpy>
          </Panel>
          <Panel
            showArrow={false}
            header={renderHeader(translate('SET_PRIVACY_SETTINGS'))}
          >
            <FormSpy subscription={{values: true}}>
              {({form}) => (
                <FieldPrefix prefix='privacy'>
                  {privacyContent.map((data, index) => (
                    <PrefixedField
                      key={index}
                      index={index}
                      name={data.value}
                      label={data.label}
                      description={data.description}
                      options={data.options}
                      component={PrivacyOption}
                      form={form}
                    />
                  ))}
                </FieldPrefix>
              )}
            </FormSpy>
          </Panel>
        </Collapse>
      </WhiteLayout>
    </Wizard.Page>
  )
}

JourneyStep2.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(JourneyStep2)
