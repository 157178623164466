import styled from 'styled-components'

export const ButtonCalendar = styled.a`
  margin: 0px 10px 10px;
  display: inline-block;
  padding: 5px 10px;
  outline: none;
  border-radius: 25px;
  cursor: pointer;
  background-color: #17ac9d;
  border: 1px solid #17ac9d;
  color: white;
  -webkit-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  white-space: nowrap;
`
