import client from '../config/AxiosConfig'

export const UpdateProfile = async (data) =>
  client.put('/auth/update', data)

export const ChangePassword = async (data) =>
  client.put('/auth/change-password', data)

export const UpdatePermissions = async (data) =>
  client.put('/auth/permissions', data)

export const GetMyGroups = async () => client.get('/groups')

export const JoinGroups = async (fields) =>
  client.post('/groups/join', fields)

export const LeaveGroup = async (id) =>
  client.delete(`/groups/leave/${id}`)

export const UpdatePhoto = async (image) => {
  const formData = new FormData()
  formData.append('photo', image.file)
  return client.put('/auth/photo', formData, {
    headers: {
      common: {'Content-Type': 'multipart/form-data'}
    }
  })
}
export const GetReceivedKudos = async (page, limit) =>
  client.get(`/kudos/received/${page}/${limit}`)
