import SocketClient from './SocketConnection'

class ChatChannelService {
  constructor() {
    this.client = null
    this.client = SocketClient.create()
  }

  static create = () => new ChatChannelService()

  join = (channel) => {
    this.client.emit('subscribe', channel)
  }

  leave = (channel) => this.client.emit('unsubscribe', channel)

  onMessageReceived = (chat, callback) => {
    this.client.subscribe(chat, callback)
  }
}

export default ChatChannelService
