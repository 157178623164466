import React, {useState, useEffect} from 'react'

import {Collapse} from 'antd'
import arrayMutators from 'final-form-arrays'
import {PropTypes} from 'prop-types'
import {Field, FormSpy} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'

import {ReactComponent as IconDown} from '../../assets/icons/chevron_down_white.svg'
import {Share, GetShare} from '../../infra/requests/CommunityRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {LineContainer} from '../../pages/create_journey/CreateJourneyStyles'
import {ThirdColor, LightGey} from '../../styles/_theme'
import {EmptyLayout, EmptyText} from '../../styles/BasicStyles'
import {InputBox, InputLabel} from '../input/InputStyles'
import TextAreaInput from '../input/TextAreaInput'
import BaseModalForm from '../modal/BaseModalForm'
import PeerBlockComponent from '../peer_block/PeerBlockComponent'

const {Panel} = Collapse

const ValidateShare = (values) => {
  const errors = FormValidator.make({
    share: 'array'
  })(values)
  return errors
}

const ShareModal = ({item, type, open, onClose, onSave, translate}) => {
  const [share, setShare] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getShare = async () => {
    setIsLoading(true)
    try {
      const result = await GetShare()
      setIsLoading(false)
      if (result.success) {
        setShare(result.data)
      }
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(() => {
    if (!share && !isLoading) getShare()
  }, [share, isLoading])

  const onSaveForm = async (fields) => {
    try {
      const users =
        fields.share.filter(
          (s) => s.type === 'peer' || s.type === 'friend'
        ) || []
      const channels =
        fields.share.filter((s) => s.type === 'channel') || []
      const companies =
        fields.share.filter((s) => s.type === 'company') || []
      const idsUsers = []
      users.map((u) => idsUsers.push(u._id))
      const idsCompanies = []
      companies.map((c) => idsCompanies.push(c._id))
      const shareContent = {
        ...fields,
        type,
        users: idsUsers,
        channels,
        general: idsCompanies,
        share: item
      }
      await Share(shareContent)
      onSave()
    } catch (error) {
      console.warn(error)
    }
  }

  const renderHeader = (title) => (
    <LineContainer bgColor={ThirdColor} cursor='pointer' spaceT={4}>
      {title}
      <IconDown />
    </LineContainer>
  )

  const onPressListItem = (peer, values, form) => {
    const selected = values.share || []
    let changes = [...selected]
    const exist = changes.findIndex((u) => peer._id === u._id)

    if (exist >= 0) {
      changes = values.share.filter((u) => peer._id !== u._id)
    } else {
      changes.push(peer)
    }
    form.change('share', changes)
  }

  return (
    <BaseModalForm
      open={open}
      onClose={onClose}
      title={translate('SHARE')}
      onSubmit={onSaveForm}
      validate={ValidateShare}
      okText={translate('SHARE')}
      initialValues={{
        users: [],
        channels: [],
        general: [],
        share: []
      }}
      mutators={{
        ...arrayMutators
      }}
    >
      <FormSpy subscription={{values: true}}>
        {({values, form}) => {
          const peersSelectedTotal = values.share ? values.share.length : 0
          return (
            <>
              <Field
                name='message'
                refField='message'
                label={type === 'reflection' ? translate('WRITE_YOUR_MESSAGE_HERE_OPTIONAL') : translate('WRITE_YOUR_MESSAGE_HERE')}
                component={TextAreaInput}
                numberOfLines={5}
                minHeight={182}
                editable
              />
              <InputBox hasTopSpace={40}>
                <InputLabel>
                  {translate('SELECT_PEERS_OR_CHAT')}
                </InputLabel>
                <Collapse accordion bordered={false} ghost>
                  <Panel
                    showArrow={false}
                    header={renderHeader(
                      !peersSelectedTotal
                        ? translate('SELECT_PEERS_OR_CHAT')
                        : `${peersSelectedTotal} ${translate('SELECTED')}`
                    )}
                  >
                    {share && share.length > 0 ? (
                      <FieldArray name='share'>
                        {() =>
                          share.map((i, index) => {
                            const type =
                              i.type === 'company'
                                ? 'isGeneral'
                                : i.type === 'channel'
                                ? 'isChannel'
                                : 'isPeer'
                            return (
                              <PeerBlockComponent
                                key={index}
                                index={index}
                                item={i}
                                onPressItem={(chat) =>
                                  onPressListItem(chat, values, form)
                                }
                                bgColor={LightGey}
                                selectedPeers={values.share || []}
                                marginB='6px'
                                type={type || false}
                              />
                            )
                          })
                        }
                      </FieldArray>
                    ) : (
                      <EmptyLayout>
                        <EmptyText>
                          {translate('EMPTY_PEERS_SHARE')}
                        </EmptyText>
                      </EmptyLayout>
                    )}
                  </Panel>
                </Collapse>
              </InputBox>
            </>
          )
        }}
      </FormSpy>
    </BaseModalForm>
  )
}
ShareModal.propTypes = {
  translate: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default withLocalize(ShareModal)
