import React from 'react'

import {Row, Col} from 'antd'
import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {withLocalize} from 'react-localize-redux'
import {useDispatch} from 'react-redux'

import {RespondFeedback} from '../../infra/requests/ActivityRequests'
import {GetValuePreview} from '../../infra/utils/CommonFunctions'
import {InformationTitle} from '../../pages/active_journey/ActiveJourneyStyles'
import {clearModal} from '../../redux/data/modal/ModalActions'
import {
  GetAndUpdateFeed,
  GetAndUpdateActivity
} from '../../redux/thunks/UpdateThunk'
import {PageForm, WhiteLayout} from '../../styles/BasicStyles'
import BaseButton from '../buttons/BaseButton'
import ReviewComponent from '../review_components/ReviewComponent'
import {RespondFeedbackValidations} from './validations/FeedbackValidations'

const RespondFeedbackComponent = ({
  translate,
  activeLanguage,
  feedback
}) => {
  const dispatch = useDispatch()

  const initialValues = {
    questions: feedback && feedback.questions ? feedback.questions : [],
    _id: feedback._id || false
  }

  const onSubmit = async (fields) => {
    try {
      const result = await RespondFeedback(fields._id, {
        questions: fields.questions,
        journey: feedback.journey
      })

      if (result.success) {
        dispatch(GetAndUpdateActivity())
        dispatch(GetAndUpdateFeed())
        dispatch(clearModal())
      }
    } catch (e) {
      console.warn(e)
    }
  }

  return (
    <Row>
      <Col xs={24}>
        <InformationTitle>
          {translate('RESPOND_FEEDBACK')}
        </InformationTitle>
      </Col>
      <Col xs={24}>
        <Row>
          <Form
            onSubmit={onSubmit}
            mutators={{
              ...arrayMutators
            }}
            initialValues={initialValues}
            validate={RespondFeedbackValidations}
          >
            {({handleSubmit, form, submitting, valid, dirty}) => (
              <PageForm
                onSubmit={(event) => {
                  const promise = handleSubmit(event).then(() => {
                    form.reset()
                  })
                  return promise
                }}
              >
                <WhiteLayout space=' 0px 30px 30px'>
                  <FieldArray name='questions'>
                    {({fields}) =>
                      fields.map((item, index) => {
                        const question = fields.value[index]
                        return (
                          <Field
                            key={index.toString()}
                            name={`questions[${index}].response`}
                            refField={`questions${index}`}
                            label={GetValuePreview(
                              question.title,
                              activeLanguage && activeLanguage.code
                            )}
                            placeholder={
                              question.placeholder
                                ? GetValuePreview(
                                    question.placeholder,
                                    activeLanguage && activeLanguage.code
                                  )
                                : ''
                            }
                            component={ReviewComponent}
                            editable
                            reviewType={question.type.toString()}
                          />
                        )
                      })
                    }
                  </FieldArray>
                </WhiteLayout>
                <BaseButton
                  type='submit'
                  label={translate('SAVE')}
                  category='secondary'
                  style={{float: 'right', marginTop: 24}}
                  disabled={submitting || !valid || !dirty}
                />
              </PageForm>
            )}
          </Form>
        </Row>
      </Col>
    </Row>
  )
}

RespondFeedbackComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  feedback: PropTypes.object
}

RespondFeedbackComponent.defaultProps = {
  feedback: undefined
}

export default withLocalize(RespondFeedbackComponent)
