import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import IconResize from '../../assets/icons/ic_resize.svg'
import {
  StyledNotFoundContainer,
  StyledTitle,
  StyledNotFoundGroup,
  StyledCompanyLogo
} from '../not_found/NotFoundStyles'

const MessagePage = ({translate}) => (
  <StyledNotFoundContainer>
    <StyledNotFoundGroup style={{padding: '0px 40px'}}>
      <StyledCompanyLogo src={IconResize} alt='resize' />
      <StyledTitle>{translate('MESSAGE_RESOLUTION_TITLE')}</StyledTitle>
    </StyledNotFoundGroup>
  </StyledNotFoundContainer>
)

MessagePage.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(MessagePage)
