import styled from 'styled-components'

import ImageComponent from '../../components/images/ImageComponent'
import {white, ThirdColor, DarkGey, DarkGrey} from '../../styles/_theme'
import {ResponsiveContainer} from '../../styles/BasicStyles'

export const LessonContent = styled(ResponsiveContainer)``

export const LessonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  padding-bottom: 40px;
`

export const LessonBox = styled.div`
  max-width: 1000px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const LessonImage = styled(ImageComponent)`
  with: 100%;
  height: 500px;
`

export const LessonTextContent = styled.div`
  width: 100%;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  padding: 40px;
`

export const LessonTitle = styled.div`
  font-size: 26px;
  font-weight: 800;
  line-height: 1.23;
  color: ${ThirdColor};
`

export const LessonCategory = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: ${DarkGey};
  margin-top: 12px;
`

export const LessonDescription = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  color: ${DarkGrey};
  margin-top: 32px;
  margin-bottom: 30px;
`

export const LessonButton = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 40px;
`
export const LessonHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 892px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 29px;
`

export const HeaderTitle = styled.div`
  font-size: 26px;
  font-weight: 800;
  line-height: 1.23;
  color: ${ThirdColor};
`

export const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
`

export const Text = styled.div`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  color: ${DarkGrey};
`

export const CategoryBtns = styled.div`
  width: 100px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
`
