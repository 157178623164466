import React from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {ReactComponent as CloseIcon} from '../../assets/icons/ic_close_blue.svg'
import {
  InformationTitle,
  CloseBtn
} from '../../pages/active_journey/ActiveJourneyStyles'
import FeedbackCard from '../cards/FeedbackCard'
import EmptyLayoutComponent from '../empty_layout/EmptyLayoutComponent'

const FeedbacksList = ({
  translate,
  activeJourney,
  completedJourney,
  onClickBack
}) => {
  const journey = completedJourney || activeJourney
  if (!journey) return null

  const renderItem = (item, index) => (
    <FeedbackCard
      key={index}
      item={item}
      bgColor='white'
      isCompleted={!!completedJourney}
    />
  )

  return (
    <Row gutter={[12, 24]}>
      <Col xs={20}>
        <InformationTitle>{translate('FEEDBACKS')}</InformationTitle>
      </Col>
      <Col xs={4}>
        <CloseBtn onClick={onClickBack} showBg>
          <CloseIcon />
        </CloseBtn>
      </Col>
      {journey?.feedbacks && journey?.feedbacks?.length > 0 ? (
        <Col xs={24}>
          <Row gutter={[12, 24]}>
            {journey.feedbacks.map((item, index) => (
              <Col md={24} lg={12} xl={8} key={index}>
                {renderItem(item, index)}
              </Col>
            ))}
          </Row>
        </Col>
      ) : (
        <EmptyLayoutComponent tag='EMPTY_FEEDBACKS' />
      )}
    </Row>
  )
}
FeedbacksList.propTypes = {
  translate: PropTypes.func.isRequired,
  onClickBack: PropTypes.func,
  activeJourney: PropTypes.object,
  completedJourney: PropTypes.object
}

FeedbacksList.defaultProps = {
  activeJourney: undefined,
  onClickBack: undefined,
  completedJourney: undefined
}
const mapStateToProps = (state) => ({
  activeJourney: state.user.active_journey
})

export default withLocalize(connect(mapStateToProps)(FeedbacksList))
