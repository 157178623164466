import {Carousel} from 'antd'
import styled from 'styled-components'

import {SecondaryColor, ThirdColor, BorderColor} from '../../styles/_theme'
import ImageComponent from '../images/ImageComponent'

export const Container = styled.div`
  display: inline-block;
  width: 100%;
  background: white;
  padding: 15px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  flex: 1;
`

export const ContainerTitle = styled.div`
  float: left;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  flex: 4;
`

export const SeeAll = styled.div`
  float: right;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: ${SecondaryColor};
  cursor: pointer;
  flex: 2;
  text-align: right;
`

export const StyledCarousel = styled(Carousel)`
  display: inline-block;
  width: 100%;
  margin-top: 20px;

  &&& {
    .slick-dots {
      align-items: center;
      height: 12px;
      margin-bottom: 0;
    }
    .slick-dots-bottom {
      bottom: 0;
    }

    .slick-dots li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 0 4px;

      button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: ${BorderColor};
        opacity: 1;
      }

      &.slick-active {
        width: 10px;
        height: 10px;

        button {
          width: 10px;
          height: 10px;
          background: ${ThirdColor};
        }
      }
    }
  }
`

export const CarouselCard = styled.div`
  display: inline-block;
  width: 100%;
  border-radius: 5px;
  padding-bottom: 25px;
  cursor: pointer;
`

export const CardImage = styled(ImageComponent)`
  height: 220px;
`

export const CardTitle = styled.div`
  margin-top: 5px;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
export const KudosMessage = styled.div`
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  position: absolute;
  bottom: 0px;
  color: white;
  padding: 10px;
  width: 100%;
`

export const H5PJourneyDetail = styled.div`
  display: flex;
  overflow: hidden;
  height: 220px;

  &&& iframe {
    width: 100%;
    height: auto;
  }
`
