import styled from 'styled-components'

import {white, lightBlue, DarkGrey, ThirdColor} from '../../styles/_theme'

export const StyledNotFoundContainer = styled.div`
  width: 100%;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${lightBlue};
`

export const StyledNotFoundGroup = styled.div`
  max-width: 920px;
  height: 600px;
  position: relative;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 190px;
  margin: 40px;
`

export const StyledTitle = styled.h2`
  font-size: 26px;
  font-weight: 800;
  line-height: 1.23;
  margin-bottom: 25px;
  color: ${ThirdColor};
`

export const StyledDescription = styled.h5`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;
  color: ${DarkGrey};
  text-align: center;
`

export const StyledCompanyLogo = styled.img`
  display: inline-block;
  width: 120px;
  margin-bottom: 60px;
`
