import client from '../config/AxiosConfig'
import {
  BuildSendKudosToFormData,
  BuildGroupFormData
} from './TransformToFormData'

export const GetChannels = async () => client.get('/community')

export const GetPrivateMessages = async (id, page, limit) =>
  client.get(`/messages/${id}/${page}/${limit}`)

export const SendPrivateMessage = async (fields) =>
  client.post('/messages', fields)

export const GetChannelMessages = async (id, page, limit) =>
  client.get(`/channels/${id}/messages/${page}/${limit}`)

export const SendChannelMessage = async (id, fields) =>
  client.post(`/channels/${id}`, fields)

export const GetGeneralMessages = async (id, page, limit) =>
  client.get(`/general-chat/${id}/${page}/${limit}`)

export const SendGeneralMessage = async (fields) =>
  client.post('/general-chat', fields)

export const GetChannelInfo = async (id) => client.get(`/channels/${id}`)

export const GetGeneralInfo = async (id) =>
  client.get(`/general-chat/${id}`)

export const GetColleagues = async (page, limit, searchValue) =>
  client.get(
    `/peers/colleagues/${page}/${limit}${
      searchValue ? `?search=${searchValue}` : ''
    }`
  )

export const GetPeer = async (id) => client.get(`/peers/${id}`)

export const GetFriends = async () => client.get('/friends')

export const DeleteFriend = async (id) => client.delete(`/friends/${id}`)

export const AcceptFriend = async (id, fields) =>
  client.put(`/friends/${id}`, fields)

export const InviteFriend = async (fields) =>
  client.post('/friends', fields)

export const RemoveFriendRequest = async (id) =>
  client.delete(`/friends/request/${id}`)

export const GetShare = async () => client.get('/share')

export const Share = async (fields) => client.post('/share', fields)

export const GetKudos = async () => client.get('/kudos')

export const SendKudos = async (data) => {
  const payload = BuildSendKudosToFormData(data)
  return client.post('/kudos', payload)
}

export const CreateChannel = async (groupData) => {
  const data = BuildGroupFormData(groupData, true)
  return client.post('/channels', data, {
    headers: {
      common: {'Content-Type': 'multipart/form-data'}
    }
  })
}

export const EditChannel = async (id, groupData) => {
  const data = BuildGroupFormData(groupData)
  return client.put(`/channels/${id}`, data, {
    headers: {
      common: {'Content-Type': 'multipart/form-data'}
    }
  })
}

export const InviteUsers = async (id, fields) =>
  client.put(`/channels/${id}/users/add`, fields)

export const GetChannelPeersList = async (id, page, limit) =>
  client.get(`/channels/${id}/peers/${page}/${limit}`)

export const RemoveUsers = async (id, fields) =>
  client.put(`/channels/${id}/users/delete`, fields)

export const LeaveChannel = async (id) =>
  client.delete(`/channels/${id}/leave`)
