import React, {useState, useEffect} from 'react'

import {notification} from 'antd'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {
  GetFriends,
  DeleteFriend,
  RemoveFriendRequest,
  AcceptFriend
} from '../../infra/requests/CommunityRequests'
import {updateFriends} from '../../redux/data/friends/FriendsActions'
import {removeNotification} from '../../redux/data/user/UserActions'
import {
  GetAndUpdateFriends,
  GetAndUpdateChats,
  GetAndUpdateActivity
} from '../../redux/thunks/UpdateThunk'
import {LightGey} from '../../styles/_theme'
import {LoadingSpin} from '../../styles/BasicStyles'
import BaseButton from '../buttons/BaseButton'
import {
  ActiveButtonGroup,
  ButtonGroupFloating
} from '../buttons/ButtonsStyles'
import EmptyLayoutComponent from '../empty_layout/EmptyLayoutComponent'
import PeerBlockComponent from '../peer_block/PeerBlockComponent'
import {TitleSection} from './CommunityStyles'
import InviteFriendModal from './InviteFriendModal'

const FriendsComponent = ({
  translate,
  user,
  friends,
  dispatch,
  onClickChat
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [inviteFriendModal, setInviteFriendModal] = useState(false)
  const [hasFetchFriends, setHasFetchFriends] = useState(false)
  // const permissions =
  //   !user.roles || !user.roles.filter((r) => r.role === 7).length
  //     ? false
  //     : 1
  const subscription = user.subscription ? user.subscription : false
  // const subscription = user.subscription ? user.subscription : 2;

  useEffect(() => {
    const getFriends = async () => {
      try {
        setIsLoading(true)
        const result = await GetFriends()
        setHasFetchFriends(true)
        dispatch(updateFriends(result.data))
      } catch (error) {
        console.warn(error)
      } finally {
        setIsLoading(false)
      }
    }

    dispatch(removeNotification('NEW_FRIEND_INVITE'))
    dispatch(removeNotification('FRIEND_ACCEPTED'))
    dispatch(removeNotification('FRIEND_REJECTED'))

    if (!isLoading && !hasFetchFriends) getFriends()
  }, [isLoading, dispatch, hasFetchFriends])

  const onPressItem = (peer) => {
    if (peer.invite) return
    onClickChat({
      type: 'private',
      user: peer._id,
      name: peer.name
    })
  }

  const onAcceptPeer = async (peer, accept) => {
    if (
      (!subscription && (!friends?.friends || !friends?.friends.length)) ||
      (subscription &&
        subscription === 1 &&
        friends?.friends &&
        friends?.friends.length < 5) ||
      (subscription &&
        subscription === 2 &&
        friends?.friends &&
        friends?.friends.length < 25) ||
      !accept
    ) {
      try {
        await AcceptFriend(peer.invite, {
          accepted: accept
        })
        dispatch(GetAndUpdateFriends())
        dispatch(GetAndUpdateActivity())
      } catch (error) {
        console.warn(error)
      }
    }
  }

  const deletePeer = async (peer) => {
    try {
      await DeleteFriend(peer._id)
      dispatch(GetAndUpdateFriends())
      dispatch(GetAndUpdateChats())
    } catch (error) {
      console.warn(error)
    }
  }

  const removeFriendRequest = async (peer) => {
    try {
      await RemoveFriendRequest(peer.invite)
      dispatch(GetAndUpdateFriends())
    } catch (error) {
      console.warn(error)
    }
  }

  const openNotification = () => {
    notification.open({
      description: translate('INVITE_FRIEND_WARNING')
    })
  }
  const renderList = (peers, type) =>
    peers.map((item, index) => (
      <div key={index}>
        {type === 'invited' ? (
          <PeerBlockComponent
            key={index}
            item={item}
            isInvited
            bgColor={LightGey}
            marginB='8px'
            onPressItem={onPressItem}
            cancelPeer={(peer) => onAcceptPeer(peer, false)}
            acceptPeer={(peer) => onAcceptPeer(peer, true)}
          />
        ) : type === 'pending' ? (
          <PeerBlockComponent
            key={index}
            item={item}
            isInvited
            bgColor={LightGey}
            marginB='8px'
            onPressItem={onPressItem}
            cancelPeer={(peer) => removeFriendRequest(peer)}
          />
        ) : (
          <PeerBlockComponent
            key={index}
            item={item}
            bgColor={LightGey}
            marginB='8px'
            onPressItem={onPressItem}
            deletePeer={(peer) => deletePeer(peer)}
          />
        )}
      </div>
    ))

  const onPressInvitePeer = () => {
    if (
      (!subscription &&
        (!friends?.friends || !friends?.friends.length) &&
        (!friends?.pending || !friends?.pending.length) &&
        (!friends?.invited || !friends?.invited.length)) ||
      (subscription &&
        subscription === 1 &&
        friends?.friends &&
        friends?.friends.length < 5) ||
      (subscription &&
        subscription === 2 &&
        friends?.friends &&
        friends?.friends.length < 25)
    ) {
      setInviteFriendModal(true)
    } else {
      openNotification()
    }
  }

  return (
    <div
      style={{
        width: '100%',
        flex: 1
      }}
    >
      {isLoading && <LoadingSpin min='200px' />}
      {friends ? (
        <>
          {friends && friends.friends && friends.friends.length > 0 && (
            <>
              <TitleSection margin='22px 14px 6px'>
                {translate('YOUR_PEERS')}
              </TitleSection>
              {renderList(friends.friends, 'friends')}
            </>
          )}
          {friends && friends.invited && friends.invited.length > 0 && (
            <>
              <TitleSection margin='22px 14px 6px'>
                {translate('INVITED')}
              </TitleSection>
              {renderList(friends.invited, 'invited')}
            </>
          )}
          {friends && friends.pending && friends.pending.length > 0 && (
            <>
              <TitleSection margin='22px 14px 6px'>
                {translate('PENDING')}
              </TitleSection>
              {renderList(friends.pending, 'pending')}
            </>
          )}
        </>
      ) : (
        <EmptyLayoutComponent tag='EMPTY_PEERS' />
      )}
      {/* {((!subscription && (!data?.friends || !data?.friends.length)) ||
        (subscription &&
          subscription === 1 &&
          data?.friends &&
          data?.friends.length < 5) ||
        (subscription &&
          subscription === 2 &&
          data?.friends &&
          data?.friends.length < 25)) && ( */}
      <ButtonGroupFloating>
        <ActiveButtonGroup>
          <BaseButton
            type='button'
            onClick={onPressInvitePeer}
            label={translate('INVITE_A_PEER')}
            category='secondary'
          />
        </ActiveButtonGroup>
      </ButtonGroupFloating>
      <InviteFriendModal
        open={inviteFriendModal}
        onClose={() => setInviteFriendModal(false)}
        onSave={() => setInviteFriendModal(false)}
        dispatch={dispatch}
      />
      {/* )} */}
    </div>
  )
}

FriendsComponent.propTypes = {
  user: PropTypes.object.isRequired,
  onClickChat: PropTypes.func,
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  friends: PropTypes.object
}

FriendsComponent.defaultProps = {
  onClickChat: undefined,
  friends: undefined
}

const mapStateToProps = (state) => ({
  user: state.user,
  friends: state.friends
})

const mapActionToProps = (dispatch) => ({
  dispatch
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(FriendsComponent)
)
