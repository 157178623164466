import React from 'react'

import {PropTypes} from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import {withLocalize} from 'react-localize-redux'

import {SearchPeers} from '../../infra/requests/JourneyRequests'
import {LightGey} from '../../styles/_theme'
import {
  LoadingSpin,
  EmptyLayout,
  EmptyText
} from '../../styles/BasicStyles'
import PeerBlockComponent from '../peer_block/PeerBlockComponent'

const limit = 20
class PeersList extends React.Component {
  constructor(props) {
    super(props)
    this.scrollComponent = null
    this.state = {
      isLoading: false,
      peers: [],
      page: 1,
      hasMore: true
    }
  }

  componentDidMount = () => {
    this.getPeers()
  }

  getPeers = async () => {
    const {page, peers, isLoading, hasMore} = this.state
    if (isLoading || !hasMore) return
    this.setState({isLoading: true})

    try {
      const result = await SearchPeers(page, limit)
      this.setState({isLoading: false})
      if (result.success) {
        this.setState({
          peers: [...peers, ...result.data],
          page: page + 1
        })
      }
      if (!result.data || !result.data.length) {
        this.setState({hasMore: false})
      }
    } catch (error) {
      console.warn(error)
    }
  }

  render() {
    const {selectedPeers, onPressListItem, translate} = this.props
    const {peers, hasMore} = this.state
    return (
      <>
        {peers && peers.length > 0 ? (
          <div style={{height: peers.length * 68, overflow: 'hidden'}}>
            <InfiniteScroll
              pageStart={0}
              loadMore={this.getPeers}
              hasMore={hasMore}
              loader={<LoadingSpin min='200px' />}
              useWindow={false}
            >
              {peers.map((item, index) => (
                <PeerBlockComponent
                  key={index}
                  index={index}
                  item={item}
                  onPressItem={(peer) => onPressListItem(peer)}
                  bgColor={LightGey}
                  selectedPeers={selectedPeers}
                  marginB='6px'
                />
              ))}
            </InfiniteScroll>
          </div>
        ) : (
          <EmptyLayout>
            <EmptyText>{translate('EMPTY_PEERS_FEEDBACK')}</EmptyText>
          </EmptyLayout>
        )}
      </>
    )
  }
}
PeersList.propTypes = {
  translate: PropTypes.func.isRequired,
  selectedPeers: PropTypes.array,
  onPressListItem: PropTypes.func
}

PeersList.defaultProps = {
  selectedPeers: undefined,
  onPressListItem: undefined
}

export default withLocalize(PeersList)
