import React from 'react'

import {PropTypes} from 'prop-types'

import {DarkGey, DarkGrey} from '../../styles/_theme'
import {MainText} from '../../styles/BasicStyles'
import LikertRatingComponent from '../review_components/LikertRatingComponent'
import PointRatingComponent from '../review_components/PointRatingComponent'
import StarRatingComponent from '../review_components/StarRatingComponent'

const QuestionsComponent = ({questions, isShare, isUser}) => (
  <div>
    {questions && questions.question1 && questions.question1.response && (
      <MainText
        color={isShare && !isUser ? DarkGey : DarkGrey}
        style={{
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden'
        }}
      >
        {questions.question1.response}
      </MainText>
    )}
    {questions && questions.question3 && questions.question3.response && (
      <div>
        {questions.question3.type === 3 ? (
          <StarRatingComponent
            noEditable
            input={{value: questions.question3.response}}
            spacet={12}
          />
        ) : questions.question3.type === 6 ? (
          <PointRatingComponent
            noEditable
            input={{value: questions.question3.response}}
            spacet={12}
            isCard
          />
        ) : (
          <LikertRatingComponent
            noEditable
            input={{value: questions.question3.response}}
            spacet={12}
          />
        )}
      </div>
    )}
  </div>
)

QuestionsComponent.propTypes = {
  questions: PropTypes.object.isRequired,
  isUser: PropTypes.bool,
  isShare: PropTypes.bool
}

QuestionsComponent.defaultProps = {
  isShare: false,
  isUser: false
}

export default QuestionsComponent
