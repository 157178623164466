import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import {localizeReducer} from 'react-localize-redux'

import ActivityReducer from './data/activity/ActivityReducer'
import ArchivedJourneysReducer from './data/archived_journeys/ArchivedJourneysReducer'
import ChatReducer from './data/chat/ChatReducer'
import ChatsReducer from './data/chats/ChatsReducer'
import FeedReducer from './data/feed/FeedReducer'
import FriendsReducer from './data/friends/FriendsReducer'
import InactiveJourneysReducer from './data/inactive_journeys/InactiveJourneysReducer'
import InfoReducer from './data/info/InfoReducer'
import KudosReducer from './data/kudos/KudosReducer'
import LatestKudosReducer from './data/latest_kudos/LatestKudosReducer'
import ModalReducer from './data/modal/ModalReducer'
import SuggestedJourneysReducer from './data/suggested_journeys/SuggestedJourneysReducer'
import UserReducer from './data/user/UserReducer'

const RootReducer = {
  localize: localizeReducer,
  user: UserReducer,
  info: InfoReducer,
  chat: ChatReducer,
  friends: FriendsReducer,
  feed: FeedReducer,
  chats: ChatsReducer,
  inactive_journeys: InactiveJourneysReducer,
  archived_journeys: ArchivedJourneysReducer,
  suggested_journeys: SuggestedJourneysReducer,
  modal: ModalReducer,
  kudos: KudosReducer,
  activity: ActivityReducer,
  latest_kudos: LatestKudosReducer
}

const ReduxMiddlewares = [
  ...getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  })
]

const ReduxStore = configureStore({
  reducer: RootReducer,
  middleware: ReduxMiddlewares,
  devTools: process.env.NODE_ENV !== 'production'
})

export default ReduxStore
