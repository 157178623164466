import React from 'react'

import PropTypes from 'prop-types'

import {ReactComponent as Shape} from '../../assets/icons/shape_white.svg'
import {StyledButton} from './ButtonsStyles'

const BaseButton = ({
  type,
  category,
  label,
  onClick,
  disabled,
  loading,
  className,
  style,
  icon,
  spaceR
}) => (
  <StyledButton
    className={className}
    category={category}
    type={type}
    onClick={onClick}
    disabled={disabled || loading}
    style={style}
    spaceR={spaceR}
  >
    {label}
    {icon && <Shape style={{marginLeft: 30}} />}
  </StyledButton>
)

BaseButton.propTypes = {
  category: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'tertiary',
    'lined',
    'enter',
    'error',
    'success'
  ]),
  type: PropTypes.oneOf(['reset', 'submit', 'button']),
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  style: PropTypes.object,
  icon: PropTypes.bool,
  spaceR: PropTypes.number
}

BaseButton.defaultProps = {
  category: 'primary',
  type: 'button',
  onClick: undefined,
  disabled: false,
  loading: false,
  className: undefined,
  style: undefined,
  icon: false,
  spaceR: undefined
}

export default BaseButton
