import {Modal as AntdModal} from 'antd'
import styled from 'styled-components'

import {
  ThirdColor,
  LightGey,
  DarkGrey,
  PrimaryColor,
  lightBlue2
} from '../../styles/_theme'
import device from '../../styles/Responsive'
import ImageComponent from '../images/ImageComponent'

export const Modal = styled(AntdModal)`
  max-width: 1220px;

  &.isFull {
    .ant-modal-body {
      background-color: ${LightGey};
    }
  }
  &&& {
    .ant-modal-body {
      padding: ${({padding}) => padding || '24px'};
      max-height: calc(100vh - 200px);
      overflow-y: auto;
    }
    .ant-modal-close {
      top: 0;
      right: -50px;
      padding: 0;
      border-radius: 50%;
      background: white;

      .ant-modal-close-x {
        width: 44px;
        height: 44px;
        align-items: center;
        justify-content: center;
        display: flex;

        &:hover,
        &:visited,
        &:active {
          svg path {
            fill: ${PrimaryColor};
          }
        }
      }
    }
  }
`

export const ModalHeader = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 40px;
`

export const ModalTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  color: ${ThirdColor};
  float: left;
  line-height: 44px;
`

export const ModalAction = styled.div`
  float: right;
`

export const UserBackground = styled.div`
  width: 100%;
  height: 140px;
  background-color: ${({color}) => color || PrimaryColor};
`

export const JourneyImage = styled(ImageComponent)`
  width: 100%;
  height: 300px;
  ratio: 0;
  background-color: ${LightGey};
`

export const ModalContainer = styled.div`
  padding: 20px 30px 30px;
`

export const UserDescription = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  padding: 22px 20px 70px;
  color: ${DarkGrey};
`

export const UserCard = styled.div`
  background: white;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`

export const JourneyLabel = styled.div`
  height: 22px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
`

export const UserImageContainer = styled.div`
  position: absolute;
  top: 70px;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const UserImage = styled(ImageComponent)`
  height: 140px;
  width: 140px;
  border-radius: 50%;
  border: 4px solid #fff;
  overflow: hidden;
  background-color: ${LightGey};
`

export const UserBtns = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: -25px;
`

export const RoundBtn = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${PrimaryColor};
  width: 50px;
  height: 50px;
  margin: ${({margin}) => margin || '0px'};

  &:hover,
  &:visited,
  &:active {
    background-color: ${lightBlue2};

    svg path {
      fill: ${PrimaryColor};
    }
  }
`
export const UserBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${device.laptop} {
    display: block;
  }
`

export const InformationPage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${LightGey};
  height: fit-content;
`

export const H5P = styled.div`
  display: flex;

  &&& iframe {
    position: absolute;
    top: 0px;
    width: 100%;
  }
`

export const H5PDisabled = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;

  &&& iframe {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
  }

  &&& .H5PTop {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding-horizontal: 10px;
    z-index: 10;
    box-shadow: inset 0px -130px 60px -60px #000000;
    display: flex;
  }
`
