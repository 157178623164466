import {Drawer} from 'antd'
import styled from 'styled-components'

import {NavbarSize} from '../../styles/_theme'

export const DrawerWindow = styled(Drawer)`
  top: ${NavbarSize};

  &&& {
    .ant-drawer-header {
      border-bottom: none;
      .ant-drawer-title {
        font-size: 22px;
        line-height: 26px;
        font-weight: bold;
      }
    }

    .ant-drawer-body {
      padding: 0 24px;
    }
  }
`

export default DrawerWindow
