import {Modal as AntdModal} from 'antd'
import styled from 'styled-components'

import {ThirdColor, DarkGrey, BorderColor} from '../../../styles/_theme'

export const ModalContainer = styled(AntdModal)`
  position: absolute;
  padding: 0px;
  top: 70px;
  right: 38px;
  box-shadow: 0 10px 30px -4px rgba(0, 0, 0, 0.3);
`

export const Button = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ButtonColumn = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: ${({spaceT}) => spaceT || 0}px;
  align-items: center;
`

export const IconContainer = styled.div`
  background-color: ${ThirdColor};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Title = styled.div`
  font-size: 16px;
  line-height: 1.38;
  font-weight: 500;
  color: ${DarkGrey};
  margin-left: ${({spaceL}) => spaceL || 0}px;
  margin-top: ${({spaceT}) => spaceT || 0}px;
  margin-bottom: ${({spaceB}) => spaceB || 0}px;
`
export const Margin = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${BorderColor};
  margin-top: 16px;
  margin-bottom: 16px;
`
