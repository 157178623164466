import React, {useState} from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {ReactComponent as CloseIcon} from '../../assets/icons/ic_close_blue.svg'
import BaseButton from '../../components/buttons/BaseButton'
import {
  Modal,
  ModalContainer
} from '../../components/modal/BaseModalStyles'
import {
  EditJourney,
  CreateJourney,
  SetJourneyActive
} from '../../infra/requests/JourneyRequests'
import {GetValuePreview} from '../../infra/utils/CommonFunctions'
import {updateActiveJourney} from '../../redux/data/user/UserActions'
import {GetAndUpdateInactiveJourneys} from '../../redux/thunks/UpdateThunk'
import {ErrorColor} from '../../styles/_theme'
import {JourneyHeaderStepsGroup} from '../journeys/JourneyStyles'
import {JourneyFormContent} from './CreateJourneyStyles'
import InitialJourneyData from './InitialJourneyData'
import JourneyStep1 from './JourneyStep1'
import JourneyStep2 from './JourneyStep2'
import JourneyStep3 from './JourneyStep3'
import Wizard from './Wizard'

const JourneyForm = ({
  translate,
  activeLanguage,
  journey,
  lang,
  router,
  dispatch,
  edit,
  onClickBack,
  activeJourney,
  inactiveJourneys
}) => {
  const languageCode = lang || activeLanguage.code
  const [errorMessage, setErrorMessage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const initialValues = journey
    ? edit
      ? {
          ...InitialJourneyData,
          from: journey.from,
          title: journey.title,
          goal: journey.goal,
          intention: journey.intention,
          review_weeks: journey.review_weeks,
          reminder: {
            ...journey.reminder,
            time: moment(journey.reminder.time, 'hh:mm')
          },
          privacy: journey.privacy,
          type: journey.type,
          steps: journey.steps,
          guided_steps: journey.guided_steps
        }
      : {
          ...InitialJourneyData,
          from: journey._id,
          title: GetValuePreview(journey.title, languageCode),
          goal: GetValuePreview(journey.goal, languageCode),
          intention: GetValuePreview(
            journey.intention,
            languageCode
          ),
          review_weeks: journey.review_weeks,
          type: 'guided',
          ignored: []
        }
    : {
        ...InitialJourneyData,
        type: 'custom'
      }

  const onSubmit = async (fields) => {
    try {
      if (edit) {
        const result = await EditJourney(journey._id, {
          ...fields,
          title: fields.goal
        })
        dispatch(
          updateActiveJourney({active_journey: result.data, translate})
        )
        onClickBack()
      } else {
        const result = await CreateJourney({
          ...fields,
          title: fields.goal
        })
        if (result.success) {
          dispatch(
            updateActiveJourney({active_journey: result.data, translate})
          )
          dispatch(GetAndUpdateInactiveJourneys())
          router.history.push('/active-journey')
        } else if (result.message) {
          setErrorMessage(result.message)
        }
      }
    } catch (e) {
      console.warn(e)
    }
  }

  const onPressActivateJourney = async (item) => {
    setIsLoading(true)
    try {
      let existJourney = activeJourney.from === journey._id || undefined
      if (!existJourney) {
        existJourney = inactiveJourneys.find(
          (ij) => ij.from === journey._id
        )
        if (existJourney?._id) {
          const result = await SetJourneyActive(existJourney._id)
          dispatch(
            updateActiveJourney({active_journey: result.data, translate})
          )
          dispatch(GetAndUpdateInactiveJourneys())
        }
      }
      setErrorMessage(false)
      router.history.push('/active-journey')
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <JourneyFormContent>
      <Modal
        padding='0px'
        visible={!!errorMessage}
        onCancel={() => setErrorMessage(false)}
        width={400}
        footer={null}
        closeIcon={<CloseIcon />}
      >
        <ModalContainer>
          <JourneyHeaderStepsGroup
            spaceT={10}
            spaceB={20}
            color={ErrorColor}
          >
            {errorMessage && translate(errorMessage)}
          </JourneyHeaderStepsGroup>
          <BaseButton
            loading={isLoading}
            type='button'
            onClick={onPressActivateJourney}
            label={translate('OPEN_MY_JOURNEY')}
            category='secondary'
            icon
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          />
        </ModalContainer>
      </Modal>
      <Wizard
        initialValues={initialValues}
        onSubmit={onSubmit}
        router={router}
        onClickBack={onClickBack}
        edit={edit}
        journeyType={edit ? journey.type : journey ? 'guided' : 'custom'}
      >
        <JourneyStep1
          type={edit ? journey.type : journey ? 'guided' : 'custom'}
        />
        <JourneyStep2
          type={edit ? journey.type : journey ? 'guided' : 'custom'}
        />
        <JourneyStep3 journey={journey} edit={edit} />
      </Wizard>
    </JourneyFormContent>
  )
}

JourneyForm.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  journey: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object,
  edit: PropTypes.bool,
  onClickBack: PropTypes.func,
  activeJourney: PropTypes.object.isRequired,
  inactiveJourneys: PropTypes.array.isRequired
}

JourneyForm.defaultProps = {
  journey: undefined,
  edit: false,
  router: undefined,
  onClickBack: undefined
}

const mapActionToProps = (dispatch) => ({dispatch})

const mapStateToProps = (state) => ({
  activeJourney: state.user.active_journey,
  inactiveJourneys: state.inactive_journeys
})

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(JourneyForm)
)
