import React from 'react'

import moment from 'moment'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useDispatch} from 'react-redux'

import Unbookmarked from '../../assets/icons/ic_bookmark.svg'
import CloseIcon from '../../assets/icons/ic_close_white.svg'
import Bookmarked from '../../assets/icons/ic_plus.svg'
import {updateModal} from '../../redux/data/modal/ModalActions'
import {white, DarkGrey} from '../../styles/_theme'
import {MainText, ClickableBox} from '../../styles/BasicStyles'
import SquareButton from '../buttons/SquareButton'
import PeerBlockComponent from '../peer_block/PeerBlockComponent'
import ShareComponent from '../share/ShareComponent'
import {
  CardContainer,
  CardTimeHeader,
  CardTask,
  CardBottom
} from './CardsStyles'

const RequestFeedbackCard = ({
  item,
  onPress,
  onPressBookmarkFeedback,
  translate,
  marginH,
  isFeed,
  onClose
}) => {
  const dispatch = useDispatch()

  const header =
    item.task && item.task.name
      ? item.task.name
      : item.step
      ? item.step
      : false

  return (
    <CardContainer
      marginH={marginH}
      isFeed={isFeed}
      style={{flex: isFeed ? 1 : 0}}
      onClick={() =>
        onPress
          ? onPress()
          : dispatch(updateModal({type: 'respond_feedback', object: item}))
      }
    >
      {(header || onClose) && (
        <CardTask>
          {header && `${translate('FROM')} ${header}`}
          {onClose && (
            <>
              {!header && <div />}
              <ClickableBox
                onClick={(e) => {
                  e.stopPropagation()
                  onClose(item)
                }}
              >
                <img src={CloseIcon} alt='close' />
              </ClickableBox>
            </>
          )}
        </CardTask>
      )}
      <CardTimeHeader>{moment(item.createdAt).fromNow()}</CardTimeHeader>
      <PeerBlockComponent
        noPadding
        item={item.from || item.email}
        isEmail={!item.from && item.email}
        bgColor='transparent'
      />
      <MainText
        margin='12px 11px 28px'
        style={{
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden'
        }}
        color={DarkGrey}
      >
        {item.message}
      </MainText>
      {onPressBookmarkFeedback && (
        <CardBottom>
          <SquareButton onClick={() => onPressBookmarkFeedback(item)}>
            <MainText color={white}>{translate('BOOKMARK')}</MainText>
            <img
              style={{marginLeft: 8}}
              src={item.bookmarked ? Bookmarked : Unbookmarked}
              alt='Bookmark'
            />
          </SquareButton>
          <ShareComponent
            item={{
              to: item.to
                ? {
                    photo: item.to.photo,
                    company_role: item.to.company_role
                      ? item.to.company_role
                      : '',
                    name: item.to.name
                  }
                : item.email || false,
              message: item.message,
              questions: item.questions
            }}
            type='feedback'
          />
        </CardBottom>
      )}
    </CardContainer>
  )
}

RequestFeedbackCard.propTypes = {
  item: PropTypes.object.isRequired,
  onPressBookmarkFeedback: PropTypes.func,
  onPress: PropTypes.func,
  translate: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  isFeed: PropTypes.bool,
  marginH: PropTypes.number
}

RequestFeedbackCard.defaultProps = {
  onPress: undefined,
  onPressBookmarkFeedback: undefined,
  onClose: undefined,
  isFeed: false,
  marginH: 0
}

export default withLocalize(RequestFeedbackCard)
