import React from 'react'

import moment from 'moment'
import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector, useDispatch} from 'react-redux'

import IconPlay from '../../assets/icons/ic_play.svg'
import BuildFileUrl from '../../infra/services/files/FileService'
import {
  GetLanguageValue
} from '../../infra/services/translations/TranslationsService'
import {
  questionsByType,
  GetTask,
  GetValuePreview
} from '../../infra/utils/CommonFunctions'
import {updateModal} from '../../redux/data/modal/ModalActions'
import {white} from '../../styles/_theme'
import {WhiteLayout, VideoBox2, VideoBox} from '../../styles/BasicStyles'
import {H5PDisabled} from '../modal/BaseModalStyles'
import {
  CardContainer,
  CardTimeHeader,
  CardTask,
  CardImage,
  CardTop,
  Shadow,
  CardTaskText
} from './CardsStyles'
import QuestionsComponent from './QuestionsComponent'

const ReflectionCard = ({
  item,
  onPressItem,
  translate,
  activeLanguage,
  isShare,
  isUser,
  bgColor,
  isCompleted
}) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const activeJourney = user?.active_journey

  const questions = questionsByType(
    item.response ? item.response.questions : item.questions
  )

  const task = GetTask(item.task_id, activeJourney)
  const header =
    item.task_id && task
      ? GetValuePreview(task.title, activeLanguage?.code)
      : item.step
      ? item.step
      : false

  const media =
    item.attachments &&
    item.attachments.find((i) => i.type === 'image' || i.type === 'video')
      ? item.attachments.find(
          (i) => i.type === 'image' || i.type === 'video'
        )
      : item.questions && item.questions.find((i) => i.type === 5)
      ? item.questions.find((i) => i.type === 5).response
      : task && task.media
      ? task.media
      : isShare && item.media
      ? item.media
      : false

  const renderImage = () => {
    switch (media?.type) {
      case 'image': {
        const image = {
          url: BuildFileUrl(
            media.media
              ? media.media._id
              : media.file
              ? media.file._id
              : false
          ),
          color: '#fff'
        }
        return <CardImage image={image} shadow={!isShare} />
      }
      case 'images':
        return <CardImage image={media.file} shadow={!isShare} />
      case 'videos': {
        const image = {
          url: GetLanguageValue(media.file.thumbnail, activeLanguage.code),
          color: '#fff'
        }
        return (
          <VideoBox>
            <img
              src={IconPlay}
              alt='video'
              style={{position: 'absolute', zIndex: 1}}
            />
            <CardImage image={image} shadow={!isShare} />{' '}
          </VideoBox>
        )
      }
      case 'h5p':
      case 'embedded': {
        const file = GetLanguageValue(
          media.file.iframe,
          activeLanguage.code
        )
        return (
          <H5PDisabled
            style={{
              height: 140
            }}
            dangerouslySetInnerHTML={{
              __html: `${file}<div class="H5PTop" />`
            }}
          />
        )
      }
      case 'video': {
        const image = BuildFileUrl(
          media.media
            ? media.media._id
            : media.file
            ? media.file._id
            : false
        )

        return (
          <VideoBox2>
            <img
              src={IconPlay}
              alt='video'
              style={{position: 'absolute'}}
            />
            <video
              style={{
                width: '100%'
              }}
            >
              <source src={image} type='video/mp4' />
            </video>
          </VideoBox2>
        )
      }
      default:
        return null
    }
  }

  const renderMedia = () => (
    <CardTop>
      {renderImage()}
      {!isShare && (
        <CardTimeHeader color={white} isAbsolute>
          {moment(item.updatedAt).fromNow()}
        </CardTimeHeader>
      )}
      <Shadow />
    </CardTop>
  )

  return (
    <CardContainer
      isFeed={isShare}
      isShare={isShare}
      $color={isShare ? 'rgba(211, 219, 242, 0.4)' : false}
      onClick={() =>
        onPressItem
          ? onPressItem()
          : dispatch(
              updateModal({
                type: 'reflection',
                task,
                object: item,
                isShare: isShare || isCompleted,
                isFeed: isShare
              })
            )
      }
    >
      {header && header !== 'undefined' && header !== 'null' && (
        <CardTask style={{marginBottom: 0}}>
          <CardTaskText>
            {translate('FROM')} {header}
          </CardTaskText>
        </CardTask>
      )}
      {renderMedia()}

      <WhiteLayout
        space='12px 11px'
        spaceB={isShare ? 0 : 28}
        style={{backgroundColor: bgColor || white}}
      >
        <QuestionsComponent
          questions={questions}
          isShare={isShare}
          isUser={isUser}
        />
      </WhiteLayout>
    </CardContainer>
  )
}

ReflectionCard.propTypes = {
  item: PropTypes.object.isRequired,
  onPressItem: PropTypes.func,
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  isShare: PropTypes.bool,
  isUser: PropTypes.bool,
  bgColor: PropTypes.string,
  isCompleted: PropTypes.bool
}

ReflectionCard.defaultProps = {
  onPressItem: undefined,
  isShare: false,
  isUser: false,
  bgColor: undefined,
  isCompleted: false
}

export default withLocalize(ReflectionCard)
