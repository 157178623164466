import React, {useState, useEffect} from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useLocation} from 'react-router-dom'

import ArrowIcon from '../../assets/icons/ic_chevron_right.svg'
import {GetGuidedJourneys} from '../../infra/requests/JourneyRequests'
import ArchivedJourneys from './ArchivedJourneys'
import InactiveJourneys from './InactiveJourneys'
import {
  InformationPage,
  JourneyLeftContent,
  SectionTitle,
  OptionsList,
  OptionBtn
} from './JourneyStyles'
import MainJourneys from './MainJourneys'
import SuggestedJourneys from './SuggestedJourneys'

const JourneysPage = ({
  translate,
  router,
  inactiveJourneys,
  archivedJourneys
}) => {
  const location = useLocation()
  const [section, setSection] = useState(
    location?.hash ? location.hash.replace('#', '') : 'overview'
  )
  const [suggested, setSuggested] = useState(0)
  const [hasFetchSuggested, setHasFetchSuggested] = useState(false)

  useEffect(() => {
    setSection(
      location?.hash ? location.hash.replace('#', '') : 'overview'
    )
    const getPeer = async () => {
      const result = await GetGuidedJourneys(1, 1)
      if (result?.success) {
        setSuggested(result.data.total)
        setHasFetchSuggested(true)
      }
    }

    if (!hasFetchSuggested) getPeer()
  }, [hasFetchSuggested, suggested, location])

  const renderContent = () => {
    switch (section) {
      case 'inactive_journeys':
        return <InactiveJourneys router={router} />
      case 'suggested_journeys':
        return <SuggestedJourneys router={router} />
      case 'archived_journeys':
        return <ArchivedJourneys router={router} />
      default:
        return <MainJourneys router={router} />
    }
  }

  return (
    <>
      <JourneyLeftContent>
        <SectionTitle>{translate('JOURNEYS')}</SectionTitle>
        <OptionsList>
          <OptionBtn
            onClick={() => {
              router.history.push('/journeys#overview')
              setSection('inactive_journeys')
            }}
            selected={section === 'overview'}
          >
            {translate('OVERVIEW')}
            <img src={ArrowIcon} alt='ArrowIcon' />
          </OptionBtn>
          <OptionBtn
            onClick={() => {
              router.history.push('/journeys#inactive_journeys')
              setSection('inactive_journeys')
            }}
            selected={section === 'inactive_journeys'}
          >
            {translate('INACTIVE_JOURNEYS')} ({inactiveJourneys?.length})
            <img src={ArrowIcon} alt='ArrowIcon' />
          </OptionBtn>
          <OptionBtn
            onClick={() => {
              router.history.push('/journeys#suggested_journeys')
              setSection('suggested_journeys')
            }}
            selected={section === 'suggested_journeys'}
          >
            {translate('SUGGESTED_JOURNEYS')} ({suggested})
            <img src={ArrowIcon} alt='ArrowIcon' />
          </OptionBtn>
          <OptionBtn
            onClick={() => {
              router.history.push('/journeys#archived_journeys')
              setSection('archived_journeys')
            }}
            selected={section === 'archived_journeys'}
          >
            {translate('ARCHIVED_JOURNEYS')} ({archivedJourneys?.length})
            <img src={ArrowIcon} alt='ArrowIcon' />
          </OptionBtn>
        </OptionsList>
      </JourneyLeftContent>
      <InformationPage>{renderContent()}</InformationPage>
    </>
  )
}

JourneysPage.propTypes = {
  translate: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  archivedJourneys: PropTypes.array,
  inactiveJourneys: PropTypes.array
}

JourneysPage.defaultProps = {
  archivedJourneys: undefined,
  inactiveJourneys: undefined
}

const mapStateToProps = (state) => ({
  journey: state.user.active_journey,
  archivedJourneys: state.archived_journeys,
  inactiveJourneys: state.inactive_journeys
})

export default withLocalize(connect(mapStateToProps, null)(JourneysPage))
