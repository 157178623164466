import FormValidator from '../../../infra/services/validations/FormValidator'

const ReflectionValidations = (values) => {
  const errors = FormValidator.make({
    questions: 'array'
  })(values)

  if (values.questions && values.questions.length > 0) {
    errors.questions = []
    values.questions.map((question, index) => {
      errors.questions[index] = FormValidator.make({
        response: 'required'
      })(question)
      return question
    })
  }
  return errors
}

export default ReflectionValidations
